import React from 'react';
import { Icon, useTheme } from '@fluentui/react';
import { EditorUi, FixedViewType, mx, mxglobals, ViewAlignment } from 'frames/TopFrame/drawing/common';
import { ICardInfo } from 'services/ICardInfo';
import { mxgraph } from 'ts-mxgraph-typings';
import { DashboardGraphService } from './DashboardGraphService';
import { TreeMouseListener } from '../../components/TreeMouseListener';
import { useEnvNavigate } from 'services/NavigationService';
import { EnvContext, isTeams } from 'services/EnvContext';
import { TreeService } from 'services/TreeService';
import { renderToString } from 'react-dom/server';
import { useZoomInfo } from 'services/useZoomInfo';

const pinHtml = renderToString(<Icon className='visto-tree-pin' iconName='Pin' />);
const unpinHtml = renderToString(<Icon className='visto-tree-pin' iconName='Unpin' />);

export const DashboardPlanTree = (props: {
  readOnly: boolean;
  zoomInfoKey: string;
  items: ICardInfo[];
  sort: (a: ICardInfo, b: ICardInfo) => number;
  setEditorUi: (editorUi: EditorUi) => void;
  onTogglePinned: (ci: ICardInfo) => void;
  onRemove: (ci: ICardInfo) => void;
  onConnect: (src: ICardInfo, dst: ICardInfo) => void;
  onDisconnect: (ci: ICardInfo, dst: ICardInfo) => void;
}) => {

  const theme = useTheme();

  const [zoomInfo] = useZoomInfo(props.zoomInfoKey);

  const tree = React.useRef<{ [key: string]: ICardInfo }>(null);

  const containerRef = React.useRef<HTMLDivElement>(null);
  const editorUiRef = React.useRef<any>(null);
  const mouseListenerRef = React.useRef<TreeMouseListener>(null);

  const { hostKind } = React.useContext(EnvContext);
  const navigate = useEnvNavigate(isTeams(hostKind));

  const getGraph = (editorUi: any) => {
    return editorUi.editor.graph as mxgraph.mxGraph;
  };

  const renderItem = (item: ICardInfo) => {

    return (
      `<div class='visto-tree-card'>` +
      `<div class='visto-tree-label'>` +
      `<div class='visto-tree-name'>${item.name}</div>` +
      `<div class='visto-tree-location'>${item.location}</div>` +
      `</div>` +
      `<div class='visto-tree-pin'>` +
      `${item.pinned ? unpinHtml : pinHtml}` +
      `</div>` +
      `</div>`
    );
  }

  React.useEffect(() => {
    if (containerRef.current) {
      const editorUi = DashboardGraphService.createTreeEditorUi(containerRef.current, theme, props.readOnly);
      const graph = getGraph(editorUi);
      if (props.readOnly) {
        mouseListenerRef.current = new TreeMouseListener(graph, theme);
        graph.addMouseListener(mouseListenerRef.current);

      } else {
        graph.addListener(mxglobals.mxEvent.CELL_CONNECTED, (sender, evt) => {

          var model = graph.getModel();
          var edge = evt.getProperty('edge');
          var src = model.getTerminal(edge, true);
          var trg = model.getTerminal(edge, false);
          var previous = evt.getProperty('previous');
  
          if (src && trg && previous) {
            props.onDisconnect(tree.current[DashboardGraphService.getCellKey(src)], tree.current[DashboardGraphService.getCellKey(previous)]);
            props.onConnect(tree.current[DashboardGraphService.getCellKey(src)], tree.current[DashboardGraphService.getCellKey(trg)]);
          }
        });

        graph.addListener(mxglobals.mxEvent.CELLS_ADDED, (sender, evt) => {

          var [edge] = evt.getProperty('cells');
          var source = graph.getModel().getTerminal(edge, true);
          var target = graph.getModel().getTerminal(edge, false);

          console.log('cells added', edge);

          if (source && target) {
            props.onConnect(tree.current[DashboardGraphService.getCellKey(source)], tree.current[DashboardGraphService.getCellKey(target)]);
          }
        });

        graph.addListener(mxglobals.mxEvent.CELLS_REMOVED, (sender, evt) => {

          var [edge] = evt.getProperty('cells');
          var source = graph.getModel().getTerminal(edge, true);
          var target = graph.getModel().getTerminal(edge, false);

          console.log('cells removed', edge);

          if (source && target) {
            props.onDisconnect(tree.current[DashboardGraphService.getCellKey(source)], tree.current[DashboardGraphService.getCellKey(target)]);
          }
        });
      }

      props.setEditorUi(editorUi);
      editorUiRef.current = editorUi;
    }
    return () => {
      const graph = getGraph(editorUiRef.current);
      if (mouseListenerRef.current) {
        graph.removeMouseListener(mouseListenerRef.current);
        mouseListenerRef.current = null;
      }
      if (editorUiRef.current) {
        graph.setEventsEnabled(false);
        props.setEditorUi(null);
        editorUiRef.current.destroy();
        editorUiRef.current = null;
      }
    }
  }, [containerRef.current, theme, props.readOnly]);

  React.useEffect(() => {
    if (editorUiRef.current) {

      tree.current = TreeService.createItemSet(props.items);

      const graph = getGraph(editorUiRef.current);
      const model: mxgraph.mxGraphModel = graph.getModel();

      const onClick = (sender: any, evt: any) => {
        var cell: mxgraph.mxCell = evt.getProperty('cell');
        var event = evt.getProperty('event');
        const item = tree.current[DashboardGraphService.getCellKey(cell)];
        if (item) {
          const target = event.target as HTMLElement;
          if (target.classList.contains('visto-tree-pin')) {
            props.onTogglePinned(item);
          } else {
            navigate(item.url);
          }
        }
      };

      if (props.readOnly) {
        graph.addListener(mxglobals.mxEvent.CLICK, onClick);
      }

      model.beginUpdate();
      try {
        graph.setEventsEnabled(false);
        model.clear();
        const roots = DashboardGraphService.getRootItems(tree.current, props.sort);
        if (roots.length) {
          const skippedItems: { parentVertex: mxgraph.mxCell, vertex: mxgraph.mxCell, fixed: boolean }[] = [];
          DashboardGraphService.addChildItems(graph, props.readOnly, tree.current, null, roots, theme, {}, props.sort, renderItem, skippedItems);
          DashboardGraphService.runLayout(graph);
          DashboardGraphService.addSkippedItems(graph, skippedItems, theme);
          mx.setFixedView(graph, zoomInfo.scale, FixedViewType.Width, ViewAlignment.Middle, ViewAlignment.Begin);
          editorUiRef.current.chromelessResize(false, null, 0, 0);
        }
      }
      finally {
        model.endUpdate();
        graph.setEventsEnabled(true);
      }

      return () => {
        graph.removeListener(onClick);
      }
    }
  }, [props.items, editorUiRef.current]);

  return <div ref={containerRef} className='geEditor' style={{ flex: 1, position: 'relative', overflow: 'auto' }}></div>
}
