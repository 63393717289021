import { ChoiceGroup, IChoiceGroupOption, TextField } from '@fluentui/react';
import React from 'react';
import { TextService } from 'services/TextService';
import { KeyResultType, VistoKeyResultItem } from 'sp';
import strings from 'VistoWebPartStrings';

export const KeyResultValueField = (props: {
  kr: VistoKeyResultItem;
  label: string;
  disabled: boolean;
  value: number;
  setValue: (val: number) => void;
}) => {

  const binaryOptions: IChoiceGroupOption[] = [
    {
      key: '0',
      text: TextService.format(strings.BinaryKeyResultValue_0),
    },
    {
      key: '1',
      text: TextService.format(strings.BinaryKeyResultValue_1),
    },
  ];

  if (props.kr.keyResultType !== KeyResultType.Binary) {
    return (
      <TextField
        autoFocus
        disabled={props.disabled}
        type='number'
        required
        label={props.label}
        suffix={props.kr.units}
        value={props.value?.toString()}
        onChange={(_, val) => props.setValue(+val)}
      />
    );
  } else {
    return (
      <ChoiceGroup
        label={props.label}
        options={binaryOptions}
        selectedKey={props.value?.toString()}
        onChange={(_, val: IChoiceGroupOption) => props.setValue(+val.key)}
      />
    );
  }
}
