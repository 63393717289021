import { ImageCacheService } from './ImageCacheService';
import { trackClient } from 'shared/clientTelemetry';
import { TextService } from './TextService';
import { api } from 'shared/api';
import { clearInfoBar, IBasicNotify, NotificationType, notifyInfoBar } from './Notify';
import { isConsentError } from 'shared/parse';
import strings from 'VistoWebPartStrings';
import '@pnp/graph/users';
import '@pnp/graph/groups';
import '@pnp/graph/photos';
import '@pnp/graph/teams';
import { AuthService } from './AuthService';
import { CachingType } from './CachingType';
import { User } from '@microsoft/microsoft-graph-types';
import { IFieldValueUser } from 'sp';

export interface IMembershipInfo {
  groups: string[];
  teams: { 
    id: string; 
    displayName: string;
    channels: { id: string; displayName: string }[];
  }[];
}

export class UserInfoService {

  private static getClient(oid: string, caching?: CachingType) {
    return AuthService.getGraphClient(api.TokenKind.dashboard, oid, caching);
  }

  public static async configure(tid: string, notify?: IBasicNotify) {
    
    const callback = async () => {
      await AuthService.getAuthToken(api.TokenKind.dashboard, tid);
    };

    try {

      await callback();

    } catch (err) {

      if (isConsentError(err)) {
        this.makeConsentNotification(callback, notify);
      } else {
        trackClient.warn('Unable to configure user info service', err);
      }
    }
  }

  public static makeConsentNotification(callback: () => Promise<void>, notify: IBasicNotify) {

    AuthService.resetAuth(api.TokenKind.dashboard);

    notifyInfoBar(notify, {
      message: TextService.format(strings.GraphNotification_AuthorizationRequired),
      group: 'Dashboard_Consent',
      type: NotificationType.warn,
      error: TextService.format(strings.GraphNotification_AuthorizationRequiredError),
      actions: [
        {
          title: TextService.format(strings.GraphNotification_AuthorizeButton),
          action: async () => {
            try {
              await AuthService.getConsent(api.TokenKind.dashboard, '', callback);
              clearInfoBar(notify, 'Dashboard_Consent');
              notifyInfoBar(notify, { type: NotificationType.success, message: TextService.format(strings.GraphNotification_ConsentGrant), group: 'Dashboard_Consent' });
            } catch (error) {
              const message = TextService.format(strings.GraphNotification_AuthorizationRequiredErrorDescription);
              notifyInfoBar(notify, { type: NotificationType.error, message, error, group: 'Dashboard_Consent' });
            }
          }
        }
      ]
    });
  }

  public static async getUserMembershipInfo(userObjectId: string): Promise<IMembershipInfo> {
    const graph = this.getClient(userObjectId, 'short');
    const groups = await graph.me.memberOf.select('id')();

    const graphLong = this.getClient(userObjectId, 'long');
    const teamsList = await graphLong.me.joinedTeams.select('id', 'displayName')();
    const teams = await Promise.all(teamsList.map(async (team) => {
      const channels = await graphLong.teams.getById(team.id).channels.select('id', 'displayName')();
      return {
        id: team.id,
        displayName: team.displayName,
        channels: channels.map((c: any) => ({ id: c.id, displayName: c.displayName }))
      }
    }));

    return {
      groups: groups.map(g => g.id),
      teams: teams
    }
  }

  public static encodeUserObjectId(rawUserObjectId: string) {
    return rawUserObjectId.replace(/#/g, '%23');
  }

  public static async getUserInfo(rawUserObjectId: string): Promise<User> {
    if (rawUserObjectId) {
      const userObjectId = this.encodeUserObjectId(rawUserObjectId);
      const graph = this.getClient(userObjectId, 'long');
      const userInfo = await graph.users.getById(userObjectId)();
      return userInfo;
    }
  }

  // public static getSharepointUserPhotoUrl(siteUrl: string, userName: string) {
  //   return `${siteUrl}/_layouts/15/userphoto.aspx?accountname=${encodeURIComponent(userName)}&size=M`;
  // }

  public static async cacheUserPictures(assignedTo: IFieldValueUser[]) {
    await Promise.all(assignedTo.map(async (user) => await this.getUserPhotoUrl(user.userName)));
  }

  public static getCachedUserPhotoUrl(rawUserObjectId: string) {
    if (!rawUserObjectId) 
        return null;
      
    const userObjectId = this.encodeUserObjectId(rawUserObjectId);
    const imageUrl = `visplan://photo/${userObjectId}`;
    return ImageCacheService.getCachedImageUrl(imageUrl);
  }

  public static async getUserPhotoUrl(rawUserObjectId: string) {
    if (rawUserObjectId) {
      const userObjectId = this.encodeUserObjectId(rawUserObjectId);
      const imageUrl = `visplan://photo/${userObjectId}`;
      return ImageCacheService.getImageAsync(imageUrl, () => {
        const graph = this.getClient(userObjectId);
        return graph.users.getById(userObjectId).photo.getBlob();
      });
    }
  }
}
