import React from 'react';
import { Link, Stack } from '@fluentui/react';
import { IntegrationService } from 'services/IntegrationService';
import { IIconInfo } from 'services/IIconInfo';
import { DevOpsIcon } from './DevOpsIcon';

export const DevOpsLink = (props: {
  url: string;
  text: string;
}) => {
  
  const [iconInfo, setIconInfo] = React.useState<IIconInfo>(null);
  React.useEffect(() => {
    IntegrationService.getIconInfo(props.url).then(iconInfo => setIconInfo(iconInfo))
  }, [props.url]);

  return props.text
    ? <Stack horizontal verticalAlign='center' tokens={{ childrenGap: 's1' }}>
      {iconInfo && <DevOpsIcon size={16} src={iconInfo.iconUrl} title={iconInfo.tooltipText} />}
      <Link href={IntegrationService.getBrowserLink(props.url)} target='_blank'>{props.text}</Link>
    </Stack>

    : null;
}
