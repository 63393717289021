import * as React from 'react';
import { Stack, CommandBar, IContextualMenuItem } from '@fluentui/react';

export interface ITopMenuProps {
  menuItems: IContextualMenuItem[];
  overflowMenuItems: IContextualMenuItem[];
  farItems: IContextualMenuItem[];
  closeButton: React.ReactNode;
}

export function SidebarTopMenu(props: ITopMenuProps) {

  return (
    <Stack grow horizontal>
      <Stack.Item grow>
        <CommandBar items={props.menuItems} overflowItems={props.overflowMenuItems} farItems={props.farItems} />
      </Stack.Item>
      {props.closeButton}
    </Stack>
  );
}
