import * as strings from 'VistoWebPartStrings';
import { mxgraph } from 'ts-mxgraph-typings';
import { FixedViewType, mxglobals } from '../common';
import { IImageInfo } from './IImageInfo';
import { ImageUpdater } from './ImageUpdater';
import { TextService } from 'services/TextService';
import { ImageCacheService } from 'services/ImageCacheService';
import { StorageService } from 'services/StorageService';

export const registerImageEditDialog = (
  ui,
  openImageDialog: (imageInfo: IImageInfo, onSave: (imageInfo: IImageInfo) => void) => void
) => {

  const graph: mxgraph.mxGraph = ui.editor.graph;

  const insertImage = () => {

    if (!graph.isEnabled() || graph.isCellLocked(graph.getDefaultParent()))
      return;

    let cell = graph.getSelectionCell();

    openImageDialog(
      ImageUpdater.getImageInfo(graph, cell),
      newImageInfo => {

        graph.getModel().beginUpdate();
        ImageUpdater.setImageInfo(graph, cell, newImageInfo);
        graph.getModel().endUpdate();

        graph.setSelectionCells([cell]);
      }
    );
  };

  ui.actions.addAction('image...', insertImage, null, null, TextService.format(strings.MenuItem_EditImage)).isEnabled = graph.isEnabled;
};

export const registerBackgroundEditDialog = (
  ui,
  openImageDialog: (imageInfo: IImageInfo, onSave: (imageInfo: IImageInfo) => void) => void
) => {

  const graph: mxgraph.mxGraph = ui.editor.graph;

  ui.showBackgroundImageDialog = (callback) => {

    openImageDialog(
      {
        src: graph.backgroundImage?.src,
        width: graph.pageFormat.width,
        height: graph.pageFormat.height,
        resizeType: graph['backgroundImageAspect'] ? (graph['backgroundImageSlice'] ? FixedViewType.FillPage : FixedViewType.FitPage) : FixedViewType.None,
        fixedAspect: true,
        opacity: graph['backgroundImageOpacity'] ?? 100,
        backgroundColor: graph['background']
      },
      imageInfo => {
        if (imageInfo.src) {
          const img = new Image();
          const onload = () => {
            const mxImg = new mxglobals.mxImage(imageInfo.src, graph.pageFormat.width, graph.pageFormat.height);
            if (callback) {
              callback(mxImg);
            } else {
              const imageAspect = imageInfo.resizeType !== FixedViewType.None;
              const imageSlice = imageInfo.resizeType === FixedViewType.FillPage;
              const imageOpacity = imageInfo.opacity;
              const change = new mxglobals.ChangePageSetup(ui, imageInfo.backgroundColor, mxImg, null, null, imageAspect, imageSlice, imageOpacity);
              graph.model.execute(change);
            }
          };
          const onerror = () => {
            ImageCacheService.getImageAsync(imageInfo.src, url => StorageService.get(url).getFile(url)).then(resolved => {
              if (resolved) {
                img.src = resolved;
              }
            });
          }
          img.onload = onload;
          img.onerror = onerror;
          img.src = imageInfo.src;
          if (img.complete)
            onload();
        } else {
          const change = new mxglobals.ChangePageSetup(ui, imageInfo.backgroundColor, null);
          graph.model.execute(change);
        }
      }
    );
  };

  ui.actions.addAction('backgroundImage...', ui.showBackgroundImageDialog, null, null, TextService.format(strings.BackgroundDialog_CommandTitle)).isEnabled = graph.isEnabled;
};
