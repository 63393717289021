import * as React from 'react';
import { LineStyleButton } from './LineStyleButton';
import { Theme, useTheme } from '@fluentui/react';
import * as strings from 'VistoWebPartStrings';
import { TextService } from 'services/TextService';

const renderWidth = (size: string, theme: Theme) => {

  return (
    <svg style={{ paddingRight: 4 }} width='32' viewBox='0 0 32 20' xmlns='http://www.w3.org/2000/svg'>
      <line x1='0' y1='10' x2='32' y2='10' stroke={theme.palette.black} strokeWidth={size} />
    </svg>
  );
};

const options = Array.from(Array(10).keys()).map(x => 1 + x).map(x => ({ name: x.toString(), val: x.toString() }));

export const WidthButton = (props: { width: string; setWidth: (val: string) => void; }) => {

  const theme = useTheme();

  return (
    <LineStyleButton
      text={TextService.format(strings.WidthButton_Title)}
      propertyName='strokeWidth'
      defaultValue='6'
      value={props.width}
      setValue={props.setWidth}
      options={options}
      renderIcon={(val: string) => renderWidth(val, theme)}
    />
  );
};
