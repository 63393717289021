import * as React from 'react';
import { AppContext } from 'services/AppContext';
import { TextService } from 'services/TextService';
import * as strings from 'VistoWebPartStrings';
import { ColorComboBox } from './ColorComboBox';

export function FillColorComboBox(props: {
  disabled: boolean;
  color: string;
  setColor: (val: string) => void;
}) {

  const { propertyBag, setPropertyBag } = React.useContext(AppContext);

  const setColor = (val) => {
    setPropertyBag({ fillColor : val });
    props.setColor(val);
  };

  return (
    <ColorComboBox
      disabled={props.disabled}
      buttonColor={propertyBag.fillColor}
      iconName='BackgroundColor'
      title={TextService.format(strings.FillColorComboBox_Title)}
      color={props.color}
      setColor={setColor}
      extraItems={null}
      isToolbar
    />
  );
}
