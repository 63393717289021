import { UrlService } from 'shared/urlService';
import { IAttachment, IVistoListItem, IVistoPlan, VistoKind } from 'sp';
import { IItemChanges } from './Interfaces';
import { IOperationOptions } from './IOperationOptions';
import { ITemplate } from './ITemplate';
import { IBasicNotify, INotify } from './Notify';
import { CachingType } from './CachingType';

export interface IStorageService {

  deleteItems(plan: IVistoPlan, items: IVistoListItem[], notify: INotify, options?: IOperationOptions): Promise<IVistoPlan>;
  createItems(plan: IVistoPlan, items: IVistoListItem[], notify: INotify, options?: IOperationOptions): Promise<IVistoPlan>;
  updateItems<T extends IVistoListItem>(plan: IVistoPlan, updates: IItemChanges<T>[], notify: INotify, options?: IOperationOptions): Promise<IVistoPlan>;
  loadItem(plan: IVistoPlan, kind: VistoKind, itemGuid: string, notify: INotify): Promise<IVistoListItem>;

  putFile(siteUrl: string, folderRelativeUrl: string, fileName: string, fileConent: File): Promise<IAttachment>;
  getFile(fileAbsoluteUrl: string): Promise<Blob>;
  getTemplates(folderAbsoluteUrl: string): Promise<ITemplate[]>;

  makeAttachment(fileName: string, fileAbsoluteUrl: string, siteUrl: string): Promise<IAttachment>;

  getPlanItems(siteUrl: string, fields: (keyof IVistoPlan)[], caching: CachingType, ids?: string[]): Promise<IVistoPlan[]>;

  createPlanItem(plan: IVistoPlan): Promise<IVistoPlan>;
  deletePlanItem(plan: IVistoPlan, deletePages?: boolean): Promise<void>;
  updatePlanItem(plan: IVistoPlan, changes: Partial<IVistoPlan>, notify: INotify): Promise<IVistoPlan>;
  loadPlanItem(plan: IVistoPlan, fields?: (keyof IVistoPlan)[]): Promise<IVistoPlan>;

  loadPlanData(plan: IVistoPlan, notify: INotify): Promise<IVistoPlan>;

  resetListCache(plan: IVistoPlan, kind?: VistoKind);
  makeConsentNotification(callback: () => Promise<void>, siteUrl: string, notify: IBasicNotify);

  get attachmentsSupported(): boolean;
  get assigneeSupported(): boolean;
}

export class StorageService {

  private static sharepointService = null;
  private static indexeddbService = null;
  public static configure(params: { sharepointService: IStorageService, indexeddbService: IStorageService} ) {
    this.sharepointService = params.sharepointService;
    this.indexeddbService = params.indexeddbService;
  }

  public static get(url: string): IStorageService {
    if (UrlService.isLocalUrl(url)) {
      return this.indexeddbService;
    } else {
      return this.sharepointService;
    }
  }
}
