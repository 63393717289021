import React from 'react';
import { AppContext } from 'services/AppContext';
import { IVistoPlan, VistoDpItem, VistoKind } from 'sp';
import { SidebarActionAssigneeList } from './SidebarActionAssigneeList';
import { SidebarActionFlatList } from './SidebarActionFlatList';
import { SidebarFocusActionList } from './SidebarFocusActionList';
import { SidebarKeyResultActionList } from './SidebarKeyResultActionList';

export const SidebarActionList = (props: {
  plan: IVistoPlan;
  dp: VistoDpItem;
}) => {

  const { propertyBag } = React.useContext(AppContext);

  switch (propertyBag.sidebarGroupBy) {
    case 'kpi':
      return <SidebarKeyResultActionList includeActions={true} plan={props.plan} item={props.dp} />;
      case 'focus':
        return <SidebarFocusActionList plan={props.plan} dp={props.dp} />;
    case 'assignee':
      return <SidebarActionAssigneeList plan={props.plan} dp={props.dp} />;
    default:
      return <SidebarActionFlatList plan={props.plan} dp={props.dp} />;
  }
}
