import React from 'react';
import strings from 'VistoWebPartStrings';
import { TextField } from '@fluentui/react';
import { InfoBar, useErrorInfo } from 'components';
import { NotificationType } from 'services/Notify';
import { TextService } from 'services/TextService';
import { IFieldValueUrl } from 'sp';
import { BasicDialog } from './BasicDialog';

export const EditUrlDialog = (props: {
  url: IFieldValueUrl;
  onDismiss: () => void;
  onCommit: (url?: IFieldValueUrl) => Promise<any>;
}) => {

  const [newUrl, setNewUrl] = React.useState(props.url ?? { url: '' });
  const [errorInfo, setErrorInfo] = useErrorInfo();

  const onCommit = async () => {
    try {
      await props.onCommit(newUrl);
      props.onDismiss();
    } catch (error) {
      setErrorInfo({ type: NotificationType.error, message: TextService.format(strings.EditUrlDialog_ErrorMessage), error });
    }
  };

  return (
    <BasicDialog
      onDismiss={props.onDismiss}
      buttonOkAction={onCommit}
      buttonOkText={TextService.format(strings.ButtonSave)}
      buttonOkBusyText={TextService.format(strings.ButtonSaving)}
      buttonCancelText={TextService.format(strings.ButtonCancel)}
      title={TextService.format(strings.EditUrlDialog_Title)}
      content={
        <React.Fragment>
          <InfoBar {...errorInfo} />
          <TextField placeholder='https://' label={TextService.format(strings.EditUrlDialog_LinkLabel)} value={newUrl.url} onChange={(e, newValue?: string) => setNewUrl({ ...newUrl, url: newValue })} />
          <TextField label={TextService.format(strings.EditUrlDialog_DescriptionLabel)} value={newUrl.description} onChange={(e, newValue?: string) => setNewUrl({ ...newUrl, description: newValue })} />
        </React.Fragment>
      }
    />
  );
};
