import { AuthService } from 'services/AuthService';
import { IExecutableAction } from 'services/IExecutableAction';
import { UrlService } from 'shared/urlService';
import { StorageCacheService } from 'services/StorageCacheService';
import { IVistoPlan, VistoKind } from 'sp';

export const makeMigrateCleanupKeyResults = (foundPlan: IVistoPlan): IExecutableAction => ({
  title: `Cleanup Key Results`,
  execute: async (p) => {

    const sp = AuthService.getSpClient(foundPlan.siteUrl);

    const krListUrl = UrlService.getListRelativeUrl(foundPlan, VistoKind.KeyResult);
    const krList = sp.web.getList(krListUrl);
    const fields = await krList.fields();
    const krField = fields.find(f => f.InternalName === 'KR');
    if (krField) {
      const items = await krList.items.top(2000)();

      for (const item of items) {

        const id = +item['Id'];
        const krId = +item['KRId'];

        if (krId) {
          await krList.items.getById(id).update({ 'KRId': null });
        }
      }

      await sp.web.getList(krListUrl).fields.getByInternalNameOrTitle('KR').delete();
    }

    const listName = `Visto_FocusKR_${foundPlan.planId}`

    const lists = await sp.web.lists.select('DefaultViewUrl')();
    const focusKrList = lists.find(l => l?.DefaultViewUrl.indexOf(listName) > -1);
    if (focusKrList) {
      const listRelativeUrl = UrlService.combine('Lists', listName);
      const siteRelativeUrl = UrlService.getPathName(foundPlan.siteUrl);
      const listUrl = UrlService.combine(siteRelativeUrl, listRelativeUrl);

      await sp.web.getList(listUrl).recycle();
    }

    StorageCacheService.resetCache();
    return p;
  }
});
