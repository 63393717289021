import * as React from 'react';
import { SidebarPlan, ISidebarPlanProps } from 'frames/TopFrame/sidebars/common';

export function SidebarTitle(props: ISidebarPlanProps) {

  return <SidebarPlan
    isOpen={props.isOpen}
    plan={props.plan}
    nameProp='name'
    descriptionProp='description'
    navigationLinkProp='navigationLink'
    onDismiss={props.onDismiss}
    advisoryTopicKey='index'
    content={null}
  />;
}
