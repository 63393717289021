import * as React from 'react';
import * as strings from 'VistoWebPartStrings';
import { TextService } from 'services/TextService';
import { Stack, useTheme } from '@fluentui/react';
import { MatrixLegendItem } from './MatrixLegendItem';

export const MatrixLegend = () => {
  const { palette }  = useTheme();
  const legendBoxStyle = {
    padding: 8,
    backgroundColor: 'transparent',
    border: `1px solid ${palette.neutralQuaternary}`
  };
  
  return (
    <Stack horizontal style={legendBoxStyle} verticalAlign='center' tokens={{ childrenGap: 's1', padding: '0 1em' }}>
      <span>{TextService.format(strings.MatrixLegend_Title)}</span>
      <MatrixLegendItem confidence={1} label={TextService.format(strings.EditAssocDialog_ConfidenceLow)} />
      <MatrixLegendItem confidence={3} label={TextService.format(strings.EditAssocDialog_ConfidenceMedium)} />
      <MatrixLegendItem confidence={9} label={TextService.format(strings.EditAssocDialog_ConfidenceHigh)} />
    </Stack>
  );
};
