import { IDatePickerStrings } from '@fluentui/react';
import * as controlStrings from 'ControlStrings';

/**
 * @class
 * Defines the labels of the DatePicker control (as months, days, etc.)
 *
 */
 export class DatePickerStrings implements IDatePickerStrings {
  /**
   * An array of strings for the full names of months.
   * The array is 0-based, so months[0] should be the full name of January.
   */
  public months: string[] = [
    controlStrings.DatePickerMonthLongJanuary, controlStrings.DatePickerMonthLongFebruary,
    controlStrings.DatePickerMonthLongMarch, controlStrings.DatePickerMonthLongApril,
    controlStrings.DatePickerMonthLongMay, controlStrings.DatePickerMonthLongJune, controlStrings.DatePickerMonthLongJuly,
    controlStrings.DatePickerMonthLongAugust, controlStrings.DatePickerMonthLongSeptember, controlStrings.DatePickerMonthLongOctober,
    controlStrings.DatePickerMonthLongNovember, controlStrings.DatePickerMonthLongDecember
  ];
  /**
   * An array of strings for the short names of months.
   * The array is 0-based, so shortMonths[0] should be the short name of January.
   */
  public shortMonths: string[] = [
    controlStrings.DatePickerMonthShortJanuary, controlStrings.DatePickerMonthShortFebruary,
    controlStrings.DatePickerMonthShortMarch, controlStrings.DatePickerMonthShortApril,
    controlStrings.DatePickerMonthShortMay, controlStrings.DatePickerMonthShortJune, controlStrings.DatePickerMonthShortJuly,
    controlStrings.DatePickerMonthShortAugust, controlStrings.DatePickerMonthShortSeptember, controlStrings.DatePickerMonthShortOctober,
    controlStrings.DatePickerMonthShortNovember, controlStrings.DatePickerMonthShortDecember
  ];
  /**
   * An array of strings for the full names of days of the week.
   * The array is 0-based, so days[0] should be the full name of Sunday.
   */
  public days: string[] = [
    controlStrings.DatePickerDayLongSunday, controlStrings.DatePickerDayLongMonday, controlStrings.DatePickerDayLongTuesday,
    controlStrings.DatePickerDayLongWednesday, controlStrings.DatePickerDayLongThursday, controlStrings.DatePickerDayLongFriday,
    controlStrings.DatePickerDayLongSaturday
  ];
  /**
   * An array of strings for the initials of the days of the week.
   * The array is 0-based, so days[0] should be the initial of Sunday.
   */
  public shortDays: string[] = [
    controlStrings.DatePickerDayShortSunday, controlStrings.DatePickerDayShortMonday, controlStrings.DatePickerDayShortTuesday,
    controlStrings.DatePickerDayShortWednesday, controlStrings.DatePickerDayShortThursday, controlStrings.DatePickerDayShortFriday,
    controlStrings.DatePickerDayShortSaturday
  ];
  /**
   * String to render for button to direct the user to today's date.
   */
  public goToToday: string = controlStrings.DatePickerGoToToday;
  /**
   * Error message to render for TextField if isRequired validation fails.
   */
  public isRequiredErrorMessage: string = controlStrings.DatePickerIsRequiredErrorMessage;
  /**
   * Error message to render for TextField if input date string parsing fails.
   */
  public invalidInputErrorMessage: string = controlStrings.DatePickerInvalidInputErrorMessage;
}
