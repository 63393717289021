import '@pnp/sp/webs';
import '@pnp/sp/sites';
import '@pnp/sp/lists';
import '@pnp/sp/items';
import '@pnp/sp/site-users';
import '@pnp/sp/files';
import '@pnp/sp/folders';
import '@pnp/sp/regional-settings/web';
import '@pnp/sp/batching';
import { PermissionKind } from '@pnp/sp/security';
import { IList } from '@pnp/sp/lists';

const mime = require('mime/lite');

import {
  IVistoPlan,
  IVistoListItem,
  IVistoListItemWithProgress,
  getListDefinition,
  VistoKind,
  VistoPlanListFields,
  IFieldDefinitions,
  VistoSoItem,
  VistoLopItem,
  VistoDpItem,
  VistoActionItem,
  VistoAssocItem,
  IPlanItems,
  IFieldValueUser,
  IFieldDefinition,
  VistoFocusItem,
  FieldType,
  VistoKeyResultItem,
  VistoKeyResultValueItem,
  IAttachment,
} from 'sp';

import { PlanDataService } from './PlanDataService';
import { ListItemUpdater } from 'sp/common/ListItemUpdater';
import { IItemChanges } from './Interfaces';
import { IVersionData, ProgressService } from './ProgressService';
import { TextService } from 'services/TextService';

import { api } from 'shared/api';
import { trackClient } from 'shared/clientTelemetry';
import { makeGuidString } from 'shared/guid';
import { AuthService } from './AuthService';
import { CachingType } from './CachingType';
import strings from 'VistoWebPartStrings';
import { clearInfoBar, IBasicNotify, INotify, NotificationType, notifyInfoBar } from './Notify';
import { PlanSettingsService } from './PlanSettingsService';
import { IOperationOptions } from './IOperationOptions';
import { IStorageService } from './StorageService';
import { Guid } from 'context';
import { UrlService } from 'shared/urlService';
import { StorageCacheService } from './StorageCacheService';
import { ITemplate } from './ITemplate';
import { getErrorMessage, getObjectValues, parseIsoDate } from 'shared/parse';
import { getListFields } from './SharePointConfigurationService';

const TOP_LIST_ITEMS = 2000;
const MIN_UPDATE_TIMEOUT_MS = 2000;

export class SharepointService implements IStorageService {

  public get attachmentsSupported() {
    return true;
  }

  public get assigneeSupported() {
    return true;
  }

  private static nextPossibleUpdate: { [key: string]: number } = {};

  private static ensureTimeout(guid: string) {
    const now = new Date().getTime();
    let nextPossibleUpdate = this.nextPossibleUpdate[guid];

    if (!nextPossibleUpdate || now > nextPossibleUpdate) {
      this.nextPossibleUpdate[guid] = now + MIN_UPDATE_TIMEOUT_MS;
      return Promise.resolve();
    }

    this.nextPossibleUpdate[guid] = nextPossibleUpdate + MIN_UPDATE_TIMEOUT_MS;
    return new Promise(resolve => setTimeout(resolve, nextPossibleUpdate - now));
  }

  private notifyItems(notify: INotify, operation: api.WSOperation, items: IVistoListItem[]) {
    if (notify) {
      for (const item of items) {
        notify.operation(operation, item.kind, item.guid);
      }
    }
  }

  public async deleteItems(
    plan: IVistoPlan,
    items: IVistoListItem[],
    notify: INotify,
    options?: IOperationOptions): Promise<IVistoPlan> {

    trackClient.debug(`[sp] schedule deleteItems (${items.length})`, items);

    const sp = AuthService.getSpClient(plan.siteUrl);

    const updatedItems = { ...plan.items };
    const itemsToNotify = [];
    const processed: IVistoListItem[] = [];

    const chunks = PlanDataService.chunkItems(items, { getItem: x => x, groupByKind: !options?.excludeGroupByKind });
    for (const chunk of chunks.reverse()) {

      const kind = chunk[0].kind;
      const definition = getListDefinition<IVistoListItem>(+kind);
      const spListUrl = UrlService.getListRelativeUrl(plan, +kind);

      const spItems = await sp.web.getList(spListUrl).items.select(definition.fields.itemId.name, definition.fields.guid.name).top(TOP_LIST_ITEMS)();
      const [batch, execute] = sp.batched();

      for (const item of chunk.reverse()) {

        await SharepointService.ensureTimeout(item.guid);

        const ids = spItems.filter(spItem => spItem[definition.fields.guid.name] === item.guid).map(x => x.ID);

        if (ids.length > 1) {
          trackClient.warn(`[sp] data issue: multiple items with the same guid ${item.guid}`);
        }

        for (let i = 0; i < ids.length; ++i) {
          batch.web.getList(spListUrl).items.getById(ids[i]).recycle().then(() => {
            const updatedItem = updatedItems[item.guid];
            if (updatedItem) {
              processed.push(updatedItem);
            }
            itemsToNotify.push(item);
            trackClient.debug(`[sp] deleted item ${VistoKind[kind]} #${item.guid}`, updatedItem);
          }, error => {
            const errorTitle = TextService.format(strings.Error_SpDeleteItems, {
              itemTitle: TextService.formatTitle(item, updatedItems)
            });
            trackClient.error(errorTitle);
            getErrorMessage(error).then(message => {
              notifyInfoBar(notify, { 
                type: NotificationType.error, 
                group: options?.notificationGroup,
                message: errorTitle,
                error: `${message}`
              });
            });
            trackClient.error(`[sp] delete item ${VistoKind[item.kind]} #${item.guid} failed`, error);
          });
        }
      }

      await execute();

      for (const item of chunk) {
        delete updatedItems[item.guid];
      }
    }

    plan = { ...plan, items: updatedItems, revision: plan.revision + 1 };

    plan = await ProgressService.ensureSync(plan, processed, notify, api.WSOperation.delete, options);

    this.notifyItems(notify, api.WSOperation.delete, itemsToNotify)

    return plan;
  }

  public static addKnownChanges<T extends IVistoListItem>(updater: ListItemUpdater, items: IPlanItems, kind: VistoKind, changes: Partial<T>) {
    const definition = getListDefinition<T>(kind);
    for (const field in changes) {
      this.addKnownChange(updater, definition.fields[field], changes[field], items);
    }
  }

  public static makeSpChanges<T extends IVistoListItem>(updater: ListItemUpdater, kind: VistoKind, changes: Partial<T>) {
    const definition = getListDefinition<T>(kind);

    const spItem = {};
    this.setSpFieldValue(definition.fields, spItem, changes, 'name');
    this.setSpFieldValue(definition.fields, spItem, changes, 'guid');
    this.setSpFieldValue(definition.fields, spItem, changes, 'description');
    this.setSpFieldValue(definition.fields, spItem, changes, 'links');

    if (definition.setItemSpecificFields)
      definition.setItemSpecificFields(updater, spItem, changes);

    if (definition.hasProgress)
      this.setItemProgressData(updater, definition.fields, spItem, changes);

    return spItem;
  }

  private static addKnownChange(updater: ListItemUpdater, fieldInfo: IFieldDefinition, change: any, items: IPlanItems) {
    switch (fieldInfo?.type) {

      case FieldType.Lookup:
        const lookupGuid: string = change;
        if (lookupGuid) {
          const item = items[lookupGuid];
          if (item) {
            updater.addKnownItem(item);
          } else {
            trackClient.error(`[sp] invalid lookup value in changes`, { fieldInfo, change });
          }
        }
        break;

      case FieldType.User:
        const users: IFieldValueUser[] = change;
        if (users) {
          if (Array.isArray(users)) {
            for (const user of users) {
              updater.addKnownUser(user);
            }
          } else {
            trackClient.error(`[sp] invalid user value in changes`, { fieldInfo, change });
          }
        }
        break;
    }
  }

  public async createItems(
    plan: IVistoPlan,
    items: IVistoListItem[],
    notify: INotify,
    options?: IOperationOptions): Promise<IVistoPlan> {

    const sp = AuthService.getSpClient(plan.siteUrl);

    trackClient.debug(`[sp] scheduled createItems (${items.length})`, items);

    const updatedItems = { ...plan.items };
    const itemsToNotify = [];
    const processed: IVistoListItem[] = [];

    const updater = new ListItemUpdater(plan.timeZoneBias);

    const chunks = PlanDataService.chunkItems(items, { getItem: x => x, groupByKind: !options?.excludeGroupByKind });
    for (const chunk of chunks) {

      const [batch, execute] = sp.batched();

      for (const item of chunk) {

        // await SharepointService.ensureTimeout(item.guid);

        SharepointService.addKnownChanges(updater, updatedItems, item.kind, item);
        const spItem = SharepointService.makeSpChanges(updater, item.kind, item);

        const url = UrlService.getListRelativeUrl(plan, item.kind);
        batch.web.getList(url).items.add(spItem).then(created => {
          trackClient.debug(`[sp] created item ${VistoKind[item.kind]} #${created.data.ID}`, created.data);
          const newItem = { ...item, ...SharepointService.getItemFromSpItem(updater, created.data, item.kind) };

          updatedItems[item.guid] = newItem;
          processed.push(newItem);
          updater.addKnownItem(newItem);
          itemsToNotify.push(item);
        }, error => {
          const errorTitle = TextService.format(strings.Error_SpCreateItems, {
            itemTitle: TextService.formatTitle(item, updatedItems)
          });
          trackClient.error(errorTitle);
          getErrorMessage(error).then(message => {
            notifyInfoBar(notify, { 
              type: NotificationType.error, 
              group: options?.notificationGroup,
              message: errorTitle, 
              error: `${message}`
            });
          });
          trackClient.error(`[sp] create item ${VistoKind[item.kind]} #${item.guid} failed`, error);
        });
      }
      
      await execute();
    }

    await updater.resolveAll(plan.siteUrl);

    plan = { ...plan, items: updatedItems, revision: plan.revision + 1 };

    plan = await ProgressService.ensureSync(plan, processed, notify, api.WSOperation.create, options);

    this.notifyItems(notify, api.WSOperation.create, itemsToNotify);

    return plan;
  }

  /**
   * Updates (or creates) an item in the SharePoint
   *
   * @param plan the Visto Plan
   * @param item item to be saved (DP, LOP, etc)
   * @param changes the changes to be applied to the item
   */
  public async updateItems<T extends IVistoListItem>(
    plan: IVistoPlan,
    updates: IItemChanges<T>[],
    notify: INotify,
    options?: IOperationOptions): Promise<IVistoPlan> {

    const sp = AuthService.getSpClient(plan.siteUrl);

    trackClient.debug(`[sp] schedule updateItems (${updates.length})`, updates);

    const updatedItems = { ...plan.items };
    const itemsToNotify = [];
    const updater = new ListItemUpdater(plan.timeZoneBias);

    let haveSpChanges = false;
    const processed: IVistoListItem[] = [];

    const chunks = PlanDataService.chunkItems(updates, { getItem: x => x.item, groupByKind: !options?.excludeGroupByKind });

    for (const chunk of chunks) {

      const [batch, execute] = sp.batched();

      let haveChunkSpChanges = false;
      for (const update of chunk) {

        const item = update.item;

        await SharepointService.ensureTimeout(item.guid);

        const changes = options?.reverse
          ? update.changes.oldValues
          : update.changes.newValues;

        SharepointService.addKnownChanges(updater, updatedItems, item.kind, changes);
        const spChanges = SharepointService.makeSpChanges(updater, item.kind, changes);

        const haveSpItemChanges = Object.keys(spChanges).length > 0;

        if (haveSpItemChanges) {

          haveChunkSpChanges = true;
          const spListUrl = UrlService.getListRelativeUrl(plan, item.kind);

          batch.web.getList(spListUrl).items.getById(item.itemId).update(spChanges).then(() => {
            processed.push(item);
            trackClient.debug(`[sp] updated item ${VistoKind[item.kind]} #${item.guid}`, update);
          }, error => {
            const errorTitle = TextService.format(strings.Error_SpUpdateItems, {
              itemTitle: TextService.formatTitle(item, updatedItems)
            });
            trackClient.error(errorTitle);
            getErrorMessage(error).then(message => {
              notifyInfoBar(notify, { 
                type: NotificationType.error, 
                group: options?.notificationGroup,
                message: errorTitle, 
                error: `${message}. properties: ${TextService.formatChanges<T>(item.kind, update.changes)}`
              });
            });
            trackClient.error(`[sp] update item ${VistoKind[item.kind]} #${item.guid} failed`, error);
          });
        } else {
          trackClient.warn(`[sp] update item ${VistoKind[item.kind]} #${item.guid}: no sharepoint changes`, update);
        }

        updatedItems[item.guid] = { ...item, ...changes };
        updater.addKnownItem(item);

        if (haveSpItemChanges && notify) {
          itemsToNotify.push(item);
        }
      }

      if (haveChunkSpChanges) {
        await execute();
        haveSpChanges = true;
      }
    }

    if (!haveSpChanges) {
      plan = { ...plan, items: updatedItems, revision: plan.revision + 1 };
      return plan;
    }

    await updater.resolveAll(plan.siteUrl);

    plan = { ...plan, items: updatedItems, revision: plan.revision + 1 };

    plan = await ProgressService.ensureSync(plan, processed, notify, api.WSOperation.update, options);

    this.notifyItems(notify, api.WSOperation.update, itemsToNotify)

    return plan;
  }

  public static getItemFromSpItem<T extends IVistoListItem>(updater: ListItemUpdater, spItem: any, kind: VistoKind): T {

    const definition = getListDefinition<IVistoListItem>(kind);

    const item = <T>{
      kind: kind,
      itemId: this.getSpFieldValue(definition.fields, spItem, 'itemId'),
      name: this.getSpFieldValue(definition.fields, spItem, 'name'),
      guid: this.getSpFieldValue(definition.fields, spItem, 'guid'),
      description: this.getSpFieldValue(definition.fields, spItem, 'description'),
      links: this.getSpFieldValue(definition.fields, spItem, 'links'),
      createdDate: parseIsoDate('createdDate', this.getSpFieldValue(definition.fields, spItem, 'createdDate')),
      modifiedDate: parseIsoDate('modifiedDate', this.getSpFieldValue(definition.fields, spItem, 'modifiedDate')),
    };

    if (definition.getItemSpecificFields)
      definition.getItemSpecificFields(updater, item, spItem);

    if (definition.hasProgress)
      this.getItemProgressData(updater, definition.fields, item, spItem);

    return item;
  }

  public async loadListData<T extends IVistoListItem>(updater: ListItemUpdater, plan: IVistoPlan, kind: VistoKind, notify: INotify): Promise<IPlanItems> {

    const spListUrl = UrlService.getListRelativeUrl(plan, kind);
    const sp = AuthService.getSpClient(plan.siteUrl, 'short');
    const spList = sp.web.getList(spListUrl);

    const fields = getListFields(kind).map(f => (f.type === FieldType.User || f.type === FieldType.Lookup) ? `${f.name}Id` : `${f.name}`);

    const spItems = await spList.items.select(...fields).top(TOP_LIST_ITEMS)();

    const items = {};
    for (let i = 0; i < spItems.length; ++i) {
      const spItem = spItems[i];
      let item = SharepointService.getItemFromSpItem<T>(updater, spItem, kind);

      // if added manually, auto-generate guid
      if (!item.guid) {
        const guid = await SharepointService.fixItemGuid<T>(kind, spList, item);
        item = { ...item, guid };
        notifyInfoBar(notify, {
          type: NotificationType.warn,
          message: TextService.format(strings.WarningMessage_MissingGuid),
          error: TextService.format(strings.WarningMessage_MissingGuidDetails, { vistoKindName: TextService.getVistoKindName(kind) })
        });
      }

      if (items[item.guid]) {
        notifyInfoBar(notify, {
          type: NotificationType.warn,
          message: TextService.format(strings.WarningMessage_DuplicateGuid),
          error: TextService.format(strings.WarningMessage_DuplicateGuidDetails, { vistoKindName: TextService.getVistoKindName(kind), guid: item.guid })
        });
      }

      items[item.guid] = item;
      updater.addKnownItem(item);
    }

    return items;
  }

  private static async fixItemGuid<T extends IVistoListItem>(kind: VistoKind, spList, item: T) {
    const guidValue = makeGuidString();
    const listDefinition = getListDefinition<T>(kind);
    const guidField = listDefinition.fields['guid'].name;
    await spList.items.getById(item.itemId).update({ [guidField]: guidValue });
    return guidValue;
  }

  protected static setItemProgressData(updater: ListItemUpdater, fields: any, spItem, item: Partial<IVistoListItemWithProgress>) {
    updater.setItemSpField(fields, spItem, item, 'sourceItemUrl');
    updater.setItemSpField(fields, spItem, item, 'startDate');
    updater.setItemSpField(fields, spItem, item, 'endDate');
    updater.setItemSpField(fields, spItem, item, 'percentComplete');
    updater.setItemSpField(fields, spItem, item, 'plannedPercentComplete');
    updater.setItemSpField(fields, spItem, item, 'comments');
  }


  protected static getItemProgressData(updater: ListItemUpdater, fields: any, item: IVistoListItemWithProgress, spItem) {

    updater.getItemSpField(fields, item, spItem, 'sourceItemUrl');
    updater.getItemSpField(fields, item, spItem, 'startDate');
    updater.getItemSpField(fields, item, spItem, 'endDate');
    updater.getItemSpField(fields, item, spItem, 'percentComplete');
    updater.getItemSpField(fields, item, spItem, 'plannedPercentComplete');
    updater.getItemSpField(fields, item, spItem, 'comments');
  }

  public static async getPlanItemFields(spList: IList, planId: string, fields: (keyof IVistoPlan)[]) {

    let query = spList.items
      .filter(`${VistoPlanListFields.planId.name} eq '${planId}'`);

    if (fields) {
      const select = fields.map(f => VistoPlanListFields[f].name);
      query = query.select(...select);
    }

    const spItems = await query();

    return spItems[0];
  }

  public static makePlanSpChanges(updater: ListItemUpdater, changes: Partial<IVistoPlan>) {
    const spItem = {};
    const definition = getListDefinition<IVistoPlan>(VistoKind.Plan);
    definition.setItemSpecificFields(updater, spItem, changes);
    return spItem;
  }

  public async createPlanItem(plan: IVistoPlan): Promise<IVistoPlan> {

    const sp = AuthService.getSpClient(plan.siteUrl);

    const updater = new ListItemUpdater(plan.timeZoneBias);
    const planSpItem = SharepointService.makePlanSpChanges(updater, plan);
    const planListUrl = UrlService.getListRelativeUrl(plan);
    const spList = sp.web.getList(planListUrl);
    const created = await spList.items.add(planSpItem);

    const itemId = SharepointService.getSpFieldValue(VistoPlanListFields, created.data, 'itemId');

    return { ...plan, itemId, revision: plan.revision + 1 };
  }

  public async updatePlanItem(plan: IVistoPlan, changes: Partial<IVistoPlan>, notify: INotify): Promise<IVistoPlan> {

    const changedProps = Object.keys(changes);
    if (!changedProps.length)
      return plan;

    const sp = AuthService.getSpClient(plan.siteUrl, 'short');

    const planListUrl = UrlService.getListRelativeUrl(plan);
    const spList = sp.web.getList(planListUrl);

    const [spItem, timeZone] = await Promise.all([
      SharepointService.getPlanItemFields(spList, plan.planId, ['itemId']),
      sp.web.regionalSettings.timeZone()
    ]);

    const itemId = SharepointService.getSpFieldValue(VistoPlanListFields, spItem, 'itemId');

    const updater = new ListItemUpdater(timeZone.Information.Bias + timeZone.Information.DaylightBias);

    const planSpChanges = SharepointService.makePlanSpChanges(updater, changes);

    trackClient.debug(`[sp] schedule update plan item`, changedProps);

    await SharepointService.ensureTimeout(plan.planId);

    const updatedItem = await spList.items.getById(itemId).update(planSpChanges);
    
    trackClient.debug(`[sp] updated plan item`, changedProps);

    if (changedProps.indexOf('name') >= 0) {
      const kinds = PlanDataService.getAllVistoKinds(true);
      for (const kind of kinds) {
        const spListUrl = UrlService.getListRelativeUrl(plan, kind);
        const list = await sp.web.getList(spListUrl).select('Title')();
  
        const oldListTitle = list.Title;
        const newListTitle = TextService.getListTitle(kind, changes.name);
        
        if (oldListTitle !== newListTitle) {
          const spListUrl = UrlService.getListRelativeUrl(plan, kind);
          await sp.web.getList(spListUrl).update({ Title: newListTitle });
        }
      }
    }

    this.resetListCache(plan);

    if (notify) {
      notify.operation(api.WSOperation.settings, VistoKind.Plan, plan.planId);
    }

    return { ...plan, ...changes, revision: plan.revision + 1 };
  }

  public resetListCache(plan: IVistoPlan, kind?: VistoKind) {
    const listUrl = UrlService.getListRelativeUrl(plan, kind);
    const absoluteUrl = AuthService.makeBaseUrl(`_api/web/getlist('${encodeURIComponent(listUrl)}')`);
    StorageCacheService.resetCache(absoluteUrl);
  }

  public async loadPlanItem(plan: IVistoPlan, fields?: (keyof IVistoPlan)[]) {

    const sp = AuthService.getSpClient(plan.siteUrl, 'short');

    const planListUrl = UrlService.getListRelativeUrl(plan);
    const spList = sp.web.getList(planListUrl);

    const [spItem, permissions, timeZone] = await Promise.all([
      SharepointService.getPlanItemFields(spList, plan.planId, fields && [...fields, 'itemId', 'planId', 'planVersion']),
      sp.web.getCurrentUserEffectivePermissions(),
      sp.web.regionalSettings.timeZone()]);

    if (spItem) {

      const itemId = SharepointService.getSpFieldValue(VistoPlanListFields, spItem, 'itemId');
      const planVersion = SharepointService.getSpFieldValue(VistoPlanListFields, spItem, 'planVersion');

      const loaded = {
        ...plan,
        itemId,
        planVersion,
        editable: sp.web.hasPermissions(permissions, PermissionKind.EditListItems),
        timeZoneBias: timeZone.Information.Bias + timeZone.Information.DaylightBias,
        items: {},
        revision: 0,
        drawingRevision: 0
      };

      const updater = new ListItemUpdater(loaded.timeZoneBias);
      getListDefinition<IVistoPlan>(VistoKind.Plan).getItemSpecificFields(updater, loaded, spItem);

      const planSettings = PlanSettingsService.getPlanSettings(loaded);
      loaded.statusDate = PlanSettingsService.getStatusDate(planSettings);

      return loaded;
    }
  }

  public async loadPlanData(plan: IVistoPlan, notify: INotify): Promise<IVistoPlan> {

    const updater = new ListItemUpdater(plan.timeZoneBias);

    const results = await Promise.all([
      this.loadListData<VistoFocusItem>(updater, plan, VistoKind.Focus, notify),
      this.loadListData<VistoSoItem>(updater, plan, VistoKind.SO, notify),
      this.loadListData<VistoLopItem>(updater, plan, VistoKind.LOP, notify),
      this.loadListData<VistoDpItem>(updater, plan, VistoKind.DP, notify),
      this.loadListData<VistoActionItem>(updater, plan, VistoKind.Action, notify),
      this.loadListData<VistoAssocItem>(updater, plan, VistoKind.Assoc, notify),
      this.loadListData<VistoKeyResultItem>(updater, plan, VistoKind.KeyResult, notify),
      this.loadListData<VistoKeyResultItem>(updater, plan, VistoKind.Effect, notify),
      this.loadListData<VistoKeyResultValueItem>(updater, plan, VistoKind.KRV, notify),
    ]);

    await updater.resolveAll(plan.siteUrl);

    const planItems = results.reduce((r, items) => ({ ...r, ...items }), {});

    return {
      ...plan,
      items: planItems,
      revision: plan.revision + 1
    };
  }

  public static setSpFieldValue<T>(fields: IFieldDefinitions<T>, spItem, item, propName: keyof T) {
    if (item.hasOwnProperty(propName)) {
      const field = fields[propName];
      spItem[field.name] = item[propName];
    }
  }

  public static getSpFieldValue<T>(fields: IFieldDefinitions<T>, spItem, propName: keyof T) {
    const field = fields[propName];
    if (!field) {
      trackClient.error(`cannot resolve property ${String(propName)} to a field`);
      return null;
    }
    return spItem[field.name];
  }

  public static async canManageLists(siteUrl: string): Promise<boolean> {
    const sp = AuthService.getSpClient(siteUrl);
    const permissions = await sp.web.getCurrentUserEffectivePermissions();
    return sp.web.hasPermissions(permissions, PermissionKind.ManageLists);
  }

  public async deletePlanItem(plan: IVistoPlan, deletePages?: boolean) {

    const sp = AuthService.getSpClient(plan.siteUrl);

    const kindList = [
      VistoKind.Focus,
      VistoKind.SO,
      VistoKind.LOP,
      VistoKind.DP,
      VistoKind.Action,
      VistoKind.Assoc,
      VistoKind.KeyResult,
      VistoKind.Effect,
      VistoKind.KRV,
    ];

    for (const kind of kindList) {
      const listUrl = UrlService.getListRelativeUrl(plan, kind);
      await sp.web.getList(listUrl).recycle();
    }

    const planListUrl = UrlService.getListRelativeUrl(plan);
    const spPlanList = sp.web.getList(planListUrl);
    const spPlanItems = await spPlanList.items.select(VistoPlanListFields.itemId.name, VistoPlanListFields.planId.name)();

    let planCount = spPlanItems.length;
    for (let i = 0; i < spPlanItems.length; ++i) {
      const spPlanItem = spPlanItems[i];
      const itemPlanId = spPlanItem[VistoPlanListFields.planId.name];
      const itemId = spPlanItem[VistoPlanListFields.itemId.name];
      if (plan.planId === itemPlanId) {
        sp.web.getList(planListUrl).items.getById(itemId).recycle().then(() => {
          --planCount;
        });
      }
    }

    if (planCount === 0) {
      await spPlanList.recycle();
    }

    if (deletePages) {

      const pagesListUrl = UrlService.makeSitePagesListUrl(plan.siteUrl);
      const pagesList = sp.web.getList(pagesListUrl);

      const planFileName = `${TextService.makePlanFileName(plan.planId)}.aspx`;
      const matrixFileName = `${TextService.makePlanFileName(plan.planId)}.aspx`;

      const pages = await pagesList.items
        .select('ID', 'FileLeafRef')
        .filter(`FileLeafRef eq '${planFileName}' or FileLeafRef eq '${matrixFileName}'`)
        ();

      for (const page of pages) {
        await pagesList.items.getById(page.Id).recycle();
      }

      const links = await sp.web.navigation.quicklaunch.select('Url', 'ID')();

      for (const link of links) {
        if (link.Url.indexOf(planFileName) >= 0 || link.Url.indexOf(matrixFileName) >= 0)
          await sp.web.navigation.quicklaunch.getById(link.Id).delete();
      }
    }

    StorageCacheService.resetCache();
  }

  public static async getSiteId(absoluteUrl: string): Promise<string> {
    const result = await AuthService.getSpClient(absoluteUrl, 'short').site.select('ID')();
    return result.Id;
  }

  public static async getWebId(absoluteUrl: string): Promise<string> {
    const result = await AuthService.getSpClient(absoluteUrl, 'short').web.select('ID')();
    return result.Id;
  }

  public static async getWebProperties(url: string): Promise<any> {
    const sp = AuthService.getSpClient(url, 'long');
    const web = await sp.web.select('AllProperties').expand('AllProperties')();
    return web['AllProperties'];
  }

  public async loadItem(plan: IVistoPlan, kind: VistoKind, itemGuid: string, notify: INotify): Promise<IVistoListItem> {

    const sp = AuthService.getSpClient(plan.siteUrl);
    const updater = new ListItemUpdater(plan.timeZoneBias);

    updater.getKnownItemByKindAndId = (knownItemKind, knownItemId) => PlanDataService.getItemByKindAndId(plan.items, knownItemKind, knownItemId);

    const spListUrl = UrlService.getListRelativeUrl(plan, kind);
    const spList = sp.web.getList(spListUrl);
    const definition = getListDefinition<IVistoListItem>(kind);
    const spItems = await spList.items.filter(`${definition.fields.guid.name} eq '${itemGuid}'`)();

    if (spItems.length > 1) {
      notifyInfoBar(notify, {
        type: NotificationType.warn,
        message: TextService.format(strings.WarningMessage_DuplicateGuid),
        error: TextService.format(strings.WarningMessage_DuplicateGuidDetails, { vistoKindName: TextService.getVistoKindName(kind), guid: itemGuid })
      });
    }

    const spItem = spItems[0];
    const newItem = SharepointService.getItemFromSpItem(updater, spItem, kind);
    await updater.resolveAll(plan.siteUrl);
    return newItem;
  }

  public static async ensureUploadFolder(siteUrl: string, uploadPath: string) {
    const sp = AuthService.getSpClient(siteUrl);
    try {
      const existingFolder = sp.web.getFolderByServerRelativePath(uploadPath);
      let folderInfo = await existingFolder.select('Exists')();
      if (folderInfo.Exists) {
        return existingFolder;
      } else {
        const { folder: newFolder } = await sp.web.folders.addUsingPath(uploadPath);
        return newFolder;
      }
    } catch (error) {
      trackClient.error(`Unable to create folder '${uploadPath}' defaulting to site assets`);
      const siteAssetsLib = await sp.web.lists.ensureSiteAssetsLibrary();
      return siteAssetsLib.rootFolder;
    }
  }

  public async putFile(siteUrl: string, folderRelativeUrl: string, fileName: string, fileConent: File): Promise<IAttachment> {
    const folder = await SharepointService.ensureUploadFolder(siteUrl, folderRelativeUrl);
    const fileInfo = await folder.files.addUsingPath(fileName, fileConent, { Overwrite: true });
    const fileAbsoluteUrl = UrlService.combine(UrlService.getOrigin(siteUrl), fileInfo.data.ServerRelativeUrl);
    return {
      fileName: fileInfo.data.Name,
      fileAbsoluteUrl: fileAbsoluteUrl,
      defaultUrl: fileAbsoluteUrl,
    };
  }

  public static async getItemVersions(plan: IVistoPlan, item: any): Promise<IVersionData[]> {

    const sp = AuthService.getSpClient(plan.siteUrl, 'short');

    const kind = +item.kind;
    const definition = getListDefinition(kind);
    const spListUrl = UrlService.getListRelativeUrl(plan, kind);

    const fieldNames = Object.keys(definition.fields).filter(x => x !== 'createdDate' && x !== 'modifiedDate');

    const fields = [
      'Created',
      'VersionId',
      'VersionLabel',
      'Editor',
      ...fieldNames.map(x => definition.fields[x].name)
    ];

    const historySpItems = await sp.web.getList(spListUrl).items.getById(item.itemId).versions.select(...fields)();
    const updater = new ListItemUpdater(plan.timeZoneBias);
    const result = historySpItems.map(historySpItem => {

      const historyItem: any = {
        created: new Date(historySpItem['Created']),
        versionId: historySpItem['VersionId'],
        versionLabel: historySpItem['VersionLabel'],
        editor: {
          id: historySpItem['Editor']?.LookupId,
          userName: historySpItem['Editor']?.Email,
          title: historySpItem['Editor']?.LookupValue,
        },
      };

      for (const fieldName of fieldNames) {
        updater.getItemSpFieldUsingFieldName<IVistoListItem>(definition.fields[fieldName], historyItem, historySpItem, fieldName as any, true);
      }

      return historyItem;
    });

    return result;
  }

  public static getSiteUrl(fileAbsoluteUrl: string) {
    const origin = UrlService.getOrigin(fileAbsoluteUrl);
    const pathname = UrlService.getPathName(fileAbsoluteUrl);
    return pathname.startsWith('/sites/')
      ? UrlService.combine(origin, pathname.split('/').slice(0, 3).join('/'))
      : '';
  }

  private static getFileType(fileAbsoluteUrl: string) {
    const url = new URL(fileAbsoluteUrl);
    const type = mime.getType(url.pathname.split('.').pop());
    return type;
  }

  public static async getTemplatesPath(folderAbsoluteUrl: string) {
    try {
      const siteUrl = SharepointService.getSiteUrl(folderAbsoluteUrl);
      const spSite = AuthService.getSpClient(siteUrl);
      const sitePropertyBag = await spSite.web.allProperties();
      const siteValue = sitePropertyBag.VISPLANTEMPLATES;
      if (siteValue) {
        return siteValue;
      }
    } catch (err) {
      trackClient.warn(`Unable to get site properties. Template path may be not defined`, err);
    }
    try {
      const tenantUrl = UrlService.getOrigin(folderAbsoluteUrl);
      const spTenant = AuthService.getSpClient(tenantUrl);
      const tenantPropertyBag = await spTenant.web.allProperties();
      const tenantValue = tenantPropertyBag.VISPLANTEMPLATES;
      if (tenantValue) {
        return tenantValue;
      }
    } catch (err) {
      trackClient.warn(`Unable to tenant properties. Template path may be not defined`, err);
    }
    return folderAbsoluteUrl;
  }

  public async getTemplates(url: string): Promise<ITemplate[]> {
    const folderAbsoluteUrl = await SharepointService.getTemplatesPath(url);
    const siteUrl = SharepointService.getSiteUrl(folderAbsoluteUrl);
    const sp = AuthService.getSpClient(siteUrl);
    const serverRelativePath = UrlService.getPathName(folderAbsoluteUrl);
    const origin = UrlService.getOrigin(folderAbsoluteUrl);
    const folder = sp.web.getFolderByServerRelativePath(decodeURIComponent(serverRelativePath));
    const files = await folder.files.select('ServerRelativeUrl', 'Name', 'Title')();
    return files.filter(x => x.Name.endsWith('.visplan.template.json')).map(f => ({
      id: `${origin}${f.ServerRelativeUrl}`,
      name: TextService.removeSuffix(f.Name, '.visplan.template.json'),
      description: f.Title,
      planJson: null
    }));
  }

  public async getFile(fileAbsoluteUrl: string) {
    const siteUrl = SharepointService.getSiteUrl(fileAbsoluteUrl);
    const sp = AuthService.getSpClient(siteUrl);
    const file = sp.web.getFileByUrl(fileAbsoluteUrl);
    const data = await file.getBuffer();
    const type = SharepointService.getFileType(fileAbsoluteUrl);
    return new Blob([data], { type });
  }

  public static async makeWebPartContext(siteUrl: string, title: string, locale: string) {
    const isLocalUrl = UrlService.isLocalUrl(siteUrl);
    const webId = siteUrl && !isLocalUrl && new Guid(await SharepointService.getWebId(siteUrl));
    const siteId = siteUrl && !isLocalUrl && new Guid(await SharepointService.getSiteId(siteUrl));
    return {
      pageContext: {
        web: {
          serverRelativeUrl: UrlService.getPathName(siteUrl),
          absoluteUrl: siteUrl,
          title: title,
          id: webId
        },
        site: {
          id: siteId
        },
        cultureInfo: {
          currentCultureName: locale
        },
      },
      httpClient: {
        fetch: (input: string, config?: any, init?: any) => fetch(input, {...init }),
        get: (input: string, config?: any, init?: any) => fetch(input, {...init, method: 'GET' }),
        post: (input: string, config?: any, init?: any) => fetch(input, {...init, method: 'POST' }),
      },
      spHttpClient: {
        fetch: (input: string, config?: any, init?: any) => AuthService.fetch(api.TokenKind.sharepoint, input, {...init }),
        get: (input: string, config?: any, init?: any) => AuthService.fetch(api.TokenKind.sharepoint, input, {...init, method: 'GET' }),
        post: (input: string, config?: any, init?: any) => AuthService.fetch(api.TokenKind.sharepoint, input, {...init, method: 'POST' }),
      }
    };
  }

  public static async getStableUrl(fileAbsoluteUrl: string) {

    try {
      const siteUrl = SharepointService.getSiteUrl(fileAbsoluteUrl);
      const sp = AuthService.getSpClient(siteUrl);
      const spItem = await sp.web.getFileByUrl(fileAbsoluteUrl).listItemAllFields();

      // if we got ServerRedirectedEmbedUrl feature (2019+)
      if (spItem.ServerRedirectedEmbedUrl) {
        if (fileAbsoluteUrl.endsWith('.pdf')) {
          return spItem.ServerRedirectedEmbedUrl;
        } else {
          return spItem.ServerRedirectedEmbedUrl.replace('&action=interactivepreview', '&action=default');
        }
      }

      // if we have docid feature enabled
      if (spItem?.OData__dlc_DocIdUrl?.Url) {
        return spItem.OData__dlc_DocIdUrl?.Url;
      }
    } catch (error) {
      trackClient.warn(`Unable to extract file name from URL`, error);
    }
  }

  public async makeAttachment(fileName: string, fileAbsoluteUrl: string, siteUrl: string): Promise<IAttachment> {

    const result: IAttachment = {
      fileName,
      fileAbsoluteUrl,
      defaultUrl: fileAbsoluteUrl
    };

    if (/\.aspx$|\.html$|\.htm$|\.url$/.test(fileName)) {

      // specaial case - office doc redirector, take name from the url
      if (fileName === 'Doc.aspx') {
        const resolvedName = fileAbsoluteUrl.match(/&file=([^&]+)/)[1];
        if (resolvedName)
          result.fileName = decodeURIComponent(resolvedName);
      }
    } else if (UrlService.haveSameOrigin(fileAbsoluteUrl, siteUrl)) {

      const defaultUrl = await SharepointService.getStableUrl(fileAbsoluteUrl);
      if (defaultUrl) {
        result.defaultUrl = defaultUrl;
      }
    } else if (UrlService.haveSameOrigin(fileAbsoluteUrl, 'https://teams.microsoft.com')) {
      try {
        const url = new URL(fileAbsoluteUrl);
        const objectUrl = url.searchParams.get('objectUrl');
        if (objectUrl) {
          const fileUrl = new URL(objectUrl);
          result.fileName = decodeURI(TextService.splitPath(fileUrl).pop());
        }
      } catch (error) {
        trackClient.warn(`Unable to extract file name from URL ${fileAbsoluteUrl}`, error);
      }
    }

    return result;
  }

  public async getPlanItems(siteUrl: string, fields: string[], caching: CachingType, ids?: string[]): Promise<IVistoPlan[]> {

    const sp = AuthService.getSpClient(siteUrl, caching);

    let query = sp.web
      .getList(UrlService.getListRelativeUrl({ siteUrl, planId: null }))
      .items;

    if (fields) {
      query = query
        .select(...[VistoPlanListFields.planId.name, ...fields.map(f => VistoPlanListFields[f].name)]);
    }

    if (0 < ids?.length && ids?.length <= 10) {
      query = query.filter(ids.map(id => `${VistoPlanListFields.planId.name} eq '${id}'`).join(' or '));
    }

    let planItems = await query();

    if (ids?.length > 0) {
      planItems = planItems.filter(x => ids.includes(x[VistoPlanListFields.planId.name]));
    }

    // is it a permission problem?
    if (ids?.length > 0 && planItems.length === 0) {
      const permissions = await sp.web.getCurrentUserEffectivePermissions();
      if (!sp.web.hasPermissions(permissions, PermissionKind.ViewListItems)) {
        throw new Error(`Unable to access plans at ${siteUrl}`);
      }
    }

    const fieldKeys = fields || Object.keys(VistoPlanListFields);

    const uniquePlanSet = {};
    for (const planItem of planItems) {
      const planId = planItem[VistoPlanListFields.planId.name];
      if (!uniquePlanSet[planId]) {
        const fieldValues = fieldKeys.reduce((r, f) => ({ ...r, [f]: planItem[VistoPlanListFields[f].name] }), {});
        uniquePlanSet[planId] =  { ...fieldValues, siteUrl } as IVistoPlan;
      } else {
        trackClient.warn(`Duplicate plan id ${planId} at ${siteUrl}`);
      }
    }

    const uniquePlans = getObjectValues<IVistoPlan>(uniquePlanSet);
    return uniquePlans;
  }


  public makeConsentNotification(callback: () => Promise<void>, siteUrl: string, notify: IBasicNotify) {

    notifyInfoBar(notify, {
      message: TextService.format(strings.AuthContext_ConsentRequired),
      group: 'SharePoint_Consent',
      type: NotificationType.warn,
      error: TextService.format(strings.AuthContext_ConsentRequiredExplanation),
      actions: [
        {
          title: TextService.format(strings.AuthContext_ConsentRequiredAction),
          action: async () => {
            try {
              await AuthService.getConsent(api.TokenKind.sharepoint, UrlService.getDomain(siteUrl), callback);
              clearInfoBar(notify, 'SharePoint_Consent');
              notifyInfoBar(notify, { type: NotificationType.success, message: TextService.format(strings.SharePointNotification_ConsentGrant), group: 'SharePoint_Consent' });
            } catch (error) {
              const message = TextService.format(strings.AuthService_ErrorGetConsent);
              notifyInfoBar(notify, { type: NotificationType.error, message, error, group: 'SharePoint_Consent' });
            }
          }
        }
      ]
    });
  }

}
