import * as React from 'react';
import { Stack } from '@fluentui/react';
import { ColorComboBox } from './ColorComboBox';
import { DashButton, IDashStyle } from './DashButton';
import { WidthButton } from './WidthButton';
import { ArrowButton, IArrowStyle } from './ArrowButton';
import * as strings from 'VistoWebPartStrings';
import { TextService } from 'services/TextService';
import { AppContext } from 'services/AppContext';

export function StrokeStyleButton(props: {
  disabled: boolean;
  color: string; setColor: (val: string) => void;
  width: string; setWidth: (val: string) => void;
  dashStyle: IDashStyle; setDashStyle: (val: IDashStyle) => void;
  arrowStyle: IArrowStyle; setArrowStyle: (val: IArrowStyle) => void;
}) {

  const { propertyBag, setPropertyBag } = React.useContext(AppContext);

  const setColor = (val) => {
    setPropertyBag({ strokeColor : val });
    props.setColor(val);
  };

  return (
    <ColorComboBox
      disabled={props.disabled}
      buttonColor={propertyBag.strokeColor}
      iconName='Brush'
      title={TextService.format(strings.StrokeStyleButton_Title)}
      color={props.color}
      setColor={setColor}
      isToolbar
      extraItems={
        <Stack>
          <WidthButton width={props.width} setWidth={props.setWidth} />
          <DashButton dashStyle={props.dashStyle} setDashStyle={props.setDashStyle} />
          <ArrowButton arrowStyle={props.arrowStyle} setArrowStyle={props.setArrowStyle} />
        </Stack>}
    />
  );
}
