import * as React from 'react';
import { IVistoListItemWithProgress, IVistoPlan } from 'sp';
import { IIconInfo } from 'services/IIconInfo';
import { useEnvNavigate } from 'services/NavigationService';
import { IntegrationService } from 'services/IntegrationService';
import { EnvContext, isTeams } from 'services/EnvContext';

export function IconButtonProgress(props: {
  plan: IVistoPlan;
  item: IVistoListItemWithProgress;
  disabled: boolean;
}) {

  if (!props.item.sourceItemUrl)
    return null;

  const href = IntegrationService.getBrowserLink(props.item.sourceItemUrl);
  const [iconInfo, setIconInfo] = React.useState<IIconInfo>(null);
  React.useEffect(() => {
    IntegrationService.getIconInfo(props.item.sourceItemUrl).then(info => setIconInfo(info));
  }, [props.item.sourceItemUrl])

  const { hostKind } = React.useContext(EnvContext);
  const navigate = useEnvNavigate(isTeams(hostKind));

  const onClick = (ev) => {
    ev.preventDefault();
    navigate(href, '_blank');
  }

  return (iconInfo ?
    <a href={href} target={props.item.guid} style={{ display: 'flex' }} onClick={onClick} title={iconInfo.tooltipText}>
      <img src={iconInfo.iconUrl} style={{ width: 15, padding: '0 4px' }} />
    </a>
    : null
  );
}
