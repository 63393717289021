import * as React from 'react';
import { IconButton, FontSizes, IContextualMenuItem } from '@fluentui/react';

export function IconButtonMore(props: {
  fontSize?: string;
  menuItems: IContextualMenuItem[]
}) {
  return (<IconButton
    menuIconProps={{ iconName: 'MoreVertical', style: { fontSize: props.fontSize } }}
    menuProps={{ items: props.menuItems }}
  />);
}
