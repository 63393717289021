import * as React from 'react';

import { IVistoPlan, VistoFocusItem } from 'sp';
import { ItemEditDialog } from 'dialogs/common';
import { TitleBlock } from 'components';
import { DatePicker, FontSizes, Stack } from '@fluentui/react';
import { AppContext } from 'services/AppContext';
import { CommandsFocus } from 'services/CommandFocus';
import { ProgressService } from 'services/ProgressService';
import { TextService } from 'services/TextService';
import { trackClient } from 'shared/clientTelemetry';
import strings from 'VistoWebPartStrings';
import { AssigneePicker } from './common/AssigneePicker';
import { StorageService } from 'services/StorageService';

export function EditFocusDialog(props: {
  plan: IVistoPlan;
  focus: VistoFocusItem;
  onDismiss: (changed: boolean, focus?: VistoFocusItem) => void;
}) {

  React.useEffect(() => trackClient.page('EditFocusDialog'), []);

  const { isPlanEditEnabled, dispatchCommand, notify } = React.useContext(AppContext);

  const oldFocus = props.focus;
  const [newFocus, setNewFocus] = React.useState<VistoFocusItem>({ ...oldFocus });

  const onCommit = async () => {
    const cmd = CommandsFocus.makeSaveFocusCommand(props.plan, oldFocus, newFocus, notify);
    await dispatchCommand(cmd, { wrap: false });
  }

  return (
    <ItemEditDialog
      onDismiss={(changed) => props.onDismiss(changed, newFocus)}
      onLoaded={loaded => setNewFocus({ ...newFocus, ...loaded })}
      plan={props.plan}
      oldItem={oldFocus}
      newItem={newFocus}
      onCommit={onCommit}
      validationIssues={!newFocus.name}
      content={
        <Stack tokens={{ childrenGap: 's1' }}>
          <TitleBlock
            fontSize={FontSizes.xLarge}
            edit={isPlanEditEnabled}
            name={newFocus.name}
            description={newFocus.description}
            nameDisabled={!isPlanEditEnabled || !ProgressService.allowEdit(newFocus, 'name')}
            descriptionDisabled={!isPlanEditEnabled || !ProgressService.allowEdit(newFocus, 'description')}
            onNameChanged={val => setNewFocus({ ...newFocus, name: val })}
            onDescriptionChanged={val => newFocus.description = val}
            assignedToDisabled={!isPlanEditEnabled || !ProgressService.allowEdit(newFocus, 'assignedTo') || !StorageService.get(props.plan.siteUrl).assigneeSupported}
            onAssignedToChanged={assignedTo => setNewFocus({ ...newFocus, assignedTo: assignedTo })}
            assignedTo={newFocus.assignedTo}
          />
          <Stack horizontal tokens={{ childrenGap: 'm' }}>
            <DatePicker
              today={props.plan.statusDate}
              initialPickerDate={newFocus.startDate || props.plan.statusDate}
              strings={TextService.datePickerStrings}
              firstDayOfWeek={TextService.firstDayOfWeek}
              formatDate={d => TextService.formatDate(d)}
              label={TextService.format(strings.EditFocusDialog_StartDateLabel)}
              disabled={!isPlanEditEnabled}
              value={newFocus.startDate} allowTextInput={true}
              onSelectDate={val => setNewFocus({ ...newFocus, startDate: val })}
            />
            <DatePicker
              today={props.plan.statusDate}
              initialPickerDate={newFocus.endDate || props.plan.statusDate}
              strings={TextService.datePickerStrings}
              firstDayOfWeek={TextService.firstDayOfWeek}
              formatDate={d => TextService.formatDate(d)}
              label={TextService.format(strings.EditFocusDialog_EndDateLabel)}
              disabled={!isPlanEditEnabled}
              value={newFocus.endDate} allowTextInput={true}
              onSelectDate={val => setNewFocus({ ...newFocus, endDate: val })}
            />
          </Stack>
        </Stack>
      }
    />);

}
