import * as React from 'react';
import { ComboBox, IComboBoxOption, SelectableOptionMenuItemType } from '@fluentui/react';
import { mxglobals } from '../drawing/common';
import { IFont, CustomFontDialog } from 'dialogs';
import * as strings from 'VistoWebPartStrings';
import { TextService } from 'services/TextService';

const onRenderOption = (item: IComboBoxOption) => {
  switch (item.itemType) {
    case SelectableOptionMenuItemType.Header:
    case SelectableOptionMenuItemType.Divider:
      return <span>{item.text}</span>;

    default:
      return <span style={{ fontFamily: item.text }}>{item.text}</span>;
  }
};

const builtinFonts = [
  'Arial',
  'Arial Black',
  'Calibri',
  'Courier New',
  'Georgia',
  'Impact',
  'Microsoft Sans Serif',
  'Segoe UI',
  'Tahoma',
  'Times New Roman',
  'Verdana'
];

export function FontNameComboBox(props: {
  selectedFont: IFont;
  setSelectedFont: (val: IFont) => void;
  disabled: boolean;
}) {

  const builtinOptions: IComboBoxOption[] = builtinFonts.map(x => ({
    key: x,
    text: x,
    data: { name: x, url: null }
  }));

  const customFonts = Object.keys(mxglobals.Graph.recentCustomFonts).map(x => mxglobals.Graph.recentCustomFonts[x]);

  const customOptions: IComboBoxOption[] = customFonts.map(x => ({
    key: x.name,
    text: x.name,
    data: x
  }));

  const options: IComboBoxOption[] = [
    { key: 'section1', text: TextService.format(strings.FontComboBox_BuiltIn), itemType: SelectableOptionMenuItemType.Header },
    ...builtinOptions,
    { key: 'section2', text: TextService.format(strings.FontComboBox_Custom), itemType: SelectableOptionMenuItemType.Header },
    ...customOptions,
    { key: 'sep2', text: '', itemType: SelectableOptionMenuItemType.Divider },
    { key: '__custom__', text: TextService.format(strings.FontComboBox_CustomOther) }
  ];

  const [showDialog, setShowDialog] = React.useState(false);

  const onChange = (_, option: IComboBoxOption, index: number, value: string) => {
    if (option.key === '__custom__') {
      setShowDialog(true);
    } else {
      const selected: IFont = value ? { name: value, url: null } : option.data;
      if (selected?.name)
        props.setSelectedFont(selected);
    }
  };

  return (
    <React.Fragment>
      <ComboBox
        disabled={props.disabled}
        text={props.selectedFont?.name}
        selectedKey={props.selectedFont?.name}
        styles={{
          container: {
            margin: '0 2px 0 2px 0',
          },
          optionsContainerWrapper: {
            maxHeight: 600
          }
        }}
        allowFreeform
        autoComplete='on'
        onChange={onChange}
        options={options}
        onRenderOption={onRenderOption}
      />
      {showDialog && <CustomFontDialog onDismiss={() => setShowDialog(false)} font={props.selectedFont} setFont={props.setSelectedFont} />}
    </React.Fragment>
  );
}
