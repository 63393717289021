import { Dropdown, Icon, IDropdownOption, Stack, Text } from '@fluentui/react';
import strings from 'VistoWebPartStrings';
import React from 'react';
import { TextService } from 'services/TextService';
import { VistoIcon } from 'components';

export const ConfidencePicker = (props: {
  disabled?: boolean;
  label: string;
  confidence: number;
  setConfidence: (confidence: number) => void;
}) => {

  const confidenceOptions = [
    { key: 9, text: TextService.format(strings.EditAssocDialog_ConfidenceHigh), data: { icon: VistoIcon.AssocConfidence9 } },
    { key: 3, text: TextService.format(strings.EditAssocDialog_ConfidenceMedium), data: { icon: VistoIcon.AssocConfidence3 } },
    { key: 1, text: TextService.format(strings.EditAssocDialog_ConfidenceLow), data: { icon: VistoIcon.AssocConfidence1 } },
  ];

  const onRenderOption = (option: IDropdownOption): JSX.Element => {
    return (
      <Stack horizontal verticalAlign='center' style={{ marginTop: -1 }} tokens={{ childrenGap: 's1' }}>
        {option.data && option.data.icon && (
          <Icon style={{ marginTop: 3 }} iconName={option.data.icon} aria-hidden='true' title={option.data.icon} />
        )}
        <Text>{option.text}</Text>
      </Stack>
    );
  };

  const onRenderTitle = (options: IDropdownOption[]) => {
    return onRenderOption(options[0]);
  };

  return (
    <Dropdown
      required
      disabled={props.disabled}
      label={TextService.format(strings.EditAssocDialog_ConfidenceSelectorLabel)}
      selectedKey={props.confidence}
      onChange={(_, val) => props.setConfidence(+val.key)}
      onRenderTitle={onRenderTitle}
      onRenderOption={onRenderOption}
      options={confidenceOptions}
    />
  );
}
