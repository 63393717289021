import React from 'react';

const TooltipRow = (rowProps: { label: string; value: string; color?: string }) => (
  <tr className='ms-Table-row'>
    <td className='ms-Table-cell'>{rowProps.label}</td>
    <td style={{ textAlign: 'right', fontWeight: 'bold', paddingLeft: '2em', color: rowProps.color }} className='ms-Table-cell'>{rowProps.value}</td>
  </tr>
);

export const ProgressBarTooltip = (props: {
  rows: { label: string, value: string, color?: string }[]
}) => (
  <table className='ms-Table'>
    <tbody>
      {props.rows.map((row, index) => <TooltipRow key={index} {...row} />)}
    </tbody>
  </table>
);
