import { DefaultButton, Icon, Stack, useTheme } from '@fluentui/react';
import { FilePicker, IFilePickerResult } from '@pnp/spfx-controls-react/lib/controls/filePicker';
import { AppFolderExplorer } from 'components/AppFolderExplorer';
import React from 'react';
import { EnvContext } from 'services/EnvContext';
import { ImageCacheService } from 'services/ImageCacheService';
import { StorageService } from 'services/StorageService';
import { TextService } from 'services/TextService';
import { UrlService } from 'shared/urlService';
import strings from 'VistoWebPartStrings';
import { FixedViewType } from '../common/mx';
import { IImageSrc } from './IImageInfo';

export const ImagePicker = (props: {
  imageSrc: IImageSrc;
  setImageSrc: (val: IImageSrc) => void;
  opacity: number;
  resizeType: FixedViewType;
  backgroundColor: string;

  isPickerOpen: boolean;
  setIsPickerOpen: (val: boolean) => void;
  previewWidth: number;
  previewHeight: number;
  getPreview: () => Promise<JSX.Element>;
}) => {

  console.log('redering ImagePicker', props);

  const theme = useTheme();

  const { webPartContext, siteUrl, defaultFolderRelativeUrl } = React.useContext(EnvContext);

  const attachmentsDisabled = !StorageService.get(siteUrl).attachmentsSupported;

  const [selectedFolder, setSelectedFolder] = React.useState(defaultFolderRelativeUrl);

  const [isLoading, setIsLoading] = React.useState(false);

  const [preview, setPreview] = React.useState<JSX.Element>(null);

  React.useEffect(() => {
    setIsLoading(true);
    const timeout = setTimeout(() => {
      props.getPreview().then(elem => {
        setPreview(elem);
        setIsLoading(false);
      }, err => {
        setIsLoading(false);
      });
    }, 200);
    return () => clearTimeout(timeout);
  }, [props.imageSrc]);

  const updateSrc = (src: string) => {

    if (src) {
      const image = new Image();
      image.src = src;

      const onload = () => {
        props.setImageSrc({ src: src, width: image.naturalWidth, height: image.naturalHeight });
      };

      const onerror = () => {
        ImageCacheService.getImageAsync(src, url => StorageService.get(url).getFile(url)).then(resolved => {
          if (resolved) {
            image.src = resolved;
          }
        });
      };

      if (image.complete) {
        onload();
      } else {
        image.onload = onload;
        image.onerror = onerror;
      }
    } else {
      props.setImageSrc({ src: null, width: null, height: null });
    }
  };

  const onUploadImage = async (results: IFilePickerResult[]) => {

    for (const result of results) {

      if (!result.fileAbsoluteUrl) {

        const fileConent = await result.downloadFileContent();
        const addedFile = await StorageService.get(selectedFolder).putFile(siteUrl, selectedFolder, result.fileName, fileConent);
        result.fileName = addedFile.fileName;
        result.fileAbsoluteUrl = addedFile.fileAbsoluteUrl;
      }

      updateSrc(result.fileAbsoluteUrl);
    }
    props.setIsPickerOpen(false);
  };

  const onRemoveImage = () => {
    updateSrc(null);
    props.setIsPickerOpen(false);
  };

  const renderCustomUploadTabContent = () => (
    <AppFolderExplorer selectedFolder={selectedFolder} setSelectedFolder={setSelectedFolder} />
  );

  // disable stock images on iOS because of the Teams bug 
  // see https://github.com/OfficeDev/microsoft-teams-library-js/issues/1941
  const stockImagesDisabled = navigator.userAgent.indexOf('TeamsMobile-iOS') >= 0;

  return (
    <Stack tokens={{ childrenGap: 's1' }}>
      <div
        style={{
          width: props.previewWidth,
          height: props.previewHeight,
          backgroundColor: props.backgroundColor,
          border: `1px solid ${theme.palette.neutralTertiary}`,
        }}>
        {preview}
        {isLoading && <Icon iconName='ProgressLoopOuter'
          style={{
            fontSize: 100,
            opacity: 0.25,
            position: 'absolute',
            left: 100,
            top: 50,
            animation: 'visplanLoadingIndicatorRotate 3s linear infinite'
          }}
        />}
      </div>

      <Stack horizontal tokens={{ childrenGap: 'm' }}>
        <FilePicker
          hideOneDriveTab
          hideRecentTab
          hideOrganisationalAssetTab
          hideStockImages={stockImagesDisabled}
          hideSiteFilesTab={attachmentsDisabled}
          hideLocalUploadTab={attachmentsDisabled}
          storeLastActiveTab={false}
          isPanelOpen={props.isPickerOpen}
          accepts={['.gif', '.jpg', '.jpeg', '.bmp', '.dib', '.tif', '.tiff', '.ico', '.png', '.jxr', '.svg', '.webp']}
          buttonLabel={TextService.format(strings.ImageDialog_ChangeButton)}
          onSave={onUploadImage}
          context={webPartContext as any}
          defaultFolderAbsolutePath={UrlService.combine(UrlService.getOrigin(siteUrl), defaultFolderRelativeUrl)}
          renderCustomUploadTabContent={renderCustomUploadTabContent}
        />
        <DefaultButton text={TextService.format(strings.ImageDialog_ResetButton)} disabled={!props.imageSrc.src} onClick={onRemoveImage} />
      </Stack>
    </Stack>
  );
}
