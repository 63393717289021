import { PnPClientStorage } from '@pnp/core';
import { api } from 'shared/api';
import { AuthService } from 'services/AuthService';
import { IProgressData } from 'services/IProgressData';
import { StorageCacheService } from 'services/StorageCacheService';
import { TextService } from 'services/TextService';
import { trackClient } from 'shared/clientTelemetry';
import { parseResponseError, parseResponseJSON } from 'shared/parse';
import { IFieldValueUser, IVistoPlan } from 'sp';
import { PlanSettingsService } from 'services/PlanSettingsService';
import { ImageCacheService } from 'services/ImageCacheService';
import { UrlService } from 'shared/urlService';
import { ApiService } from 'services/ApiService';

export interface IDevOpsWorkItem {
  id: string;
  fields: { [key: string]: any };
  relations: { rel: string; url: string; attributes: { isLocked: boolean; name: string; } }[];
}

const makeItemTypeList = (itemTypeNames: string[]) => itemTypeNames.map(x => `'${x}'`).join(',');

export interface IDevOpsProject {
  id: string;
  name: string;
}

export interface IDevOpsItemType {
  name: string;
  icon: string;
  color: string;
}

export interface IDevOpsIteration {
    id: number;
    identifier: string;
    name: string;
    structureType: string;
    hasChildren: boolean;
    children?: IDevOpsIteration[];
    attributes: {
      startDate?: Date;
      finishDate?: Date;
    }
    path: string;
}

const makeProgressQuery = (itemTypeNames: string[], ids: string[], condition: string) => `
SELECT 
  [System.Id],
  [System.WorkItemType],
  [System.Title],
  [System.State]
FROM workitemLinks
WHERE 
  [Source].[System.WorkItemType] IN (${makeItemTypeList(itemTypeNames)})
  AND [Source].[System.Id] IN (${ids.join(',')})
  AND [Source].[System.State] <> 'Removed'
  AND [System.Links.LinkType] = 'System.LinkTypes.Hierarchy-Forward'
  AND ${condition}
ORDER BY [System.Id]
MODE (MustContain)`;

const makeProgressQueryAll = (itemTypeNames: string[], ids: string[]) => makeProgressQuery(itemTypeNames, ids, `[Target].[System.State] <> 'Removed'`);
const makeProgressQuery50 = (itemTypeNames: string[], ids: string[]) => makeProgressQuery(itemTypeNames, ids, `[Target].[System.State] IN ('Active', 'Doing', 'Committed', 'In Progress')`);
const makeProgressQuery75 = (itemTypeNames: string[], ids: string[]) => makeProgressQuery(itemTypeNames, ids, `[Target].[System.State] IN ('Resolved', 'Ready')`);
const makeProgressQuery100 = (itemTypeNames: string[], ids: string[]) => makeProgressQuery(itemTypeNames, ids, `[Target].[System.State] IN ('Done', 'Closed')`);

const makeSearchQuery = (projectName: string, text: string, itemTypeNames: string[]) => `
SELECT
  [System.Id],
  [System.WorkItemType],
  [System.Title],
  [System.State]
FROM workitems
WHERE
  [System.TeamProject] = '${projectName}'
  AND [System.WorkItemType] IN (${makeItemTypeList(itemTypeNames)})
  AND [System.State] <> 'Removed'
  AND ([System.Title] CONTAINS '${text}' OR [System.Id] = ${isNaN(+text) ? 0 : +text})`;


const mapFieldName = (key: string) => {
  switch (key as keyof IProgressData) {
    case 'name':
      return '/fields/System.Title';
    case 'description':
      return '/fields/System.Description';
    case 'startDate':
      return '/fields/Microsoft.VSTS.Scheduling.StartDate';
    case 'endDate':
      return '/fields/Microsoft.VSTS.Scheduling.TargetDate';
    case 'assignedTo':
      return '/fields/System.AssignedTo';
  }
};

const getAssignedToUserName = (val: IFieldValueUser[]) => {
  return val.length ? val[0].userName : '';
};

const getDevOpsDescription = (val: string) => {
  const lines = TextService.htmlToLines(val, ['p']);
  return (lines.length < val.length)
    ? TextService.linesToHtml(lines, 'div')
    : val;
};

const mapFieldValue = (key: string, val: any) => {
  switch (key as keyof IProgressData) {
    case 'assignedTo':
      return getAssignedToUserName(val);
    case 'description':
      return getDevOpsDescription(val);
    default:
      return val;
  }
};

export class DevOpsService {

  static getPlanDevOpsUrl(plan: IVistoPlan) {
    const planSettings = PlanSettingsService.getPlanSettings(plan);
    return planSettings?.integrations?.devops?.devopsUrl;
  }

  public static getDefaultItemTypes(): IDevOpsItemType[] {
    return [{
      name: 'Feature',
      icon: 'icon_trophy',
      color: '773B93'
    }, {
      name: 'Epic',
      icon: 'icon_crown',
      color: 'E06C00'
    }, {
      name: 'Product Backlog Item',
      icon: 'icon_list',
      color: '0098C7'
    }, {
      name: 'Task',
      icon: 'icon_clipboard',
      color: 'A4880A'
    }];
  }

  public static getDefaultItemTypeNames(): string[] {
    return ['Feature', 'Epic'];
  }

  public static async getProjectIdFromProjectName(devopsUrl: string, projectName: string) {
    const projects = await this.getProjects(devopsUrl);
    return projects.find(x => x.name === projectName)?.id;
  }

  public static async getProjectNameFromProjectId(devopsUrl: string, projectId: string) {
    const projects = await this.getProjects(devopsUrl);
    return projects.find(x => x.id === projectId)?.id;
  }

  public static getPlanItemTypeNames(plan: IVistoPlan) {
    const planSettings = PlanSettingsService.getPlanSettings(plan);
    const itemTypeNames = planSettings?.integrations?.devops?.itemTypeNames ?? DevOpsService.getDefaultItemTypeNames();
    return itemTypeNames;
  }

  public static getPlanItemType(plan: IVistoPlan, name: string): IDevOpsItemType {
    const planSettings = PlanSettingsService.getPlanSettings(plan);
    const itemTypes = planSettings?.integrations?.devops?.itemTypes ?? DevOpsService.getDefaultItemTypes();
    return itemTypes.find(x => x.name === name);
  }

  private static cache = new PnPClientStorage();

  private static async fetch(url: string, init?: any): Promise<any> {
    const response = await AuthService.fetch(api.TokenKind.devops, url, init);
    if (response.ok) {
      return await parseResponseJSON(response);
    } else {
      throw await parseResponseError(response);
    }
  }

  private static async get(url: string) {
    return await this.cache.session.getOrPut<any>(url, () => this.fetch(url));
  }

  private static async query(devopsUrl: string, query: string) {
    return await this.fetch(`${devopsUrl}/_apis/wit/wiql?api-version=6.0`, {
      method: 'POST',
      body: JSON.stringify({ query })
    });
  }

  public static async getWorkItemIconUrl(devopsUrl: string, icon: string, color: string): Promise<string> {
    if (devopsUrl && icon && color) {
      const imageUrl = `${devopsUrl}/_apis/wit/workitemicons/${icon}?color=${color}`;
      try {
        const cachedImage = await ImageCacheService.getImageAsync(imageUrl, async (url) => {
          if (url) {
            const token = await AuthService.getAuthToken(api.TokenKind.devops, UrlService.getDomain(devopsUrl));
            const blob = await ApiService.fetchImage(url, token);
            return blob;
          }
        }, 60*24);
        if (cachedImage) {
          return cachedImage;
        }
      } catch (err) {
        trackClient.error(`Unable to fetch icon image ${devopsUrl}`, err);
      }
    }
    return require('static/assets/links/devops.svg');
  }

  public static async getWorkItemTypes(devopsUrl: string) {

    const result = new Map<string, IDevOpsItemType>();
    const processesJson = await this.get(`${devopsUrl}/_apis/work/processes`);
    const processes: { typeId: string; name: string; isEnabled: boolean }[] = processesJson.value;
    for (const process of processes.filter(p => p.isEnabled)) {
      const workItemTypesJson = await this.get(`${devopsUrl}/_apis/work/processes/${process.typeId}/workitemtypes`);
      const workItemTypes: { name: string; icon: string; color: string; isDisabled: boolean }[] = workItemTypesJson.value;
      for (const workItemType of workItemTypes.filter(wit => !wit.isDisabled)) {
        if (!result.has(workItemType.name)) {
          result.set(workItemType.name, {
            name: workItemType.name,
            color: workItemType.color,
            icon: workItemType.icon
          });
        }
      }
    }
    return Array.from(result.values()).sort((a, b) => TextService.compareNames(a.name, b.name));
  }

  public static async getWorkItems(devopsUrl: string, ids: string[], relations?: boolean): Promise<{ [key: string]: IDevOpsWorkItem }> {
    if (ids.length) {
      let url = `${devopsUrl}/_apis/wit/workitems/`;
      url += `?ids=${ids.join(',')}`;
      if (relations) {
        url += `&$expand=relations`;
      }
      url = url + `&api-version=6.0`;

      const json = await this.get(url);
      return json.value && json.value.reduce((r, v) => ({ ...r, [v.id]: v }), {});
    }
  }

  private static cachedIterations: { [devopsUrl: string] : IDevOpsIteration } = {};
  public static async getIterationsTree(devopsUrl: string, projectName: string) {
    const projectUrl = `${devopsUrl}/${projectName}`;
    if (!this.cachedIterations[projectUrl]) {
      const json = await this.get(`${projectUrl}/_apis/wit/classificationnodes/iterations?$depth=4&api-version=6.0`);
      this.cachedIterations[projectUrl] = json;
    }
    return this.cachedIterations[projectUrl];
  }

  private static cachedProjects: { [devopsUrl: string] : IDevOpsProject[] } = {};
  public static async getProjects(devopsUrl: string): Promise<{ id: string, name: string }[]> {
    if (!this.cachedProjects[devopsUrl]) {
      const json = await this.get(`${devopsUrl}/_apis/projects`);
      this.cachedProjects[devopsUrl] = json.value;
    }
    return this.cachedProjects[devopsUrl];
  }

  public static async queryWorkItemBasicData(devopsUrl: string, projectName: string, text: string, itemTypeNames: string[]): Promise<{ [key: string]: { title: string; type: string } }> {
    try {
      const query = makeSearchQuery(projectName, text, itemTypeNames);
      const json = await this.query(devopsUrl, query);
      const ids = json && json.workItems.map(x => x.id);
      const result = ids && await this.getWorkItems(devopsUrl, ids);
      return result ? Object.keys(result).reduce((r, v) => ({
        ...r, [result[v].id]: {
          title: result[v].fields['System.Title'],
          type: result[v].fields['System.WorkItemType']
        }
      }), {}) : {};
    } catch (error) {
      trackClient.error(`Unable to get the list of devops items`, error);
      return {};
    }
  }

  public static async resetCache(devopsUrl: string) {
    await StorageCacheService.resetCache(devopsUrl);
  }

  public static async updateWorkItem(devopsUrl: string, itemId: string, progress: IProgressData) {
    const body = Object.keys(progress).map(key => (
      {
        op: progress[key] ? 'add' : 'remove',
        path: mapFieldName(key),
        ...(progress[key] ? { value: mapFieldValue(key, progress[key]) } : {})
      }));
    this.resetCache(devopsUrl);
    return await this.fetch(`${devopsUrl}/_apis/wit/workitems/${itemId}?api-version=6.0`, {
      method: 'PATCH',
      headers: {
        'Accept': 'application/json;odata=nometadata',
        'Content-Type': 'application/json-patch+json'
      },
      body: JSON.stringify(body)
    });
  }

  public static async queryProgress(devopsUrl: string, itemTypeNames: string[], itemIds: string[]): Promise<{ [key: string]: number }> {

    const [jsonAll, json50, json75, json100] = await Promise.all([
      this.query(devopsUrl, makeProgressQueryAll(itemTypeNames, itemIds)),
      this.query(devopsUrl, makeProgressQuery50(itemTypeNames, itemIds)),
      this.query(devopsUrl, makeProgressQuery75(itemTypeNames, itemIds)),
      this.query(devopsUrl, makeProgressQuery100(itemTypeNames, itemIds)),
    ]);

    const countItems = (itemId: number, workItemRelations: any[]) => {
      return workItemRelations.reduce((r, x) => r + (x?.source?.id === itemId ? 1 : 0), 0);
    };

    const result = {};
    for (const itemId of itemIds) {
      const all = countItems(+itemId, jsonAll.workItemRelations);
      const done50 = countItems(+itemId, json50.workItemRelations);
      const done75 = countItems(+itemId, json75.workItemRelations);
      const done100 = countItems(+itemId, json100.workItemRelations);
      if (all > 0) {
        result[itemId] = Math.round(100 * (0.5 * done50 + 0.75 * done75 + done100) / all);
      }
    }
    return result;
  }

  public static getDevOpsUrl(url: string) {
    const parsed = url && new URL(url);
    return parsed && `${parsed.origin}${TextService.splitPath(parsed).slice(0, 2).join('/')}`;
  }

  public static getProjectName(url: string) {
    const parsed = url && new URL(url);
    const result = TextService.splitPath(parsed)?.[2];
    return result && decodeURIComponent(result);
  }

  public static getWorkItemIdFromSourceUrl(url: string) {
    const matches = url?.match(/_workitems\/edit\/(\d+)/i);
    const result = matches && matches[1];
    return result;
  }

  public static getItemTypeFromSourceUrl(url: string) {
    const parsed = url && new URL(url);
    const result = parsed?.searchParams?.get('type');
    return result;
  }

  public static getWorkItemIdFromApiUrl(url: string) {
    const matches = url?.match(/workItems\/(\d+)/i);
    const result = matches && matches[1];
    return result;
  }

  public static getWorkItemIconFomSourceUrl(url: string) {
    const parsed = url && new URL(url);
    return parsed?.searchParams?.get('icon');
  }

  public static getWorkItemColorFomSourceUrl(url: string) {
    const parsed = url && new URL(url);
    return parsed?.searchParams?.get('color');
  }
}
