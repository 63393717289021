import * as strings from 'VistoWebPartStrings';
import * as React from 'react';
import { Stack, Checkbox, TextField } from '@fluentui/react';
import { TextService } from 'services/TextService';

export const DevOpsWizardStartPage = (props: {
  enabled: boolean;
  setEnabled: (val: boolean) => void;
  devopsUrl: string;
  setDevOpsUrl: (val: string) => void;
}) => {

  return (
    <Stack grow tokens={{ childrenGap: 'm' }}>
      <Checkbox
        label={TextService.format(strings.DevOpsWizard_StartPage_EnableBoxTitle)}
        checked={props.enabled}
        onChange={(_, val) => props.setEnabled(val)}
      />
      <TextField
        disabled={!props.enabled}
        value={props.devopsUrl}
        placeholder={TextService.format(strings.DevOpsWizard_StartPage_UrlPlaceholder)}
        onChange={(_, val) => props.setDevOpsUrl(val)} />
    </Stack>
  );
};
