import { loadStyles } from '@microsoft/load-themed-styles';

const styles = {
  libraryItem: 'libraryItem_6c7e2e74',
  folderIcon: 'folderIcon_6df67e89',
  defaultText: 'defaultText_11bef586',
  button: 'button_a5a54fee',
  spinner: 'spinner_2f51fd3b',
};

export default styles;

loadStyles('.libraryItem_6c7e2e74{padding:5px 0 5px 10px;cursor:pointer;min-height:32px;line-height:32px;border-width:1px;display:flex;align-items:start}.libraryItem_6c7e2e74:hover{background-color:"[theme:neutralLighter, default:#f3f2f1]"}.folderIcon_6df67e89{margin-right:10px;color:"[theme:themeSecondary, default:#2b88d8]"}.defaultText_11bef586{width:100%}.button_a5a54fee{background-color:"[theme:themePrimary, default:#0078d4]";color:#fff}.button_a5a54fee:hover{background-color:"[theme:themeSecondary, default:#2b88d8]";color:#fff}.button_a5a54fee:disabled{background-color:"[theme:neutralLighter, default:#f3f2f1]"}.button_a5a54fee i{font-weight:bolder}.spinner_2f51fd3b{padding-right:10px}');
