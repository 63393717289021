import { Dropdown } from '@fluentui/react';
import strings from 'VistoWebPartStrings';
import React from 'react';
import { PlanDataService } from 'services/PlanDataService';
import { TextService } from 'services/TextService';
import { IVistoPlan, VistoActionItem, VistoKind } from 'sp';
import { NULL_KEY } from 'shared/parse';

export const ActionPicker = (props: {
  disabled?: boolean;
  label: string;
  plan: IVistoPlan;
  dpGuid?: string;
  actionGuid: string;
  setActionGuid: (actionGuid: string) => void;
}) => {

  const actionList = PlanDataService.getItemsHaving<VistoActionItem>(props.plan.items, x => x.kind === VistoKind.Action && x.dpGuid === props.dpGuid)
    .map(a => ({ key: a.guid, text: a.name }));

  actionList.unshift({
    key: NULL_KEY,
    text: TextService.format(strings.UnassignedItem)
  })

  return (
    <Dropdown
      label={props.label}
      disabled={props.disabled}
      selectedKey={props.actionGuid ?? NULL_KEY}
      onChange={(_, val) => props.setActionGuid(val.key === NULL_KEY ? null : '' + val.key)}
      options={actionList}
    />
  );
}
