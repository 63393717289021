import { makeGuidString } from 'shared/guid';
import { ITextMessage, TextPlanJson } from 'shared/PlanGenerationModel';
import { ApiService } from './ApiService';
import { PlanDataService, PlanVersion } from './PlanDataService';
import { IVistoListItem, IVistoPlan, VistoDpItem, VistoFocusItem, VistoKind, VistoLopItem } from 'sp';
import { Commands } from './Commands';
import { INotify } from './Notify';
import { ChangesService } from './ChangesService';
import { SampleInput } from './sampleInputs';
import { IPlanGenerationUpdates } from './PlanGenerationInterfaces';
import { PlanDrawingGenerationService } from './PlanDrawingGenerationService';

function isSameName(a: string, b: string) {
  if (!a || !b) {
    return true;
  } else if (!a && b) {
    return false;
  } else if (a && !b) {
    return false;
  } else {
    const aText = a.trim().replace(/[\s\r\n]+/g, ' ').toLowerCase();
    const bText = b.trim().replace(/[\s\r\n]+/g, ' ').toLowerCase();
    return aText === bText;
  }
}

export class PlanGenerationService {

  public static getTextPlan(plan: IVistoPlan): TextPlanJson {
    const lops = PlanDataService.getItems(plan.items, VistoKind.LOP);
    const capabilities = lops.map(lop => {
      const ambitions = PlanDataService.getLopDps(plan, lop.guid);
      return {
        name: lop.name,
        description: lop.description,
        ambitions: ambitions.map(ambition => {
          return {
            name: ambition.name,
            description: ambition.description
          }
        })
      }
    });

    const activeFocus = PlanDataService.getActiveFocus(plan);
    const focusName = activeFocus ? activeFocus.name : 'Focus';

    const planName = plan.name;
    const planDescription = plan.description;
    const positionName = plan.esName;
    const positionDescription = plan.esDescription;

    const json: TextPlanJson = {
      planName,
      planDescription,
      focusName,
      positionName,
      positionDescription,
      capabilities
    };

    return json;
  }

  public static generateVistoPlan(jsonText: TextPlanJson, oldPlan: IVistoPlan, notify?: INotify) {

    const newPlan: IVistoPlan = {
      ...oldPlan,
      items: {},
      name: jsonText.planName,
      description: jsonText.planDescription,

      esName: jsonText.positionName,
      esDescription: jsonText.positionDescription,

      planVersion: PlanVersion.current,
    };

    const foundFocus = PlanDataService.getActiveFocus(oldPlan);
    const focusGuid = foundFocus ? foundFocus.guid : makeGuidString();
    const focusName = foundFocus ? foundFocus.name : jsonText.focusName;

    newPlan.items[focusGuid] = {
      guid: focusGuid,
      kind: VistoKind.Focus,
      name: focusName,
      description: jsonText.focusDescription,
      active: true,
    } as VistoFocusItem;

    const oldLops = PlanDataService.getLops(oldPlan);	
    for (const capability of jsonText.capabilities) {

      const foundLop = oldLops.find(lop => isSameName(lop.name, capability.name));
      const lopGuid = foundLop ? foundLop.guid : makeGuidString();
      const lopName = foundLop ? foundLop.name : capability.name;

      newPlan.items[lopGuid] = {
        ...foundLop,
        guid: lopGuid,
        kind: VistoKind.LOP,
        name: lopName,
        description: capability.description,
      } as VistoLopItem;
    }

    const newLops = PlanDataService.getLops(newPlan);

    const oldDps = PlanDataService.getDps(oldPlan);
    for (const capability of jsonText.capabilities) {
      const foundLop = newLops.find(lop => isSameName(lop.name, capability.name));

      for (const ambition of capability.ambitions) {

        const foundDp = oldDps.find(dp => isSameName(dp.name, ambition.name));
        const dpGuid = foundDp ? foundDp.guid : makeGuidString();
        const dpName = foundDp ? foundDp.name : ambition.name;

        newPlan.items[dpGuid] = {
          ...foundDp,
          guid: dpGuid,
          kind: VistoKind.DP,
          lopGuid: foundLop.guid,
          name: dpName,
          description: ambition.description,
        } as VistoDpItem;
      }
    }

    const changes = PlanGenerationService.getUpdateCommands(oldPlan, newPlan, notify);

    const drawingXml = oldPlan.drawingXml || 
      PlanDrawingGenerationService.getEmptyDrawingXml(jsonText, PlanDrawingGenerationService.defaultDrawingParams);

    newPlan.drawingXml = PlanDrawingGenerationService.generatePlanDrawingXml(drawingXml, changes);

    for (const guid in oldPlan.items) {
      const item = oldPlan.items[guid];
      if (item.kind !== VistoKind.LOP && item.kind !== VistoKind.DP) {
        newPlan.items[guid] = oldPlan.items[guid];
      }
  }

    return { newPlan, changes }
  }

  public static async getAssistantPlan(messages: ITextMessage[]): Promise<TextPlanJson> {

    const json = await ApiService.fetch('/generate/new', 'POST', { messages }) as TextPlanJson;

    // const index = Math.floor((messages.length + 1) / 2 - 1);
    // const json = SampleInput[index];

    return json;
  }

  public static getUpdateCommands(srcPlan: IVistoPlan, dstPlan: IVistoPlan, notify: INotify) {

    const srcCapabilities = PlanDataService.getLops(srcPlan);
    const dstCapabilities = PlanDataService.getLops(dstPlan);

    const result: IPlanGenerationUpdates = {
      addedCapabilities: [],
      deletedCapabilities: [],
      changedCapabilities: [],
      addedAmbitions: [],
      deletedAmbitions: [],
      changedAmbitions: [],
      changedActions: [],
      commands: []
    };

    const planChanges = ChangesService.getChanges(srcPlan, dstPlan, ['name', 'description', 'esName', 'esDescription']);
    if (planChanges.detected) {
      result.commands.push(Commands.makeUpdatePlanPropertyCommand(planChanges, `Update plan name`, notify));
    }

    const srcFocus = PlanDataService.getActiveFocus(srcPlan);
    const dstFocus = PlanDataService.getActiveFocus(dstPlan);
    if (srcFocus && dstFocus) {
      const focusChanges = ChangesService.getChanges(srcFocus, dstFocus, ['name', 'description']);
      if (focusChanges.detected) {
        result.commands.push(Commands.makeUpdateCommand([{ item: srcFocus, changes: focusChanges }], notify));
      }
    }

    result.addedCapabilities = dstCapabilities.filter(dst => !srcCapabilities.some(src => isSameName(src.name, dst.name)));
    result.deletedCapabilities = srcCapabilities.filter(src => !dstCapabilities.some(dst => isSameName(dst.name, src.name)));

    result.changedCapabilities = srcCapabilities
      .map(src => ({ src, dst: dstCapabilities.find(dst => isSameName(dst.name, src.name))}))
      .filter(x => x.dst)
      .map(x => ({ item: x.src, changes: ChangesService.getChanges(x.src, x.dst, ['name', 'description']) }))
      .filter(x => x.changes.detected);

    // rename case
    if (result.changedCapabilities.length === 0 && result.addedCapabilities.length === 1 && result.deletedCapabilities.length === 1) {

      const deleted = result.deletedCapabilities[0];
      const added = result.addedCapabilities[0];
      dstPlan.items[deleted.guid] = { ...dstPlan.items[added.guid], guid: deleted.guid };
      delete dstPlan.items[added.guid];

      result.changedCapabilities.push({ 
        item: result.deletedCapabilities[0], 
        changes: ChangesService.getChanges(result.deletedCapabilities[0], result.addedCapabilities[0], ['name', 'description']) 
      });
      result.addedCapabilities.pop();
      result.deletedCapabilities.pop();
    }

    const srcAmbitions = PlanDataService.getDps(srcPlan);
    const dstAmbitions = PlanDataService.getDps(dstPlan);

    result.addedAmbitions = dstAmbitions.filter(dst => !srcAmbitions.some(src => isSameName(src.name, dst.name)));
    result.deletedAmbitions = srcAmbitions.filter(src => !dstAmbitions.some(dst => isSameName(dst.name, src.name)));

    result.changedAmbitions = srcAmbitions
      .map(src => ({ src, dst: dstAmbitions.find(dst => isSameName(dst.name, src.name)) }))
      .filter(x => x.dst)
      .map(x => ({ item: x.src, changes: ChangesService.getChanges(x.src, x.dst, ['name', 'description', 'lopGuid']) }))
      .filter(x => x.changes.detected);

    // rename case
    if (result.changedAmbitions.length === 0 && result.addedAmbitions.length === 1 && result.deletedAmbitions.length === 1) {

      const deleted = result.deletedAmbitions[0];
      const added = result.addedAmbitions[0];
      dstPlan.items[deleted.guid] = { ...dstPlan.items[added.guid], guid: deleted.guid };
      delete dstPlan.items[added.guid];

      result.changedAmbitions.push({ 
        item: result.deletedAmbitions[0], 
        changes: ChangesService.getChanges(result.deletedAmbitions[0], result.addedAmbitions[0], ['name', 'description', 'lopGuid']) 
      });
      result.addedAmbitions.pop();
      result.deletedAmbitions.pop();
    }

    for (const dpChanges of result.changedAmbitions) {
      if (dpChanges.changes.properties.includes('lopGuid')) {
        const actions = PlanDataService.getDpActions(srcPlan, dpChanges.item.guid);
        for (const action of actions) {
          const actionChanges = ChangesService.getChanges(action, { ...action, lopGuid: dpChanges.changes.newValues.lopGuid }, ['lopGuid']);
          if (actionChanges.detected) {
            result.changedActions.push({ item: action, changes: actionChanges });
          }
        }
      }
    }

    if (result.addedCapabilities.length + result.addedAmbitions.length > 0) {
      result.commands.push(Commands.makeCreateCommand([...result.addedCapabilities, ...result.addedAmbitions], notify));
    }
    if (result.deletedCapabilities.length + result.deletedAmbitions.length > 0) {
      result.commands.push(Commands.makeDeleteCommand([...result.deletedAmbitions, ...result.deletedCapabilities], notify));
    }
    if (result.changedCapabilities.length + result.changedAmbitions.length + result.changedActions.length > 0) {
      result.commands.push(Commands.makeUpdateCommand<IVistoListItem>([...result.changedAmbitions, ...result.changedCapabilities, ...result.changedActions], notify));
    }

    return result;
  }
}
