import React from 'react';

import { Chart, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
Chart.register(TimeScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, annotationPlugin);

import { Line } from 'react-chartjs-2';

import moment from 'moment';
import 'chartjs-adapter-moment';

import { IVistoPlan, VistoKeyResultItem, VistoKeyResultValueItem } from 'sp';
import { useTheme, Stack } from '@fluentui/react';
import strings from 'VistoWebPartStrings';
import { AppContext } from 'services/AppContext';
import { TextService } from 'services/TextService';
import { PlanKeyresultsService } from 'services/PlanKeyresultsService';
import { HtmlLegendPlugin } from 'components/HtmlLegendPlugin';
import { ColorService } from 'services/colorService';
import { PlanDataService } from 'services/PlanDataService';

const getJsonDate = (d: Date) => d && TextService.getDate(d).toJSON();

export const MatrixCellChart = (props: {
  plan: IVistoPlan;

  items: VistoKeyResultItem[];

  minHeight?: number;
}) => {

  moment.locale(TextService.uiLanguage);

  const { palette } = useTheme();

  const date = getJsonDate(props.plan.statusDate);
  const activeFocus = PlanDataService.getActiveFocus(props.plan);
  const viewStartDate = getJsonDate(activeFocus.startDate);
  const viewEndDate = getJsonDate(activeFocus.endDate);

  const datasets = [];

  const colors = ColorService.getFluentColors(palette);

  const scales: any = {
    x: {
      type: 'time',
    }
  }

  for (let i = 0; i < props.items.length; ++i) {

    const item = props.items[i];
    const color = colors[i % colors.length];

    if (item) {

      const { targets, actuals } = PlanKeyresultsService.getKeyResultValues(props.plan, item.guid);

      const isKPI = !!item.parentKrGuid;

      const labelActuals = isKPI
        ? TextService.format(strings.MatrixChartDialog_KpiActuals, { type: strings.__KPI, title: item.name })
        : TextService.format(strings.MatrixChartDialog_KrActuals, { type: strings.__KeyResult, title: item.name });

      const labelTargets = isKPI
        ? TextService.format(strings.MatrixChartDialog_KpiTargets, { type: strings.__KPI, title: item.name })
        : TextService.format(strings.MatrixChartDialog_KrTargets, { type: strings.__KeyResult, title: item.name });

      scales[`KR${i}`] = {
        type: 'linear',
        position: isKPI ? 'left' : 'right',
        title: {
          display: true,
          text: `${item.name} (${item.units})`,
          color: color
        },
        ticks: {
          color: color
        }
      },

      datasets.push({
        item: item,
        source: actuals,
        label: labelActuals,
        borderColor: color,
        data: actuals.map(v => ({
          x: v.valueDate,
          y: v.value
        })),
        yAxisID: `KR${i}`,
        tension: 0,
        borderWidth: 3
      });

      datasets.push({
        item: item,
        source: targets,
        label: labelTargets,
        borderColor: color,
        data: targets.map(v => ({
          x: v.valueDate,
          y: v.value
        })),
        yAxisID: `KR${i}`,
        tension: 0,
        borderWidth: 1,
        borderDash: [10, 5],
      })

    }
  }

  const plugins = {
    htmlLegend: {
      // ID of the container to put the legend in
      containerId: 'legend-container',
      direction: 'column',
      todayDate: date,
      todayColor: palette.red,
      focusStartDate: viewStartDate,
      focusEndDate: viewEndDate,
      focusTitle: `${TextService.formatTitle(activeFocus, props.plan.items)} 🟊`,
      focusColor: ColorService.hexToRGB(palette.neutralSecondary, '0.2')
  },
    legend: {
      display: false
    },
    tooltip: {
      callbacks: {
        title: (context) => {
          const ctx = context[0];
          const title = TextService.formatDate(TextService.getDate(ctx.raw['x']));
          return `${title} ${ctx.dataset.label}`;
        },
        label: (ctx) => {
          const val = PlanKeyresultsService.formatKeyResultValue(ctx.raw['y'], ctx.dataset.item, true);
          const krv: VistoKeyResultValueItem = ctx.dataset.source[ctx.dataIndex];
          return `${val} ${krv.description || ''}`;
        }
      }
    },
    annotation: {
      annotations: {
        today: {
          type: 'line',
          xMin: date,
          xMax: date,
          borderDash: [5, 5],
          borderColor: palette.red,
          borderWidth: 1,
        },
        focus: {
          type: 'box',
          xMin: viewStartDate,
          xMax: viewEndDate,
          // adjustScaleRange: false,
          backgroundColor: ColorService.hexToRGB(palette.neutralSecondary, '0.2')
        }
      }
    }
  };

  const chartOptions = { plugins, scales };

  const chartPlugins = [HtmlLegendPlugin];

  return (
    <Stack style={{ minHeight: props.minHeight }}>
      <Line datasetIdKey='label' data={{ datasets }} plugins={chartPlugins} options={chartOptions as any} />
      <div id='legend-container' style={{ alignSelf: 'start' }}></div>
    </Stack>
  );
};
