import { mxgraph } from 'ts-mxgraph-typings';

export const mxglobals = require('mxgraph');

export declare class Editor {
  constructor(chromeless, themes, model, graph, editable);
  public graph: mxgraph.mxGraph;
  public undoManager: mxgraph.mxUndoManager;
  public setModified(val: boolean);
  public readGraphState(node);
  public updateGraphComponents();
  public fireEvent(event);
  public setGraphXml(node);
}

export declare class EditorUi {
  constructor(editor: Editor, container, lightbox: boolean);
  public editor: Editor;
  public sidebar: any;
  public keyHandler: any;
  public chromelessResize: any;
  public chromeButtonColor: any;
  public destroy();
}

export declare class Graph {
  constructor(container, model, renderHint, stylesheet, themes);
  public static recentCustomFonts: [];
  public getSvg(background?, scale?, border?, nocrop?, crisp?, ignoreSelection?, showText?, imgExport?, linkTarget?, hasShadow?);
  public background: string;
  public backgroundImage: string;
}

interface IGlobals {
  mxPoint: typeof mxgraph.mxPoint;
  mxGeometry: typeof mxgraph.mxGeometry;
  mxCell: typeof mxgraph.mxCell;
  mxRectangle: typeof mxgraph.mxRectangle;
  mxImage: typeof mxgraph.mxImage;
  mxEdgeHandler: typeof mxgraph.mxEdgeHandler;
  mxConstants: typeof mxgraph.mxConstants;
  mxEvent: typeof mxgraph.mxEvent;

  ChangePageSetup: any;
  Graph: typeof Graph;
  mxResources;
  mxUtils;
  EditorUi: typeof EditorUi;
  mxGraphModel: typeof mxgraph.mxGraphModel;
  mxCellHighlight: typeof mxgraph.mxCellHighlight;
  Editor: typeof Editor;
  mxVertexHandler: typeof mxgraph.mxVertexHandler;
  mxCodec: typeof mxgraph.mxCodec;
  mxEventObject: typeof mxgraph.mxEventObject;
  mxCellOverlay: typeof mxgraph.mxCellOverlay;
}

