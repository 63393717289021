import * as strings from 'VistoWebPartStrings';
import * as React from 'react';
import { Stack, Text, IconButton, TooltipHost, TooltipDelay } from '@fluentui/react';
import { VistoAssocItem, IVistoPlan, IVistoListItem, VistoKind, VistoSoItem, VistoKeyResultItem } from 'sp';
import { MenuItems } from 'frames/TopFrame/MenuItems';
import { VistoIcon } from 'components';
import { EditAssocDialog, ConfirmDeleteDialog } from 'dialogs';
import { AppContext } from 'services/AppContext';
import { Commands } from 'services/Commands';
import { PlanDataService } from 'services/PlanDataService';
import { TextService } from 'services/TextService';
import { makeGuidString } from 'shared/guid';

const getConfidenceIconName = (confidence: number) => {
  switch (+confidence) {
    case 1: return VistoIcon.AssocConfidence1;
    case 3: return VistoIcon.AssocConfidence3;
    case 9: return VistoIcon.AssocConfidence9;
  }
};

export const SidebarAssoc = (props: {
  plan: IVistoPlan,
  assoc: VistoAssocItem;
}) => {

  const { dispatchCommand, notify, isPlanEditEnabled, isPopupOpen, setIsPopupOpen } = React.useContext(AppContext);

  const [editDialogVisible, setEditDialogVisible] = React.useState(false);
  const [deleteDialogVisible, setDeleteDialogVisible] = React.useState(false);

  React.useEffect(() => {
    setIsPopupOpen(editDialogVisible || deleteDialogVisible);
  }, [editDialogVisible, deleteDialogVisible]);

  const isEditDisabled = !isPlanEditEnabled || isPopupOpen;

  const menuItems = [
    MenuItems.getDefaultMenuItemValidation(isPopupOpen, isPlanEditEnabled, () => setEditDialogVisible(true)),
    MenuItems.getDeleteAssocMenuItem(isEditDisabled, () => setDeleteDialogVisible(true))
  ];

  const deps = PlanDataService.getDependencis(props.plan.items, props.assoc);

  const deleteConfirmed = (items: IVistoListItem[]) => {
    return dispatchCommand(Commands.makeDeleteCommand(items, notify), { wrap: false });
  };

  const so = PlanDataService.getItemByGuid<VistoSoItem>(props.plan.items, props.assoc.soGuid);

  const isMissing = !so;
  const isDuplicate = PlanDataService.isDuplicateAssoc(props.plan.items, props.assoc);

  const name = isDuplicate ? TextService.format(strings.Sidebar_AssocDuplicate) : isMissing ? TextService.format(strings.Sidebar_AssocMissing) : so.name;
  const iconName = isDuplicate ? VistoIcon.AssocDuplicate : isMissing ? VistoIcon.AssocMissing : getConfidenceIconName(props.assoc.confidence);

  const kr = props.assoc.kind === VistoKind.Assoc
    ? PlanDataService.getItemByGuid<VistoKeyResultItem>(props.plan.items, (props.assoc as VistoAssocItem).krGuid)
    : null;

  const description = props.assoc.description;

  const tooltipId = makeGuidString();

  return (
    <Stack horizontal>
      <IconButton menuIconProps={{ iconName: iconName }} menuProps={{ items: menuItems }} />
      <Stack tokens={{ padding: 's1 0', childrenGap: 's2' }}>
        <Text variant='smallPlus'>{name}</Text>
        {kr && <Text variant='small'>{TextService.format(strings.SidebarAssoc_TooltipKRI, { krName: kr.name })}</Text>}
        <Text variant='small'>{description}</Text>
      </Stack>
      {editDialogVisible && <EditAssocDialog disableAction plan={props.plan} onDismiss={() => setEditDialogVisible(false)} assoc={props.assoc} />}
      {deleteDialogVisible && <ConfirmDeleteDialog planItems={props.plan.items} items={[props.assoc, ...deps]} onDelete={deleteConfirmed} onDismiss={() => setDeleteDialogVisible(false)} />}
    </Stack>);
};
