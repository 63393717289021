import * as React from 'react';
import * as microsoftTeams from '@microsoft/teams-js';
import { TeamsAuthScope } from 'teams/components/TeamsAuthScope';
import { PlanWizard } from 'wizard';
import { Placeholder } from '@pnp/spfx-controls-react/lib/controls/placeholder';
import { Stack } from '@fluentui/react';
import { api } from 'shared/api';
import strings from 'VistoWebPartStrings';
import { TextService } from 'services/TextService';
import { IEnvContext } from 'services/EnvContext';
import { TeamsAuthService } from 'teams/services/TeamsAuthService';
import { AuthService } from 'services/AuthService';

/**
 * Implementation of VistoTeams Tab configuration page
 */
export const VistoTeamsTabConfig = () => {

  const planId = React.useRef('');
  const planName = React.useRef(TextService.format(''));

  AuthService.resetAuth(api.TokenKind.sharepoint);

  const [isWizardOpen, setIsWizardOpen] = React.useState(false);
  const [siteUrl, setSiteUrl] = React.useState<string>();

  const onLoad = async (ctx: IEnvContext) => {

    const onSaveHandler = (saveEvent: microsoftTeams.settings.SaveEvent) => {
      const host = 'https://' + window.location.host;
      const settings = TeamsAuthService.makeTabSettings(host, planId.current, ctx.siteUrl);
      settings.suggestedDisplayName = planName.current;
      microsoftTeams.settings.setSettings(settings, (succeeded, reason) => {
        if (succeeded) {
          saveEvent.notifySuccess();
        } else {
          saveEvent.notifyFailure(reason);
        }
      });
    };

    planName.current = TextService.format(strings.PlanConfiguration_NewPlanName);

    // existing plan
    if (ctx.entityId) {
      setSiteUrl(ctx.siteUrl);
    } else {
      microsoftTeams.settings.registerOnSaveHandler(onSaveHandler);
      setIsWizardOpen(true);
      setSiteUrl(ctx.siteUrl);
    }
  };

  const onWizardFinished = (changed: boolean, newPlanId?: string, newPlanName?: string) => {
    if (changed) {
      planId.current = newPlanId;
      planName.current = newPlanName;
    }
    setIsWizardOpen(false);
    microsoftTeams.settings.setValidityState(!!newPlanId);
  };

  return (
    <TeamsAuthScope name='Teams_Config' kind={api.TokenKind.sharepoint} onLoad={onLoad}>
      {siteUrl ? (
        isWizardOpen ?
          <PlanWizard
            isPopup={false}
            planId={planId.current}
            planName={planName.current}
            siteUrl={siteUrl}
            onDismiss={onWizardFinished}
            trigger='TeamsConfigTab'
          />
          :
          <Stack grow>
            {planId.current
              ? <Placeholder iconName='CheckMark' iconText={TextService.format(strings.TabConfig_ReadyIconText)} description={TextService.format(strings.TabConfig_Ready)} hideButton />
              : <Placeholder iconName='CheckMark' iconText={TextService.format(strings.TabConfig_ConfiguredIconText)} description={TextService.format(strings.TabConfig_Configured)} hideButton />
            }
          </Stack>
      ) : (
        <Placeholder iconName='Info'
          iconText={TextService.format(strings.PlanConfiguration_AddingMobileNotSupportedTItle)}
          description={TextService.format(strings.PlanConfiguration_AddingMobileNotSupportedDescription)}
          hideButton
        />
      )
      }
    </TeamsAuthScope>
  );
};
