import React from 'react';
import { FontSizes, mergeStyles, useTheme } from '@fluentui/react';
import { SvgIndicators } from 'graphics/SvgIndicators';
import { AppContext } from 'services/AppContext';
import { PlanKeyresultsService } from 'services/PlanKeyresultsService';
import { IVistoPlan, VistoKeyResultItem, VistoKeyResultValueItem } from 'sp';

export const ProgressBarBlockBinary = (props: {
  plan: IVistoPlan;
  viewStartDate?: Date;
  viewEndDate?: Date;
  targets: VistoKeyResultValueItem[];
  actuals: VistoKeyResultValueItem[];
  kr: VistoKeyResultItem;
  onClick?: () => void;
}) => {

  const { palette } = useTheme();

  const { currentValue } = PlanKeyresultsService.getKeyResultProgress(props.targets, props.actuals,
    props.viewStartDate, props.viewEndDate, props.plan.statusDate);

  const outerDivStyle = mergeStyles({
    cursor: props.onClick ? 'pointer' : 'default',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  });

  const wrapDivStyle = mergeStyles({
    width: 120,
    textOverflow: 'ellipsis',
    fontWeight: 'bold',
    textAlign: 'center',
  });

  const styleStart = mergeStyles({
    minWidth: 32,
    textAlign: 'right',
    marginRight: 4,
    color: palette.neutralSecondary,
    fontSize: FontSizes.smallPlus,
  });

  const styleTarget = mergeStyles({
    minWidth: 32,
    textAlign: 'left',
    marginLeft: 4,
    color: palette.neutralSecondary,
    fontSize: FontSizes.smallPlus,
  });

  const value = PlanKeyresultsService.formatKeyResultValue(currentValue, props.kr, true);

  return (
    <div onClick={props.onClick} className={outerDivStyle}>
      <div className={styleStart}></div>
        <div className={wrapDivStyle}>{value}</div>
      <div className={styleTarget}></div>
    </div>

  );
};
