// emulation of the SharePoint context for spfx-controls-react library

declare function SpFetch(input: string, config: any, init?: any): Promise<Response>;

interface ISPHttpClient {
  get: typeof SpFetch;
  post: typeof SpFetch;
  fetch: typeof SpFetch;
}

export const SPHttpClient = {
  configurations: {
    v1: null
  }
};

export class Guid {
  private guid: string;
  constructor(guid: string) {
    this.guid = guid;
  }
  public toString(): string {
    return this.guid;
  }
}

export interface BaseComponentContext {
  pageContext: {
    web: {
      absoluteUrl: string;
      serverRelativeUrl: string;
      title: string;
      id: Guid;
    };
    site: {
      id: Guid;
    };
    list?: {
      id: Guid;
    };
    listItem?: {
      id: number;
    }
    cultureInfo: {
      currentCultureName: string;
    };
  };

  spHttpClient: ISPHttpClient;
  httpClient: ISPHttpClient;
}
