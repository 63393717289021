import * as React from 'react';
import { BaseComponentContext } from 'context';

export type EnvHostKind = 'TeamsDesktop' | 'TeamsMobile' | 'TeamsWeb' | 'WebMobile' | 'WebDesktop';

export interface IEnvContext {
  hostKind: EnvHostKind;
  siteUrl: string;

  teamId: string;
  teamName: string;
  channelId: string;
  channelName: string;

  defaultFolderName: string;
  defaultFolderRelativeUrl: string;
  webPartContext: BaseComponentContext;

  groupId: string;
  tid: string;
  userObjectId: string;
  userPrincipalName: string;
  entityId: string;
  subEntityId: string;
}

export const EnvContext = React.createContext<IEnvContext>(null);

export const isTeams = (hostKind: EnvHostKind) => (hostKind === 'TeamsDesktop' || hostKind === 'TeamsMobile' || hostKind === 'TeamsWeb');
