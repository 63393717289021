
export enum CommandName {
  AddExistingAction,
  MoveActionToDp,
  EditSourceLink,
  UpdateItemsFromGantt,
  AddMicrosoftProjectActionDialog,
  AddMicrosoftDevOpsActionDialog,
  UpdateItemSidebar,
  SetActiveFocus,
  AddAttachment,
  DeleteAttachment,
  CreateItemDrop,
  AssociateLOP,
  RenameItem,
  ReorderItems,
  UpdatePlanProperty,
  UpdateFocus,
  BreakLink,
  DeleteItemWithDependencies,

  MicrosoftPlanner_RenameBucket,
  MicrosoftPlanner_RenamePlan,
  MicrosoftPlanner_CreateMissingPlan,
  MicrosoftPlanner_CreateMissingBucket,
  MicrosoftPlanner_CreateMissingTask,
  MicrosoftPlanner_DeleteTask,
  MicrosoftPlanner_DeleteBucket,
  MicrosoftPlanner_GrantConsent,

  MicrosoftProject_GrantConsent,

  MicrosoftDevOps_GrantConsent,
  MicrosoftDevOps_UpdateWorkItem,
  MicrosoftDevOps_UpdateItem,

  Dashboard_GrantConsent,

  HideLicenseBubble,
  DeleteItem,
  CreateItem,
  UpdateItem,
  SetPlanSettings,
  RefreshPlan,
  EditKeyResult,
  MoveKeyResultToDp,
  UpdateFocusSidebar,
  UpdateEffectSidebar,
  RenameItemShape,

  BreakLinkUsingDialog,
  BreakLinkPlanner,
  BreakLinkDevOps,
  BreakLinkProject,
  BreakLinkVisPlan
}
