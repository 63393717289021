import { SvgIndicators } from 'graphics/SvgIndicators';
import React from 'react';
import { IProgressData } from 'services/IProgressData';
import { IVistoPlan } from 'sp';

export const ProgressBubble = (props: {
  size: number;
  plan: IVistoPlan;
  progress: IProgressData;
}) => {

  const style = {
    width: props.size, 
    height: props.size,
  };

  const svg = SvgIndicators.buildSvgProgress(props.size, 4, props.plan, props.progress);
  return (<div style={style} dangerouslySetInnerHTML={{ __html: svg }}></div>);
}
