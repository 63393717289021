import * as React from 'react';
import * as strings from 'VistoWebPartStrings';
import { BasicDialog } from './BasicDialog';
import { Text } from '@fluentui/react';
import { trackClient } from 'shared/clientTelemetry';
import { TextService } from 'services/TextService';

export function DeletePlanDialog(props: {
  onCommit: () => Promise<any>;
  onDismiss: () => void;
}) {

  React.useEffect(() => trackClient.page('DeletePlanDialog'), []);

  return (
    <BasicDialog
      onDismiss={props.onDismiss}
      buttonOkAction={props.onCommit}
      buttonOkText={TextService.format(strings.ButtonDelete)}
      buttonOkBusyText={TextService.format(strings.ButtonDeleting)}
      buttonCancelText={TextService.format(strings.ButtonCancel)}
      title={TextService.format(strings.DeletePlanDialog_Title)}
      content={
        <React.Fragment>
          <Text>{TextService.format(strings.DeletePlanDialog_Description)}</Text>
        </React.Fragment>
      }
    />
  );
}
