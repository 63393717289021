import * as strings from 'VistoWebPartStrings';
import * as React from 'react';
import { Stack } from '@fluentui/react';
import { TextService } from 'services/TextService';
import { IDevOpsItemType } from '../services/DevOpsService';
import { DevOpsItemTypeCombobox } from '../components/DevOpsItemTypeCombobox';

export const DevOpsWizardConfigPage = (props: {
  devopsUrl: string;
  itemTypes: IDevOpsItemType[];
  itemTypeNames: string[];
  setItemTypeNames: (itemTypeNames: string[]) => void;
}) => {

  return (
    <Stack grow tokens={{ childrenGap: 'm' }}>
      <DevOpsItemTypeCombobox
        devopsUrl={props.devopsUrl}
        label={TextService.format(strings.DevOpsWizard_WorkItemTypesLabel)}
        itemTypes={props.itemTypes}
        itemTypeNames={props.itemTypeNames}
        setItemTypeNames={props.setItemTypeNames}
      />
    </Stack>
  );
};
