import { ComboBox, IComboBoxOption, SelectableOptionMenuItemType } from '@fluentui/react';
import React from 'react';
import { PlanDataService } from 'services/PlanDataService';
import { IVistoPlan } from 'sp';

export const LopMultiPicker = (props: {
  disabled?: boolean;
  label: string;
  plan: IVistoPlan;
  lopGuids: string[];
  setLopGuids: (lopGuids: string[]) => void;
}) => {

  const allLops = PlanDataService.getLops(props.plan);

  const options = allLops.map(lop => ({ 
    key: lop.guid, 
    text: lop.name,
    itemType: SelectableOptionMenuItemType.Normal,
  }));

  const onChange = (_, option?: IComboBoxOption) => {

    if (option) {
      if (option?.selected) {
        props.setLopGuids([...props.lopGuids, option.key as string])
      } else {
        props.setLopGuids(props.lopGuids.filter(key => key !== option.key));
      }
    }
  };

  return (
    <ComboBox
      multiSelect
      disabled={allLops.length === 0 || props.disabled}
      label={props.label}
      selectedKey={props.lopGuids}
      onChange={onChange}
      options={options}
    />
  );
}
