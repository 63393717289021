import { mxgraph } from 'ts-mxgraph-typings';
import { EditorUi, FixedViewType, mx, mxglobals, ViewAlignment } from 'frames/TopFrame/drawing/common';
import Mustache from 'mustache';
import { PlanStylesService } from 'services/PlanStylesService';
import { ITheme } from '@fluentui/react';
import { getObjectValues } from 'shared/parse';
import { IOkrCardInfo } from './IOkrCardInfo';
import { TreeService } from 'services/TreeService';
import { IRenderCardData } from './IRenderCardData';
import { VistoKind } from 'sp';

const svgArrow = require('mxresources/styles/connect.svg.txt');
const svgQuestion = require('mxresources/styles/question.svg.txt');

const getStyleString = (style: any) =>
  Object.keys(style).map(key => `${key}=${style[key]}`).join(';');

const savedStyles = {};

const getDefaultVertexStyle = (theme: ITheme): Object => {

  const styleKey = `${theme.name}.okr.defaultVertexStyle`

  if (savedStyles[styleKey]) {
    return savedStyles[styleKey];
  } else {

    const style = {
      html: 1,
      perimeter: 'rectanglePerimeter',
      align: 'left',
      verticalAlign: 'bottom',
      allowArrows: 0,
      whiteSpace: 'wrap',
      fontSize: Number.parseInt(theme.fonts.medium.fontSize + '', 10),
      fontFamily: theme.fonts.medium.fontFamily,
      imageBorder: theme.palette.neutralQuaternary,
      strokeColor: theme.palette.neutralQuaternary,
      strokeWidth: 1,
      fillColor: theme.palette.white,
      connectable: 0,
      deletable: 0,
      overflow: 'fill',
      shadow: 1,
    };
    savedStyles[styleKey] = style;
    return style;
  }
}

const getDefaultEdgeStyle = (theme: ITheme): Object => {
  const styleKey = `${theme.name}.okr.defaultEdgeStyle`;

  if (savedStyles[styleKey]) {
    return savedStyles[styleKey];
  } else {
    const style = {
      shape: 'connector',
      endArrow: 'classic',
      strokeWidth: 3,
      rounded: 1,
      dashPattern: '1 1',
      strokeColor: theme.palette.neutralSecondary,
    };
    savedStyles[styleKey] = style;
    return style;
  }
}

const deepGetChildren = (cell: mxgraph.mxCell, ids: Set<string>) => {
  const cellId = OkrService.getCellKey(cell);
  if (!ids.has(cellId)) {
    ids.add(cellId)
    if (cell.edges) {
      for (const item of cell.edges) {
        if (item.source !== cell) {
          deepGetChildren(item.source, ids);
        }
        if (item.target !== cell) {
          deepGetChildren(item.target, ids);
        }
      }
    }
  }
}

export class OkrService {

  public static createTreeEditorUi = (
    container: HTMLDivElement,
    theme: ITheme,
    readOnly: boolean
  ) => {

    const defaultStyles = {
      styles: {
        defaultEdge: getDefaultEdgeStyle(theme),
        defaultVertex: getDefaultVertexStyle(theme),
      }
    };

    const mxStylesheet = PlanStylesService.getMxStylesheet(JSON.stringify(defaultStyles));

    const mxThemes = {};
    const editorStyles = Mustache.render(mxStylesheet, theme);
    const node = new DOMParser().parseFromString(editorStyles, 'text/xml').documentElement;
    mxThemes['default'] = node;

    const newEditor = new mxglobals.Editor(true, mxThemes, null, null, true);
    const graph: mxgraph.mxGraph = newEditor.graph;

    // Disables some global features
    graph.panningHandler.useLeftButtonForPanning = true;
    graph.setEventsEnabled(false);
    graph.setConnectable(!readOnly);
    graph.setCellsDisconnectable(!readOnly);
    graph.setCellsCloneable(false);
    graph.setCellsMovable(false);
    graph.setCellsResizable(false);
    graph.setCellsSelectable(false);
    graph.swimlaneNesting = false;
    graph.dropEnabled = false;
    graph.autoExtend = false;
    graph.setCellsEditable(false);
    graph['defaultPageBackgroundColor'] = 'transparent'; // theme.palette.white;
    graph['defaultPageBorderColor'] = theme.palette.neutralLighterAlt;
    graph.canExportCell = () => false;
    graph.canImportCell = () => false;
    graph['isFastZoomEnabled'] = () => false;

    graph.view['defaultGridColor'] = theme.palette.neutralQuaternary;
    graph.view['gridColor'] = theme.palette.neutralQuaternary;

    graph.connectionHandler.select = false;
    graph.connectionHandler.connectImage = new mxglobals.mxImage(`data:image/svg+xml;utf8,${encodeURIComponent(Mustache.render(svgArrow, theme))}`, 50, 50);
    // newGraph.model.createId = makeGuidString;
    graph.connectionHandler.factoryMethod = () => {
      const edge = new mxglobals.mxCell('');
      edge.setEdge(true);
      const edgeStyle = getDefaultEdgeStyle(theme);
      edge.setStyle(getStyleString(edgeStyle));

      const geo = new mxglobals.mxGeometry();
      geo.relative = true;
      edge.setGeometry(geo);
      return edge;
    }
    graph.connectionHandler.isValidSource = (cell) => {
      return !readOnly && cell.isVertex();
    };

    graph.connectionHandler.validateConnection = (source, target) => {
      if (readOnly || !source?.isVertex() || !target?.isVertex()) {
        return '';
      }
      const keys = new Set<string>();
      deepGetChildren(source, keys);
      const targetId = this.getCellKey(target);
      if (keys.has(targetId)) {
        return '';
      }
    }

    mxglobals.EditorUi.prototype.chromeButtonColor = theme.palette.black;
    const newEditorUi = new mxglobals.EditorUi(newEditor, container, null);
    newEditorUi.setScrollbars(true);

    graph.allowLoops = false;
    graph.allowDanglingEdges = false;
    graph.gridEnabled = !readOnly;
    graph.pageVisible = !readOnly;
    graph.pageBreaksVisible = false;
    graph.preferPageSize = false;
    graph.allowAutoPanning = true;

    graph.view.validateBackground();
    graph.sizeDidChange();

    if (readOnly) {
      graph.isLabelMovable = (cell) => false;
      graph.isCellMovable = (cell) => false;
      graph.isCellResizable = (cell) => false;
      graph.isVertexLabelsMovable = () => false;
      graph.isEdgeLabelsMovable = () => false;
      graph.isCellsEditable = () => false;
      graph.isCellBendable = (cell) => false;
      graph['handlesDisabled'] = true;

      // graph.setCellsMovable(false);
      graph.setAutoSizeCells(true);
      graph.setPanning(true);
      graph.setCellsResizable(false);
      // graph.setResizeContainer(true);
      // graph.centerZoom = true;
      // graph.setEnabled(false);
      // graph.panningHandler.useLeftButtonForPanning = true;

      newEditorUi.keyHandler.enabled = false;
    }

    // var layoutMgr = new mxglobals.mxLayoutManager(graph);
    // layoutMgr.getLayout = function (cell) {
    //   if (cell.getChildCount() > 0) {
    //     return layout;
    //   }
    // };
    return newEditorUi;
  };

  private static getChildItems(tree: { [key: string]: IOkrCardInfo }, key: string, sort: (a: IOkrCardInfo, b: IOkrCardInfo) => number) {
    const childSet = TreeService.getChildSet(tree, key);
    return getObjectValues(childSet)
      .map(child => ({
        ...tree[child.key],
        name: tree[child.key]?.item.name || child.key.split(':')[0],
        fixed: child.fixed
      })).filter(c => !!c.key).sort(sort);
  }

  public static getRootItems(tree: { [key: string]: IOkrCardInfo }, sort: (a: IOkrCardInfo, b: IOkrCardInfo) => number) {
    const roots = TreeService.getRoots(tree);
    return roots
    .filter(c => c.item.kind === VistoKind.SO)
    .map(c => ({
      ...c,
      name: c.item.name,
      fixed: true,
    })).filter(c => !!c.key).sort(sort);
  }

  private static makeVertex(
    pos: number,
    graph: mxgraph.mxGraph,
    item: IOkrCardInfo,
    theme: ITheme,
    renderCard: (item: IOkrCardInfo) => IRenderCardData,
  ): mxgraph.mxCell {

    const vertexStyle = {
      ...getDefaultVertexStyle(theme),
      connectable: 0
    };

    const vertexStyleString = getStyleString(vertexStyle);

    const v = renderCard(item);

    const cellId = this.makeCellId(pos, item.key);
    return graph.insertVertex(null, cellId, v.html, undefined, undefined, v.width, v.height, vertexStyleString);
  }

  private static makeEdge(graph: mxgraph.mxGraph, parentVertex: mxgraph.mxCell, vertex: mxgraph.mxCell, theme: ITheme) {
    const edgeStyle = getDefaultEdgeStyle(theme);
    const edge = graph.insertEdge(null, null, '', parentVertex, vertex, getStyleString(edgeStyle));
    graph.orderCells(true, [edge]);
  }

  public static addChildItems(
    graph: mxgraph.mxGraph,
    readOnly: boolean,
    tree: { [key: string]: IOkrCardInfo },
    parentVertex: mxgraph.mxCell,
    items: IOkrCardInfo[],
    theme: ITheme,
    marks: { [key: string]: mxgraph.mxCell },
    sort: (a: IOkrCardInfo, b: IOkrCardInfo) => number,
    renderCard: (item: IOkrCardInfo) => IRenderCardData,
    expandedCards: { [key: string]: IOkrCardInfo }
  ) {

    graph['tree'] = tree;

    const newVertexes: { vertex: mxgraph.mxCell, childItems: IOkrCardInfo[] }[] = [];

    for (let i = 0; i < items.length; ++i) {
      const item = items[i];

      if (marks[item.key]) {
        continue;
      }

      const childItems = this.getChildItems(tree, item.key, sort);
      const vertex = this.makeVertex(i, graph, item, theme, renderCard);

      marks[item.key] = vertex;

      if (parentVertex) {
        this.makeEdge(graph, parentVertex, vertex, theme);
      }

      if (childItems.length > 0) {
        newVertexes.push({ vertex, childItems });
      }
    }

    for (let i = 0; i < newVertexes.length; ++i) {
      const newVertex = newVertexes[i];
      if (expandedCards[this.getCellKey(newVertex.vertex)]) {
        this.addChildItems(graph, readOnly, tree, newVertex.vertex, newVertex.childItems, theme, marks, sort, renderCard, expandedCards);
      }
    }
  }

  public static getCellKey(cell: mxgraph.mxCell) {
    return cell?.id?.substring(5 + 1);
  }

  public static makeCellId(index: number, key: string) {
    return (2 + index).toString().padStart(5, '0') + '_' + key;
  }

  public static runLayout(graph: mxgraph.mxGraph) {
    const page = graph.getDefaultParent();
    const layout = new mxglobals.mxOrgChartLayout(graph, 8, 75, 50);
    layout.isVertexMovable = () => true;
    layout.execute(page);
    if (page.children) {
      const bounds = graph.getBoundingBoxFromGeometry(page.children, true);
      graph.moveCells(page.children, -bounds.x, -bounds.y);
      graph.pageFormat = new mxglobals.mxRectangle(0, 0, bounds.width, bounds.height + 50);
    }
  }
}
