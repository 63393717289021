import React from 'react'
import { Dropdown } from '@fluentui/react'
import { TextService } from 'services/TextService';
import { VistoKind } from 'shared/VistoKind';

export const SharePointItemKindPicker = (props: {
  label: string;
  disabled: boolean;
  itemKind: VistoKind;
  setItemKind: (val: VistoKind) => void;
}) => {
  const [itemKinds] = React.useState([
    { key: VistoKind.LOP, text: TextService.getVistoKindName(VistoKind.LOP) },
    { key: VistoKind.DP, text: TextService.getVistoKindName(VistoKind.DP) },
    { key: VistoKind.Action, text: TextService.getVistoKindName(VistoKind.Action) },
  ]);

  return (
    <Dropdown
      label={props.label}
      disabled={props.disabled}
      calloutProps={{ calloutMaxHeight: 200 }}
      options={itemKinds}
      selectedKey={props.itemKind}
      onChange={(_, val) => props.setItemKind(+val.key)}
    />
  )
}
