import React from 'react';
import { AppContext } from './AppContext';

export const useExpandedPropertyBag = (expandKey: string, defaultExpanded: boolean) => {

  const { propertyBag, setPropertyBag } = React.useContext(AppContext);

  const isExpanded = typeof (propertyBag?.expanded[expandKey]) !== 'undefined' ? propertyBag.expanded[expandKey] : defaultExpanded;

  const onToggleExpand = () => {
    setPropertyBag({ expanded: { ...propertyBag.expanded, [expandKey]: !isExpanded } });
  };

  return [isExpanded, onToggleExpand] as const;
}
