import { DatePicker, Dropdown, IDropdownOption, Stack, Toggle } from '@fluentui/react';
import React from 'react';
import { TextService } from 'services/TextService';
import { IVistoPlan } from 'sp';
import strings from 'VistoWebPartStrings';
import { IGanttPdfOptions } from './IGanttPdfOptions';

export const ExportGanttToPdfSettingsPage = (props: {
  plan: IVistoPlan;
  options: IGanttPdfOptions;
  setOptions: (options: IGanttPdfOptions) => void;
}) => {

  const formats: IDropdownOption[] = [
    { key: 'a0', text: TextService.format(strings.Page_A0) },
    { key: 'a1', text: TextService.format(strings.Page_A1) },
    { key: 'a2', text: TextService.format(strings.Page_A2) },
    { key: 'a3', text: TextService.format(strings.Page_A3) },
    { key: 'a4', text: TextService.format(strings.Page_A4) },
    { key: 'auto', text: TextService.format(strings.Page_Auto) },
  ];
  
  const exportModes: IDropdownOption[] = [
    { key: 'all', text: TextService.format(strings.GanttExportMode_All) },
    { key: 'chart', text: TextService.format(strings.GanttExportMode_Chart) },
    { key: 'treeList', text: TextService.format(strings.GanttExportMode_TreeList) }
  ];
  
  const dateRanges: IDropdownOption[] = [
    { key: 'all', text: TextService.format(strings.GanttExportDateRange_All) },
    { key: 'visible', text: TextService.format(strings.GanttExportDateRange_Visible) },
    { key: 'custom', text: TextService.format(strings.GanttExportDateRange_Custom) }
  ];
  
  return (
    <Stack horizontal grow tokens={{ childrenGap: 'l1' }}>
      <Stack grow tokens={{ childrenGap: 's1' }}>
        <Dropdown
          label={TextService.format(strings.GanttExportLabel_Format)}
          selectedKey={props.options.format}
          options={formats}
          onChange={(_, val) => props.setOptions({ ...props.options, format: '' + val.key })}
        />
        <Toggle
          label={TextService.format(strings.GanttExportLabel_Landscape)}
          checked={props.options.landscape}
          onChange={(_, val) => props.setOptions({ ...props.options, landscape: val })}
        />
        <Dropdown
          label={TextService.format(strings.GanttExportLabel_Mode)}
          selectedKey={props.options.exportMode}
          options={exportModes}
          onChange={(_, val) => props.setOptions({ ...props.options, exportMode: val.key as any })}
        />
      </Stack>
      <Stack grow tokens={{ childrenGap: 's1' }}>
        <Dropdown
          label={TextService.format(strings.GanttExportLabel_DateRange)}
          selectedKey={props.options.dateRange}
          options={dateRanges}
          onChange={(_, val) => props.setOptions({ ...props.options, dateRange: val.key as any })}
        />
        <DatePicker
          disabled={props.options.dateRange !== 'custom'}
          today={props.plan.statusDate}
          initialPickerDate={props.options.startDate || props.plan.statusDate}
          strings={TextService.datePickerStrings}
          firstDayOfWeek={TextService.firstDayOfWeek}
          formatDate={d => TextService.formatDate(d)}
          label={TextService.format(strings.GanttExportLabel_StartDate)}
          value={props.options.startDate}
          allowTextInput={true}
          onSelectDate={val => props.setOptions({ ...props.options, startDate: val })}
        />
        <DatePicker
          disabled={props.options.dateRange !== 'custom'}
          today={props.plan.statusDate}
          initialPickerDate={props.options.endDate || props.plan.statusDate}
          strings={TextService.datePickerStrings}
          firstDayOfWeek={TextService.firstDayOfWeek}
          formatDate={d => TextService.formatDate(d)}
          label={TextService.format(strings.GanttExportLabel_EndDate)}
          value={props.options.endDate}
          allowTextInput={true}
          onSelectDate={val => props.setOptions({ ...props.options, endDate: val })}
        />
      </Stack>
    </Stack>
  );
};
