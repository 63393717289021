import { AuthService } from 'services/AuthService';
import { IExecutableAction } from 'services/IExecutableAction';
import { UrlService } from 'shared/urlService';
import { StorageCacheService } from 'services/StorageCacheService';
import { IVistoPlan, VistoKind } from 'sp';
import { makeGuidString } from 'shared/guid';
import * as strings from 'VistoWebPartStrings';
import { MigrationHelperService } from 'services/MigrationHelperService';
import { FieldGenerator } from 'sp/common/FieldGenerator';

export const makeMigrateKeyResultsToAssoc = (foundPlan: IVistoPlan, getListId: (kind: VistoKind) => string): IExecutableAction => ({
  title: `Migrating parent KRs to Validations`,
  execute: async (p) => {

    const sp = AuthService.getSpClient(foundPlan.siteUrl);

    const krListUrl = UrlService.getListRelativeUrl(foundPlan, VistoKind.KeyResult);
    const assocListUrl = UrlService.getListRelativeUrl(foundPlan, VistoKind.Assoc);

    const assocFields = await sp.web.getList(assocListUrl).fields();
    if (!assocFields.some(f => f.InternalName === 'KPI')) {
      const fd = FieldGenerator.makeLookupField('VISTO_Assoc_KPI', 'KPI', true, VistoKind.KeyResult, false)
      const xml = MigrationHelperService.getFieldXml(fd, getListId);
      await sp.web.getList(assocListUrl).fields.createFieldAsXml(xml);
    }

    const krs = await sp.web.getList(krListUrl).items.top(2000)();

    const map_KR_SO = {};

    for (const kr of krs) {
      const Id = +kr['Id'];
      const soId = +kr['SOId'];

      map_KR_SO[Id] = soId;
    }

    for (const kr of krs) {

      const id = +kr['Id'];
      const krId = +kr['KRId'];

      if (krId) {

        await sp.web.getList(assocListUrl).items.add({
          'VISTO_Guid': makeGuidString(),
          'Title': 'migrated',
          'KeyResultId': krId,
          'SOId': map_KR_SO[krId],
          'KPIId': id,
          'Confidence': strings.__ConfidenceHigh
        });

        // await sp.web.getList(krListUrl).items.getById(id).update({ 'KRId': null });
      }
    }

    // await sp.web.getList(krListUrl).fields.getByInternalNameOrTitle('KR').delete();

    StorageCacheService.resetCache();
    return p;
  }
});
