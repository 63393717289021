import { Stack, Icon, Text, useTheme } from '@fluentui/react';
import { ProgressBlockDates } from 'frames/TopFrame/sidebars/common/ProgressBlockDates';
import { ProgressBubble } from 'frames/TopFrame/sidebars/common/ProgressBubble';
import { SimpleProgressBar } from 'frames/TopFrame/sidebars/common/SimpleProgressBar';
import React from 'react';
import { TextService } from 'services/TextService';
import { IVistoPlan, VistoActionItem } from 'sp';
import { OkrAssigneeBlock } from './OkrAssigneeBlock';
import { OkrCardName } from './OkrCardName';

export const OkrCardAction = (props: {
  plan: IVistoPlan;
  item: VistoActionItem;
}) => {

  const { palette } = useTheme();

  const progress = props.item;

  const haveProgressDates = !!progress?.startDate || !!progress?.endDate;
  const haveProgressPercents = TextService.isValidNumber(progress.percentComplete) || TextService.isValidNumber(progress.plannedPercentComplete);

  return (
    <Stack horizontalAlign='center' tokens={{ padding: 12, childrenGap: 8 }} style={{ height: '100%' }}>
      <Stack grow horizontal style={{ overflow: 'hidden' }} verticalAlign='start' tokens={{ childrenGap: 8, padding: '4px 0' }}>
        <Icon iconName='LightningBolt' style={{ fontSize: '1.5rem', color: palette.neutralTertiary }} />
        <OkrCardName variant='medium' name={props.item.name} />
        <ProgressBubble size={24} plan={props.plan} progress={progress} />
      </Stack>
      <OkrAssigneeBlock assignedTo={progress.assignedTo} />
      {haveProgressDates && <ProgressBlockDates startDate={progress?.startDate} endDate={props.item?.endDate} />}
      {haveProgressPercents && <SimpleProgressBar
        color={palette.neutralTertiaryAlt}
        value={progress.percentComplete}
        reference={progress.plannedPercentComplete}
        valueText={`${progress.percentComplete ?? '? '}%`}
        start={'0'} end={'100'} />}
      <Stack horizontal horizontalAlign='center' style={{ height: '1rem' }} />
    </Stack>
  );
}
