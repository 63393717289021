import { trackClient } from 'shared/clientTelemetry';

type IndexedDbStore = 'images' | 'plans' | 'files';

export class IndexedDbService {

  public static openIndexedDb() {

    return new Promise<IDBDatabase>((resolve, reject) => {
      try {
        const openRequest = indexedDB.open('visplan', 3);
        openRequest.onerror = (err) => {
          trackClient.error(`Failed to open visplan indexedb to cache images`, err);
          resolve(null);
        };
        openRequest.onupgradeneeded = (opened: any) => {
          const db = opened.target.result as IDBDatabase;

          if (!db.objectStoreNames.contains('images')) {
            db.createObjectStore('images');
          }
          if (!db.objectStoreNames.contains('plans')) {
            db.createObjectStore('plans');
          }
          if (!db.objectStoreNames.contains('files')) {
            db.createObjectStore('files');
          }
        };

        openRequest.onsuccess = (opened: any) => {
          resolve(opened.target.result as IDBDatabase);
        };
      } catch (err) {
        reject(err);
      }
    });
  }

  public static getItem(storeName: IndexedDbStore, id: string): Promise<any> {
    return new Promise((resolve) => {
      IndexedDbService.openIndexedDb().then(db => {
        const store = db.transaction([storeName], 'readwrite').objectStore(storeName);
        const req = store.get(id);
        req.onerror = (err) => {
          trackClient.error(`Failed to get data from local storage`, err);
          resolve(null);
        };
        req.onsuccess = (e: any) => {
          const data = e.target.result;
          resolve(data);
        };
      }, err => {
        trackClient.error(`Failed to open local storage`, err);
      });
    });
  }

  public static setItem(storeName: IndexedDbStore, key: string, data: any): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      IndexedDbService.openIndexedDb().then(db => {
        const store = db.transaction([storeName], 'readwrite').objectStore(storeName);
        const req = store.put(data, key);
        req.onerror = (err) => {
          trackClient.error(`Failed to put data to local storage`, err);
          resolve(false);
        };
        req.onsuccess = () => {
          resolve(true);
        };
      }, err => {
        trackClient.error(`Failed to open local storage`, err);
        resolve(false);
      });
    });
  }

  public static deleteItem(storeName: IndexedDbStore, key: string): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      IndexedDbService.openIndexedDb().then(db => {
        const store = db.transaction([storeName], 'readwrite').objectStore(storeName);
        const req = store.delete(key);
        req.onerror = (err) => {
          trackClient.error(`Failed to delete data from local storage`, err);
          resolve(false);
        };
        req.onsuccess = () => {
          resolve(true);
        };
      }, err => {
        trackClient.error(`Failed to open local storage`, err);
        resolve(false);
      });
    });
  }

  public static listItems(storeName: IndexedDbStore): Promise<any[]> {
    return new Promise<any[]>((resolve) => {
      IndexedDbService.openIndexedDb().then(db => {
        const store = db.transaction([storeName], 'readwrite').objectStore(storeName);
        const req = store.getAll();
        req.onerror = (err) => {
          trackClient.error(`Failed list items in the local storage`, err);
          resolve(null);
        };
        req.onsuccess = (e: any) => {
          resolve(e.target.result);
        };
      }, err => {
        trackClient.error(`Failed to open local storage`, err);
      });
    });    
  }

}
