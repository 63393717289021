import * as strings from 'VistoWebPartStrings';
import { VistoKind, IFieldDefinitions, IVistoListItemWithProgress, addListDefinition, ListItemUpdater, IFieldValueUser, Choices } from './common';
import { FieldGenerator } from './common/FieldGenerator';

export interface VistoActionItem extends IVistoListItemWithProgress {
  readonly dpGuid: string;
  readonly lopGuid: string;
  readonly focusGuid: string;
  readonly effectGuid?: string;
  readonly useFocusDates?: boolean;
  sortOrder?: number;
}

const VistoActionListFields: IFieldDefinitions<VistoActionItem> = {
  itemId: FieldGenerator.makeIdField('VISTO_Action_ItemId'),
  guid: FieldGenerator.makeGuidField('VISTO_Action_Guid'),
  name: FieldGenerator.makeTitleField('VISTO_Action_Name'),
  description: FieldGenerator.makeDescriptionField('VISTO_Action_Description'),
  links: FieldGenerator.makeLinksField('VISTO_Action_Links'),
  dpGuid: FieldGenerator.makeLookupField('VISTO_Action_DP', 'DP', true, VistoKind.DP, true),
  lopGuid: FieldGenerator.makeLookupField('VISTO_Action_LOP', 'LOP', true, VistoKind.LOP, true),
  startDate: FieldGenerator.makeDateField('VISTO_Action_StartDate', 'ActionStartDate', true),
  endDate: FieldGenerator.makeDateField('VISTO_Action_EndDate', 'ActionEndDate', true),
  percentComplete: FieldGenerator.makePercentCompleteField('VISTO_Action_PercentComplete', 'ActionPercentComplete'),
  plannedPercentComplete: FieldGenerator.makePlannedPercentCompleteField('VISTO_Action_PlannedPercentComplete', 'ActionPlannedPercentComplete'),
  comments: FieldGenerator.makeCommentsField('VISTO_Action_Comments', 'ActionComments', true),
  sourceItemUrl: FieldGenerator.makeSourceItemField('VISTO_Action_SourceItem', 'SourceItem', true),
  assignedTo: FieldGenerator.makeUserField('VISTO_Action_AssignedTo', 'AssignedTo', true),
  focusGuid: FieldGenerator.makeLookupField('VISTO_Action_Focus', 'Focus', true, VistoKind.Focus, false),
  effectGuid: FieldGenerator.makeLookupField('VISTO_Action_Effect', 'Effect', true, VistoKind.Effect, false),
  sortOrder: FieldGenerator.makeNumberField('VISTO_Action_SortOrder', 'SortOrder', true),
  createdDate: FieldGenerator.makeCreatedField('VISTO_Action_Created'),
  modifiedDate: FieldGenerator.makeModifiedField('VISTO_Action_Modified'),
  useFocusDates: FieldGenerator.makeBooleanField('VISTO_Action_UseFocusDates', 'UseFocusDates', true),
};

addListDefinition({

  kind: VistoKind.Action,
  fields: VistoActionListFields,
  enableVersioning: true,
  majorVersionLimit: 500,
  hasProgress: true,

  defaultViewXml: `<View>
    <Query>
      <GroupBy Collapse='FALSE' GroupLimit='30'>
        <FieldRef Name='${VistoActionListFields.lopGuid.name}' />
        <FieldRef Name='${VistoActionListFields.dpGuid.name}' />
      </GroupBy>
    </Query>
    <ViewFields>
      <FieldRef Name='${VistoActionListFields.lopGuid.name}' />
      <FieldRef Name='${VistoActionListFields.dpGuid.name}' />
      <FieldRef Name='LinkTitle' />
      <FieldRef Name='${VistoActionListFields.description.name}' />
      <FieldRef Name='${VistoActionListFields.startDate.name}' />
      <FieldRef Name='${VistoActionListFields.endDate.name}' />
      <FieldRef Name='${VistoActionListFields.percentComplete.name}' />
      <FieldRef Name='${VistoActionListFields.plannedPercentComplete.name}' />
      <FieldRef Name='${VistoActionListFields.useFocusDates.name}' />
      <FieldRef Name='${VistoActionListFields.assignedTo.name}' />
      <FieldRef Name='${VistoActionListFields.comments.name}' />
    </ViewFields>
    <RowLimit Paged='TRUE'>30</RowLimit>
  </View>`,

  additionalViews: [{
    viewNameStringId: 'VistoActionItem_ViewMyActionsTitle',
    viewXml: `<View>
      <Query>
        <Where>
          <Eq>
            <FieldRef Name='${VistoActionListFields.assignedTo.name}' />
            <Value Type='Integer'>
              <UserID />
            </Value>
          </Eq>
        </Where>
        <GroupBy Collapse='FALSE' GroupLimit='30'>
          <FieldRef Name='${VistoActionListFields.lopGuid.name}' />
          <FieldRef Name='${VistoActionListFields.dpGuid.name}' />
        </GroupBy>
      </Query>
      <ViewFields>
        <FieldRef Name='${VistoActionListFields.lopGuid.name}' />
        <FieldRef Name='${VistoActionListFields.dpGuid.name}' />
        <FieldRef Name='LinkTitle' />
        <FieldRef Name='${VistoActionListFields.description.name}' />
        <FieldRef Name='${VistoActionListFields.startDate.name}' />
        <FieldRef Name='${VistoActionListFields.endDate.name}' />
        <FieldRef Name='${VistoActionListFields.percentComplete.name}' />
        <FieldRef Name='${VistoActionListFields.plannedPercentComplete.name}' />
        <FieldRef Name='${VistoActionListFields.useFocusDates.name}' />
        <FieldRef Name='${VistoActionListFields.assignedTo.name}' />
        <FieldRef Name='${VistoActionListFields.comments.name}' />
      </ViewFields>
      <RowLimit Paged='TRUE'>30</RowLimit>
    </View>`
  }],

  setItemSpecificFields: (updater: ListItemUpdater, spItem, update: Partial<VistoActionItem>) => {
    updater.setItemSpField(VistoActionListFields, spItem, update, 'dpGuid');
    updater.setItemSpField(VistoActionListFields, spItem, update, 'lopGuid');
    updater.setItemSpField(VistoActionListFields, spItem, update, 'focusGuid');
    updater.setItemSpField(VistoActionListFields, spItem, update, 'effectGuid');
    updater.setItemSpField(VistoActionListFields, spItem, update, 'assignedTo');
    updater.setItemSpField(VistoActionListFields, spItem, update, 'sortOrder');
    updater.setItemSpField(VistoActionListFields, spItem, update, 'useFocusDates');
  },

  getItemSpecificFields: (updater: ListItemUpdater, item: Partial<VistoActionItem>, spItem) => {
    updater.getItemSpField(VistoActionListFields, item, spItem, 'dpGuid');
    updater.getItemSpField(VistoActionListFields, item, spItem, 'lopGuid');
    updater.getItemSpField(VistoActionListFields, item, spItem, 'focusGuid');
    updater.getItemSpField(VistoActionListFields, item, spItem, 'effectGuid');
    updater.getItemSpField(VistoActionListFields, item, spItem, 'assignedTo');
    updater.getItemSpField(VistoActionListFields, item, spItem, 'sortOrder');
    updater.getItemSpField(VistoActionListFields, item, spItem, 'useFocusDates');
  },
});
