import * as React from 'react';
import { IPalette, Theme, useTheme } from '@fluentui/react';
import { LineStyleButton } from './LineStyleButton';
import { TextService } from 'services/TextService';
import * as strings from 'VistoWebPartStrings';

export interface IArrowStyle {
  endarrow: string;
}

interface IArrowOption {
  name: string;
  val: IArrowStyle;
}

const arrowStyles: IArrowOption[] = [
  {
    name: TextService.format(strings.ArrowButton_Classic),
    val: { endarrow: 'classic' },
  },
  {
    name: TextService.format(strings.ArrowButton_ClassicThin),
    val: { endarrow: 'classicThin' },
  },
  {
    name: TextService.format(strings.ArrowButton_Open),
    val: { endarrow: 'open' },
  },
  {
    name: TextService.format(strings.ArrowButton_OpenThin),
    val: { endarrow: 'openThin' },
  },
  {
    name: TextService.format(strings.ArrowButton_Block),
    val: { endarrow: 'block' },
  },
  {
    name: TextService.format(strings.ArrowButton_BlockThin),
    val: { endarrow: 'blockThin' },
  },
];

const renderArrow = (arrowStyle: IArrowStyle, theme: Theme) => {

  switch (arrowStyle.endarrow) {
    case 'classic':
      return (
        <svg style={{ paddingRight: 4 }} width='32' viewBox='-6 -6 32 32' xmlns='http://www.w3.org/2000/svg'>
          <line x1='-4' y1='10' x2='16' y2='10' stroke={theme.palette.black} strokeWidth='1' />
          <path d='M 20 10 L 5 17 L 9 10 L 5 3 Z' fill={theme.palette.black} stroke={theme.palette.black} stroke-width='0.1' />
        </svg>
      );
    case 'classicThin':
      return (
        <svg style={{ paddingRight: 4 }} width='32' viewBox='-6 -6 32 32' xmlns='http://www.w3.org/2000/svg'>
          <line x1='-4' y1='10' x2='16' y2='10' stroke={theme.palette.black} strokeWidth='1' />
          <path d='M 20 10 L 5 14 L 8 10 L 5 6 Z' fill={theme.palette.black} stroke={theme.palette.black} stroke-width='0.1' />
        </svg>
      );
    case 'open':
      return (
        <svg style={{ paddingRight: 4 }} width='32' viewBox='-6 -6 32 32' xmlns='http://www.w3.org/2000/svg'>
          <line x1='-4' y1='10' x2='19' y2='10' stroke={theme.palette.black} />
          <line x1='6' y1='17' x2='20' y2='10' stroke={theme.palette.black} />
          <line x1='6' y1='3' x2='20' y2='10' stroke={theme.palette.black} />
        </svg>
      );
    case 'openThin':
      return (
        <svg style={{ paddingRight: 4 }} width='32' viewBox='-6 -6 32 32' xmlns='http://www.w3.org/2000/svg'>
          <line x1='-4' y1='10' x2='19' y2='10' stroke={theme.palette.black} />
          <line x1='6' y1='17' x2='20' y2='10' stroke={theme.palette.black} />
          <line x1='6' y1='3' x2='20' y2='10' stroke={theme.palette.black} />
        </svg>
      );
    case 'block':
      return (
        <svg style={{ paddingRight: 4 }} width='32' viewBox='-6 -6 32 32' xmlns='http://www.w3.org/2000/svg'>
          <line x1='-4' y1='10' x2='16' y2='10' stroke={theme.palette.black} strokeWidth='1' />
          <path d='M 20 10 L 7 17 L 7 10 L 7 3 Z' fill={theme.palette.black} stroke={theme.palette.black} stroke-width='0.1' />
        </svg>
      );
    case 'blockThin':
      return (
        <svg style={{ paddingRight: 4 }} width='32' viewBox='-6 -6 32 32' xmlns='http://www.w3.org/2000/svg'>
          <line x1='-4' y1='10' x2='16' y2='10' stroke={theme.palette.black} strokeWidth='1' />
          <path d='M 20 10 L 7 14 L 7 10 L 7 6 Z' fill={theme.palette.black} stroke={theme.palette.black} stroke-width='0.1' />
        </svg>
      );
    default:
      return null;
  }
};

export const ArrowButton = (props: { arrowStyle: IArrowStyle, setArrowStyle: (arrowStyle: IArrowStyle) => void }) => {

  const theme = useTheme();

  return (
    <LineStyleButton
      text={TextService.format(strings.ArrowButton_Title)}
      propertyName='arrow'
      defaultValue={arrowStyles[0].val}
      value={props.arrowStyle}
      setValue={props.setArrowStyle}
      options={arrowStyles}
      renderIcon={(arrowStyle: IArrowStyle) => renderArrow(arrowStyle, theme)}
    />
  );
};
