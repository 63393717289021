import * as strings from 'VistoWebPartStrings';
import * as React from 'react';

import { AppContext } from 'services/AppContext';
import { ChangesService } from 'services/ChangesService';
import { INotification, NotificationType } from 'services/Notify';
import { StorageService } from 'services/StorageService';
import { IOperationOptions } from 'services/IOperationOptions';
import { TextService } from 'services/TextService';
import { IVistoPlan, IVistoListItemWithProgress, IVistoPlanSettings } from 'sp';
import { BasicDialog } from 'dialogs/common';
import { Stack, TextField, Spinner, Text, Pivot, PivotItem, Link, Image } from '@fluentui/react';
import { InfoBar } from 'components';
import { getObjectValues, isConsentError } from 'shared/parse';
import { trackClient } from 'shared/clientTelemetry';
import { CommandName } from 'shared/CommandName';
import { ProjectDataService, ProjectLinkEditor } from 'integrations/project';
import { PlannerIntegrationLevel, PlannerLinkService } from 'integrations/planner';
import { DevOpsDataService, DevOpsLinkEditor } from 'integrations/devops';
import { api } from 'shared/api';
import { LicenseService } from 'services/LicenseService';
import { IntegrationService } from 'services/IntegrationService';
import { ProgressService } from 'services/ProgressService';
import { PlanSettingsService } from 'services/PlanSettingsService';
import { DevOpsLink } from 'integrations/devops/components/DevOpsLink';
import { PlanValidationService } from 'services/PlanValidationService';
import { SharepointExternalDataService, SharePointExternalDataLinkEditor } from 'integrations/sharepoint';
import { PlannerNotifications } from 'integrations/planner/services/PlannerNotifications';
import { AuthService } from 'services/AuthService';

export function EditSourceLinkDialog(props: {
  onDismiss: (changed: boolean) => void;
  plan: IVistoPlan;
  item: IVistoListItemWithProgress;
}) {

  React.useEffect(() => trackClient.page('EditSourceLinkDialog'), []);

  const planSettings = PlanSettingsService.getPlanSettings(props.plan);

  const { dispatchCommand, notify, isPlanEditEnabled } = React.useContext(AppContext);
  const [sourceItemUrl, setSourceItemUrl] = React.useState(props.item.sourceItemUrl);
  const [linknName, setLinkName] = React.useState('');

  const [errorInfo, setErrorInfo] = React.useState<INotification>(null);

  const [isReadingLinkedProgress, setIsReadingLinkedProgress] = React.useState(false);

  const getLinkName = async (url: string) => {
    const hook = getObjectValues(IntegrationService.hooks).find(h => h.isRecognizedLink(url));
    return hook && await hook.getLinkName(url);
  };

  React.useEffect(() => {

    const duplicateError = PlanValidationService.validateSourceLink(props.plan, props.item, sourceItemUrl);
    if (duplicateError) {
      setErrorInfo(duplicateError);
      setIsReadingLinkedProgress(false);
      return;
    }

    if (sourceItemUrl) {

      const callGetLinkName = async () => {
        try {
          setErrorInfo(null);
          setLinkName('');
          setIsReadingLinkedProgress(true);
          const linkName = await getLinkName(sourceItemUrl);
          setIsReadingLinkedProgress(false);
          setLinkName(linkName);
        } finally {
          setIsReadingLinkedProgress(false);
        }
      }

      const timeout = setTimeout(async () => {
        try {
          await callGetLinkName();
        } catch (error: any) {
          if (isConsentError(error)) {
            if (PlannerLinkService.isPlannerLink(sourceItemUrl)) {
              AuthService.resetAuth(api.TokenKind.planner);
              PlannerNotifications.makeConsentNotification(callGetLinkName, {
                addNotification: (n) => setErrorInfo(n),
                clearNotifications: () => setErrorInfo(null)
              }, TextService.format(strings.PlannerNotification_TestAuthorizationRequired));
            }
          } else {
            setErrorInfo({ type: NotificationType.error, message: TextService.format(strings.EditSoureLinkDialog_ErrorReadProgress), error });
          }
        }
      }, 500);
      return () => clearTimeout(timeout);
    }
  }, [sourceItemUrl]);

  const ensurePlannerSyncEnabled = async (plan: IVistoPlan) => {
    const settings = PlanSettingsService.getPlanSettings(plan);
    if (!settings.integrations?.planner?.level) {
      const updatedSettings: IVistoPlanSettings = {
        ...settings,
        integrations: {
          ...settings.integrations,
          planner: {
            level: PlannerIntegrationLevel.LOP,
            enableLabelSync: false,
            url: undefined,
          }
        }
      };
      plan = PlanSettingsService.setPlanSettings(plan, updatedSettings);
      plan = await StorageService.get(plan.siteUrl).updatePlanItem(plan, { settingsJson: plan.settingsJson }, notify);
    }
    return plan;
  }

  const save = () => {
    return dispatchCommand({
      prepare: async () => {

        const changes = ChangesService.getChanges(props.item, { sourceItemUrl }, ['sourceItemUrl']);
        const options: IOperationOptions = {
          excludeExternals: true,
          excludePercentComplete: true,
          excludeGroupByKind: true
        };

        return {
          do: async (plan) => {
            if (PlannerLinkService.isPlannerLink(sourceItemUrl)) {
              plan = await ensurePlannerSyncEnabled(plan);
            }
            
            plan = await StorageService.get(plan.siteUrl).updateItems(plan, [{ item: props.item, changes }], notify, options);
            plan = await ProgressService.ensureSync(plan, [props.item], notify, api.WSOperation.load, { dashboard: true });

            return plan;
          },
          undo: async (plan) => {
            plan = await StorageService.get(plan.siteUrl).updateItems(plan, [{ item: props.item, changes }], notify, { ...options, reverse: true });
            plan = await ProgressService.ensureSync(plan, [props.item], notify, api.WSOperation.load, { dashboard: true });
            return plan;
          },
        };
      },
      message: TextService.format(strings.EditSourceLinkDialog_Command, { itemName: props.item.name }),
      name: CommandName.EditSourceLink
    }, {
      wrap: false
    });
  };

  enum TabKey {
    default,
    project,
    devops,
    sharepoint
  }

  const getDefaultTab = (): TabKey => {
    if (PlannerLinkService.isPlannerLink(sourceItemUrl) && LicenseService.license?.plannerEnabled) {
      return TabKey.default;
    }
    if (ProjectDataService.isLinkedItem(sourceItemUrl) && LicenseService.license?.projectEnabled) {
      return TabKey.project;
    }
    if (DevOpsDataService.isLinkedItem(sourceItemUrl) && LicenseService.license?.devopsEnabled) {
      return TabKey.devops;
    }
    if (SharepointExternalDataService.isLinkedItem(sourceItemUrl)) {
      return TabKey.sharepoint;
    }
    if (!sourceItemUrl) {
      if (planSettings?.integrations?.project?.enabled && LicenseService.license?.projectEnabled) {
        return TabKey.project;
      }
      if (planSettings?.integrations?.devops?.enabled && LicenseService.license?.devopsEnabled) {
        return TabKey.devops;
      }
      return TabKey.sharepoint;
    }
    return TabKey.default;
  };

  const [selectedTab, setSelectedTab] = React.useState(getDefaultTab());

  return (<BasicDialog
    isModeless={true}
    onDismiss={props.onDismiss}
    buttonOkAction={save}
    maxWidth={800}
    isButtonOkDisabled={!isPlanEditEnabled || !!errorInfo && (errorInfo.type === NotificationType.error || errorInfo.type === NotificationType.warn)}
    title={isPlanEditEnabled ? TextService.format(strings.EditSourceLinkDialog_TitleEdit) : TextService.format(strings.EditSourceLinkDialog_TItleView)}
    buttonOkText={TextService.format(strings.EditSourceLinkDialog_ButtonOk)}
    buttonOkBusyText={TextService.format(strings.EditSourceLinkDialog_ButtonOkBusy)}
    buttonCancelText={TextService.format(strings.ButtonCancel)}
    content={
      <Stack style={{ minHeight: 350 }} tokens={{ childrenGap: 'm' }}>

        <Text>{TextService.format(strings.EditSourceLinkDialog_Text)}</Text>

        <Pivot selectedKey={TabKey[selectedTab]} onLinkClick={(item) => setSelectedTab(TabKey[item.props.itemKey])} >
          {planSettings?.integrations?.project?.enabled && LicenseService.license?.projectEnabled &&
            <PivotItem itemKey={TabKey[TabKey.project]} headerText={TextService.format(strings.EditSourceLink_ProjectTab)}>
              <ProjectLinkEditor plan={props.plan} sourceItemUrl={sourceItemUrl} setSourceItemUrl={setSourceItemUrl} />
            </PivotItem>
          }
          {planSettings?.integrations?.devops?.enabled && LicenseService.license?.devopsEnabled &&
            <PivotItem itemKey={TabKey[TabKey.devops]} headerText={TextService.format(strings.EditSourceLink_DevOpsTab)}>
              <DevOpsLinkEditor plan={props.plan} sourceItemUrl={sourceItemUrl} setSourceItemUrl={setSourceItemUrl} />
            </PivotItem>
          }
          {true &&
            <PivotItem itemKey={TabKey[TabKey.sharepoint]} headerText={(strings.EditSourceLink_VisplanTab)}>
              <SharePointExternalDataLinkEditor plan={props.plan} sourceItemUrl={sourceItemUrl} setSourceItemUrl={setSourceItemUrl} />
            </PivotItem>
          }
          <PivotItem itemKey={TabKey[TabKey.default]} headerText={TextService.format(strings.EditSourceLink_UrlTab)}>
            <TextField
              disabled={!isPlanEditEnabled}
              value={sourceItemUrl}
              onChange={(_, val) => setSourceItemUrl(val)}
              label={TextService.format(strings.EditSourceLinkDialog_FieldLabel)}
            />
          </PivotItem>
        </Pivot>

        <InfoBar {...errorInfo} />

        {sourceItemUrl &&
          <Stack tokens={{ childrenGap: 's1', padding: 's1' }}>
            {isReadingLinkedProgress
              ? <Stack horizontal verticalAlign='center' tokens={{ childrenGap: 's1' }}><Spinner /><Text>{TextService.format(strings.EditSourceLinkDialog_Reading)}</Text></Stack>
              : <DevOpsLink url={sourceItemUrl} text={linknName} />
            }
          </Stack>
        }
      </Stack>
    }
  />);
}
