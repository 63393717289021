import * as strings from 'VistoWebPartStrings';
import * as React from 'react';
import { IAttachment } from 'sp';
import { AppContext } from 'services/AppContext';
import { EnvContext } from 'services/EnvContext';
import { notifyInfoBar, NotificationType } from 'services/Notify';
import { StorageService } from 'services/StorageService';
import { TextService } from 'services/TextService';
import { TextField, Stack } from '@fluentui/react';
import styles from './AttachDocument.module.scss';
import { FilePicker, IFilePickerResult } from '@pnp/spfx-controls-react/lib/controls/filePicker';
import { AppFolderExplorer } from 'components/AppFolderExplorer';
import { UrlService } from 'shared/urlService';

export function AttachDocument(props: {
  isOpen: boolean;
  onChange: (attachment: IAttachment) => void;
}) {

  const [linkName, setLinkName] = React.useState(null);

  const { siteUrl, defaultFolderRelativeUrl, webPartContext } = React.useContext(EnvContext);
  const { notify } = React.useContext(AppContext);

  const onSave = async (results: IFilePickerResult[]) => {

    for (const result of results) {
      
      try {

        if (!result.fileAbsoluteUrl) {

          const fileConent = await result.downloadFileContent();
          const addedFile = await StorageService.get(selectedFolder).putFile(siteUrl, selectedFolder, result.fileName, fileConent);
          result.fileName = addedFile.fileName;
          result.fileAbsoluteUrl = addedFile.fileAbsoluteUrl;
        }

        const permalink = await StorageService.get(result.fileAbsoluteUrl).makeAttachment(result.fileName, result.fileAbsoluteUrl, siteUrl);

        if (linkName && results.length === 1) {
          permalink.fileName = linkName;
        }

        props.onChange(permalink);

      } catch (error) {
        notifyInfoBar(notify, { 
          type: NotificationType.error, 
          message: TextService.format(strings.AttachDocument_Error), 
          error 
        });
      }
    }
  };

  const renderLinkTabContent = (fliePickerResult: IFilePickerResult) => {
    return (
      <Stack>
        <TextField className={styles.linkNameField} label={TextService.format(strings.AttachDocument_LinkName)} value={linkName} onChange={(_, val) => setLinkName(val)} />
      </Stack>
    );
  };

  const [selectedFolder, setSelectedFolder] = React.useState(defaultFolderRelativeUrl);

  const renderCustomUploadTabContent = () => (
    <AppFolderExplorer selectedFolder={selectedFolder} setSelectedFolder={setSelectedFolder} />
  );

  const attachmentsSupported = StorageService.get(siteUrl).attachmentsSupported;
  const defaultFolderAbsolutePath = UrlService.combine(UrlService.getOrigin(siteUrl), defaultFolderRelativeUrl);

  return (
    <div style={{ display: 'none' }}>

      <FilePicker
        hideStockImages
        hideRecentTab
        hideOrganisationalAssetTab
        hideOneDriveTab
        hideSiteFilesTab={!attachmentsSupported}
        hideLocalUploadTab={!attachmentsSupported}
        storeLastActiveTab={false}
        isPanelOpen={props.isOpen}
        buttonLabel={null}
        onSave={onSave}
        context={webPartContext as any}
        onCancel={() => props.onChange(null)}
        renderCustomLinkTabContent={renderLinkTabContent}
        defaultFolderAbsolutePath={defaultFolderAbsolutePath}
        renderCustomUploadTabContent={renderCustomUploadTabContent}
      />
    </div>
  );

}
