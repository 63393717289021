import React from 'react';
import { TooltipHost } from '@fluentui/react';
import { TextService } from 'services/TextService';
import strings from 'VistoWebPartStrings';

export const PendingChanges = (props: {
  items: any[];
}) => {
  return props.items.length > 0
    ? <TooltipHost content={TextService.formatList(props.items)}>
      <div style={{ fontSize: 'small' }}>{TextService.format(strings.ActionLassoDialog_UnsavedChanges, { pendingChangesCount: props.items.length })}</div>
    </TooltipHost>
    : null;
};
