import { MessageBarType } from '@fluentui/react';
import { NotificationType } from 'services/Notify';

export function translateInfoBarType(type: NotificationType) {
  switch (type) {
    case NotificationType.warn:
      return MessageBarType.warning;
    case NotificationType.error:
      return MessageBarType.error;
    case NotificationType.success:
      return MessageBarType.success;
    default:
    case NotificationType.log:
      return MessageBarType.info;
  }
}
