export enum CellKind {
  NONE,

  DP,
  LOP,
  FOCUS,
  POSITION,
  TITLE,
  PHASE,

  IMAGE,
  text,
  PAGESIZER,

  COUNT
}
