import React from 'react';
import { PlanDataService } from 'services/PlanDataService';
import { IVistoPlan, VistoDpItem } from 'sp';
import { SidebarActionListGroup } from './SidebarActionListGroup';


export function SidebarActionFlatList(props: {
  plan: IVistoPlan;
  dp: VistoDpItem;
}) {

  const actions = PlanDataService.getDpActions(props.plan, props.dp.guid);

  return <SidebarActionListGroup
    plan={props.plan}
    expandKey='FLAT_LIST'
    defaultExpanded
    actions={actions} />;
}
