import * as React from 'react';
import strings from 'VistoWebPartStrings';
import { AppContext } from 'services/AppContext';
import { AttachmentService } from 'services/AttachmentService';
import { ChangesService } from 'services/ChangesService';
import { Commands } from 'services/Commands';
import { useEnvNavigate } from 'services/NavigationService';
import { StorageService } from 'services/StorageService';
import { TextService } from 'services/TextService';
import { Sidebar } from './Sidebar';
import { IContextualMenuItem } from '@fluentui/react';
import { getListDefinition, IAttachment, IFieldValueUrl, IVistoPlan, VistoKind } from 'sp';
import { MenuItems } from 'frames/TopFrame/MenuItems';
import { AttachDocument } from './AttachDocument';
import { ISidebarProps } from './ISidebarProps';
import { EditUrlDialog } from 'dialogs/common/EditUrlDialog';
import { DeleteUrlDialog } from 'dialogs/common/DeleteUrlDialog';
import { useSidebarShareDialog } from '../SidebarShareHook';
import { EnvContext, isTeams } from 'services/EnvContext';

export interface ISidebarPlanProps extends ISidebarProps {
  plan: IVistoPlan;
  nameProp: keyof IVistoPlan;
  descriptionProp: keyof IVistoPlan;
  navigationLinkProp: keyof IVistoPlan;
}

export function SidebarPlan(props: ISidebarPlanProps) {

  const { dispatchCommand, isPlanEditEnabled, isPopupOpen, notify } = React.useContext(AppContext);

  const onLoad = async () => {
    const loaded = await StorageService.get(props.plan.siteUrl).loadPlanItem(props.plan, [props.nameProp, props.descriptionProp]);
    return { name: loaded[props.nameProp] as string, description: loaded[props.descriptionProp] as string };
  };

  const onUpdatePlan = async (changes) => {
    const listDefinition = getListDefinition<IVistoPlan>(VistoKind.Plan);
    const modifiedProperties = changes.properties.map(x => `'${TextService.format(strings[listDefinition.fields[x].key])}'`);
    const message = TextService.format(strings.EditPlanPropertyDialog_Command, { property: TextService.formatList(modifiedProperties) });
    const command = Commands.makeUpdatePlanPropertyCommand(changes, message, notify);
    return await dispatchCommand(command, { wrap: true });
  };

  const onSave = (name, description) => {
    const changes = ChangesService.getChanges(props.plan, { [props.nameProp]: name, [props.descriptionProp]: description });
    return onUpdatePlan(changes);
  };

  const [isAttachDocumentOpen, setIsAttachDocumentOpen] = React.useState(false);
  const [editLinkDialogVisible, setEditLinkDialogVisible] = React.useState(false);
  const [deleteLinkDialogVisible, setDeleteLinkDialogVisible] = React.useState(false);

  const onAttachDocument = (attachment: IAttachment) => {
    setIsAttachDocumentOpen(false);
    if (attachment) {
      dispatchCommand(AttachmentService.makeAddAttachmentCommand(props.plan, attachment, AttachmentService.makePlanAttachmentsCommand, notify), { wrap: true });
    }
  };

  const isEditDisabled = !isPlanEditEnabled || isPopupOpen;

  const attachmentsMenuItem: IContextualMenuItem = props.plan.links && {
    key: 'MenuItem_Attachments',
    subMenuProps: {
      items: MenuItems.getAttachmentsMenuItems(props.plan, dispatchCommand, AttachmentService.makePlanAttachmentsCommand, notify)
    },
    iconProps: {
      iconName: 'Attach'
    }
  };

  const { hostKind } = React.useContext(EnvContext);
  const navigate = useEnvNavigate(isTeams(hostKind));

  const navigationLink = props.plan[props.navigationLinkProp] as IFieldValueUrl;

  const navigationLinkMenuItem: IContextualMenuItem = navigationLink && {
    key: 'MenuItem_Progress',
    title: navigationLink?.description ?? navigationLink.url,
    iconProps: { iconName: 'Relationship' },
    onClick: (event) => {
      event.preventDefault();
      navigate(navigationLink.url, '_blank');
    },
    href: navigationLink.url,
    target: '_blank'
  };

  const menuItems = [
    ...(props.menuItems ?? []),
    ...(attachmentsMenuItem ? [attachmentsMenuItem] : []),
    ...(navigationLinkMenuItem ? [navigationLinkMenuItem] : [])
  ];

  const overflowMenuItems = [
    ...(props.overflowMenuItems ?? []),
    MenuItems.getAttachmentsMenuItem(isEditDisabled, () => setIsAttachDocumentOpen(true)),
    MenuItems.GetTeamsLinkMenuItem(() => setShareLinkDialogVisible(true)),
    MenuItems.getRelationshipMenuItem(TextService.format(strings.MenuItem_NavigationLink), [
      MenuItems.getEditNavigationLinkMenuItem(isEditDisabled, () => setEditLinkDialogVisible(true)),
      MenuItems.getBreakNavigationLinkMenuItem(isEditDisabled || !navigationLink, () => setDeleteLinkDialogVisible(true)),
    ])
  ];

  const farItems = props.farItems ?? [];

  const onEditLink = (newUrl: IFieldValueUrl) => {
    const changes = ChangesService.getChanges(props.plan, { [props.navigationLinkProp]: newUrl });
    return onUpdatePlan(changes);
  };

  const onDeleteLink = () => {
    const changes = ChangesService.getChanges(props.plan, { [props.navigationLinkProp]: null });
    return onUpdatePlan(changes);
  };

  const [ShareLinkDialog, setShareLinkDialogVisible] = useSidebarShareDialog();

  return <Sidebar
    hideAssignedTo
    advisoryTopicKey={props.advisoryTopicKey}
    onLoad={onLoad}
    isOpen={props.isOpen} onDismiss={props.onDismiss}
    menuItems={menuItems}
    overflowMenuItems={overflowMenuItems}
    farItems={farItems}
    content={
      <React.Fragment>
        {props.content}
        {isAttachDocumentOpen && <AttachDocument onChange={onAttachDocument} isOpen={isAttachDocumentOpen} />}
        {editLinkDialogVisible && <EditUrlDialog url={navigationLink} onCommit={onEditLink} onDismiss={() => setEditLinkDialogVisible(false)} />}
        {deleteLinkDialogVisible && <DeleteUrlDialog onCommit={onDeleteLink} onDismiss={() => setDeleteLinkDialogVisible(false)} />}
        {ShareLinkDialog}
      </React.Fragment>
    }
    name={props.plan[props.nameProp] as string}
    disableName={false}
    description={props.plan[props.descriptionProp] as string}
    disableDescription={false}
    onSave={onSave}
  />;
}
