import * as React from 'react';
import { AppContext } from 'services/AppContext';
import { TextService } from 'services/TextService';
import * as strings from 'VistoWebPartStrings';
import { ColorComboBox } from './ColorComboBox';

export function FontColorComboBox(props: {
  disabled: boolean;
  color: string;
  setColor: (val: string) => void
}) {

  const { propertyBag, setPropertyBag } = React.useContext(AppContext);

  const setColor = (val) => {
    setPropertyBag({ fontColor : val });
    props.setColor(val);
  };

  return (
    <ColorComboBox
      disabled={props.disabled}
      buttonColor={propertyBag.fontColor}
      iconName='FontColor'
      title={TextService.format(strings.FontColorComboBox_TItle)}
      color={props.color}
      setColor={setColor}
      extraItems={null}
      isToolbar
    />
  );
}
