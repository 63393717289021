import * as React from 'react';
import { Coachmark, DirectionalHint, IconButton, TeachingBubbleContent } from '@fluentui/react';
import { mxglobals } from '../drawing/common';
import { TextService } from 'services/TextService';
import strings from 'VistoWebPartStrings';

export const TopMenuButton = (props: {
  actions: any;
  name: string;
  iconName: string;
  title: string;
  checked?: boolean;
  coachmarkName?: string;
  coachmarkText?: string;
  coachmarkTitle?: string;
  onClick?: (name: string) => void;
}) => {
  const action = props.actions.get(props.name);

  const [disabled, setDisabled] = React.useState(!action.isEnabled());
  const onStateChanged = () => setDisabled(!action.isEnabled());

  React.useEffect(() => {
    action.addListener('stateChanged', onStateChanged);
    return () => action.removeListener(onStateChanged);
  }, [action]);

  const onClick = () => {
    action.funct();
    if (typeof props.onClick === 'function')
      props.onClick(props.name);
  };

  if (!action)
    return null;

  const targetButton = React.useRef(null);

  const [isCoachmarkVisible, setCoachmarkVisible] = React.useState(props.coachmarkName && !localStorage.getItem(props.coachmarkName));

  const hideCoachmark = () => {
    setCoachmarkVisible(false);
    localStorage.setItem(props.coachmarkName, 'done');
  };

  return (
    <div ref={targetButton}>
      <IconButton
        styles={{
          root: {
            backgroundColor: 'transparent',
            padding: 6,
            margin: '0 2px 0 2px 0',
          },
          rootDisabled: {
            backgroundColor: 'transparent'
          }
        }}
        iconProps={{ iconName: props.iconName }}
        title={props.title}
        checked={props.checked}
        disabled={disabled}
        onClick={onClick}
      />
      {isCoachmarkVisible && (
        <Coachmark target={targetButton.current} positioningContainerProps={{ directionalHint: DirectionalHint.bottomCenter }}>
          <TeachingBubbleContent headline={props.coachmarkTitle} hasCloseButton
            primaryButtonProps={{ text: TextService.format(strings.CoachmarkButton_TryIt), onClick: onClick }}
            secondaryButtonProps={{ text: TextService.format(strings.CoachmarkButton_Later), onClick: hideCoachmark }}
            onDismiss={hideCoachmark}
          >{props.coachmarkText}</TeachingBubbleContent>
        </Coachmark>
      )}
    </div>
  );
};
