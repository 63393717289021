import { Stack, Icon, Text, useTheme } from '@fluentui/react';
import { ProgressBarBlock } from 'frames/TopFrame/sidebars/common/ProgressBarBlock';
import { ProgressCircle } from 'frames/TopFrame/sidebars/common/ProgressCircle';
import React from 'react';
import { PlanKeyresultsService } from 'services/PlanKeyresultsService';
import { IVistoPlan, VistoKeyResultItem } from 'sp';
import { OkrAssigneeBlock } from './OkrAssigneeBlock';
import { OkrCardName } from './OkrCardName';

export const OkrCardKR = (props: {
  plan: IVistoPlan;
  item: VistoKeyResultItem;
  viewStartDate: Date;
  viewEndDate: Date;
  showExpand: boolean;
  expanded: boolean;
}) => {

  const { palette } = useTheme();

  const { targets, actuals } = PlanKeyresultsService.getKeyResultValues(props.plan, props.item.guid);

  return (
    <Stack horizontalAlign='center' tokens={{ padding: 12, childrenGap: 8 }} style={{ height: '100%' }}>
      <Stack grow horizontal style={{ overflow: 'hidden' }} verticalAlign='start' tokens={{ childrenGap: 8, padding: '4px 0' }}>
        <Icon iconName='Chart' style={{ fontSize: '1.5rem', color: palette.neutralTertiary }} />
        <OkrCardName variant='mediumPlus' name={props.item.name} />
        <ProgressCircle size={24} plan={props.plan} kr={props.item} actuals={actuals} targets={targets} viewStartDate={props.viewStartDate} viewEndDate={props.viewEndDate} />
      </Stack>
      <OkrAssigneeBlock assignedTo={props.item.assignedTo} />
      <ProgressBarBlock plan={props.plan} kr={props.item} actuals={actuals} targets={targets} viewStartDate={props.viewStartDate} viewEndDate={props.viewEndDate} />
      <Stack horizontal horizontalAlign='center' style={{ height: '2rem' }}>
        {props.showExpand && <Icon className='expand' style={{ fontSize: '2rem', color: palette.neutralTertiary }} iconName={props.expanded ? 'CollapseContentSingle' : 'ExploreContentSingle'} />}
      </Stack>
    </Stack>
  );
}
