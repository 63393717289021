import React from 'react';
import { ChoiceGroup, IChoiceGroupOption, IChoiceGroupOptionProps, Stack, Text } from '@fluentui/react';

export interface IWizardChoiceGroupOption extends IChoiceGroupOption {
  description: string;
}

const renderLabel = (props: IChoiceGroupOptionProps, defaultRender: (props?: IChoiceGroupOptionProps) => JSX.Element | null, description: string) => {
  return (
    <Stack>
      {defaultRender(props)}
      <Stack tokens={{ padding: '4px 0px 8px 26px' }}>
        <Text variant='small'>{description}</Text>
      </Stack>
    </Stack>
  );
};

export const WizardChoiceGroup = (props: {
  options: IWizardChoiceGroupOption[];
  selectedKey: string;
  onKeyChanged: (val: string) => void;
}) => {

  const options = props.options.map(x => ({
    ...x,
    onRenderLabel: (renderProps, defaultRenderer) => renderLabel(renderProps, defaultRenderer, x.description)
  }));

  return (
    <ChoiceGroup
      options={options}
      selectedKey={props.selectedKey}
      onChange={(_, val) => props.onKeyChanged(val.key)}
    />
  );
};
