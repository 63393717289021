import { UrlService } from 'shared/urlService';
import { SharepointService } from 'services/SharepointService';
import { AuthService } from 'services/AuthService';
import { ListItemUpdater, VistoKind } from 'sp';

export class ItemInfoService {

  static getSpList(siteUrl: string, planId: string, itemKind: VistoKind) {
    const spListUrl = UrlService.getListRelativeUrl({ planId, siteUrl }, itemKind);
    const sp = AuthService.getSpClient(siteUrl, 'short');
    const spList = sp.web.getList(spListUrl);
    return spList;
  }

  public static async getSpItemInfo(siteUrl: string, planId: string, itemKind: VistoKind, itemId: number) {
    const spList = this.getSpList(siteUrl, planId, itemKind);
    const spItem = await spList.items.getById(itemId)();
    const updater = new ListItemUpdater(0);
    const item = SharepointService.getItemFromSpItem(updater, spItem, itemKind);
    const result = {
      key: item.itemId,
      guid: item.guid,
      name: item.name,
    };
    return result;
  }

  public static async getSpItemInfos(siteUrl: string, planId: string, itemKind: VistoKind, filter: string) {
    const spList = this.getSpList(siteUrl, planId, itemKind);
    const spItems = await spList.items.filter(`substringof('${filter}',Title)`).top(10)();
    const updater = new ListItemUpdater(0);
    const result = spItems.map(spItem => {
      const item = SharepointService.getItemFromSpItem(updater, spItem, itemKind);
      return {
        key: item.itemId,
        guid: item.guid,
        name: item.name,
      };
    });
    return result;
  }

}
