import React from 'react';
import strings from 'VistoWebPartStrings';
import { FilePicker, IFilePickerResult } from '@pnp/spfx-controls-react/lib/controls/filePicker';
import styles from './WebFilePicker.module.scss';
import { TextService } from 'services/TextService';
import { AppFolderExplorer } from 'components/AppFolderExplorer';
import { EnvContext } from 'services/EnvContext';
import { UrlService } from 'shared/urlService';

export interface IWebFilePickerResult {
  selectedFolder: string;
  results: IFilePickerResult[];
}

export const WebFilePicker = (props: {
  isOpen: boolean;
  onCancel: () => void;
  onSave: (val: IWebFilePickerResult) => void;
}) => {

  const { webPartContext, defaultFolderRelativeUrl, siteUrl } = React.useContext(EnvContext);

  const [selectedFolder, setSelectedFolder] = React.useState<string>(defaultFolderRelativeUrl);

  const renderCustomUploadTabContent = () => (
    <AppFolderExplorer selectedFolder={selectedFolder} setSelectedFolder={setSelectedFolder} />
  );

  const defaultFolderAbsolutePath = UrlService.combine(UrlService.getOrigin(siteUrl), defaultFolderRelativeUrl);

  return (
    <FilePicker
      disabled={!siteUrl}
      hideStockImages
      hideRecentTab
      hideOrganisationalAssetTab
      hideOneDriveTab
      hideLinkUploadTab
      storeLastActiveTab={false}
      panelClassName={styles.panel}
      isPanelOpen={props.isOpen}
      accepts={['json']}
      buttonLabel={TextService.format(strings.Button_BrowseSite)}
      onSave={(results) => props.onSave({ selectedFolder, results })}
      context={webPartContext}
      onCancel={props.onCancel}
      defaultFolderAbsolutePath={defaultFolderAbsolutePath}
      renderCustomUploadTabContent={renderCustomUploadTabContent}
    />
  );
};
