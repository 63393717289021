import { Stack, TextField, DefaultButton, Link, IContextualMenuProps } from '@fluentui/react'
import { InfoBar, useErrorInfo } from 'components'
import React from 'react'
import { EnvContext } from 'services/EnvContext'
import { ExcelService } from 'services/ExcelService'
import { NotificationType } from 'services/Notify'
import { StorageCacheService } from 'services/StorageCacheService'
import { TextService } from 'services/TextService'
import { isConsentError } from 'shared/parse'
import { IVistoPlan, KeyResultValueKind, VistoKeyResultItem, VistoKeyResultValueItem } from 'sp'
import strings from 'VistoWebPartStrings'

export const EditKeyResultExcel = (props: {
  plan: IVistoPlan;

  valueKind: KeyResultValueKind;
  kr: VistoKeyResultItem;

  values: VistoKeyResultValueItem[];
  onSetValues: (values: VistoKeyResultValueItem[]) => void;

  excelOptions: IkeyResultExcelOptions;
  setExcelOptions: (val: IkeyResultExcelOptions) => void;
}) => {

  const { defaultFolderRelativeUrl } = React.useContext(EnvContext);

  const setDefaultExcelOptions = async () => {
    const action = async () => {
      const getDefaultExcelOptions = await ExcelService.getDefaultExcelOptions(props.plan, props.kr, defaultFolderRelativeUrl, props.valueKind);
      props.setExcelOptions(getDefaultExcelOptions);
    }
    try {
      setIsLoading(true);
      await action();
    } catch (error) {
      if (isConsentError(error)) {
        ExcelService.makeConsentNotification(action, {
          addNotification: (n) => setErrorInfo(n),
          clearNotifications: () => setErrorInfo(null)
        })
      } else {
        setErrorInfo({
          type: NotificationType.error,
          message: TextService.format(strings.ExcelError_CannotGetDefaultOptions),
          error
        });
      }
    } finally {
      updateWorkbookUrl();
      setIsLoading(false);
    }
  }

  React.useEffect(() => {
    const excelOptions = props.excelOptions;
    if (!excelOptions.excelWorkbook && !excelOptions.excelTable && !excelOptions.excelDateColumn && !excelOptions.excelValueColumn && !excelOptions.excelCommentColumn) {
      setDefaultExcelOptions();
    }
  }, [props.plan, props.kr, defaultFolderRelativeUrl, props.valueKind]);

  const createKeyResultWorkbook = async () => {

    const action = async () => {
      setIsLoading(true);
      setErrorInfo(null);
      StorageCacheService.resetCache('https://graph.microsoft.com/v1.0/drive');
      const options = await ExcelService.createKeyResultData(
        props.plan,
        props.kr,
        props.excelOptions,
        props.values);

      props.setExcelOptions(options);
    }

    try {
      await action();
    } catch (error) {
      if (isConsentError(error)) {
        ExcelService.makeConsentNotification(action, {
          addNotification: (n) => setErrorInfo(n),
          clearNotifications: () => setErrorInfo(null)
        })
      } else {
        setErrorInfo({
          type: NotificationType.error,
          message: TextService.format(strings.ExcelService_CreateError),
          error
        });
      }

    } finally {
      updateWorkbookUrl();
      setIsLoading(false);
    }
  }

  const refreshKeyResultValues = async () => {

    const notify = {
      addNotification: (n) => setErrorInfo(n),
      clearNotifications: () => setErrorInfo(null)
    };

    const action = async () => {
      setErrorInfo(null);
      setIsLoading(true);
      StorageCacheService.resetCache('https://graph.microsoft.com/v1.0/drive');
      const krvs = await ExcelService.getKeyResultData(props.plan, props.kr, props.excelOptions, props.valueKind, notify);
      if (ExcelService.changesDetected(props.values, krvs)) {
        props.onSetValues(krvs);
      }
    }

    try {
      await action();
    } catch (error) {
      if (isConsentError(error)) {
        ExcelService.makeConsentNotification(action, notify)
      } else {
        setErrorInfo({ 
          type: NotificationType.error,
          message: TextService.format(strings.ExcelService_RefreshError), 
          error
        });
      }
    } finally {
      updateWorkbookUrl();
      setIsLoading(false);
    }
  }

  const [isLoading, setIsLoading] = React.useState(false);

  const [errorInfo, setErrorInfo] = useErrorInfo();

  const [isCheckingWorkbookStatus, setCheckingWorkbookStatus] = React.useState(false);
  const [workbookUrl, setWorkbookUrl] = React.useState<string | null>(null);

  const updateWorkbookUrl = async () => {
    if (props.excelOptions.excelWorkbook && props.excelOptions.excelTable) {
      const action = async () => {
        setCheckingWorkbookStatus(true);
        const url = await ExcelService.getWorkbookUrl(props.plan.siteUrl, props.excelOptions);
        setWorkbookUrl(url);
      }
      try {
        await action();
        setCheckingWorkbookStatus(false);
      } catch (error) {
        if (isConsentError(error)) {
          ExcelService.makeConsentNotification(action, {
            addNotification: (n) => setErrorInfo(n),
            clearNotifications: () => setErrorInfo(null)
          })
        } else {
          if (error.status !== 404) {
            setErrorInfo({
              type: NotificationType.error,
              message: TextService.format(strings.ExcelService_RefreshError), 
              error
            });
          }
        }
      }
    }
  }

  React.useEffect(() => {
    setCheckingWorkbookStatus(true);
    const timeout = setTimeout(updateWorkbookUrl, 500);
    return () => {
      setCheckingWorkbookStatus(false);
      clearTimeout(timeout);
    }
  }, [props.excelOptions.excelWorkbook, props.excelOptions.excelTable]);

  return (
    <Stack tokens={{ childrenGap: 's1' }}>
      <Stack style={{ maxWidth: 600 }}><InfoBar {...errorInfo} /></Stack>
      <Stack horizontal verticalAlign='end' tokens={{ childrenGap: 'm' }}>
        <Stack.Item grow>
          <TextField
            label={TextService.format(strings.ExcelBlockLabel_Workbook)}
            disabled={isLoading}
            value={props.excelOptions.excelWorkbook}
            onChange={(_, val) => props.setExcelOptions({ ...props.excelOptions, excelWorkbook: val })}
          />
        </Stack.Item>
        <TextField
          label={TextService.format(strings.ExcelBlockLabel_Table)}
          disabled={isLoading}
          value={props.excelOptions.excelTable}
          onChange={(_, val) => props.setExcelOptions({ ...props.excelOptions, excelTable: val })}
        />
        {workbookUrl
          ? <DefaultButton
            text={TextService.format(strings.ExcelBlockLabel_ButtonRefresh)}
            onClick={refreshKeyResultValues}
            disabled={isLoading || isCheckingWorkbookStatus}
          />
          : <DefaultButton
            text={TextService.format(strings.ExcelBlockLabel_ButtonCreate)}
            onClick={createKeyResultWorkbook}
            disabled={isLoading || isCheckingWorkbookStatus}
          />
        }
      </Stack>
      <Stack.Item>
        <Link href={workbookUrl} disabled={isCheckingWorkbookStatus || !workbookUrl} target='_blank'>
          {TextService.format(isLoading
            ? strings.ExcelBlockStatus_Processing
            : isCheckingWorkbookStatus
              ? strings.ExcelBlockStatus_CheckingFileStatus
              : workbookUrl
                ? strings.ExcelBlockStatus_OpenExcelWorkbook
                : strings.ExcelBlockStatus_NoWorkbook)
          }
        </Link>
      </Stack.Item>
      <Stack horizontal verticalAlign='end' horizontalAlign='start' tokens={{ childrenGap: 'm' }}>
        <TextField
          label={TextService.format(strings.ExcelBlockLabel_DateColumn)}
          disabled={isLoading}
          value={props.excelOptions.excelDateColumn}
          onChange={(_, val) => props.setExcelOptions({ ...props.excelOptions, excelDateColumn: val })}
        />
        <TextField
          label={TextService.format(strings.ExcelBlockLabel_ValueColumn)}
          disabled={isLoading}
          value={props.excelOptions.excelValueColumn}
          onChange={(_, val) => props.setExcelOptions({ ...props.excelOptions, excelValueColumn: val })}
        />
        <TextField
          label={strings.ExcelBlockLabel_CommentColumn}
          disabled={isLoading}
          value={props.excelOptions.excelCommentColumn}
          onChange={(_, val) => props.setExcelOptions({ ...props.excelOptions, excelCommentColumn: val })}
        />
      </Stack>
    </Stack>

  )
}
