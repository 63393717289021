import { IContextualMenuItem, Panel, PanelType, Stack, Text } from '@fluentui/react';
import { InfoBar } from 'components/InfoBar';
import * as React from 'react';
import { INotification } from 'services/Notify';
import { TextService } from 'services/TextService';
import strings from 'VistoWebPartStrings';
import { SidebarTopMenu } from './common';

export const Notifications = (props: {
  isOpen: boolean;
  items: INotification[];
  menuItems: IContextualMenuItem[];
  onDismiss: () => void;
}) => {

  const items = [...props.items].reverse();

  return (
    <Panel
      type={PanelType.customNear}
      customWidth='350px'
      isBlocking={false}
      isLightDismiss
      isOpen={props.isOpen}
      onDismiss={props.onDismiss}
      layerProps={{ styles: { root: { zIndex: 1000 } } }}
      onRenderNavigationContent={(panelProps, defaultRender) =>
        <SidebarTopMenu
          menuItems={props.menuItems}
          overflowMenuItems={[]}
          farItems={[]}
          closeButton={defaultRender(panelProps)} />
      }
    >
      <Stack tokens={{ childrenGap: 'm', padding: 'm 0' }}>
        <Text variant='large'>{TextService.format(strings.NotificationSidebar_Title)}</Text>
        {items.map((item, index) => (
          <InfoBar key={index} {...item} />)
        )}
      </Stack>
    </Panel>
  );
};

