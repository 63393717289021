import { IVistoListItem, VistoKind, addListDefinition, IFieldDefinitions, ListItemUpdater, Choices } from './common';
import { FieldGenerator } from './common/FieldGenerator';

export enum KeyResultValueKind {
  Actual = 0,
  Target = 1,
}

const choices: Choices = [
  { key: KeyResultValueKind.Target, stringId: 'VistoKRVItem_Target' },
  { key: KeyResultValueKind.Actual, stringId: 'VistoKRVItem_Actual' }
];

export interface VistoKeyResultValueItem extends IVistoListItem {
  readonly krGuid: string;
  readonly valueKind: KeyResultValueKind;
  readonly valueDate: Date;
  readonly value: number;
}

export const VistoKeyResultValueListFields: IFieldDefinitions<VistoKeyResultValueItem> = {
  itemId: FieldGenerator.makeIdField('VISTO_KRV_ItemId'),
  guid: FieldGenerator.makeGuidField('VISTO_KRV_Guid'),
  name: FieldGenerator.makeTitleField('VISTO_KRV_Name'),
  description: FieldGenerator.makeDescriptionField('VISTO_KRV_Description'),
  links: FieldGenerator.makeLinksField('VISTO_KRV_Links'),
  krGuid: FieldGenerator.makeLookupField('VISTO_KRV_KeyResult', 'KeyResult', true, VistoKind.KeyResult, true),
  valueKind: FieldGenerator.makeChoiceField('VISTO_KRV_ValueKind', 'ValueKind', true, choices),
  valueDate: FieldGenerator.makeDateField('VISTO_KRV_ValueDate', 'ValueDate', true),
  value: FieldGenerator.makeNumberField('VISTO_KRV_CurrentValue', 'CurrentValue', true),
  createdDate: FieldGenerator.makeCreatedField('VISTO_KRV_Created'),
  modifiedDate: FieldGenerator.makeModifiedField('VISTO_KRV_Modified'),
};

addListDefinition({
  kind: VistoKind.KRV,
  fields: VistoKeyResultValueListFields,
  enableVersioning: true,
  majorVersionLimit: 500,

  defaultViewXml: `<View>
    <Query />
    <ViewFields>
      <FieldRef Name='${VistoKeyResultValueListFields.name.name}' />
      <FieldRef Name='${VistoKeyResultValueListFields.valueKind.name}' />
      <FieldRef Name='${VistoKeyResultValueListFields.valueDate.name}' />
      <FieldRef Name='${VistoKeyResultValueListFields.value.name}' />
    </ViewFields>
    <RowLimit Paged='TRUE'>30</RowLimit>
  </View>`,

  setItemSpecificFields: (updater: ListItemUpdater, spItem, update: Partial<VistoKeyResultValueItem>) => {
    updater.setItemSpField(VistoKeyResultValueListFields, spItem, update, 'krGuid');
    updater.setItemSpField(VistoKeyResultValueListFields, spItem, update, 'valueKind');
    updater.setItemSpField(VistoKeyResultValueListFields, spItem, update, 'valueDate');
    updater.setItemSpField(VistoKeyResultValueListFields, spItem, update, 'value');
  },

  getItemSpecificFields: (updater: ListItemUpdater, item: Partial<VistoKeyResultValueItem>, spItem) => {
    updater.getItemSpField(VistoKeyResultValueListFields, item, spItem, 'krGuid');
    updater.getItemSpField(VistoKeyResultValueListFields, item, spItem, 'valueKind');
    updater.getItemSpField(VistoKeyResultValueListFields, item, spItem, 'valueDate');
    updater.getItemSpField(VistoKeyResultValueListFields, item, spItem, 'value');
  },
});
