import * as React from 'react';
import * as strings from 'VistoWebPartStrings';
import { Dialog, DialogType, PrimaryButton, Stack, Checkbox } from '@fluentui/react';
import { defaultModalProps } from './common';
import { Advisory, TopicKey } from 'components/Advisory';
import { trackClient } from 'shared/clientTelemetry';
import { TextService } from 'services/TextService';

export function GettingStartedDialog(props: {
  topic: TopicKey;
  showCheckbox: boolean;
  skipGettingStarted: boolean;
  onDismiss: (val: boolean) => void;
}) {

  React.useEffect(() => trackClient.page('GettingStartedDialog'), []);

  const [skipGettingStarted, setSkipGettingStarted] = React.useState(props.skipGettingStarted);

  return (
    <Dialog
      minWidth={640}
      hidden={false}
      dialogContentProps={{ title: TextService.format(strings.GettingStartedDialog_Title), type: DialogType.normal }}
      modalProps={defaultModalProps}
      onDismiss={() => props.onDismiss(skipGettingStarted)} >
      <Advisory style={{ maxHeight: '75vh', overflow: 'auto' }} topic={props.topic} />
      <Stack horizontal horizontalAlign='end' tokens={{ childrenGap: 'l1', padding: 'm 0 0 0' }} grow>
        {props.showCheckbox && <Checkbox label={TextService.format(strings.GettingStarted_ShowOnStartup)} checked={skipGettingStarted} onChange={(_, val) => setSkipGettingStarted(!!val)} />}
        <PrimaryButton onClick={() => props.onDismiss(skipGettingStarted)} text={TextService.format(strings.ButtonOK)} />
      </Stack>
    </Dialog>
  );
}
