import { AuthService } from 'services/AuthService';
import { IExecutableAction } from 'services/IExecutableAction';
import { UrlService } from 'shared/urlService';
import { StorageCacheService } from 'services/StorageCacheService';
import { getListDefinition, IFieldDefinition, IFieldValueUrl, IVistoPlan, VistoKind } from 'sp';
import { MigrationHelperService } from 'services/MigrationHelperService';

export const makeMigrateNavigationLinkFields = (foundPlan: IVistoPlan, getListId: (kind: VistoKind) => string): IExecutableAction => ({
  title: `Convert NavigationLink fields to Note`,
  execute: async (p) => {

    const sp = AuthService.getSpClient(foundPlan.siteUrl);

    const listUrl = UrlService.getListRelativeUrl(foundPlan);

    const listDefinition = getListDefinition(VistoKind.Plan);
    const idField: IFieldDefinition = listDefinition.fields['itemId'];
    const navigationLinkField: IFieldDefinition = listDefinition.fields['navigationLink'];

    const items = await sp.web.getList(listUrl).select(idField.name, navigationLinkField.name).items.top(2000)();

    const field = await sp.web.getList(listUrl).fields.getByInternalNameOrTitle(navigationLinkField.name)();
    if (field.TypeAsString !== 'Note') {
      
      await sp.web.getList(listUrl).fields.getByInternalNameOrTitle(navigationLinkField.name).delete();

      const xml = MigrationHelperService.getFieldXml(navigationLinkField, getListId);
      await sp.web.getList(listUrl).fields.createFieldAsXml(xml);

      for (const plan of items) {
        const id = plan[idField.name];
        const navigationLink = plan[navigationLinkField.name];
        const value: IFieldValueUrl = navigationLink ? ({
          url: navigationLink['Url'],
          description: navigationLink['Description']
        }) : null;
        await sp.web.getList(listUrl).items.getById(id).update({ [navigationLinkField.name]: value ? JSON.stringify(value) : null });
      }
    }

    StorageCacheService.resetCache();
    return p;
  }
});
