import React from 'react';
import { Dialog, DialogType, Stack, PrimaryButton, Spinner, DefaultButton } from '@fluentui/react';
import { useErrorInfo, InfoBar } from 'components';
import { applyPlugin } from 'jspdf-autotable'
import { EnvContext } from 'services/EnvContext';
import { NotificationType } from 'services/Notify';
import { StorageService } from 'services/StorageService';
import { TextService } from 'services/TextService';
import { trackClient } from 'shared/clientTelemetry';
import { defaultModalProps } from '../common';
import { ExportLinkPage } from './ExportLinkPage';
import { ExportPlanFileNamePage } from './ExportPlanFileNamePage';

import strings from 'VistoWebPartStrings';
import { exportGantt as exportGanttToPdf } from 'devextreme/pdf_exporter';
import { jsPDF } from 'jspdf';
import { ExportGanttToPdfSettingsPage } from './ExportGanttToPdfSettingsPage';
import { IVistoPlan } from 'sp';
import { IGanttPdfOptions } from './IGanttPdfOptions';

applyPlugin(jsPDF)

export const ExportGanttToPdfDialog = (props: {
  plan: IVistoPlan;
  instance: any;
  onDismiss: () => void;
}) => {

  React.useEffect(() => trackClient.page('ExportGanttToPdfDialog'), []);

  const { siteUrl, defaultFolderRelativeUrl } = React.useContext(EnvContext);

  const [selectedFolder, setSelectedFolder] = React.useState(defaultFolderRelativeUrl);
  const [planUrl, setPlanUrl] = React.useState('');

  const defaultFileName = TextService.makeExportFileName(props.plan.name, '.pdf');
  const [fileName, setFileName] = React.useState(defaultFileName);
  const [errorInfo, setErrorInfo] = useErrorInfo();
  const [uploading, setUploading] = React.useState(false);

  const [page, setPage] = React.useState<'name' | 'filter' | 'done'>('name');

  const [options, setOptions] = React.useState<IGanttPdfOptions>({
    format: 'auto',
    landscape: true,
    exportMode: 'all',
    dateRange: 'visible',
  })

  const upload = async () => {
    setPlanUrl('');

    try {
      const doc = await exportGanttToPdf(
        {
          component: props.instance,
          createDocumentMethod: (args) => new jsPDF(args),
          format: options.format,
          landscape: options.landscape,
          exportMode: options.exportMode,
          dateRange: options.dateRange === 'custom' ? { startDate: options.startDate, endDate: options.endDate } : options.dateRange
        },
      );

      const blob = doc.output('blob');

      var file = new File([blob], fileName, { type: 'application/json' });
      setUploading(true);
      const addedFile = await StorageService.get(siteUrl).putFile(siteUrl, selectedFolder, fileName, file);
      setPlanUrl(addedFile.fileAbsoluteUrl);
      setPage('done');
      setUploading(false);
    } catch (error) {
      setUploading(false);
      setErrorInfo({ type: NotificationType.error, message: TextService.format(strings.GanttExport_ErrorMessage), error });
    }
  };

  return (
    <Dialog
      minWidth={640}
      hidden={false}
      dialogContentProps={{ title: TextService.format(strings.GanttExport_DialogTitle), type: DialogType.normal }}
      modalProps={defaultModalProps}
      onDismiss={() => props.onDismiss()} >

      <Stack style={{ minHeight: 300 }} tokens={{ childrenGap: 'm' }}>
        <InfoBar {...errorInfo} />

        {page === 'name' && <ExportPlanFileNamePage suffix='.pdf' fileName={fileName} setFileName={setFileName} selectedFolder={selectedFolder} setSelectedFolder={setSelectedFolder} />}
        {page === 'filter' && <ExportGanttToPdfSettingsPage plan={props.plan} options={options} setOptions={setOptions} />}
        {page === 'done' && <ExportLinkPage link={planUrl} fileName={fileName} />}
      </Stack>

      <Stack horizontal horizontalAlign='end' tokens={{ padding: 'm 0 0 0', childrenGap: 'm' }} grow>
        {page === 'name' && <PrimaryButton onClick={() => setPage('filter')}>{TextService.format(strings.PlanWizard_Next)}</PrimaryButton>}

        {page === 'filter' && <PrimaryButton onClick={() => setPage('name')}>{TextService.format(strings.PlanWizard_Back)}</PrimaryButton>}
        {page === 'filter' && <PrimaryButton disabled={uploading} onClick={() => upload()}>{uploading ? <Spinner style={{ marginRight: 8 }} /> : null}{uploading ? TextService.format(strings.Button_Exporting) : TextService.format(strings.Button_Export)}</PrimaryButton>}

        <DefaultButton onClick={() => props.onDismiss()}>{page === 'done' ? TextService.format(strings.ButtonClose) : TextService.format(strings.ButtonCancel)}</DefaultButton>

      </Stack>

    </Dialog>
  );
}
