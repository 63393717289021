import { CommandName } from 'shared/CommandName';
import { IVistoListItem, IVistoPlan, VistoActionItem, VistoFocusItem, VistoKind } from 'sp';
import { ChangesService, IChanges } from './ChangesService';
import { Commands } from './Commands';
import { IItemChanges } from './Interfaces';
import { INotify } from './Notify';
import { PlanDataService } from './PlanDataService';
import { TextService } from './TextService';
import strings from 'VistoWebPartStrings';
import { VisualFilterService } from 'frames/VisualFilter/VisualFilterService';
import { StorageService } from './StorageService';
import { ICommand } from './ICommand';
import { Editor } from 'frames/TopFrame/drawing/common';

export class CommandsFocus {

  public static makeDeleteFocusCommand(plan: IVistoPlan, items: IVistoListItem[], notify: INotify): ICommand {
    return {
      prepare: async () => {

        const filter = VisualFilterService.getSavedFilter(plan);

        const updates: IItemChanges<VistoActionItem>[] = [];
        for (const item of items) {
          const actions = PlanDataService.getFocusActions(plan, item.guid);
          for (const action of actions) {
            updates.push({ item: action, changes: ChangesService.getChanges(action, { focusGuid: null }) });
          }

          if (filter.focusGuids.indexOf(item.guid) >= 0) {
            filter.focusGuids = filter.focusGuids.filter(f => f !== item.guid);
          }
        }

        VisualFilterService.saveFilter(plan, filter);

        return {
          do: async (p) => {
            p = await StorageService.get(p.siteUrl).deleteItems(p, items, notify, { validate: true });
            p = await StorageService.get(p.siteUrl).updateItems(p, updates, notify, { enableSimpleUpdate: true });
            return p;
          },
          undo: async (p) => {
            p = await StorageService.get(p.siteUrl).createItems(p, items, notify, { validate: true });
            p = await StorageService.get(p.siteUrl).updateItems(p, updates, notify, { enableSimpleUpdate: true, reverse: true });
            return p;
          }
        };
      },
      message: TextService.format(strings.Command_DeleteItems, { number: items.length }),
      name: CommandName.DeleteItem
    };
  }

  public static makeFocusUpdates(plan: IVistoPlan, focus: VistoFocusItem, focusChanges: IChanges<VistoFocusItem>) {
    const updates: IItemChanges<IVistoListItem>[] = [{ item: focus, changes: focusChanges }];
    if (focusChanges.properties.indexOf('startDate') >= 0 || focusChanges.properties.indexOf('endDate') >= 0) {
      const actions = PlanDataService.getFocusActions(plan, focus.guid);
      for (const action of actions) {
        if (action.useFocusDates) {
          const actionChanges = ChangesService.getChanges(action, {
            startDate: focusChanges.newValues?.startDate ?? focus?.startDate ?? null,
            endDate: focusChanges.newValues?.endDate ?? focus?.endDate ?? null
          });
          if (actionChanges.detected) {
            updates.push({ item: action, changes: actionChanges });
          }
        }
      }
    }
    return updates;
  }

  public static makeSaveFocusCommand(plan: IVistoPlan, oldItem: VistoFocusItem, newItem: VistoFocusItem, notify: INotify) {
    
    if (newItem.itemId) {
      const focusChanges = ChangesService.getChanges(oldItem, newItem);
      const updates = this.makeFocusUpdates(plan, newItem, focusChanges);
  
      return Commands.makeUpdateCommand(updates, notify);
    } else {
      return Commands.makeCreateCommand([newItem], notify);
    }
  }

  public static makeSetActiveFocusCommand(plan: IVistoPlan, focus: VistoFocusItem, notify: INotify) {
    return {
      prepare: async () => {
        const items = PlanDataService.getItems<VistoFocusItem>(plan.items, VistoKind.Focus)
          .filter(f => f.active);

        const changes: IItemChanges<VistoFocusItem>[] = items.map(f => ({ item: f, changes: ChangesService.getChanges({ active: f.active }, { active: false }) }));
        changes.push({ item: focus, changes: ChangesService.getChanges({ active: focus.active }, { active: true }) });

        const filter = VisualFilterService.getSavedFilter(plan);
        filter.focusGuids = [focus.guid];
        VisualFilterService.saveFilter(plan, filter);
        
        return {
          do: async (plan: IVistoPlan, editor: Editor) => {
            plan = await StorageService.get(plan.siteUrl).updateItems(plan, changes, notify);
            setTimeout(() => notify.update(), 0);
            return plan;
          },
          undo: async (plan: IVistoPlan, editor: Editor) => {
            plan = await StorageService.get(plan.siteUrl).updateItems(plan, changes, notify, { reverse: true });
            setTimeout(() => notify.update(), 0);
            return plan;
          }
        };
      },
      message: TextService.format(strings.Command_SetActiveFocus),
      name: CommandName.SetActiveFocus
    };
  }

}
