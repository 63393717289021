import { AuthService } from 'services/AuthService';
import { IExecutableAction } from 'services/IExecutableAction';
import { UrlService } from 'shared/urlService';
import { StorageCacheService } from 'services/StorageCacheService';
import { IVistoPlan } from 'sp';

export const makeMigrateNoteFields = (foundPlan: IVistoPlan): IExecutableAction => ({
  title: `Convert plan settings field to Note`,
  execute: async (p) => {
    const sp = AuthService.getSpClient(foundPlan.siteUrl);
    
    const planListUrl = UrlService.getListRelativeUrl(foundPlan);
    const spList = sp.web.getList(planListUrl);

    await spList.fields.getByInternalNameOrTitle('VISTO_Settings').update({ FieldTypeKind: 3 }, 'SP.Field');
    StorageCacheService.resetCache();
    return p;
  }
});
