import { Stack, Icon, Text, useTheme, IFontStyles } from '@fluentui/react';
import React from 'react';

export const OkrCardName = (props: { name: string; variant: keyof IFontStyles; }) => {
  const theme = useTheme();

  const style = {
    maxWidth: 200,
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  };

  return (
    <Stack horizontal verticalAlign='start' tokens={{ childrenGap: 's1' }} grow>
      <Text variant={props.variant} style={style}>{props.name}</Text>
      <Icon className='menu' style={{ fontSize: '1rem', color: theme.palette.neutralTertiary }} iconName='MoreVertical' />
    </Stack>
  );
};
