import React from 'react';
import { Stack } from '@fluentui/react';
import { IVistoPlan, VistoActionItem, VistoAssocItem, VistoDpItem, VistoKeyResultItem, VistoKind } from 'sp';
import { SidebarKeyResult } from './SidebarKeyResult';
import { PlanDataService } from 'services/PlanDataService';
import { SidebarActionListGroup } from './SidebarActionListGroup';

export function SidebarKeyResultList(props: {
  plan: IVistoPlan;
  dp: VistoDpItem;
  includeActions: boolean;
  items: VistoKeyResultItem[];
  roots: VistoKeyResultItem[];
}) {

  if (!props.roots.length)
    return null;

  return (
    <Stack verticalAlign='center' tokens={{ childrenGap: 's2' }}>
      {props.roots.map(kr => {
        const children = props.items.filter(x => x.parentKrGuid === kr.guid);

        const actions: VistoActionItem[] = [];
        if (props.includeActions) {
          const actionGuids = PlanDataService.getItemsHaving<VistoAssocItem>(props.plan.items, x => x.kind === VistoKind.Assoc && x.krGuid === kr.guid)
            .filter(x => x.actionGuid)
            .reduce((r, x) => ({ ...r, [x.actionGuid]: x }), {});

          actions.push(...Object.keys(actionGuids)
            .map(x => PlanDataService.getItemByGuid<VistoActionItem>(props.plan.items, x))
            .filter(x => x && (!props.dp || props.dp.guid === x.dpGuid))
            .sort((a, b) => a.sortOrder - b.sortOrder));
        }

        return (
          <SidebarKeyResult key={kr.guid} plan={props.plan} kr={kr} dp={props.dp} expandable={children.length > 0 || actions.length > 0}>
            {children.length > 0 && <SidebarKeyResultList includeActions={props.includeActions} plan={props.plan} dp={props.dp} items={props.items} roots={children} />}
            {actions.length > 0 && <SidebarActionListGroup plan={props.plan} actions={actions} expandKey={kr.guid} />}
          </SidebarKeyResult>
        );
      })}
    </Stack>
  );
}
