import { AuthService } from 'services/AuthService';
import { IExecutableAction } from 'services/IExecutableAction';
import { UrlService } from 'shared/urlService';
import { StorageCacheService } from 'services/StorageCacheService';
import { TextService } from 'services/TextService';
import { IVistoPlan, ListItemUpdater, VistoKind } from 'sp';

export const makeMigrateFocuses = (foundPlan: IVistoPlan): IExecutableAction => ({
  title: `Migrate Focuses`,
  execute: async (p) => {

    const sp = AuthService.getSpClient(foundPlan.siteUrl);

    const timeZone = await sp.web.regionalSettings.timeZone();
    const timeZoneBias = timeZone.Information.Bias + timeZone.Information.DaylightBias;

    const updater = new ListItemUpdater(timeZoneBias);

    // migrate focus dates
    const actionListUrl = UrlService.getListRelativeUrl(foundPlan, VistoKind.Action);
    const focusListUrl = UrlService.getListRelativeUrl(foundPlan, VistoKind.Focus);

    const dates: { [key: string]: { startDate, endDate } } = {};

    const actionSpItems = await sp.web.getList(actionListUrl).items.top(2000)();
    for (const actionSpItem of actionSpItems) {
      const focusId = actionSpItem['FocusId'];
      if (focusId) {
        const startDate = updater.parseDate(actionSpItem['ActionStartDate']);
        const endDate = updater.parseDate(actionSpItem['ActionEndDate']);
        if (dates[focusId]) {
          if (startDate && dates[focusId].startDate && TextService.compareDateTime(startDate, dates[focusId].startDate) > 0) {
            dates[focusId].startDate = startDate;
          }
          if (endDate && dates[focusId].endDate && TextService.compareDateTime(dates[focusId].endDate, endDate) > 0) {
            dates[focusId].endDate = endDate;
          }
        } else {
          dates[focusId] = { startDate, endDate };
        }
      }
    }

    const [batch, execute] = sp.batched();

    let modified = false;
    const focusSpItems = await sp.web.getList(focusListUrl).items.top(2000)();
    for (const focusSpItem of focusSpItems) {
      const focusId = focusSpItem['ID'];
      const focusStartDate = updater.parseDate(focusSpItem['StartDate']);
      const focusEndDate = updater.parseDate(focusSpItem['EndDate']);
      if (!focusStartDate && !focusEndDate && dates[focusId]) {
        const changes: { StartDate?, EndDate?} = {};
        if (dates[focusId].startDate) {
          changes.StartDate = updater.parseDate(dates[focusId].startDate);
        }
        if (dates[focusId].endDate) {
          changes.EndDate = updater.unparseDate(dates[focusId].endDate);
        }
        if (changes.StartDate || changes.EndDate) {
          modified = true;
          batch.web.getList(focusListUrl).items.getById(focusId).update(changes);
        }
      }
    }

    if (modified) {
      await execute();
    }

    StorageCacheService.resetCache();

    return p;
  }
});
