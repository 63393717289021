import * as React from 'react';

import { AppContext } from 'services/AppContext';
import { AttachmentService } from 'services/AttachmentService';
import { PlanDataService } from 'services/PlanDataService';
import { CopyLinkDialog, EditLopDialog } from 'dialogs';
import { MenuItems } from 'frames/TopFrame/MenuItems';
import { SidebarLopDp } from './SidebarLopDp';
import { ProgressBlock, SidebarItem, ISidebarItemProps, AttachDocument } from 'frames/TopFrame/sidebars/common';
import { VistoLopItem, IAttachment, VistoKind } from 'sp';
import { DeleteSourceLinkDialog, EditSourceLinkDialog } from 'dialogs/common';
import { HistoryBlock } from './common/HistoryBlock';
import { HistoryDialog } from './common/HistoryDialog';
import { useSidebarShareDialog } from './SidebarShareHook';

export function SidebarLop(props: ISidebarItemProps<VistoLopItem>) {

  const [editLopDialogVisible, setEditLopDialogVisible] = React.useState(false);
  const [isAttachDocumentOpen, setIsAttachDocumentOpen] = React.useState(false);
  const [historyDialogVisible, setHistoryDialogVisible] = React.useState(false);
  const [editLinkDialogVisible, setEditLinkDialogVisible] = React.useState(false);
  const [deleteLinkDialogVisible, setDeleteLinkDialogVisible] = React.useState(false);
  const [copyLinkDialogVisible, setCopyLinkDialogVisible] = React.useState(false);

  const { isPlanEditEnabled, isPlanLive, isPlanLocal, isPopupOpen, setIsPopupOpen, notify, dispatchCommand, propertyBag, setPropertyBag } = React.useContext(AppContext);
  React.useEffect(() => {
    setIsPopupOpen(editLopDialogVisible);
  }, [editLopDialogVisible]);

  const isEditDisabled = !isPlanEditEnabled || isPopupOpen;
  const isHistoryDisabled = !isPlanLive || isPlanLocal;

  const menuItems = [];

  const [ShareLinkDialog, setShareLinkDialogVisible] = useSidebarShareDialog();

  const overflowMenuItems = [
    ...props.overflowMenuItems ?? [],
    // MenuItems.getDefaultOverflowMenuItem(isPopupOpen, isPlanEditEnabled, () => setEditLopDialogVisible(true)),
    MenuItems.getAttachmentsMenuItem(isEditDisabled, () => setIsAttachDocumentOpen(true)),
    MenuItems.getShowHistoryDialogMenu(isHistoryDisabled, () => setHistoryDialogVisible(true)),
    MenuItems.GetTeamsLinkMenuItem(() => setShareLinkDialogVisible(true)),
    MenuItems.getProgressLinkMenuItem([
      MenuItems.getOpenProgressLinkMenuItem(!props.item.sourceItemUrl, props.item),
      MenuItems.getEditProgressLinkMenuItem(isEditDisabled, () => setEditLinkDialogVisible(true)),
      MenuItems.getBreakProgressLinkMenuItem(isEditDisabled || !props.item.sourceItemUrl, () => setDeleteLinkDialogVisible(true)),
      MenuItems.getDividerMenuItem(1),
      MenuItems.getCopyToClipboardMenuItem(() => setCopyLinkDialogVisible(true)),
    ]),
  ];

  const farItems = [
    MenuItems.getGroupByMenu(propertyBag, setPropertyBag),
    MenuItems.getShowMenu([
      MenuItems.getShowValidationsMenu(propertyBag, setPropertyBag),
      MenuItems.getShowProgressMenu(propertyBag, setPropertyBag),
      MenuItems.getShowAssigneesMenu(propertyBag, setPropertyBag),
      MenuItems.getShowHistoryMenu(isHistoryDisabled, propertyBag, setPropertyBag),
      MenuItems.getDividerMenuItem(4),
      MenuItems.getShowAdvisoryMenu(propertyBag, setPropertyBag)
    ])
  ];

  const onAttachDocument = (attachment: IAttachment) => {
    setIsAttachDocumentOpen(false);
    if (attachment) {
      dispatchCommand(AttachmentService.makeAddAttachmentCommand(props.item, attachment, AttachmentService.makeItemAttachmentsCommand, notify), { wrap: true });
    }
  };

  const haveHistory = isPlanLive && propertyBag?.showHistory;

  return <SidebarItem
    isOpen={props.isOpen}
    onDismiss={() => props.onDismiss()}
    advisoryTopicKey='LOP'
    menuItems={menuItems}
    overflowMenuItems={overflowMenuItems}
    farItems={farItems}
    plan={props.plan} item={props.item}
    content={
      <React.Fragment>
        {haveHistory && <HistoryBlock />}
        <ProgressBlock hideAssignedTo item={props.item} plan={props.plan} />
        {...PlanDataService.getLopDps(props.plan, props.item.guid)
          .map(dp => <SidebarLopDp key={dp.guid} plan={props.plan} dp={dp} />)}
        {editLopDialogVisible && <EditLopDialog onDismiss={() => setEditLopDialogVisible(false)} plan={props.plan} lop={props.item} />}
        {editLinkDialogVisible && <EditSourceLinkDialog plan={props.plan} item={props.item} onDismiss={() => setEditLinkDialogVisible(false)} />}
        {deleteLinkDialogVisible && <DeleteSourceLinkDialog plan={props.plan} item={props.item} onDismiss={() => setDeleteLinkDialogVisible(false)} />}
        {copyLinkDialogVisible && <CopyLinkDialog plan={props.plan} onDismiss={() => setCopyLinkDialogVisible(false)} item={props.item} />}
        {isAttachDocumentOpen && <AttachDocument onChange={onAttachDocument} isOpen={isAttachDocumentOpen} />}
        {historyDialogVisible && <HistoryDialog item={props.item} plan={props.plan} onDismiss={() => setHistoryDialogVisible(false)} />}
        {ShareLinkDialog}
      </React.Fragment>
    }
  />;
}
