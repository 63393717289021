import * as React from 'react';
import { AppContext } from 'services/AppContext';
import { NotificationType } from 'services/Notify';
import { TextService } from 'services/TextService';
import { ComboBox, Stack, IComboBoxOption, Link } from '@fluentui/react';
import { ProjectDataService } from '../services';
import { IVistoPlan } from 'sp';
import { InfoBar, useErrorInfo } from 'components/InfoBar';
import * as strings from 'VistoWebPartStrings';
import { PlanSettingsService } from 'services/PlanSettingsService';

export function ProjectLinkEditor(props: {
  plan: IVistoPlan;
  sourceItemUrl: string;
  setSourceItemUrl: (sourceItemUrl: string) => void;
}) {

  const [errorInfo, setErrorInfo] = useErrorInfo();

  const [projects, setProjects] = React.useState<IComboBoxOption[]>(null);
  const [projectTasks, setProjectTasks] = React.useState<IComboBoxOption[]>(null);

  const planSettings = PlanSettingsService.getPlanSettings(props.plan);

  const pwaUrl = planSettings?.integrations?.project?.pwaUrl;

  const selectedProjectId = ProjectDataService.getProjectId(props.sourceItemUrl);
  const selectedProjectTaskId = ProjectDataService.getProjectTaskId(props.sourceItemUrl);

  const onProjectIdChanged = (projectId: string) => {
    props.setSourceItemUrl(`${pwaUrl}/_api/ProjectServer/Projects('${projectId}')`);
  };

  const onProjectTaskChanged = (projectTaskId: string) => {
    const url = projectTaskId
      ? `${pwaUrl}/_api/ProjectServer/Projects('${selectedProjectId}')/Tasks('${projectTaskId}')`
      : `${pwaUrl}/_api/ProjectServer/Projects('${selectedProjectId}')`;
    props.setSourceItemUrl(url);
  };

  React.useEffect(() => {
    setErrorInfo(null);
    ProjectDataService.getProjects(pwaUrl).then(results => {
      setProjects(results.map(d => ({ key: d.Id, text: d.Name })));
    }, error => {
      setErrorInfo({ type: NotificationType.error, message: TextService.format(strings.MSProjectConnection_ErrorGettingProjectList), error });
    });
  }, [pwaUrl]);

  React.useEffect(() => {
    setErrorInfo(null);
    if (selectedProjectId) {
      ProjectDataService.getProjectTasks(pwaUrl, selectedProjectId).then(results => {
        setProjectTasks([
          {
            key: '',
            text: TextService.format(strings.MSProjectConnection_LinkToSummary)
          },
          ...results
            .sort((a, b) => a.OutlinePosition < b.OutlinePosition ? -1 : a.OutlinePosition > b.OutlinePosition ? +1 : 0)
            .map(d => ({
              key: d.Id,
              text: Array(('' + d.OutlinePosition).length * 2).fill('\xa0').join('') + d.Name
            }))
        ]);
      }, error => {
        setErrorInfo({ type: NotificationType.error, message: TextService.format(strings.MSProjectConnection_ErrorGettingProjectTasks), error });
      });
    }
  }, [selectedProjectId]);

  return (
    <Stack tokens={{ padding: 's1', childrenGap: 's1' }}>
      <InfoBar {...errorInfo} />
      <ComboBox
        placeholder={TextService.format(strings.ProjectLinkEditor_SelectProjectPlaceholder)}
        disabled={!projects}
        label={TextService.format(strings.MSProjectConnection_LabelProject)}
        autoComplete='on'
        options={projects}
        selectedKey={selectedProjectId}
        onChange={(_, val) => onProjectIdChanged(val.key as string)}
      />
      <ComboBox
        placeholder={TextService.format(strings.ProjectLinkEditor_SelectProjectTaskPlaceholder)}
        disabled={!projectTasks}
        label={TextService.format(strings.MSProjectConnection_LabelTask)}
        autoComplete='on'
        required={false}
        options={projectTasks}
        selectedKey={selectedProjectTaskId}
        onChange={(_, val) => onProjectTaskChanged(val.key as string)}
      />
    </Stack>
  );

}
