import { IPlanItems, IVistoListItem, VistoAssocItem, VistoKeyResultValueItem, KeyResultValueKind, VistoActionItem, VistoDpItem, VistoKeyResultItem, VistoDpListFields, getListDefinition, IFieldDefinition } from 'sp';
import { VistoKind } from 'shared/VistoKind';
import * as Mustache from 'mustache';
import * as appStrings from 'VistoWebPartStrings';
import * as controlStrings from 'ControlStrings';
import { trackClient } from 'shared/clientTelemetry';
import { DatePickerStrings } from './DatePickerStrings';
import { DayOfWeek } from '@fluentui/react';
import { IChanges } from './ChangesService';

interface IVistoKindInfo {
  nameStringId: keyof IVistoWebPartStrings;
  listTitleStringId: keyof IVistoWebPartStrings;
  sortOrder: number;
}

export const VistoKindInfo: { [kind in VistoKind]: IVistoKindInfo } = {
  [VistoKind.Plan]: <IVistoKindInfo>{
    nameStringId: '__Plan',
    listTitleStringId: 'VistoKind_Plan_list',
    sortOrder: 0
  },
  [VistoKind.SO]: <IVistoKindInfo>{
    nameStringId: '__StrategicObjective',
    listTitleStringId: 'VistoKind_SO_list',
    sortOrder: 2
  },
  [VistoKind.Focus]: <IVistoKindInfo>{
    nameStringId: '__Focus',
    listTitleStringId: 'VistoKind_Focus_list',
    sortOrder: 4
  },
  [VistoKind.LOP]: <IVistoKindInfo>{
    nameStringId: '__Capability',
    listTitleStringId: 'VistoKind_LOP_list',
    sortOrder: 5
  },
  [VistoKind.DP]: <IVistoKindInfo>{
    nameStringId: '__Ambition',
    listTitleStringId: 'VistoKind_DP_list',
    sortOrder: 6
  },
  [VistoKind.KeyResult]: <IVistoKindInfo>{
    nameStringId: '__KeyResult',
    listTitleStringId: 'VistoKind_KR_list',
    sortOrder: 7
  },
  [VistoKind.Effect]: <IVistoKindInfo>{
    nameStringId: '__Effect',
    listTitleStringId: 'VistoKind_Effect_list',
    sortOrder: 8
  },
  [VistoKind.Action]: <IVistoKindInfo>{
    nameStringId: '__Action',
    listTitleStringId: 'VistoKind_Action_list',
    sortOrder: 9
  },
  [VistoKind.Assoc]: <IVistoKindInfo>{
    nameStringId: '__Validation',
    listTitleStringId: 'VistoKind_Assoc_list',
    sortOrder: 10
  },
  [VistoKind.KRV]: <IVistoKindInfo>{
    nameStringId: '__KeyResultValue',
    listTitleStringId: 'VistoKind_KRV_list',
    sortOrder: 12
  },
};

export class TextService {

  public static getListTitle(kind: VistoKind, planName: string) {
    return TextService.format(appStrings[VistoKindInfo[kind].listTitleStringId], { planName });
  }

  public static getVistoKindName(kind: VistoKind) {
    const kindInfo = VistoKindInfo[kind];
    if (kindInfo)
      return appStrings[kindInfo.nameStringId];
  }

  public static removeLineBreaks(val: string) {
    return val && val.split(/\r\n|\r|\n/).join(' ');
  }

  public static formatMultiline(val: string) {
    return val && val
      .split('\r').join('')
      .split('\n').join('<br/>');
  }

  public static unformatMultiline(val: string) {
    return val && val
      .split('<br>').join('\n');
  }

  // format date as 1999-01-01
  public static formatDate(d: Date, defaultValue = '') {
    if (d && typeof d === 'object' && typeof d.toLocaleDateString === 'function') {
      return d.toLocaleDateString(this.currentLanguage);
    } else if (d) {
      trackClient.warn(`Formatting non-date object: ${d}`);
      return new Date(d).toLocaleDateString(this.currentLanguage);
    }
    return defaultValue;
  }

  public static formatDateTime(d: Date, defaultValue = '') {
    if (d && typeof d === 'object' && typeof d.toLocaleString === 'function') {
      return d.toLocaleString(this.currentLanguage);
    } else if (d) {
      trackClient.warn(`Formatting non-date object: ${d}`);
      return new Date(d).toLocaleString(this.currentLanguage);
    }
    return defaultValue;
  }

  public static formatPercents(percent: number, prefix?: boolean, suffix?: boolean) {
    const valid = TextService.isValidNumber(percent);
    return `${(prefix && valid && percent > 0) ? '+' : ''}${valid ? percent : ''}${valid && suffix ? '%' : ''}`;
  }

  private static formatAssocTitle(assoc: VistoAssocItem, planItems: IPlanItems) {
    const from = planItems?.[assoc.actionGuid];
    const to = planItems?.[assoc.krGuid] || planItems?.[assoc.soGuid];
    return TextService.format(appStrings.FormatTitle_Assoc, {
      from: TextService.formatTitle(from, planItems),
      to: TextService.formatTitle(to, planItems)
    });
  }

  private static formatKeyResultTitle(item: VistoKeyResultItem) {
    return TextService.format(appStrings.FormatTitle_Default, {
      kindName: TextService.format(item.parentKrGuid ? appStrings.__KPI : appStrings.__KeyResult),
      itemName: this.trimLength(item.name)
    });
  }

  private static formatKeyResultValueTitle(krv: VistoKeyResultValueItem, planItems: IPlanItems) {
    const kr = planItems?.[krv.krGuid] as VistoKeyResultItem;
    return TextService.format(appStrings.FormatTitle_KRV, {
      kindName: TextService.formatTitle(kr, planItems),
      valueDate: this.formatDate(krv.valueDate),
      valueKindName: KeyResultValueKind[krv.valueKind],
      value: krv.value
    });
  }

  public static formatParentTitle(item: IVistoListItem, planItems: IPlanItems) {
    if (item) {
      switch (item.kind) {
        case VistoKind.Action: {
          const action = item as VistoActionItem;
          const dp = planItems?.[action.dpGuid];
          return TextService.formatTitle(dp, planItems);
        }
        case VistoKind.DP: {
          const dp = item as VistoDpItem;
          const lop = planItems?.[dp.lopGuid];
          return TextService.formatTitle(lop, planItems);
        }
        default:
          return TextService.getVistoKindName(VistoKind.Plan);
      }
    }
  }

  public static trimLength(val: string, maxLength = 50) {
    return val && val.length > maxLength ? val.substring(0, maxLength) + '...' : val;
  }

  public static formatActionTitle(action: VistoActionItem, planItems: IPlanItems) {
    const result = TextService.format(appStrings.FormatTitle_Default, {
      kindName: TextService.getVistoKindName(action.kind),
      itemName: this.trimLength(action.name)
    });
    const dp = planItems?.[action.dpGuid] as VistoDpItem;
    if (dp) {
      return `${result} (${TextService.formatTitle(dp, planItems)})`;
    } else {
      return result;
    }
  }

  public static formatTitle(item: IVistoListItem, planItems: IPlanItems) {
    if (item) {
      switch (item.kind) {
        case VistoKind.Action:
          return this.formatActionTitle(item as VistoActionItem, planItems);
        case VistoKind.Assoc:
          return this.formatAssocTitle(item, planItems);
        case VistoKind.KeyResult:
          return this.formatKeyResultTitle(item as VistoKeyResultItem);
        case VistoKind.KRV:
          return this.formatKeyResultValueTitle(item as VistoKeyResultValueItem, planItems);
        default:
          return TextService.format(appStrings.FormatTitle_Default, {
            kindName: TextService.getVistoKindName(item.kind),
            itemName: this.trimLength(item.name)
          });
      }
    }
  }

  public static naturalComparer = Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });

  public static getPlainText(html: string) {
    if (html) {
      const span = document.createElement('span');
      span.innerHTML = html;
      return span.textContent || '';
    } else {
      return '';
    }
  }

  public static getTooltipHtml(tooltip: string) {
    if (this.getPlainText(tooltip))
      return `<div class='ql-editor' style='padding: 0; min-height:0'>${tooltip}</div>`;
  }

  public static makeFileName(title: string): string {
    return title
      .replace(/["#%*:<>?/|\\]/g, '-')
      .replace('{', '%7B')
      .replace('}', '%7D')
      .replace('`', '%60')
      .replace(/[\t\r\n\v]/g, '_');
  }

  public static escapeJson(unsafe: string) {
    if (unsafe) {
      const safe = JSON.stringify(unsafe);
      return safe.substr(1, safe.length - 2);
    } else {
      return unsafe;
    }
  }

  public static unescapeXml(safe: string) {
    return safe?.replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&apos;/g, '\'').replace(/&quot;/g, '"').replace(/&amp;/g, '&');
  }

  public static escapeXml(unsafe: string) {
    return unsafe?.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/'/g, '&apos;').replace(/"/g, '&quot;');
  }

  public static format(format: string, params?: object) {

    if (format?.indexOf('{{') >= 0) {
      // validate params
      for (const param in params) {
        if (format.indexOf(`{{${param}}}`) < 0)
          trackClient.warn(`Message parameters mismatch: ${format}, ${JSON.stringify(params)}`);
      }

      try {
        return Mustache.render(format, { ...params, strings: appStrings });
      } catch (err) {
        trackClient.error(`Error formatting message: ${format}, ${JSON.stringify(params)}`, err);
        return format;
      }
    } else {
      return format;
    }
  }

  public static ensureEnum(val, options, defaultOption) {
    return typeof (val) === 'string' && TextService.isValidNumber(options[val]) ? val : options[defaultOption];
  }

  public static makePlanFileName(planId: string) {
    return `visto-plan-${planId}`;
  }

  public static makeMatrixFileName(planId: string) {
    return `visto-matrix-${planId}`;
  }

  public static makeExportFileName(planName: string, ext: string, d = new Date()) {
    const zpad = (n: number) => ('00' + n).slice(-2);
    const timestamp = `${d.getFullYear()}_${zpad(d.getMonth() + 1)}_${zpad(d.getDate())} ${zpad(d.getHours())}_${zpad(d.getMinutes())}_${zpad(d.getSeconds())}`;
    const planFileName = TextService.makeFileName(planName);
    return `${planFileName} ${timestamp}${ext}`;
  }

  public static parseJsonDate(jsonDate: string) {
    return jsonDate ? new Date(jsonDate.substring(0, 10)) : null;
  }

  public static getDate(date: Date) {
    return date && new Date(date.toDateString());
  }

  public static formatList(list: any[]) {
    // TODO: localize list separator?
    return list?.length ? list.join(', ') : '';
  }

  private static supportedLanguages = ['en-us', 'sv-se'];
  private static currentLanguage = 'en-us';

  private static getSupportedLanguage(language) {
    return this.supportedLanguages.indexOf(language) >= 0 ? language : 'en-us';
  }

  private static localizedStrings: { [key: string]: { app: any, controls: any, firstDayOfWeek: DayOfWeek } } = {
    'en-us': {
      app: { ...require('loc/en-us.js') },
      controls: { ...require('@pnp/spfx-controls-react/lib/loc/en-us.js') },
      firstDayOfWeek: DayOfWeek.Sunday
    },
    'sv-se': {
      app: { ...require('loc/sv-se.js') },
      controls: { ...require('@pnp/spfx-controls-react/lib/loc/sv-se.js') },
      firstDayOfWeek: DayOfWeek.Monday
    }
  };

  public static get shortUiLanguage() {
    return this.currentLanguage.split('-')[0];
  }

  public static get uiLanguage() {
    return this.currentLanguage;
  }

  public static datePickerStrings = new DatePickerStrings();
  public static firstDayOfWeek = DayOfWeek.Sunday;

  public static getDefaultUiStrings() {
    return this.localizedStrings[this.currentLanguage].app;
  }

  public static clearCustomStrings(customStrings) {
    return Object.keys(customStrings).reduce((r, v) => customStrings[v] ? ({ ...r, [v]: customStrings[v] }) : r, {});
  }

  public static customStrings: any;
  public static setUiLanguage(language: string, customStrings?: any): string {
    this.currentLanguage = this.getSupportedLanguage(language);
    const currentSettings = this.localizedStrings[this.currentLanguage];
    Object.assign(appStrings, currentSettings.app);
    Object.assign(controlStrings, currentSettings.controls);
    if (customStrings?.[this.currentLanguage]) {
      this.customStrings = customStrings?.[this.currentLanguage];
      const clearedCustomStrings = this.clearCustomStrings(this.customStrings);
      Object.assign(appStrings, clearedCustomStrings);
    }
    this.firstDayOfWeek = currentSettings.firstDayOfWeek;
    this.datePickerStrings = new DatePickerStrings();
    return this.currentLanguage;
  }

  public static enumKeys<O extends object, K extends keyof O = keyof O>(obj: O): K[] {
    return Object.keys(obj).filter(k => Number.isNaN(+k)) as K[];
  }

  public static compareNames(a: string, b: string): number {
    if (!a && !b) return 0;
    if (a && !b) return -1;
    if (!a && b) return +1;
    return a.localeCompare(b, TextService.currentLanguage, { numeric: true });
  }

  public static compareStrings(a: string, b: string): number {
    if (a > b) return -1;
    if (a < b) return +1;
    return 0;
  }

  public static compareDateTime(a: Date, b: Date): number {
    try {
      if (!a && !b) return 0;
      if (a && !b) return -1;
      if (!a && b) return +1;
      return b.getTime() - a.getTime();
    } catch (e) {
      trackClient.error(`Unable to compare date-time for ${a} ${b}`, e);
      return 0;
    }
  }

  public static compareKeyResults(a: VistoKeyResultItem, b: VistoKeyResultItem): number {

    if (!a.parentKrGuid && b.parentKrGuid) return +1;
    if (!b.parentKrGuid && a.parentKrGuid) return -1;

    return TextService.compareNames(a.name, b.name);
  }

  public static compareAssoc(a: VistoAssocItem, b: VistoAssocItem, planItems: IPlanItems): number {

    if (!a.actionGuid && b.actionGuid) return -1;
    if (!b.actionGuid && a.actionGuid) return +1;

    return TextService.compareNames(TextService.formatTitle(a, planItems), TextService.formatTitle(b, planItems));
  }

  public static addDays(d: Date, days: number) {
    const result = new Date(d);
    result.setDate(d.getDate() + days);
    return result;
  }

  public static formatAssignedToTitle(val) {
    return val?.length ? val.map(x => x.title).join(', ') : appStrings.TextValue_None;
  }

  public static makeValue(key: string, val: any) {
    switch (key) {
      case 'assignedTo':
        return this.formatAssignedToTitle(val);
      case 'startDate':
      case 'endDate':
        return this.formatDate(val, appStrings.TextValue_None);
      default:
        return (val === null || typeof val === 'undefined') ? appStrings.TextValue_None : val;
    }
  }

  public static formatChanges<T>(kind: VistoKind, changes: IChanges<T>) {
    return changes.properties.map(name => {
      const field: IFieldDefinition = getListDefinition<T>(kind).fields[name];
      const title = TextService.format(appStrings[field?.key] ?? name);
      return `<div style='margin-top: 4px; overflow: auto; max-height: 100px'>
        <span style='font-weight: 500; margin-right: 4px'>${title}</span>
        <span style='margin-right: 4px'>${this.makeValue(name, changes.oldValues[name])}</span>
        <span style='font-weight: 500; margin-right: 4px'>${appStrings.RightArrow}</span>
        <span style='margin-right: 4px'>${this.makeValue(name, changes.newValues[name])}</span>
      </div>`;
    }).join('');
  }

  /**
   * Generates Initials from a full name
   */
  public static getFullNameInitials(fullName: string): string {
    if (fullName === null) {
      return fullName;
    }

    const words: string[] = fullName.split(' ');
    if (words.length === 0) {
      return '';
    } else if (words.length === 1) {
      return words[0].charAt(0);
    } else {
      return (words[0].charAt(0) + words[1].charAt(0));
    }
  }

  public static linesToHtml(description: string, tag: string) {
    return description ? description.split('\n').map(x => x ? x : '<br>').map(x => `<${tag}>${x}</${tag}>`).join('') : null;
  }

  private static htmlToLinesUsingTag(html: string, tag: string) {
    let start = 0;
    const lines = [];
    while (start < html.length) {
      const p = html.indexOf(`<${tag}>`, start);
      if (p < 0) {
        return html;
      }

      const pp = html.indexOf(`</${tag}>`, p);
      if (pp < 0) {
        return html;
      }

      const line = html.substr(p + `<${tag}>`.length, pp - p - `<${tag}>`.length).trim();
      if (line == '<br>') {
        lines.push('');
      } else if (line.match(/<[^>]*>/)) {
        return html;
      } else {
        lines.push(line);
      }
      start = pp + `</${tag}>`.length;
    }
    return lines.join('\n');
  }

  public static htmlToLines(html: string, tags: string[]) {
    if (!html) {
      return html;
    }
    for (const tag of tags) {
      const result = this.htmlToLinesUsingTag(html, tag);
      if (result.length < html.length) {
        return result;
      }
    }
    return html;
  }

  public static hasCustomStringPrefix(s: string) {
    return s.startsWith('__');
  }

  public static isValidNumber(n: number) {
    return typeof n === 'number' && !isNaN(n);
  }

  public static foramtDateRange(format: string, startDate: Date, endDate: Date) {
    return this.format(format, {
      viewStartDate: TextService.formatDate(startDate),
      viewEndDate: TextService.formatDate(endDate)
    });
  }

  public static removeSuffix(str: string, suffix: string): string {
    const index = str.lastIndexOf(suffix);
  
    if (index !== -1 && index === str.length - suffix.length) {
      return str.slice(0, -suffix.length);
    }
  
    return str;
  }

  public static getPlanDashboardKey(siteUrl: string, planId: string) {
    return `visplan:${siteUrl}##${planId}`;
  }

  public static splitPath(url: URL) {
    return url?.pathname?.replace(/\/+/g, '/').split('/');
  }

  public static getInitials = (name: string) => {
    let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');
    let initials = [...name.matchAll(rgx)] || [];
    return ((initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')).toUpperCase();
  }

  public static getUserDisplayName(title: string) {
    if (!title || /[0-9a-f]{8}-?[0-9a-f]{4}-?[0-5][0-9a-f]{3}-?[089ab][0-9a-f]{3}-?[0-9a-f]{12}/i.test(title)) {
      return TextService.format(appStrings.Error_InvalidUserName);
    } else {
      return title.replace(/[\/]/g, '_');
    }
  }
}
