import { ComboBox, IComboBoxOption, Image, Stack } from '@fluentui/react'
import React from 'react'
import { DevOpsService, IDevOpsItemType } from '../services/DevOpsService'
import { DevOpsIcon } from './DevOpsIcon';

const DevOpsItemTypeComboboxIcon = (props: {
  devopsUrl: string;
  itemType: IDevOpsItemType;
}) => {
  const [iconUrl, setIconUrl] = React.useState<string>(null);
  React.useEffect(() => {
    DevOpsService.getWorkItemIconUrl(props.devopsUrl, props.itemType.icon, props.itemType.color).then(url => setIconUrl(url));
  }, [props.devopsUrl, props.itemType.icon, props.itemType.color]);
  return iconUrl ? <DevOpsIcon size={16} src={iconUrl} /> : null;
}

export const DevOpsItemTypeCombobox = (props: {
  devopsUrl: string;
  label: string;
  itemTypes: IDevOpsItemType[];
  itemTypeNames: string[];
  setItemTypeNames: (itemTypeNames: string[]) => void;
}) => {
  const onItemTypeChange = (option: IComboBoxOption) => {
    props.setItemTypeNames(option?.selected ? [...props.itemTypeNames, option!.key as string] : props.itemTypeNames.filter(k => k !== option!.key));
  };

  return (
    <ComboBox
      multiSelect
      label={props.label}
      options={props.itemTypes.map(itemType => ({
        key: itemType.name,
        text: itemType.name,
        data: itemType
      }))}
      selectedKey={props.itemTypeNames}
      onChange={(_, val) => onItemTypeChange(val)}
      calloutProps={{ calloutMaxHeight: 200 }}
      onRenderItem={(p, defaultRenderer) => {
        return (
          <Stack key={p.id} horizontal verticalAlign='center' tokens={{ padding: '0 s1', childrenGap: 's2' }}>
            <DevOpsItemTypeComboboxIcon devopsUrl={props.devopsUrl} itemType={p.data} />
            {defaultRenderer(p)}
          </Stack>
        );
      }
      }
    />
  )
}
