import { TextService } from 'services/TextService';
import { PlanDataService } from 'services/PlanDataService';
import { PlanStylesService } from 'services/PlanStylesService';
import { PlanSettingsService } from 'services/PlanSettingsService';
import { IFieldValueUser, IVistoListItem, IVistoListItemWithProgress, IVistoPlan, VistoActionItem, VistoAssocItem, VistoDpItem, VistoFocusItem, VistoKind, VistoLopItem, VistoSoItem } from 'sp';
import strings from 'VistoWebPartStrings';
import { ProgressService } from 'services/ProgressService';

const getTitle = (item: IVistoListItem) => {
  return TextService.removeLineBreaks(item.name);
};

export class GanttService {

  public static getTasksGrouppedByLopDp(plan: IVistoPlan, defaultColor: string, showCompleted: boolean) {

    const planSettings = PlanSettingsService.getPlanSettings(plan);

    const getColor = (item: IVistoListItemWithProgress) => {
      return planSettings?.showIndicators
        ? PlanStylesService.getColor(plan, item, 'fillColor')
        : defaultColor;
    };

    const tasks = [];

    const lops = PlanDataService.getItems<VistoLopItem>(plan.items, VistoKind.LOP)
      .filter(a => a.startDate && a.endDate);
    tasks.push(...lops.map(lop => ({
      id: lop.guid,
      parentId: null,
      title: getTitle(lop),
      start: lop.startDate,
      end: lop.endDate,
      progress: lop.percentComplete,
      color: getColor(lop)
    })));

    const dps = PlanDataService.getItems<VistoDpItem>(plan.items, VistoKind.DP)
      .filter(a => a.startDate && a.endDate);

    tasks.push(...dps.map(dp => ({
      id: dp.guid,
      parentId: dp.lopGuid,
      title: getTitle(dp),
      start: dp.startDate,
      end: dp.endDate,
      progress: dp.percentComplete,
      color: getColor(dp)
    })));

    const actions = PlanDataService.getItems<VistoActionItem>(plan.items, VistoKind.Action)
      .filter(a => a.startDate && a.endDate && (showCompleted || !ProgressService.isCompleted(a)))
    tasks.push(...actions.map(a => ({
      id: a.guid,
      parentId: a.dpGuid,
      title: getTitle(a),
      start: a.startDate,
      end: a.endDate,
      progress: a.percentComplete,
      color: getColor(a)
    })));

    return tasks;
  }

  public static getTasksGrouppedByObjective(plan: IVistoPlan, defaultColor: string, showCompleted: boolean) {

    const planSettings = PlanSettingsService.getPlanSettings(plan);

    const getColor = (item: IVistoListItemWithProgress) => {
      return planSettings?.showIndicators
        ? PlanStylesService.getColor(plan, item, 'fillColor')
        : defaultColor;
    };

    const tasks = [];

    const sos = PlanDataService.getItems<VistoSoItem>(plan.items, VistoKind.SO);

    tasks.push(...sos.map(so => ({
      id: so.guid,
      parentId: null,
      title: getTitle(so),
      progress: 0,
      color: defaultColor
    })));

    const actions = sos
      .reduce((r, so) => ([...r, ...PlanDataService.getItems<VistoAssocItem>(plan.items, VistoKind.Assoc)
        .filter(v => v.soGuid === so.guid && v.actionGuid)
        .filter((v, index, self) => index === self.findIndex(x => x.soGuid === v.soGuid && x.actionGuid === v.actionGuid)) // dedupe
        .map(v => PlanDataService.getItemByGuid<VistoActionItem>(plan.items, v.actionGuid))
        .filter(a => a.startDate && a.endDate && (showCompleted || !ProgressService.isCompleted(a)))
        .map(a => ({
          id: `${a.guid}_${so.guid}`,
          parentId: so.guid,
          title: getTitle(a),
          start: a.startDate,
          end: a.endDate,
          progress: a.percentComplete,
          color: getColor(a)
        }))
      ]), []);

    tasks.push(...actions);

    return tasks;
  }

  public static getTasksGrouppedByFocus(plan: IVistoPlan, defaultColor: string, showCompleted: boolean) {

    const planSettings = PlanSettingsService.getPlanSettings(plan);

    const getColor = (item: IVistoListItemWithProgress) => {
      return planSettings?.showIndicators
        ? PlanStylesService.getColor(plan, item, 'fillColor')
        : defaultColor;
    };

    const tasks = [];

    const focuses = PlanDataService.getItems<VistoFocusItem>(plan.items, VistoKind.Focus);

    tasks.push(...focuses.map(focus => ({
      id: focus.guid,
      parentId: null,
      title: getTitle(focus),
      start: focus.startDate,
      end: focus.endDate,
      color: defaultColor
    })));

    tasks.push(...focuses
      .reduce((r, focus) => ([...r, ...PlanDataService.getFocusActions(plan, focus.guid)
        .filter(a => a.startDate && a.endDate && (showCompleted || !ProgressService.isCompleted(a)))
        .map(a => ({
          id: `${a.guid}_${focus.guid}`,
          parentId: focus.guid,
          title: getTitle(a),
          start: a.startDate,
          end: a.endDate,
          progress: a.percentComplete,
          color: getColor(a)
        }))
      ]), []));

    const NOFOCUS = 'NOFOCUS';

    tasks.push({
      id: NOFOCUS,
      parentId: null,
      title: TextService.format(strings.Gantt_NoFocus),
      progress: 0,
      color: defaultColor
    });

    const noFocusActions = PlanDataService
      .getItems<VistoActionItem>(plan.items, VistoKind.Action).filter(a => !a.focusGuid)
      .filter(a => a.startDate && a.endDate);

    tasks.push(...noFocusActions.map(a => ({
        id: `${a.guid}_${NOFOCUS}`,
        parentId: NOFOCUS,
        title: a.name,
        start: a.startDate,
        end: a.endDate,
        progress: a.percentComplete,
        color: getColor(a)
      }))
    );

    return tasks;
  }

  public static getTasksGrouppedByAssignee(plan: IVistoPlan, defaultColor: string, showCompleted: boolean) {

    const planSettings = PlanSettingsService.getPlanSettings(plan);

    const getColor = (item: IVistoListItemWithProgress) => {
      return planSettings?.showIndicators
        ? PlanStylesService.getColor(plan, item, 'fillColor')
        : defaultColor;
    };

    const tasks = [];

    const actions = PlanDataService.getItems<VistoActionItem>(plan.items, VistoKind.Action);

    const isUnassigned = (a: VistoActionItem) => {
      return !a.assignedTo || !a.assignedTo.length;
    };

    const isAssignedTo = (a: VistoActionItem, assignee: IFieldValueUser) => {
      if (isUnassigned(a) || !assignee) {
        return isUnassigned(a) && !assignee;
      } else {
        return a.assignedTo.some(x => x.userName === assignee.userName);
      }
    };

    const assignees: IFieldValueUser[] = Array
      .from(new Map(actions.reduce((r, v) => [...r, ...v.assignedTo], []).map(x => [x.userName, x])).values())
      .sort((a, b) => TextService.naturalComparer.compare(a.title, b.title));

    if (actions.some(a => isUnassigned(a))) {
      assignees.push(null);
    }

    const NOASSIGNEE = 'NOASSIGNEE';

    tasks.push(...assignees.map(a => ({
      id: `${a ? a.id : NOASSIGNEE}`,
      parentId: null,
      title: a ? a.title : TextService.format(strings.Gantt_NoAssignee),
      color: defaultColor
    })));

    tasks.push(...assignees
      .reduce((r, assignee) => ([...r, 
        ...actions
        .filter(a => isAssignedTo(a, assignee))
        .filter(a => a.startDate && a.endDate && (showCompleted || !ProgressService.isCompleted(a)))
        .map(a => ({
          id: `${a.guid}_${assignee ? assignee.id : NOASSIGNEE}`,
          parentId: assignee ? assignee.id : NOASSIGNEE,
          title: getTitle(a),
          start: a.startDate,
          end: a.endDate,
          progress: a.percentComplete,
          color: getColor(a)
        }))
      ]), []));

    return tasks;
  }

  public static getTaskGuid(taskKey: string) {
    return taskKey && taskKey.split('_')[0];
  }

}
