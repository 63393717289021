import { TextField } from '@fluentui/react';
import { InfoBar } from 'components';
import React from 'react';
import { AppContext } from 'services/AppContext';
import { NotificationType } from 'services/Notify';
import { PropertyBagService } from 'services/PropertyBagService';
import { TextService } from 'services/TextService';
import strings from 'VistoWebPartStrings';

export const AssistantInputField = (props: {
  text: string;
  setText: (text: string) => void;
  disabled?: boolean;
  placeholder: string;
  autoFocus?: boolean;
  styles?: any;
}) => {

  const appContext = React.useContext(AppContext);

  const [, updateState] = React.useState(null);
 
  const assistantConsented = appContext
    ? appContext.propertyBag.assistantConsented
    : PropertyBagService.loadSettings().assistantConsented;

  return <React.Fragment>
    {!assistantConsented && <InfoBar
      type={NotificationType.warn}
      message={TextService.format(strings.Assistant_ConsentTitle)}
      error={{
        message: TextService.format(strings.Assistant_ConsentText)
      }}
      actions={[{
        title: strings.Assistant_ConsentButton,
        action: async () => {
          if (appContext) {
            appContext.setPropertyBag({ assistantConsented: true });
          } else {
            PropertyBagService.saveSettings({ ...PropertyBagService.loadSettings(), assistantConsented: true });
            updateState({});
          }
        }
      }
      ]}
    />
    }
    {assistantConsented && <TextField
      disabled={props.disabled}
      value={props.text}
      onChange={(_, val) => props.setText(val || '')}
      placeholder={props.placeholder}
      multiline
      rows={5}
      autoFocus={props.autoFocus}
      styles={props.styles}
    />}
  </React.Fragment>
}
