import { IFluidContainer, IValueChanged, SharedMap } from 'fluid-framework';
import { AzureClient, AzureContainerServices, ITokenResponse } from '@fluidframework/azure-client';
import { FluidTokenProvider } from 'shared/FluidTokenProvider';
import { fluidContainerSchema } from 'shared/FluidContainerSchema';
import { env } from 'shared/clientEnv';
import { ApiService } from './ApiService';
import { trackClient } from 'shared/clientTelemetry';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { api } from 'shared/api';
import { IRealtimeService } from './IRealtimeService';
import { VistoKind } from 'sp';
import { IUserInfo } from 'shared/IUserInfo';

export class FluidClientService implements IRealtimeService {

  public getToken = async (tenantId: string, documentId: string, refresh: boolean): Promise<ITokenResponse> => {

    const key = `auth.visplan.fluid.${tenantId}.${this.userId}.${documentId}`;

    try {
      if (!refresh) {
        const current = localStorage.getItem(key);
        if (current) {
          const payload = jwtDecode<JwtPayload>(current);
          if (new Date() < new Date(payload.exp * 1000))
            trackClient.debug(`[FLUID] returning existing token from cache`);
            return {
              jwt: current,
              fromCache: true
            };
        }
      }
    } catch (err) {
      trackClient.error(`[FLUID] failed to get cached token`, err);
    }

    trackClient.debug(`[FLUID] trying to get new token`);
    const token = await ApiService.getFluidToken(tenantId, documentId);
    localStorage.setItem(key, token);
    return {
      jwt: token
    };
  }

  private containerId: string;
  private userId: string;
  realtimeUpdate: (msg: api.IWSMessage) => void;

  constructor(userId: string, containerId: string, realtimeUpdate: (msg: api.IWSMessage) => void) {
    this.userId = userId;
    this.containerId = containerId;
    this.realtimeUpdate = realtimeUpdate;
  }

  private container: IFluidContainer = null;
  private services: AzureContainerServices = null;

  private get sharedStatus(): SharedMap {
    return this.container?.initialObjects?.status as SharedMap;
  }

  private get sharedSelection(): SharedMap {
    return this.container?.initialObjects?.selection as SharedMap;
  }

  private get sharedItems(): SharedMap {
    return this.container?.initialObjects?.items as SharedMap;
  }

  private get audience() {
    return this.services?.audience;
  }

  public get connectionId(): string {
    const audience = this.audience;
    if (audience) {
      const myself = audience.getMyself();
      if (myself) {
        return myself.userId;
      }
    }
  }

  public setEditor(set: boolean) {
    const sharedStatus = this.sharedStatus;
    const connectionId = this.connectionId;

    if (sharedStatus && connectionId) {
      sharedStatus.set('editorId', set ? connectionId : null);
    }
  }

  public setDrawingRevision(value: number) {
    const sharedStatus = this.sharedStatus;
    if (sharedStatus) {
      sharedStatus.set('drawingRevision', value);
    }
  }

  public setSelection(value: string) {
    const sharedSelection = this.sharedSelection;
    const connectionId = this.connectionId;

    if (sharedSelection && connectionId) {
      if (value) {
        sharedSelection.set(connectionId, value);
      } else {
        sharedSelection.delete(connectionId);
      }
    }
  }

  public notifyItemOperation(operation: api.WSOperation, planId: string, kind: VistoKind, guid: string) {
    const sharedItems = this.sharedItems;
    if (sharedItems) {
      sharedItems.set(guid, { operation, kind })
    }
  }

  private makeStatusUpdate = () => {
    const users = [];

    const audience = this.services.audience;
    const members = audience.getMembers();

    const selection = {};
    const sharedSelection = this.sharedSelection;

    for (const [connectionId, member] of members) {
      const additionalDetails: IUserInfo = member.additionalDetails;
      const user: api.IWSUserInfo = {...additionalDetails, connection_id: connectionId }
      users.push(user);
      const selectedShapeId = sharedSelection.get(connectionId);
      if (selectedShapeId) {
        selection[additionalDetails.oid] = selectedShapeId;
      }
    }

    const sharedStatus = this.sharedStatus;

    const statusUpdate: api.IWSPlanStatus = {
      type: api.WSMessageType.status,
      drawing_revision: sharedStatus.get('drawingRevision') || 0,
      editor: sharedStatus.get('editorId') || '',
      selection,
      users
    };

    trackClient.debug(`[FLUID] new status`, statusUpdate);

    return statusUpdate;
  }

  private makeItemUpdate = (changed: IValueChanged, target: SharedMap) => {
    const update = target.get(changed.key);
    const itemUpdate: api.IWSItemOperationInfo = {
      type: api.WSMessageType.item,
      guid: changed.key,
      kind: update.kind,
      operation: update.operation,
      planId: update.planId
    };

    trackClient.debug(`[FLUID] item update`, itemUpdate);

    return itemUpdate;
  }

  public async connect() {

    trackClient.debug(`[FLUID] connecting to container ${this.containerId}`);

    const client = new AzureClient({
      connection: {
        type: 'remote',
        tenantId: env.getTemplateParamValue('FLUID_TENANT_ID'),
        tokenProvider: new FluidTokenProvider((tenantId, documentId, refresh) => this.getToken(tenantId, documentId, refresh)),
        endpoint: env.getTemplateParamValue('FLUID_ENDPOINT')
      }
    });

    const connection = await client.getContainer(this.containerId, fluidContainerSchema);
    this.container = connection.container;
    this.services = connection.services;

    this.sharedStatus.on('valueChanged', (changed: IValueChanged, local) => {
      this.realtimeUpdate(this.makeStatusUpdate());
      trackClient.debug(`[FLUID] status changed ${changed.key}`)
    });

    this.sharedSelection.on('valueChanged', (changed: IValueChanged) => {
      this.realtimeUpdate(this.makeStatusUpdate());
      trackClient.debug(`[FLUID] selection changed ${changed.key}`);
    });

    this.sharedItems.on('valueChanged', (changed: IValueChanged, local: boolean, target: SharedMap) => {
      if (!local) {
        this.realtimeUpdate(this.makeItemUpdate(changed, target));
      }
    });

    this.audience.on('membersChanged', () => {
      this.realtimeUpdate(this.makeStatusUpdate());
      trackClient.debug(`[FLUID] audience changed`);
    })
  }

  disconnect() {
    this.container.disconnect()
    this.container.dispose();
  }

}
