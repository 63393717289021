import { api } from 'shared/api';
import { stringifyError } from 'shared/parse';
import { TextService } from 'services/TextService';
import * as strings from 'VistoWebPartStrings';
import { trackClient } from 'shared/clientTelemetry';
import { env } from 'shared/clientEnv';
import { Configuration, PublicClientApplication } from '@azure/msal-browser';

export const msalConfig: Configuration = {
  auth: {
    clientId: env.getTemplateParamValue('APPLICATION_ID'),
    authority: 'https://login.microsoftonline.com/common',
    redirectUri: location.origin,
  },
  cache: {
    cacheLocation: 'localStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to true if you are having issues on IE11 or Edge
  }
};

export class WebAuthService {

  public static msal = new PublicClientApplication(msalConfig);

  public static getWebAuthScopes(tokenKind: api.TokenKind, domain: string): string[] {
    
    const appId = env.getTemplateParamValue('APPLICATION_ID');
    const appDomain = document.location.host;

    return api.getAuthScopes(tokenKind, domain, appId, appDomain);
  }

  public static async getMsalToken(tokenKind: api.TokenKind, domain: string) {
    const account = this.msal.getAllAccounts()[0];
    const scopes = this.getWebAuthScopes(tokenKind, domain);
    if (account) {
      const authResult = await this.msal.acquireTokenSilent({ scopes, account });
      return authResult.accessToken;
    }
  }

  // Show the consent pop-up
  public static getConsent(tokenKind: api.TokenKind, domain: string, callback: () => Promise<any>): Promise<any> {

    return new Promise((resolve, reject) => {
      const scopes = WebAuthService.getWebAuthScopes(tokenKind, domain);
      WebAuthService.msal.loginPopup({ scopes, prompt: 'consent' }).then(() => {
        let attempt = 0;
        const test = () => {
          callback().then((result) => {
            resolve(result);
          }).catch((error) => {
            trackClient.warn(`Consent check failed (attempt ${attempt} of 10)`, stringifyError(error));
            if (attempt < 15) {
              attempt = attempt + 1;
              setTimeout(test, 2000);
            } else {
              reject(TextService.format(strings.AuthError_Grant));
            }
          });
        };
        test();
      }, err => {
        reject(err);
      });
    });
  }
}
