import { Dialog, DialogType, DialogFooter, PrimaryButton } from '@fluentui/react'
import React from 'react'
import { TextService } from 'services/TextService'
import { IVistoPlan, VistoKeyResultItem } from 'sp'
import strings from 'VistoWebPartStrings'
import { MatrixCellChart } from './MatrixCellChart'

export const MatrixChartDialog = (props: {
  plan: IVistoPlan;
  items: VistoKeyResultItem[];
  onDismiss: () => void;
}) => {

  return (
    <Dialog
      minWidth={800}
      hidden={false}
      dialogContentProps={{ title: TextService.format(strings.MatrixChartDialog_Title), type: DialogType.normal, }}
      onDismiss={props.onDismiss}
    >
      <MatrixCellChart minHeight={480} items={props.items} plan={props.plan} />

      <DialogFooter>
        <PrimaryButton onClick={() => props.onDismiss()} text={TextService.format(strings.ButtonClose)} />
      </DialogFooter>
    </Dialog>
  )
}

