import { FieldType, IChoiceFieldDefinition, IFieldDefinition, ILookupFieldDefinition, VistoKind } from 'sp';
import { TextService } from './TextService';
import strings from 'VistoWebPartStrings';

export class MigrationHelperService {

  public static getFieldXml(field: IFieldDefinition, getListId?: (kind: VistoKind) => string) {

    const show = field.show ? 'TRUE' : 'FALSE';

    let data: any = {
      DisplayName: field.name,
      Type: FieldType[field.type],
      Group: 'Visto',
      ShowInDisplayForm: show,
      ShowInEditForm: show,
      ShowInNewForm: show
    };

    if (field.usePercents) {
      data.Min = 0;
      data.Max = 1;
      data.Decimals = 0;
      data.Percentage = 'TRUE';
    }

    if (field.type === FieldType.Note) {
      data.NumLines = 6;
      data.UnlimitedLengthInDocumentLibrary = 'TRUE';
      data.RichText = 'FALSE';
    }

    if (field.type === FieldType.DateTime) {
      data.Format = 'DateOnly';
      data.FriendlyDisplayFormat = 'Disabled';
    }

    if (field.type === FieldType.Lookup) {
      const linkField = field as ILookupFieldDefinition;
      data.Mult = 'FALSE';
      data.ShowField = 'Title';
      data.List = getListId(linkField.lookupKind);
    }

    if (field.type === FieldType.User) {
      data.Type = 'UserMulti';
      data.UserSelectionMode = '1';
      data.Mult = 'TRUE';
    }

    if (field.type === FieldType.URL) {
      data.Type = 'Note';
    }

    const doc = document.implementation.createDocument(null, 'root', null);
    const node = doc.createElement('Field');
    for (const key in data) {
      node.setAttribute(key, data[key]);
    }

    if (field.type === FieldType.Choice) {
      const choiceField = field as IChoiceFieldDefinition;
      const nodeChoices = doc.createElement('CHOICES');
      for (const choice of choiceField.choices) {
        const nodeChoice = doc.createElement('CHOICE');
        nodeChoice.textContent = TextService.format(strings[choice.stringId]);
        nodeChoices.appendChild(nodeChoice);
      }
      node.appendChild(nodeChoices);
    }

    return node.outerHTML;
  }

}
