import { VistoKind, addListDefinition, IFieldDefinitions, ListItemUpdater, IVistoListItemWithAssignee } from './common';
import { FieldGenerator } from './common/FieldGenerator';

export interface VistoFocusItem extends IVistoListItemWithAssignee {
  active: boolean;
  sortOrder?: number;
  readonly startDate?: Date;
  readonly endDate?: Date;
}

export const VistoFocusListFields: IFieldDefinitions<VistoFocusItem> = {
  itemId: FieldGenerator.makeIdField('VISTO_Focus_ItemId'),
  guid: FieldGenerator.makeGuidField('VISTO_Focus_Guid'),
  name: FieldGenerator.makeTitleField('VISTO_Focus_Name'),
  description: FieldGenerator.makeDescriptionField('VISTO_Focus_Description'),
  links: FieldGenerator.makeLinksField('VISTO_Focus_Links'),
  active: FieldGenerator.makeBooleanField('VISTO_Focus_Active', 'Active', true),
  sortOrder: FieldGenerator.makeNumberField('VISTO_Focus_SortOrder', 'SortOrder', true),
  createdDate: FieldGenerator.makeCreatedField('VISTO_Focus_Created'),
  modifiedDate: FieldGenerator.makeModifiedField('VISTO_Focus_Modified'),
  startDate: FieldGenerator.makeDateField('VISTO_Focus_StartDate', 'StartDate', true),
  endDate: FieldGenerator.makeDateField('VISTO_Focus_EndDate', 'EndDate', true),
  assignedTo: FieldGenerator.makeUserField('VISTO_Focus_AssignedTo', 'AssignedTo', true),
};

addListDefinition({
  kind: VistoKind.Focus,
  fields: VistoFocusListFields,
  enableVersioning: true,
  majorVersionLimit: 500,

  defaultViewXml: `<View>
    <Query />
    <ViewFields>
      <FieldRef Name='LinkTitle' />
      <FieldRef Name='${VistoFocusListFields.description.name}' />
      <FieldRef Name='${VistoFocusListFields.active.name}' />
      <FieldRef Name='${VistoFocusListFields.sortOrder.name}' />
      <FieldRef Name='${VistoFocusListFields.startDate.name}' />
      <FieldRef Name='${VistoFocusListFields.endDate.name}' />
      <FieldRef Name='${VistoFocusListFields.assignedTo.name}' />
    </ViewFields>
    <RowLimit Paged='TRUE'>30</RowLimit>
  </View>`,

  setItemSpecificFields: (updater: ListItemUpdater, spItem, update: Partial<VistoFocusItem>) => {
    updater.setItemSpField(VistoFocusListFields, spItem, update, 'active');
    updater.setItemSpField(VistoFocusListFields, spItem, update, 'sortOrder');
    updater.setItemSpField(VistoFocusListFields, spItem, update, 'startDate');
    updater.setItemSpField(VistoFocusListFields, spItem, update, 'endDate');
    updater.setItemSpField(VistoFocusListFields, spItem, update, 'assignedTo');
  },

  getItemSpecificFields: (updater: ListItemUpdater, item: Partial<VistoFocusItem>, spItem) => {
    updater.getItemSpField(VistoFocusListFields, item, spItem, 'active');
    updater.getItemSpField(VistoFocusListFields, item, spItem, 'sortOrder');
    updater.getItemSpField(VistoFocusListFields, item, spItem, 'startDate');
    updater.getItemSpField(VistoFocusListFields, item, spItem, 'endDate');
    updater.getItemSpField(VistoFocusListFields, item, spItem, 'assignedTo');
  }
});
