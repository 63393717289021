import { AuthService } from 'services/AuthService';
import { IExecutableAction } from 'services/IExecutableAction';
import { TextService } from 'services/TextService';
import { makeGuidString } from 'shared/guid';
import { UrlService } from 'shared/urlService';
import { IVistoPlan, VistoKind } from 'sp';

export const MigrateKrHierarchy = (foundPlan: IVistoPlan): IExecutableAction => ({
  title: `Migrate KPI to KR hierarchy`,
  execute: async (p) => {
    const sp = AuthService.getSpClient(foundPlan.siteUrl);

    const assocListUrl = UrlService.getListRelativeUrl(foundPlan, VistoKind.Assoc);
    const krListUrl = UrlService.getListRelativeUrl(foundPlan, VistoKind.KeyResult);
    const actionListUrl = UrlService.getListRelativeUrl(foundPlan, VistoKind.Action);
    const dpListUrl = UrlService.getListRelativeUrl(foundPlan, VistoKind.DP);

    let count = 1;

    const krSet = {};
    const krs = await sp.web.getList(krListUrl).items.top(2000)();
    krs.sort((a, b) => TextService.compareNames(a['Title'], b['Title']));
    let firstSoId = null;
    for (const kr of krs) {
      krSet[kr.Id] = kr;
      const soId = kr['SOId'];
      if (soId) {
        firstSoId = soId;
      }
    }

    const assocs = await sp.web.getList(assocListUrl).items.top(2000)();
    for (const assoc of assocs) {
      const kpiId = assoc['KPIId'];
      if (kpiId && krSet[kpiId]) {
        const krId = assoc['KeyResultId'];
        const soId = assoc['SOId'] || krSet[krId]['SOId'];
        krSet[kpiId]['KRId'] = krId;
        krSet[kpiId]['SOId'] = soId;
        await sp.web.getList(krListUrl).items.getById(kpiId).update({ KRId: krId, SOId: soId });

        let actionId = assoc['ActionId'];
        if (!actionId) {
          const dpId = krSet[kpiId]['DPId'];

          const dp = await sp.web.getList(dpListUrl).items.getById(dpId)();
          const lopId = dp['LOPId'];

          const added = await sp.web.getList(actionListUrl).items.add({
            'VISTO_Guid': makeGuidString(),
            'Title': `migrated ${count++}`,
            'DPId': dpId,
            'LOPId': lopId,
          });

          actionId = added.data.ID;
        }

        await sp.web.getList(assocListUrl).items.getById(assoc.Id).update({
          ActionId: actionId,
          KeyResultId: kpiId,
          ShowOnDiagram: krSet[kpiId]['ShowOnDiagram']
        });

        await sp.web.getList(krListUrl).items.getById(kpiId).update({ ShowOnDiagram: false });
      }
    }

    for (const krId in krSet) {
      const kr = krSet[krId];
      const soId = kr['SOId'];
      if (!soId) {
        await sp.web.getList(krListUrl).items.getById(+krId).update({ SOId: firstSoId, Title: `${kr['Title']} (migrated)` });
      }
    }

    const assocFields = await sp.web.getList(assocListUrl).fields();
    if (assocFields.some(f => f.InternalName === 'KPI')) {
      await sp.web.getList(assocListUrl).fields.getByInternalNameOrTitle('KPI').delete();
    }

    const actionFields = await sp.web.getList(actionListUrl).fields();
    if (actionFields.some(f => f.InternalName === 'KPI')) {
      await sp.web.getList(actionListUrl).fields.getByInternalNameOrTitle('KPI').delete();
    }
    
    const krFields = await sp.web.getList(krListUrl).fields();
    if (krFields.some(f => f.InternalName === 'DP')) {
      await sp.web.getList(krListUrl).fields.getByInternalNameOrTitle('DP').delete();
    }

    return p;
  }
});
