import { Dialog, DialogType, Stack, Calendar, DialogFooter, PrimaryButton, DefaultButton } from '@fluentui/react';
import React from 'react';
import { TextService } from 'services/TextService';
import strings from 'VistoWebPartStrings';

export const HistoryDatePickerDialog = (props: {
  date: Date;
  onDismiss: (save: boolean, date?: Date) => void;
}) => {
  const [date, setDate] = React.useState(props.date);

  return (
    <Dialog minWidth={500} hidden={false} dialogContentProps={{ title: TextService.format(strings.HistoryBlock_DialogTitle), type: DialogType.normal, }} onDismiss={() => props.onDismiss(false)}>
      <Stack>
        <Calendar
          showGoToToday={false}
          onSelectDate={setDate}
          value={date}
          strings={TextService.datePickerStrings}
        />
      </Stack>
      <DialogFooter>
        <PrimaryButton onClick={() => props.onDismiss(true, date)} text={TextService.format(strings.ButtonOK)} />
        <DefaultButton onClick={() => props.onDismiss(false)} text={TextService.format(strings.ButtonCancel)} />
      </DialogFooter>
    </Dialog>
  );
};

