import { Stack, Text } from '@fluentui/react';
import React from 'react';
import { ITemplate } from 'services/ITemplate';
import { NewPlanCard } from './NewPlanCard';

export const NewPlanCardList = (props: {
  templates: ITemplate[];
  selectedId: string;
  setSelectedId: (val) => void;
}) => {

  const seelctedTemplate = props.templates.find(x => x.id === props.selectedId);

  return (
    <Stack grow tokens={{ padding: 'm 0' }}>
      <Stack horizontal grow>
        {props.templates.map(t => <NewPlanCard template={t} key={t.id} isSelected={t.id === props.selectedId} onClick={() => props.setSelectedId(t.id)} />)}
      </Stack>
      <Stack.Item>
        <Text variant='small'>{seelctedTemplate?.description}</Text>
      </Stack.Item>
    </Stack>
  );
};
