import * as microsoftTeams from '@microsoft/teams-js';
import { api } from 'shared/api';
import { stringifyError } from 'shared/parse';
import { TextService } from 'services/TextService';
import * as strings from 'VistoWebPartStrings';
import { trackClient } from 'shared/clientTelemetry';

export class TeamsAuthService {

  public static getTeamsContext() {
    return new Promise<microsoftTeams.Context>((resolve) => {
      microsoftTeams.getContext(teamsContext => {

        // workaround for teams bug on android
        // https://github.com/OfficeDev/microsoft-teams-library-js/issues/1438
        if (teamsContext.hostClientType === microsoftTeams.HostClientType.android) {
          if (teamsContext.channelRelativeUrl && teamsContext.teamSiteUrl) {
            const teamSuteUrl = new URL(teamsContext.teamSiteUrl);
            const pathItems = teamsContext.channelRelativeUrl.split('/');
            teamsContext.teamSitePath = `/${pathItems[1]}/${pathItems[2]}`
            teamsContext.teamSiteUrl = `${teamSuteUrl.origin}${teamsContext.teamSitePath}`;
          }
        }

        resolve(teamsContext);
      });
    });
  }

  public static getTeamsToken(): Promise<string> {

    return new Promise((resolve, reject) => {
      microsoftTeams.authentication.getAuthToken({
        successCallback: (token: string) => {
          resolve(token);
        },
        failureCallback: (message: string) => {
          microsoftTeams.appInitialization.notifyFailure({
            reason: microsoftTeams.appInitialization.FailedReason.AuthFailed,
            message
          });
          reject({ error_description: TextService.format(strings.AuthService_ErrorGetTeamsToken, { message }) });
        },
        silent: false
      });
    });
    
  }

  public static makeTabSettings(host: string, planId: string, siteUrl: string): microsoftTeams.settings.Settings {
    const encodedPlanId = encodeURIComponent(planId);
    const encodedSiteUrl = encodeURIComponent(siteUrl);
    return {
      contentUrl: host + `/tab?planId=${encodedPlanId}&name={loginHint}&theme={theme}`,
      websiteUrl: host + `/#/teams/${encodedPlanId}?siteUrl=${encodedSiteUrl}`,
      removeUrl: host + `/tab/remove?planId=${encodedPlanId}&name={loginHint}&theme={theme}`,
      entityId: planId
    }
  }

  // Show the consent pop-up
  public static getConsent(kind: api.TokenKind, domain: string, callback: () => Promise<any>): Promise<any> {

    return new Promise((resolve, reject) => {

      microsoftTeams.authentication.authenticate({
        url: `${window.location.origin}/auth/auth-start#kind=${kind}`,
        width: 600,
        height: 535,
        successCallback: () => {
          let attempt = 0;
          const test = () => {
            callback().then((result) => {
              resolve(result);
            }).catch((error) => {
              trackClient.warn(`Consent check failed (attempt ${attempt} of 10)`, stringifyError(error));
              if (attempt < 15) {
                attempt = attempt + 1;
                setTimeout(test, 2000);
              } else {
                reject(TextService.format(strings.AuthError_Grant));
              }
            });
          };
          test();
        },
        failureCallback: (reason) => {
          reject(reason);
        }
      });
      
    });
  }
}
