import * as strings from 'VistoWebPartStrings';
import { EditDpDialog, EditLopDialog } from 'dialogs';
import { TextService } from 'services/TextService';
import { ICommand } from 'services/ICommand';
import { INotify } from 'services/Notify';
import { StorageService } from 'services/StorageService';
import { IVistoPlan, VistoDpItem, VistoKind, VistoLopItem } from 'sp';
import { mxgraph } from 'ts-mxgraph-typings';
import { mx, FixedViewType } from 'frames/TopFrame/drawing/common';
import { IImageInfo, ImageDialog, ImageUpdater } from '../images';
import { getPosition } from '../DrawingUpdate';
import { CommandName } from 'shared/CommandName';
import { CellKind } from 'shared/CellKind';
import { ICommandOptions } from 'services/ICommandOptions';

export interface IDropInfo {
  data;
  dialog;
  drop;
}

export const replaceDropHandler = (sidebar, graph: mxgraph.mxGraph, setDropInfo: (IDropInfo) => void) => {

  const createDropHandlerDefault = sidebar.createDropHandler;
  sidebar.createDropHandler = (cells, allowSplit, allowCellsInserted, bounds) => {

    const defaultDropHandler = createDropHandlerDefault.call(sidebar, cells, allowSplit, allowCellsInserted, bounds);
    return (g, evt, target, x, y, force) => {

      const doDrop = () => {
        defaultDropHandler.call(sidebar, graph, evt, target, x, y, force);
      };
      const kind = cells.length === 1 && mx.getCellKind(cells[0]);
      switch (kind) {

        case CellKind.DP:
          const dpDropInfo = {
            dialog: EditDpDialog,
            data: <VistoDpItem>{
              kind: VistoKind.DP,
              name: TextService.getVistoKindName(VistoKind.DP)
            },
            drop: (item: VistoDpItem) => {
              dpDropInfo.data = item;
              doDrop();
            }
          };
          setDropInfo(dpDropInfo);
          break;

        case CellKind.LOP:
          const lopDropInfo = {
            dialog: EditLopDialog,
            data: <VistoLopItem>{
              kind: VistoKind.LOP,
              name: TextService.getVistoKindName(VistoKind.LOP)
            },
            drop: (item: VistoLopItem) => {
              lopDropInfo.data = item;
              doDrop();
            }
          };
          setDropInfo(lopDropInfo);
          break;

        case CellKind.IMAGE:
          const imageDropInfo = {
            dialog: ImageDialog,
            data: <IImageInfo>{
              resizeType: FixedViewType.FitPage,
              opacity: 100,
              fixedAspect: true
            },
            drop: (item: IImageInfo) => {
              imageDropInfo.data = item;
              doDrop();
            }
          };
          setDropInfo(imageDropInfo);
          break;

        default:
          doDrop();
          break;
      }
    };
  };
};

export const handleCellsAdded = (
  dropInfo: IDropInfo,
  dispatchCommand: (command: ICommand, options: ICommandOptions) => Promise<IVistoPlan>,
  graph: mxgraph.mxGraph,
  event: mxgraph.mxEventObject,
  notify: INotify) => {

  /**
   * Event handler for DP dropped
   *
   * @param graph
   * @param addedCell
   */

  const onDpCellAdded = (addedCell: mxgraph.mxCell) => {

    const targetLopCell = mx.findOverlappingLopCell(graph, addedCell);
    if (targetLopCell) {
      mx.centerDpOnLop(addedCell, targetLopCell);
    }

    const item: VistoDpItem = {
      kind: VistoKind.DP,
      guid: mx.getCellGuid(addedCell),
      lopGuid: mx.getCellGuid(targetLopCell),
      name: dropInfo?.data?.name || graph.model.getValue(addedCell),
      description: dropInfo?.data?.description,
      assignedTo: dropInfo?.data?.assignedTo,
      position: getPosition(addedCell)
    };

    addedCell.setValue(item.name);
    graph.cellsOrdered([addedCell], false);
    graph.removeCellOverlays(addedCell);

    return item;
  };

  /**
   * Event handler for LOP dropped
   *
   * @param graph
   * @param addedCell
   */

  const onLopCellAdded = (addedCell: mxgraph.mxCell) => {
    const foundFocusCell = mx.findCellByKind(graph, CellKind.FOCUS);
    if (foundFocusCell) {
      graph.connectCell(addedCell, foundFocusCell, false);
    }

    const item: VistoLopItem = {
      kind: VistoKind.LOP,
      guid: mx.getCellGuid(addedCell),
      name: dropInfo?.data?.name || graph.model.getValue(addedCell),
      description: dropInfo?.data?.description,
      assignedTo: dropInfo?.data?.assignedTo,
      position: getPosition(addedCell)
    };

    addedCell.setValue(item.name);
    graph.cellsOrdered([addedCell], true);
    graph.removeCellOverlays(addedCell);

    return item;
  };


  /**
   * Event handler for LOP dropped
   *
   * @param graph
   * @param addedCell
   */

  const onImageCellAdded = (addedCell: mxgraph.mxCell) => {

    const imageInfo: IImageInfo = dropInfo.data;

    if (imageInfo.fixedAspect) {
      const geo: mxgraph.mxGeometry = addedCell.geometry.clone();
      const ws = geo.width / imageInfo.width;
      const hs = geo.height / imageInfo.height;
      if (hs < ws)
        geo.width = imageInfo.width * hs;
      else
        geo.height = imageInfo.height * ws;

      addedCell.setGeometry(geo);
    }

    ImageUpdater.setImageInfo(graph, addedCell, imageInfo);

    graph.cellsOrdered([addedCell], false);
  };

  /**
   * Even handler called when shape is added to the diagram
   *
   * @param graph
   * @param event
   */

  const items = [];
  const repaintCellIds: string[] = [];

  for (const cell of event.properties.cells) {
    const kind = mx.getCellKind(cell);
    switch (kind) {

      case CellKind.DP:
        items.push(onDpCellAdded(cell));
        repaintCellIds.push(mx.getCellGuid(cell));
        break;

      case CellKind.LOP:
        items.push(onLopCellAdded(cell));
        repaintCellIds.push(mx.getCellGuid(cell));
        break;

      case CellKind.IMAGE:
        onImageCellAdded(cell);
        break;
    }
  }

  if (items.length) {
    dispatchCommand({
      prepare: async () => {
        return {
          do: (plan) => StorageService.get(plan.siteUrl).createItems(plan, items, notify, { enableSimpleUpdate: items.length === 1, validate: true }),
          undo: (plan) => StorageService.get(plan.siteUrl).deleteItems(plan, items, notify, { enableSimpleUpdate: items.length === 1, validate: true }),
        };
      },
      message: TextService.format(strings.ShapeAdded_Command, { number: items.length }),
      name: CommandName.CreateItemDrop
    }, {
      wrap: true,
      repaintCellIds
    });
  }
};
