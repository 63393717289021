import { AuthService } from 'services/AuthService';
import { IExecutableAction } from 'services/IExecutableAction';
import { UrlService } from 'shared/urlService';
import { IVistoPlan, VistoKind } from 'sp';
import { TextService } from 'services/TextService';
import { PlannerLinkService, PlannerService } from 'integrations/planner';
import { PlanSettingsService } from 'services/PlanSettingsService';

export const MigrateFocusOrderFix = (foundPlan: IVistoPlan): IExecutableAction => ({
  title: `Fix Focus order with planner`,
  execute: async (p) => {

    const options = PlanSettingsService.getPlanSettings(foundPlan);
    if (options.integrations?.planner?.enableLabelSync) {
      const sp = AuthService.getSpClient(foundPlan.siteUrl);
      const focusListUrl = UrlService.getListRelativeUrl(foundPlan, VistoKind.Focus);

      const focuses: { itemId: number, guid: string, name: string }[] = [];

      const focusSpItems = await sp.web.getList(focusListUrl).items.top(2000)();
      for (const focusSpItem of focusSpItems) {
        const guid = focusSpItem['VISTO_Guid'];
        const name = focusSpItem['Title'];
        const itemId = +focusSpItem['ID'];
        focuses.push({ itemId, name, guid });
      }

      focuses.sort((a, b) => TextService.naturalComparer.compare(a.guid, b.guid));

      const plannerPlanIds = new Set<string>();

      const lopListUrl = UrlService.getListRelativeUrl(foundPlan, VistoKind.LOP);
      const lopSpItems = await sp.web.getList(lopListUrl).items.top(2000)();
      for (const lopSpItem of lopSpItems) {
        const sourceItem = lopSpItem['SourceItem'];
        if (PlannerLinkService.isPlannerPlanLink(sourceItem)) {
          const { planId } = PlannerLinkService.parsePlannerPlanLink(sourceItem);
          plannerPlanIds.add(planId);
        }
      }

      for (const planId of plannerPlanIds) {
        const categoryDescriptions = focuses.reduce((r, f, i) => ({ ...r, [`category${i + 1}`]: f.name ?? {} }), {});
        await PlannerService.updatePlanDetails(planId, { categoryDescriptions });
      }

      const actionListUrl = UrlService.getListRelativeUrl(foundPlan, VistoKind.Action);
      const actionSpItems = await sp.web.getList(actionListUrl).items.top(2000)();
      for (const actionSpItem of actionSpItems) {
        const sourceItem = actionSpItem['SourceItem'];
        if (PlannerLinkService.isPlannerTaskLink(sourceItem)) {
          const focusId = +actionSpItem['FocusId'];
          const { taskId } = PlannerLinkService.parsePlannerTaskLink(sourceItem);
          const appliedCategories = focuses.reduce((r, f, i) => ({ ...r, [`category${i + 1}`]: f.itemId === focusId }), {});
          await PlannerService.updateTask(taskId, { appliedCategories });
        }
      }
    }

    return p;
  }
});
