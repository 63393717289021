import { Stack } from '@fluentui/react';
import React from 'react';
import { IFieldValueUser } from 'sp';
import { OkrUserPicture } from './OkrUserPicture';

export const OkrAssigneeBlock = (props: {
  assignedTo: IFieldValueUser[];
}) => {

  const haveAssignedTo = props.assignedTo?.length > 0;

  const assignedToUnfiltered = props.assignedTo ? props.assignedTo.map(x => ({
    userName: x.userName || x.guid,
    displayName: x.title
  })) : [];

  const assignedTo = assignedToUnfiltered.length <= 4
    ? assignedToUnfiltered
    : assignedToUnfiltered.slice(0, 4).concat({
      userName: '',
      displayName: `+${assignedToUnfiltered.length - 4}`
    });

  return (
    haveAssignedTo
      ? <Stack horizontal verticalAlign='center' horizontalAlign='center' tokens={{ childrenGap: 4 }}>
        {assignedTo?.length > 0 && assignedTo.map(x => (
          <Stack key={x.userName} horizontal verticalAlign='center' tokens={{ childrenGap: 8 }}>
            <OkrUserPicture size={24} userName={x.userName} displayName={x.displayName} />
            {assignedTo.length == 1 && <span>{x.displayName}</span>}
          </Stack>
        ))}
      </Stack>
      : null
  );
}
