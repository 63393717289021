import * as React from 'react';
import { LineStyleButton } from './LineStyleButton';
import { TextService } from 'services/TextService';
import { IPalette, Theme, useTheme } from '@fluentui/react';
import * as strings from 'VistoWebPartStrings';

export interface IDashStyle {
  dash: string;
  dasharray: string;
}

interface IDashStyleOption {
  name: string;
  val: IDashStyle;
}

const options: IDashStyleOption[] = [
  { name: TextService.format(strings.DashButton_Solid), val: { dash: null, dasharray: null } },
  { name: TextService.format(strings.DashButton_42), val: { dash: '1', dasharray: '4 2' } },
  { name: TextService.format(strings.DashButton_22), val: { dash: '1', dasharray: '2 2' } },
  { name: TextService.format(strings.DashButton_44), val: { dash: '1', dasharray: '4 4' } },

  { name: TextService.format(strings.DashButton_11), val: { dash: '1', dasharray: '1 1' } },
  { name: TextService.format(strings.DashButton_12), val: { dash: '1', dasharray: '1 2' } },
  { name: TextService.format(strings.DashButton_14), val: { dash: '1', dasharray: '1 4' } },
];

const renderDash = (option: IDashStyle, theme: Theme) => {
  
  return (
    <svg style={{ paddingRight: 4 }} width='32' viewBox='0 0 32 20' xmlns='http://www.w3.org/2000/svg'>
      <line x1='0' y1='10' x2='32' y2='10' stroke={theme.palette.black} strokeWidth={1} strokeDasharray={option.dasharray} />
    </svg>
  );
};

export const DashButton = (props: { dashStyle: IDashStyle, setDashStyle: (dashStyle: IDashStyle) => void }) => {

  const theme = useTheme();

  return (
    <LineStyleButton
      text={TextService.format(strings.DashButton_Title)}
      propertyName='dash'
      defaultValue={options[0].val}
      value={props.dashStyle}
      setValue={props.setDashStyle}
      options={options}
      renderIcon={(option: IDashStyle) => renderDash(option, theme)}
    />
  );
};
