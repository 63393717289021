import React from 'react';
import { EnvContext } from 'services/EnvContext';
import { ICardInfo } from 'services/ICardInfo';
import { DashboardPlanCard } from './DashboardPlanCard';

export const DashboardPlanList = (props: {
  items: ICardInfo[];
  onTogglePinned: (val: ICardInfo) => void;
  onDelete: (val: ICardInfo) => void;
}) => {
  const { hostKind } = React.useContext(EnvContext);

  const isWeb = (hostKind === 'WebMobile' || hostKind === 'WebDesktop');
  const height = `calc(100vh - ${202 + (isWeb ? 62 : 0)}px)`;

  return (<div style={{ overflow: 'auto', height }} data-is-scrollable='true'>
    {props.items.map(ci =>
      <DashboardPlanCard
        key={ci.key}
        cardInfo={ci}
        onTogglePinned={props.onTogglePinned}
        onDelete={props.onDelete}
      />
    )}
  </div>);
}
