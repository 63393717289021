import * as React from 'react';
import { TeamsAuthScope } from 'teams/components/TeamsAuthScope';
import { TeamsAuthService } from 'teams/services/TeamsAuthService';
import { SharepointExternalDataService } from 'integrations/sharepoint';
import { env } from 'shared/clientEnv';
import { MainFrame } from 'frames/MainFrame';

import { IEnvContext } from 'services/EnvContext';
import { api } from 'shared/api';
import { PlannerConfigurationService } from 'integrations/planner';
import { ProjectDataService } from 'integrations/project';
import { DevOpsDataService } from 'integrations/devops';
import { trackClient } from 'shared/clientTelemetry';
import * as microsoftTeams from '@microsoft/teams-js';
import { ApiService } from 'services/ApiService';

import { UrlService } from 'shared/urlService';
import { makeGuidString } from 'shared/guid';
import { WSContext } from 'services/WSContext';

import { FluidClientService } from 'services/FluidClientService';

/**
 * Implementation of the VistoTeams Tab content page
 */

export const VistoTeamsTab = () => {

  const [context, setContext] = React.useState<{
    planId: string;
    envContext: IEnvContext;
  }>(null);

  const onLoad = async (envContext: IEnvContext) => {

    const planId = env.getParamFromUrl('planId');

    microsoftTeams.settings.getSettings(oldSettings => {
      const host = 'https://' + window.location.host;
      const newSettings = TeamsAuthService.makeTabSettings(host, planId, envContext.siteUrl);
      if (oldSettings.websiteUrl !== newSettings.websiteUrl) {
        microsoftTeams.settings.setSettings({ ...oldSettings, websiteUrl: newSettings.websiteUrl }, (succeeded, reason) => {
          if (succeeded) {
            trackClient.log(`Updated websiteUrl for plan ${planId}`);
          } else {
            trackClient.warn(`Failed to udpate websiteUrl for plan ${planId} ${reason}`);
          }
        });
      }
    });

    PlannerConfigurationService.configure(envContext.tid, envContext.groupId);
    SharepointExternalDataService.configure();
    ProjectDataService.configure();
    DevOpsDataService.configure();
    ApiService.configure(() => TeamsAuthService.getTeamsToken());

    setContext({ planId, envContext });
  };

  const getRealtimeService = (userId, containerId, callback) => {
    if (+env.getTemplateParamValue('FLUID_ENABLED')) {
      return new FluidClientService(userId, containerId, callback);
    } else {
      return new WSContext(UrlService.makeInstanceId(context.envContext.siteUrl, context.planId), makeGuidString(), callback);
    }
  }

  return (
    <TeamsAuthScope name='Teams_Tab' kind={api.TokenKind.sharepoint} onLoad={onLoad}>
      {context && <MainFrame source={{ planId: context.planId, siteUrl: context.envContext.siteUrl }} getRealtimeService={getRealtimeService} />}
    </TeamsAuthScope>
  );
};
