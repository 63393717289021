import * as strings from 'VistoWebPartStrings';
import { ICommand } from 'services/ICommand';
import { INotify } from 'services/Notify';
import { ChangesService } from 'services/ChangesService';
import { Commands } from 'services/Commands';
import { TextService } from 'services/TextService';
import { PlanDataService } from 'services/PlanDataService';
import { IVistoListItem, IVistoPlan } from 'sp';
import { mxgraph } from 'ts-mxgraph-typings';
import { setIsLoading } from 'services/LoadingIndicator';
import { mx } from 'frames/TopFrame/drawing/common';
import { CommandName } from 'shared/CommandName';
import { CellKind } from 'shared/CellKind';
import { ICommandOptions } from 'services/ICommandOptions';

export const handleLabelChanged = (
  graph: mxgraph.mxGraph,
  currentPlan: IVistoPlan,
  dispatchCommand: (command: ICommand, options: ICommandOptions) => Promise<IVistoPlan>,
  event: mxgraph.mxEventObject,
  notify: INotify) => {

  const onPlanLabelChanged = async (propertyName: keyof IVistoPlan, newLabel: string, message: string) => {
    try {
      graph.setEventsEnabled(false);
      setIsLoading('Renaming...');
      const changes = ChangesService.getChanges(currentPlan, { [propertyName]: newLabel });
      const command = Commands.makeUpdatePlanPropertyCommand(changes, message, notify);
      return await dispatchCommand(command, { wrap: true });
    } finally {
      setIsLoading('');
      graph.setEventsEnabled(true);
    }
  };

  const onPlanFocusChanged = async (newLabel: string) => {

    const item = PlanDataService.getActiveFocus(currentPlan);

    const changes = ChangesService.getChanges<Partial<IVistoListItem>>({
      name: item.name,
    }, {
      name: newLabel,
    });

    const command = Commands.makeUpdateCommand([{ item: item, changes }], notify, { enableSimpleUpdate: true });
    return dispatchCommand(command, { wrap: true });
  };

  const onItemLabelChanged = (item: IVistoListItem, newLabel: string, oldLabel: string) => {
    return dispatchCommand({
      prepare: async () => {
        const changes = ChangesService.getChanges({ name: oldLabel }, { name: newLabel });
        return Commands.makeUpdateUndoUnit([{ item, changes }], notify, { enableSimpleUpdate: true });
      },
      message: TextService.format(strings.Command_RenameItem),
      name: CommandName.RenameItemShape
    }, {
      wrap: true
    });
  };

  const cell = event.properties.cell;
  const cellKind = mx.getCellKind(cell);

  // do not change to empty string
  if (cellKind && !event.properties.value) {
    graph.cellLabelChanged(cell, event.properties.old, false);
    return currentPlan;
  }

  switch (cellKind) {
    case CellKind.TITLE:
      return onPlanLabelChanged('name', event.properties.value, TextService.format(strings.LabeChangedCommand_RenamePlan));
    case CellKind.FOCUS:
      return onPlanFocusChanged(event.properties.value);
    case CellKind.POSITION:
      return onPlanLabelChanged('esName', event.properties.value, TextService.format(strings.LabeChangedCommand_RenamePosition));
    case CellKind.DP:
    case CellKind.LOP:
      const guid = mx.getCellGuid(cell);
      const item = PlanDataService.getItemByGuid(currentPlan.items, guid);
      const newLabel = TextService.unformatMultiline(event.properties.value);
      const oldLabel = TextService.unformatMultiline(event.properties.old);
      return onItemLabelChanged(item, newLabel, oldLabel);
  }
};
