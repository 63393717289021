
import { IVistoListItem, IVistoListItemWithProgress, IVistoPlan, IVistoPlanSettings } from 'sp';
import { ProgressService } from 'services/ProgressService';
import { IProgressData } from 'services/IProgressData';
import { TextService } from 'services/TextService';
import { clearInfoBar, IBasicNotify, INotify, NotificationType, notifyInfoBar } from 'services/Notify';
import { ICommand } from 'services/ICommand';
import { AuthService } from 'services/AuthService';
import { StorageService } from 'services/StorageService';
import { IOperationOptions } from 'services/IOperationOptions';
import { IItemChanges } from 'services/Interfaces';
import { PlanDataService } from 'services/PlanDataService';
import { ChangesService } from 'services/ChangesService';
import { trackClient } from 'shared/clientTelemetry';
import { CommandName } from 'shared/CommandName';
import { isConsentError, parseResponseError, parseResponseJSON, stringifyError } from 'shared/parse';
import { api } from 'shared/api';
import strings from 'VistoWebPartStrings';
import { PlanSettingsService } from 'services/PlanSettingsService';
import { LicenseService } from 'services/LicenseService';
import { IntegrationService } from 'services/IntegrationService';
import { IIconInfo } from 'services/IIconInfo';
import { Commands } from 'services/Commands';
import { UrlService } from 'shared/urlService';

export interface IProjectIntegrationSettings {
  enabled: boolean;
  pwaUrl: string;
  syncDate?: Date;
}

export class ProjectDataService {

  private static timezoneBiasSet: any = {};

  public static isLinkedItem(url: string) {
    return url && (this.isProjectLink(url) || this.isProjectTaskLink(url));
  }

  public static formatLinkName(projectName: string, taskName: string) {
    if (taskName && projectName) {
      return TextService.format(strings.ProjectData_ProjectTaskLinkName, { taskName, projectName });
    }
    else if (projectName) {
      return TextService.format(strings.ProjectData_ProjectLinkName, { projectName });
    }
    else {
      return '';
    }
  }

  public static makeBreakProjectLinkNotificationAction(plan: IVistoPlan, item: IVistoListItemWithProgress, notify: INotify) {
    return {
      title: TextService.format(strings.ProjectNotification_BreakLinkTitle),
      command: Commands.makeBreakLinkAction(plan, item, true, CommandName.BreakLinkProject, notify),
      confirmation: {
        buttonOkText: TextService.format(strings.ButtonBreak),
        buttonOkBusyText: TextService.format(strings.ButtonBreaking),
        buttonCancelText: TextService.format(strings.ButtonCancel),
        title: TextService.format(strings.ProjectNotification_BreakProjectLink),
        content: TextService.format(strings.ProjectNotification_BreakProjectLinkDescription, { title: TextService.formatTitle(item, plan.items) }),
      }
    };
  }

  public static makeConsentNotification(pwaUrl: string, callback: () => Promise<void>, notify: IBasicNotify) {

    notifyInfoBar(notify, {
      message: TextService.format(strings.ProjectNotification_AuthorizationRequired),
      group: 'Project_Consent',
      type: NotificationType.warn,
      error: TextService.format(strings.ProjectNotification_AuthorizationRequiredError),
      actions: [
        {
          title: TextService.format(strings.ProjectNotification_ButtonAuthorize),
          action: async () => {
            try {
              await AuthService.getConsent(api.TokenKind.project, UrlService.getDomain(pwaUrl), callback);
              clearInfoBar(notify, 'Project_Consent');
              notifyInfoBar(notify, { type: NotificationType.success, message: TextService.format(strings.ProjectNotification_ConsentGrant), group: 'Project_Consent' });
            } catch (error) {
              const message = TextService.format(strings.ProjectNotification_ConsentRequiredDescription);
              notifyInfoBar(notify, { type: NotificationType.error, message, error, group: 'Project_Consent' });
            }
          }
        }
      ]
    });
  }

  public static getBrowserLink(pwaUrl: string, projectId: string) {
    return `${pwaUrl}/project%20detail%20pages/schedule.aspx?projuid=${projectId}`;
  }

  public static configure() {

    IntegrationService.hooks['project'] = {

      isRecognizedLink: url => {
        return this.isLinkedItem(url);
      },

      getBrowserLink: (url) => {
        const pwaUrl = ProjectDataService.getPwaUrl(url);
        const projectId = pwaUrl && ProjectDataService.getProjectId(url);
        return projectId ? ProjectDataService.getBrowserLink(pwaUrl, projectId) : url;
      },

      getSyncDate: (settings: IVistoPlanSettings): Date => {
        return settings?.integrations?.project?.syncDate;
      },

      getIconInfo: async (url: string): Promise<IIconInfo> => {
        return {
          iconUrl: require('static/assets/links/project.svg'),
          tooltipText: TextService.format(strings.LinkIconTitle_Project)
        };
      },

      getDashbordRef: async (url: string) => {
        const pwaUrl = ProjectDataService.getPwaUrl(url);
        const projectId = pwaUrl && ProjectDataService.getProjectId(url);
        return projectId && `project:${pwaUrl}##${projectId}`;
      },

      getCheckList: async (url: string, plan: IVistoPlan) => {
        return undefined;
      },

      getLinkName: async (url) => {
        const projectId = this.getProjectId(url);
        const project = projectId && await this.get(url);
        const projectName = project && project.Name;

        const taskId = this.getProjectTaskId(url);
        const task = taskId && await this.get(url);
        const taskName = task && task.Name;

        return this.formatLinkName(projectName, taskName);
      },

      allowRecalculation: (name: keyof IProgressData) => {
        return name === 'plannedPercentComplete';
      },

      allowEdit: (name: keyof IProgressData) => {
        return name !== 'name' && name !== 'startDate' && name !== 'endDate' && name !== 'percentComplete' && name !== 'plannedPercentComplete';
      },

      removalWarning: (items: IVistoListItem[]) => '',
      removalAction: (items: IVistoListItem[]) => Promise.resolve(),

      synchronize: async (p: IVistoPlan, items: IVistoListItemWithProgress[], notify: INotify, operation: api.WSOperation, options: IOperationOptions): Promise<IVistoPlan> => {

        clearInfoBar(notify, 'Project');

        const oldSettings = PlanSettingsService.getPlanSettings(p);
        if (!oldSettings?.integrations?.project?.enabled || !LicenseService.license?.projectEnabled) {
          return p;
        }

        const callback = async () => {
          const updates: IItemChanges<IVistoListItemWithProgress>[] = [];

          const cache = {};
          const linkedItems = PlanDataService.getItemsHaving<IVistoListItemWithProgress>(p.items, (item: IVistoListItemWithProgress) => this.isLinkedItem(item.sourceItemUrl));
          if (!linkedItems.length) {
            return;
          }

          for (const linkedItem of linkedItems) {

            try {
              const progress = await this.getItemProgress(linkedItem, p.statusDate, cache);
              const changes = ChangesService.getChanges(linkedItem, progress, ['name', 'startDate', 'endDate', 'percentComplete', 'plannedPercentComplete']);
              if (changes.detected) {
                updates.push({ item: linkedItem, changes });
              }
            } catch (error) {
              notifyInfoBar(notify, {
                type: NotificationType.warn,
                message: TextService.format(strings.MessageError_Project_BrokenLinkDescription, {
                  title: TextService.formatTitle(linkedItem, p.items)
                }),
                error,
                kind: linkedItem.kind,
                group: 'Project',
                guid: Commands.getClickableGuid(linkedItem),
                actions: [
                  this.makeBreakProjectLinkNotificationAction(p, linkedItem, notify)
                ]
              });
            }
          }

          if (updates.length) {
            trackClient.debug(`Received project data (${updates.length})`, updates);
            p = await StorageService.get(p.siteUrl).updateItems(p, updates, notify, { excludeExternals: true, excludeGroupByKind: true });
          }
        };

        try {

          await callback();

          const newSettings = { ...oldSettings, integrations: { ...oldSettings?.integrations, project: { ...oldSettings?.integrations?.project, syncDate: new Date() } } };
          p = PlanSettingsService.setPlanSettings(p, newSettings);

        } catch (error) {

          if (isConsentError(error)) {
            AuthService.resetAuth(api.TokenKind.project);
            const pwaUrl = oldSettings?.integrations?.project?.pwaUrl;
            ProjectDataService.makeConsentNotification(pwaUrl, callback, notify);
          }
          else {
            notifyInfoBar(notify, {
              type: NotificationType.error,
              message: TextService.format(strings.MessageError_Project_UnableToUpdateProgress),
              group: 'Project',
              error: error
            });
          }
        }

        return p;
      }
    };

  }

  public static getPwaUrl(url: string) {
    return url.split('/_api')[0];
  }

  public static async getProjectProgress(sourceItemUrl: string, statusDate: Date, cache: any) {

    const projectId = this.getProjectId(sourceItemUrl);
    const timeZoneBias = await this.getTimeZoneBias(this.getPwaUrl(sourceItemUrl));

    if (cache[projectId]) {
      return cache[projectId];
    }
    try {
      const pwaUrl = this.getPwaUrl(sourceItemUrl);
      const jsonProjects = await this.getProjects(pwaUrl);
      for (const jsonProject of jsonProjects) {
        cache[jsonProject.Id] = this.parseProjectProgress(jsonProject, statusDate, timeZoneBias);
      }
      if (cache[projectId]) {
        return cache[projectId];
      }
    }
    catch (err) {
      trackClient.warn(`Unable to get list of projects, executing fallback`, err);
    }

    const json = await this.get(sourceItemUrl);
    cache[projectId] = this.parseProjectProgress(json, statusDate, timeZoneBias);
    return cache[projectId];
  }

  public static async getProjectTaskProgress(sourceItemUrl: string, statusDate: Date, cache: any) {

    const projectId = this.getProjectId(sourceItemUrl);
    const projectTaskId = this.getProjectTaskId(sourceItemUrl);
    const timeZoneBias = await this.getTimeZoneBias(this.getPwaUrl(sourceItemUrl));

    if (cache[projectTaskId]) {
      return cache[projectTaskId];
    }
    try {
      const pwaUrl = this.getPwaUrl(sourceItemUrl);
      const jsonProjectTasks = await this.getProjectTasks(pwaUrl, projectId);
      for (const jsonProjectTask of jsonProjectTasks) {
        cache[jsonProjectTask.Id] = this.parseProjectTaskProgress(jsonProjectTask, statusDate, timeZoneBias);
      }
      if (cache[projectTaskId]) {
        return cache[projectTaskId];
      }
    }
    catch (err) {
      trackClient.warn(`Unable to get list of tasks, executing fallback`, err);
    }

    const json = await this.get(sourceItemUrl);
    cache[projectTaskId] = this.parseProjectTaskProgress(json, statusDate, timeZoneBias);
    return cache[projectTaskId];
  }

  public static parseDate(date: string, timezoneBias: number) {
    const value = date && new Date(date);
    const timeZoneAwareValue = value && new Date(value.getTime() - (timezoneBias - value.getTimezoneOffset()) * 60000);
    return timeZoneAwareValue;
  }

  public static parseProjectTaskProgress(json: any, statusDate: Date, timeZoneBias: number): Partial<IVistoListItemWithProgress> {
    const name = json.Name;
    const startDate = this.parseDate(json.Start, timeZoneBias);
    const endDate = this.parseDate(json.Finish, timeZoneBias);
    const percentComplete = json.PercentComplete;
    const plannedPercentComplete = ProgressService.getPlannedPercentComplete(startDate, endDate, statusDate);

    return {
      name,
      startDate,
      endDate,
      percentComplete,
      plannedPercentComplete
    };
  }

  public static parseProjectProgress(json: any, statusDate: Date, timeZoneBias: number): Partial<IVistoListItemWithProgress> {

    const name = json.Name;
    const startDate = this.parseDate(json.StartDate, timeZoneBias);
    const endDate = this.parseDate(json.FinishDate, timeZoneBias);
    const percentComplete = json.PercentComplete;
    const plannedPercentComplete = ProgressService.getPlannedPercentComplete(startDate, endDate, statusDate);

    return {
      name,
      startDate,
      endDate,
      percentComplete,
      plannedPercentComplete
    };
  }

  public static async getItemProgress(item: IVistoListItemWithProgress, statusDate: Date, cache: any): Promise<Partial<IVistoListItemWithProgress>> {

    const progress = this.isProjectTaskLink(item.sourceItemUrl)
      ? this.getProjectTaskProgress(item.sourceItemUrl, statusDate, cache)
      : this.getProjectProgress(item.sourceItemUrl, statusDate, cache);

    return progress;
  }

  public static isProjectLink(url) {
    return !!ProjectDataService.getProjectId(url);
  }

  public static getProjectId(url: string) {
    const matches = url?.match(/Projects\(\'([^']+)\'\)/i);
    return matches && matches[1] || '';
  }

  public static isProjectTaskLink(url: string) {
    return !!ProjectDataService.getProjectTaskId(url);
  }

  public static getProjectTaskId(url: string) {
    const matches = url?.match(/Tasks\(\'([^']+)\'\)/i);
    return matches && matches[1] || '';
  }

  public static async get(url: string): Promise<any> {
    const response = await AuthService.fetch(api.TokenKind.project, url);
    if (response.ok) {
      return await parseResponseJSON(response);
    } else {
      const error = await parseResponseError(response);
      trackClient.error(`Failed to get data from ${url}`, error);
      throw error;
    }
  }

  public static async getTimeZoneBias(pwaUrl: string): Promise<number> {
    if (TextService.isValidNumber(this.timezoneBiasSet[pwaUrl])) {
      return this.timezoneBiasSet[pwaUrl];
    } else if (pwaUrl) {
      try {
        const tz: { Information: { Bias: number, DaylightBias: number } } = await this.get(`${pwaUrl}/_api/web/regionalSettings/TimeZone`);
        this.timezoneBiasSet[pwaUrl] = tz.Information.Bias + tz.Information.DaylightBias;
        return this.timezoneBiasSet[pwaUrl];
      } catch (err) {
        this.timezoneBiasSet[pwaUrl] = 0;
        trackClient.error(`Unable to get timezone for ${pwaUrl}, using zero`, err);
        return 0;
      }
    } else {
      return 0;
    }
  }

  public static async getProjects(pwaUrl: string): Promise<{ Name, Id, StartDate, FinishDate, PercentComplete }[]> {
    if (pwaUrl) {
      const json = await this.get(`${pwaUrl}/_api/ProjectServer/Projects?$select=Name,Id,StartDate,FinishDate,PercentComplete`);
      return json.value;
    }
  }

  public static async getProjectTasks(pwaUrl: string, projectId: string): Promise<{ Name, Id, Start, Finish, PercentComplete, OutlinePosition }[]> {
    if (pwaUrl && projectId) {
      const json = await this.get(`${pwaUrl}/_api/ProjectServer/Projects('${projectId}')/Tasks?$select=Name,Id,Start,Finish,PercentComplete,OutlinePosition`);
      return json.value;
    }
  }

}
