import { Dropdown } from '@fluentui/react';
import strings from 'VistoWebPartStrings';
import React from 'react';
import { PlanDataService } from 'services/PlanDataService';
import { TextService } from 'services/TextService';
import { IVistoPlan, VistoKind, VistoSoItem } from 'sp';

export const SoPicker = (props: {
  disabled?: boolean;
  label: string;
  plan: IVistoPlan;
  soGuid: string;
  setSoGuid: (soGuid: string) => void;
}) => {

  const allSos = PlanDataService.getItems<VistoSoItem>(props.plan.items, VistoKind.SO);
  const soOptions = allSos.map(so => ({ key: so.guid, text: so.name }));

  return (
    <Dropdown
      required
      disabled={props.disabled}
      label={TextService.format(strings.EditAssocDialog_StrategicObjectiveSelectorLabel)}
      selectedKey={props.soGuid}
      onChange={(_, val) => props.setSoGuid('' + val.key)}
      options={soOptions}
    />
  );
}
