
import { mxglobals } from '../drawing/common';
import { mxgraph } from 'ts-mxgraph-typings';

export class SelectionState {

  public static get = (graph: mxgraph.mxGraph, cells: mxgraph.mxCell[]) => {

    var result = SelectionState.initSelectionState();

    for (var i = 0; i < cells?.length ?? 0; i++) {
      SelectionState.updateSelectionStateForCell(graph, result, cells[i]);
    }

    return result;
  }

  private static initSelectionState() {
    return {
      vertices: [], edges: [], x: null, y: null, width: null, height: null, style: {},
      containsImage: false, containsLabel: false, fill: true, glass: true, rounded: true,
      comic: true, autoSize: false, image: true, shadow: true, lineJumps: true
    };
  }

  /**
   * Returns information about the current selection.
   */
  private static updateSelectionStateForCell(graph: mxgraph.mxGraph, result, cell) {

    if (graph.getModel().isVertex(cell)) {
      result.vertices.push(cell);
      var geo = graph.getCellGeometry(cell);

      if (geo != null) {
        if (geo.width > 0) {
          if (result.width == null) {
            result.width = geo.width;
          }
          else if (result.width != geo.width) {
            result.width = '';
          }
        }
        else {
          result.containsLabel = true;
        }

        if (geo.height > 0) {
          if (result.height == null) {
            result.height = geo.height;
          }
          else if (result.height != geo.height) {
            result.height = '';
          }
        }
        else {
          result.containsLabel = true;
        }

        if (!geo.relative || geo.offset != null) {
          var x = (geo.relative) ? geo.offset.x : geo.x;
          var y = (geo.relative) ? geo.offset.y : geo.y;

          if (result.x == null) {
            result.x = x;
          }
          else if (result.x != x) {
            result.x = '';
          }

          if (result.y == null) {
            result.y = y;
          }
          else if (result.y != y) {
            result.y = '';
          }
        }
      }
    }
    else if (graph.getModel().isEdge(cell)) {
      result.edges.push(cell);
    }

    var state = graph.view.getState(cell);

    if (state != null) {
      result.image = result.image && SelectionState.isImageState(state);
      result.fill = result.fill && SelectionState.isFillState(state);

      var shape = mxglobals.mxUtils.getValue(state.style, mxglobals.mxConstants.STYLE_SHAPE, null);
      result.containsImage = result.containsImage || shape == 'image';

      for (var key in state.style) {
        var value = state.style[key];

        if (value != null) {
          if (result.style[key] == null) {
            result.style[key] = value;
          }
          else if (result.style[key] != value) {
            result.style[key] = '';
          }
        }
      }
    }
  }

  private static isFillState(state) {
    return state.view.graph.model.isVertex(state.cell) ||
      mxglobals.mxUtils.getValue(state.style, mxglobals.mxConstants.STYLE_SHAPE, null) == 'arrow' ||
      mxglobals.mxUtils.getValue(state.style, mxglobals.mxConstants.STYLE_SHAPE, null) == 'filledEdge' ||
      mxglobals.mxUtils.getValue(state.style, mxglobals.mxConstants.STYLE_SHAPE, null) == 'flexArrow';
  }

  private static isImageState(state) {
    var shape = mxglobals.mxUtils.getValue(state.style, mxglobals.mxConstants.STYLE_SHAPE, null);

    return (shape == 'label' || shape == 'image');
  }

}
