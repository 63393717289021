import { mxglobals } from 'frames/TopFrame/drawing/common';
import Mustache from 'mustache';
import { TextPlanJson } from 'shared/PlanGenerationModel';
import { IPlanGenerationUpdates as IPlanGenerationChanges, IPlanGneerationDrawingParams } from './PlanGenerationInterfaces';
import { VistoKind } from 'sp';
import { TextService } from './TextService';
import { makeGuidString } from 'shared/guid';

const defaultDrawingTemplate = require('mxresources/styles/generate/generate-plan-drawing.txt') as string;

const lopTemplate = `
<mxCell id='{{{lopGuid}}}' value='{{{lopName}}}' style='{{{lopStyle}}}' parent='1' target='FOCUS' edge='1'>
  <mxGeometry x='-1' width='1000' height='45' relative='1' as='geometry'>
    <mxPoint x='{{x0}}' y='{{y}}' as='sourcePoint' />
    <mxPoint x='{{x2}}' y='{{y}}' as='targetPoint' />
    <Array as='points'>
      <mxPoint x='{{x1}}' y='{{y}}' />
    </Array>
    <mxPoint as='offset' />
  </mxGeometry>
</mxCell>
`;

const dpTemplate = `
<mxCell id='{{{dpGuid}}}' value='{{{dpName}}}' style='{{{dpStyle}}}' parent='1' vertex='1'>
  <mxGeometry x='{{x}}' y='{{y}}' width='{{width}}' height='{{height}}' as='geometry' />
</mxCell>
`

export class PlanDrawingGenerationService {

  public static defaultDrawingParams: IPlanGneerationDrawingParams = {
    pageWidth: 1354,
    pageHeight: 825,
  
    top: 90,
    bottom: 825,
  
    itemWidth: 110,
    itemHeight: 60,
  
    x0: 60,
    x1: 1354 - 110 * 4,
    x2: 1354 - 110 * 3,
  
    defaultLopStyle: 'LOP',
    defaultDpStyle: 'DP',
  }

  private static getIdealPosition(min: number, max: number, index: number, count: number): number {
    // generate postion for element using space-around 
    const space = (max - min) / (count + 1);
    return min + space * (index + 1);
  }

  private static getLopNodes(el: Element) {
    const nodeList = el.querySelectorAll(`mxCell[style^='LOP']`);
    const result: { position: { y: number }, node: Element }[] = [];
    for (let i = 0; i < nodeList.length; i++) {
      const node = nodeList[i];
      const position = this.getLopPosition(node);
      result.push({ position, node });
    }
    result.sort((a, b) => a.position.y - b.position.y);
    return result.map(n => n.node);
  }

  private static getDpNodes(el: Element) {
    const nodeList = el.querySelectorAll(`mxCell[style^='DP']`);
    const result: { position: { x: number, y: number }, node: Element} [] = [];
    for (let i = 0; i < nodeList.length; i++) {
      const node = nodeList[i];
      const position = this.getDpPosition(node);
      result.push({ position, node });
    }
    result.sort((a, b) => {
      return (a.position.y * 10000 + a.position.x) - (b.position.y * 10000 + b.position.x);
    });
    return result.map(n => n.node);
  }

  private static getDefaultDrawingParams(
    documentElement: Element, 
    lopNodes: Element[],
    dpNodes: Element[],
    params: IPlanGneerationDrawingParams
  ) {

    const pw = parseFloat(documentElement.getAttribute('pageWidth'));
    if (pw) {
      params.pageWidth = pw;
      params.x0 = 60;
      params.x1 = pw - params.itemWidth * 4;
      params.x2 = pw - params.itemWidth * 3;
    }

    var ph = parseFloat(documentElement.getAttribute('pageHeight'));
    if (ph) {
      params.pageHeight = ph;
      params.top = 90;
      params.bottom = ph;
    }

    if (lopNodes.length > 0) {
      let x0 = 0;
      let x1 = 0;
      let x2 = 0;
      let defaultLopStyle = '';

      for (let i = 0; i < lopNodes.length; i++) {
        const lopNode = lopNodes[i];
        const position = this.getLopPosition(lopNode);
        x0 += position.x0;
        x1 += position.x1;
        x2 += position.x2;
        const lopStyle = lopNode.getAttribute('style');
        if (!defaultLopStyle) {
          defaultLopStyle = lopStyle;
        } else if (lopStyle !== defaultLopStyle) {
          defaultLopStyle = 'LOP';
        }
      }
      params.x0 = x0 / lopNodes.length;
      params.x1 = x1 / lopNodes.length;
      params.x2 = x2 / lopNodes.length
      params.defaultLopStyle = defaultLopStyle;
    }

    if (dpNodes.length > 0) {
      let itemWidth = 0;
      let itemHeight = 0;
      let defaultDpStyle = '';

      for (let i = 0; i < dpNodes.length; i++) {
        const dpNode = dpNodes[i];
        const position = this.getDpPosition(dpNode);
        itemWidth += position.width;
        itemHeight += position.height;

        const dpStyle = dpNode.getAttribute('style');
        if (!defaultDpStyle) {
          defaultDpStyle = dpStyle;
        } else if (dpStyle !== defaultDpStyle) {
          defaultDpStyle = 'DP';
        }
      }
      params.itemWidth = itemWidth / dpNodes.length;
      params.itemHeight = itemHeight / dpNodes.length;
      params.defaultDpStyle = defaultDpStyle;
    }

  }

  private static removeElements(root: Element, guids: string[]) {
    const allCells = root.querySelectorAll('mxCell');
    for (let i = allCells.length - 1; i >= 0; i--) {
      const cell = allCells[i];
      const value = cell.getAttribute('id');
      if (value && guids.indexOf(value) >= 0) {
        cell.remove();
      }
    }
  }

  private static getLopPosition(lopNode: Element): { y: number, x0: number, x1: number, x2: number } {
    const points = lopNode.querySelectorAll('mxPoint');
    const nodeX0 = points[0];
    const nodeX2 = points[1];
    const nodeX1 = points[2];

    return {
      y: parseFloat(nodeX0.getAttribute('y')),
      x0: parseFloat(nodeX0.getAttribute('x')),
      x1: parseFloat(nodeX1.getAttribute('x')),
      x2: parseFloat(nodeX2.getAttribute('x')),
    }
  }

  private static setLopPosition(lopNode: Element, position: { y: number, x0: number, x1: number, x2: number }) {

    const points = lopNode.querySelectorAll('mxPoint');

    const nodeX0 = points[0];
    nodeX0.setAttribute('x', position.x0.toString());
    nodeX0.setAttribute('y', position.y.toString());

    const nodeX2 = points[1];
    nodeX2.setAttribute('x', position.x2.toString());
    nodeX2.setAttribute('y', position.y.toString());

    const nodeX1 = points[2];
    nodeX1.setAttribute('x', position.x1.toString());
    nodeX1.setAttribute('y', position.y.toString());
  }

  private static updateLopList(root: Element, 
    nodeList: Element[],
    lopMap: { [lopGuid: string]: Element[] }, drawingParams: IPlanGneerationDrawingParams, updates: IPlanGenerationChanges) {
    // copy style from the first capability
    // ....

    const lopNodes: Element[] = [];
    for (let i = 0; i < nodeList.length; i++) {
      const lopNode = nodeList[i];
      lopNodes.push(lopNode);
    }

    if (updates.deletedCapabilities.length + updates.addedCapabilities.length > 0) {

      for (const capability of updates.deletedCapabilities) {
        this.removeElements(root, [capability.guid]);
        const foundIndex = lopNodes.findIndex(n => n.getAttribute('id') === capability.guid);
        if (foundIndex >= 0) {
          const lopNode = lopNodes[foundIndex];
          this.updateDpList(root, lopNode, lopMap, drawingParams, updates);
          lopNodes.splice(foundIndex, 1);
        }
      }

      for (const capability of updates.addedCapabilities) {
        const lopXml = Mustache.render(lopTemplate, {
          lopName: TextService.escapeXml(capability.name),
          lopGuid: capability.guid,
          lopStyle: TextService.escapeXml(drawingParams.defaultLopStyle),
          x0: drawingParams.x0,
          x1: drawingParams.x1,
          x2: drawingParams.x2,
        });

        root.insertAdjacentHTML('beforeend', lopXml);
        lopNodes.push(root.lastElementChild);
      }

      for (let i = 0; i < lopNodes.length; i++) {
        const lopNode = lopNodes[i];
        const lopGuid = lopNode.getAttribute('id');
        const position = this.getLopPosition(lopNode);
        const dpList = lopMap[lopGuid] || [];
        position.y = this.getIdealPosition(drawingParams.top, drawingParams.bottom, i, lopNodes.length);
        this.setLopPosition(lopNode, position);
        for (let j = 0; j < dpList.length; j++) {
          const dpNode = dpList[j];
          const dpPosition = this.getDpPosition(dpNode);
          dpPosition.y = position.y - (dpPosition.height / 2);
          this.setDpPosition(dpNode, dpPosition);
        }
      }
    }

    return lopNodes;
  }

  private static getDpPosition(dp: Element) {
    const geo = dp.querySelector('mxGeometry');
    return {
      x: parseFloat(geo.getAttribute('x')),
      y: parseFloat(geo.getAttribute('y')),
      width: parseFloat(geo.getAttribute('width')),
      height: parseFloat(geo.getAttribute('height')),
    }
  }

  private static setDpPosition(dp: Element, position: { x: number, y: number, width?: number, height?: number }) {
    const geo = dp.querySelector('mxGeometry');
    geo.setAttribute('x', position.x.toString());
    geo.setAttribute('y', position.y.toString());
    if (typeof position.width !== 'undefined') {
      geo.setAttribute('width', position.width.toString());
    }
    if (typeof position.height !== 'undefined') {
      geo.setAttribute('height', position.height.toString());
    }
  }

  private static getLopDpList(dpNodes: Element[], lop: Element) {
    const { x0, x1, x2, y: y0 } = this.getLopPosition(lop);

    const result: Element[] = [];
    for (let i = 0; i < dpNodes.length; i++) {
      const node = dpNodes[i];
      const { x, y, width, height } = this.getDpPosition(node);
      if (x0 - (width / 2) <= x && x <= x1 + (width / 2) && y0 - (height / 2) <= y && y <= y0 + (height / 2)) {
        result.push(node);
      }
    }
    return result;
  }

  private static updateDpList(root: Element, lopNode: Element, lopMap: { [lopGuid: string]: Element[] }, drawingParams: IPlanGneerationDrawingParams, updates: IPlanGenerationChanges) {

    const lopGuid = lopNode.getAttribute('id');
    const dpList = lopMap[lopGuid] || [];
    
    const addedAmbitions = [...updates.addedAmbitions.filter(a => a.lopGuid === lopGuid), ...updates.changedAmbitions.filter(a => a.changes.newValues.lopGuid === lopGuid).map(a => a.item)];
    const deletedAmbitions = [...updates.deletedAmbitions.filter(a => a.lopGuid === lopGuid), ...updates.changedAmbitions.filter(a => a.changes.oldValues.lopGuid === lopGuid).map(a => a.item)];

    if (addedAmbitions.length + deletedAmbitions.length > 0) {

      for (const ambition of deletedAmbitions) {
        this.removeElements(root, [ambition.guid]);
        const dpList = lopMap[lopGuid] || [];
        const foundIndex = dpList.findIndex(n => n.getAttribute('id') === ambition.guid);
        if (foundIndex >= 0) {
          dpList.splice(foundIndex, 1);
        }
      }

      for (const ambition of addedAmbitions) {
        const dpXml = Mustache.render(dpTemplate, {
          dpName: TextService.escapeXml(ambition.name),
          dpGuid: ambition.guid,
          dpStyle: TextService.escapeXml(drawingParams.defaultDpStyle),
          lopGuid: lopGuid,
          y: 0,
          x: 0,
          width: drawingParams.itemWidth,
          height: drawingParams.itemHeight,
        });
        root.insertAdjacentHTML('beforeend', dpXml);
        dpList.push(root.lastElementChild);
      }

      const lopPosition = this.getLopPosition(lopNode);
      for (let i = 0; i < dpList.length; i++) {
        const dpNode = dpList[i];
        const position = this.getDpPosition(dpNode);
        position.x = this.getIdealPosition(lopPosition.x0, lopPosition.x1, i, dpList.length) - (position.width / 2),
          position.y = lopPosition.y - (position.height / 2),
          this.setDpPosition(dpNode, position);
      }
    }

    return dpList;
  }

  public static getEmptyDrawingXml(input: TextPlanJson, drawingParams: IPlanGneerationDrawingParams) {
    const params = {
      planName: TextService.escapeXml(input.planName),
      positionName: TextService.escapeXml(input.positionName),

      pageWidth: drawingParams.pageWidth,
      pageHeight: drawingParams.pageHeight,

      focusName: TextService.escapeXml(input.focusName),
    }

    const drawingXml = Mustache.render(defaultDrawingTemplate, params);
    return drawingXml;
  }

  public static generatePlanDrawingXml(drawingXml: string, changes: IPlanGenerationChanges) {
    
    const drawingParams = { ...this.defaultDrawingParams };

    const doc: Document = mxglobals.mxUtils.parseXml(drawingXml);
    const root = doc.documentElement.firstElementChild as Element;

    const lopNodes = this.getLopNodes(root);
    const dpNodes = this.getDpNodes(root);

    this.getDefaultDrawingParams(doc.documentElement, lopNodes, dpNodes, drawingParams);

    const lopMap: { [lopGuid: string]: Element[] } = {};
    
    for (let i = 0; i < lopNodes.length; i++) {
      const lopNode = lopNodes[i];
      const lopGuid = lopNode.getAttribute('id');
      const lopDpNodes = this.getLopDpList(dpNodes, lopNode);
      lopMap[lopGuid] = lopDpNodes;
    }

    const lopList = this.updateLopList(root, lopNodes, lopMap, drawingParams, changes);

    for (let i = 0; i < lopList.length; i++) {
      this.updateDpList(root, lopList[i], lopMap, drawingParams, changes);
    }

    return doc.documentElement.outerHTML;
  }

}
