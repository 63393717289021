import { Text, Stack, Link } from '@fluentui/react';
import * as React from 'react';
import { TextService } from 'services/TextService';
import strings from 'VistoWebPartStrings';

export const ExportLinkPage = (props: {
  link: string;
  fileName: string;
}) => {

  return (
    <Stack tokens={{ childrenGap: 'm' }}>
      <Text variant='large'>{TextService.format(strings.ExportLinkPage_Text)}</Text>
      <Link href={props.link} download={props.fileName} target='_blank'>{props.fileName}</Link>
    </Stack>
  );
};
