import { trackClient } from 'shared/clientTelemetry';

export const setIsLoading = (msg: string) => {
  if (msg) {
    trackClient.debug(msg);
  }

  const indicator = document.querySelector('.visplanLoadingIndicator') as HTMLDivElement;
  if (indicator) {
    indicator.style.display = msg ? 'inline-block' : 'none';
    const div = indicator.firstChild;
    if (div) {
      div.textContent = msg;
    }
  }
};
