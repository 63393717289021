import { Dropdown } from '@fluentui/react';
import strings from 'VistoWebPartStrings';
import React from 'react';
import { PlanDataService } from 'services/PlanDataService';
import { TextService } from 'services/TextService';
import { IVistoPlan, VistoFocusItem, VistoKind } from 'sp';
import { NULL_KEY } from 'shared/parse';

export const FocusPicker = (props: {
  label: string;
  plan: IVistoPlan;
  focusGuid: string;
  setFocusGuid: (focusGuid: string) => void;
  disabled?: boolean;
}) => {

  const activeFocus = PlanDataService.getActiveFocus(props.plan);

  const allFocuses = PlanDataService.getItems<VistoFocusItem>(props.plan.items, VistoKind.Focus);
  const focusOptions = allFocuses.map(focus => {
    return {
      key: focus.guid,
      text: focus.name + (activeFocus?.guid === focus?.guid ? ' ★' : '')
    };
  });

  focusOptions.unshift({
    key: NULL_KEY,
    text: TextService.format(strings.UnassignedItem)
  });

  return (
    <Dropdown
      disabled={props.disabled}
      label={props.label}
      selectedKey={props.focusGuid ?? NULL_KEY}
      onChange={(_, val) => props.setFocusGuid(val.key === NULL_KEY ? null : '' + val.key)}
      options={focusOptions}
    />
  );
}
