import { SPFI } from '@pnp/sp/fi';
import { AuthService } from 'services/AuthService';
import { IExecutableAction } from 'services/IExecutableAction';
import { UrlService } from 'shared/urlService';
import { StorageCacheService } from 'services/StorageCacheService';
import { IChoiceFieldDefinition, IVistoPlan, ListItemUpdater, VistoKeyResultValueListFields, VistoKind } from 'sp';

export const makeMigrateFixEnumTranslation = (foundPlan: IVistoPlan): IExecutableAction => ({
  title: `Fix KR Enum List`,
  execute: async (p) => {

    const sp = AuthService.getSpClient(foundPlan.siteUrl);
    
    const timeZone = await sp.web.regionalSettings.timeZone();
    const timeZoneBias = timeZone.Information.Bias + timeZone.Information.DaylightBias;

    const updater = new ListItemUpdater(timeZoneBias);

    const choices = (VistoKeyResultValueListFields.valueKind as IChoiceFieldDefinition).choices;
    const krvListUrl = UrlService.getListRelativeUrl(foundPlan, VistoKind.KRV);

    const krvItems = await sp.web.getList(krvListUrl).items.top(2000)();
    let batchCount = 0;
    let batch: SPFI;
    let execute: () => Promise<void>;
    for (const krvitem of krvItems) {
      const itemId = krvitem[VistoKeyResultValueListFields.itemId.name];

      const oldValue = updater.parseChoice(krvitem[VistoKeyResultValueListFields.valueKind.name], choices);
      const newValue = updater.unparseChoice(oldValue, choices);
      if (oldValue !== newValue) {
        if (!batchCount) {
          [batch, execute] = sp.batched()
        }
        batch.web.getList(krvListUrl).items.getById(itemId).update({ [VistoKeyResultValueListFields.valueKind.name]: newValue });
        ++batchCount;
      }

      if (batchCount === 20) {
        await execute();
        batchCount = 0;
      }
    }

    if (batchCount > 0) {
      await execute();
    }

    StorageCacheService.resetCache();

    return p;
  }
});
