import { mx } from 'frames/TopFrame/drawing/common';
import { ChangesService } from 'services/ChangesService';
import { PlanDataService } from 'services/PlanDataService';
import { PlanSettingsService } from 'services/PlanSettingsService';
import { TextService } from 'services/TextService';
import { CellKind } from 'shared/CellKind';
import { parseJSON } from 'shared/parse';
import { IFieldValueUser, IVistoListItemWithAssignee, IVistoPlan, VistoAssocItem, VistoDpItem, VistoKeyResultItem, VistoKind, VistoLopItem } from 'sp';
import { mxgraph } from 'ts-mxgraph-typings';
import strings from 'VistoWebPartStrings';
import { IVisualFilter } from './IVisualFilter';

export class VisualFilterService {

  public static getAssigneeKey = (a: IFieldValueUser) => a.userName || a.guid;

  private static ensureFilterValid(p: IVistoPlan, filter: IVisualFilter) {
    if (filter?.focusGuids?.length) {
      for (const focusGuid of filter.focusGuids) {
        if (!PlanDataService.getItemByGuid<VistoDpItem>(p.items, focusGuid)) {
          filter.focusGuids = filter.focusGuids.filter(guid => guid !== focusGuid);
        }
      }
    }

    if (filter?.krGuids?.length) {
      for (const krGuid of filter.krGuids) {
        if (!PlanDataService.getItemByGuid<VistoKeyResultItem>(p.items, krGuid)) {
          filter.krGuids = filter.krGuids.filter(guid => guid !== krGuid);
        }
      }
    }

    if (filter?.lopGuids?.length) {
      for (const lopGuid of filter.lopGuids) {
        if (!PlanDataService.getItemByGuid<VistoLopItem>(p.items, lopGuid)) {
          filter.lopGuids = filter.lopGuids.filter(lop => lop !== lopGuid);
        }
      }
    }

    if (filter?.assignees?.length) {
      const assigneeSet = this.getAssigneeSet(p);
      for (const assignee of filter.assignees) {
        const assigneeKey = VisualFilterService.getAssigneeKey(assignee);
        if (!assigneeSet[assigneeKey]) {
          filter.assignees = filter.assignees.filter(a => VisualFilterService.getAssigneeKey(a) !== assigneeKey);
        }
      }
    }
}

  private static lastCheckedPlanRevision = 0;
  public static getSavedFilter(p: IVistoPlan): IVisualFilter {

    const visualFilterKey = `PlanView_VisualFilter_${p.planId}`
    const result: IVisualFilter = parseJSON(localStorage.getItem(visualFilterKey), { 
      assignees: [], 
      lopGuids: [],
      krGuids: [],
      focusGuids: []
    });

    if (p.revision != this.lastCheckedPlanRevision) {
      this.ensureFilterValid(p, result);
      this.lastCheckedPlanRevision = p.revision;
    }

    return result;
  }

  public static saveFilter(p: IVistoPlan, filter: IVisualFilter) {
    const visualFilterKey = `PlanView_VisualFilter_${p.planId}`
    localStorage.setItem(visualFilterKey, JSON.stringify(filter));
  }

  private static hasActionsInFocus(plan: IVistoPlan, dp: VistoDpItem, focusGuids: string[]) {
    const actions = PlanDataService.getDpActions(plan, dp.guid);
    return actions.length && actions.some(action => 
      focusGuids.includes(action.focusGuid)
    );
  }

  private static hasIntersection(item: IVistoListItemWithAssignee, assignees: IFieldValueUser[]) {
    return item.assignedTo?.length > 0 &&  item.assignedTo.some(a => assignees.some(b => ChangesService.isSameUser(a, b)));
  }

  private static hasAssignees(plan: IVistoPlan, item: IVistoListItemWithAssignee, assignees: IFieldValueUser[]) {
    if (this.hasIntersection(item, assignees)) {
      return true;
    }
    const actions = PlanDataService.getDpActions(plan, item.guid);
    return actions.length && actions.some(action =>
      this.hasIntersection(action, assignees)
    );
  }

  private static hasKr(plan: IVistoPlan, dp: VistoDpItem, krGuids: string[]) {

    const assocs = PlanDataService.getItemsHaving(plan.items, (assoc: VistoAssocItem) => {
      return assoc.kind === VistoKind.Assoc && krGuids.includes(assoc.krGuid);
    });

    const actions = PlanDataService.getDpActions(plan, dp.guid);
    return actions.length && actions.some(action => 
      assocs.some(x => x.actionGuid === action.guid)
    );
  }

  private static hasLOP(plan: IVistoPlan, dp: VistoDpItem, lopGuids: string[]) {
    return lopGuids.includes(dp.lopGuid);
  }

  public static isDpVisible(plan: IVistoPlan, dpGuid: string, filter: IVisualFilter) {
    const item = PlanDataService.getItemByGuid<VistoDpItem>(plan.items, dpGuid);

    if (item) {
      if (filter.focusGuids?.length > 0 && !VisualFilterService.hasActionsInFocus(plan, item, filter.focusGuids)) {
        return false;
      }

      if (filter.assignees?.length > 0 && !VisualFilterService.hasAssignees(plan, item, filter.assignees)) {
        return false;
      }

      if (filter.krGuids?.length > 0 && !VisualFilterService.hasKr(plan, item, filter.krGuids)) {
        return false;
      }

      if (filter.lopGuids?.length > 0 && !VisualFilterService.hasLOP(plan, item, filter.lopGuids)) {
        return false;
      }
    }

    return true;
  }

  public static formatFilter(plan: IVistoPlan, filter: IVisualFilter) {
    const result = [];
    if (filter.focusGuids) {
      result.push(filter.focusGuids.map(focusGuid => TextService.formatTitle(PlanDataService.getItemByGuid(plan.items, focusGuid), plan.items)).join(', '));
    }
    if (filter.krGuids?.length > 0) {
      result.push(filter.krGuids.map(krGuid => TextService.formatTitle(PlanDataService.getItemByGuid(plan.items, krGuid), plan.items)).join(', '));
    } 
    if (filter.lopGuids?.length > 0) {
      result.push(filter.lopGuids.map(lopGuid => TextService.formatTitle(PlanDataService.getItemByGuid(plan.items, lopGuid), plan.items)).join(', '));
    }
    if (filter.assignees?.length > 0) {
      result.push(TextService.format(strings.VisualFilter_AssignedTo, { userList: filter.assignees.map(user => user.title).join(', ') }));
    }
    return result;
  }

  public static isFilterEmpty(filter: IVisualFilter) {
    return !filter.assignees?.length && !filter.focusGuids?.length && !filter.krGuids?.length && !filter.lopGuids?.length;
  }

  public static isLopVisible(plan: IVistoPlan, lopGuid: string, filter: IVisualFilter) {
    const lop = PlanDataService.getItemByGuid<VistoLopItem>(plan.items, lopGuid);
    const dps = PlanDataService.getLopDps(plan, lopGuid);
    return this.isFilterEmpty(filter) || lop && (dps.length && dps.every(dp => this.isDpVisible(plan, dp.guid, filter)) || 
      filter.assignees?.length > 0 && this.hasIntersection(lop, filter.assignees))
  }

  public static isLopTextVisible(plan: IVistoPlan, lopGuid: string, filter: IVisualFilter) {
    const lop = PlanDataService.getItemByGuid<VistoLopItem>(plan.items, lopGuid);
    const dps = PlanDataService.getLopDps(plan, lopGuid);
    return this.isFilterEmpty(filter) || lop && (dps.length && dps.some(dp => this.isDpVisible(plan, dp.guid, filter)) || 
      filter.assignees?.length > 0 && this.hasIntersection(lop, filter.assignees))
  }

  public static apply(graph: mxgraph.mxGraph, plan: IVistoPlan, filter: IVisualFilter): void {

    const settings = PlanSettingsService.getPlanSettings(plan);
    const percentOpacity = typeof (settings.percentOpacity) === 'number' ? settings.percentOpacity : 40;

    graph.getModel().beginUpdate();
    try {
      const cells = mx.getAllCells(graph);

      for (const cell of cells) {
        const cellGuid = mx.getCellGuid(cell);
        const cellKind = mx.getCellKind(cell);
        switch (cellKind) {
          case CellKind.DP: {
            const dpVisible = this.isDpVisible(plan, cellGuid, filter);
            graph.setCellStyles('opacity', dpVisible ? null : percentOpacity , [cell]);
            graph.setCellStyles('textOpacity', dpVisible ? null : percentOpacity, [cell]);
            break;
          }
          case CellKind.LOP: {
            const lopVisible = this.isLopVisible(plan, cellGuid, filter);
            graph.setCellStyles('opacity', lopVisible ? null : percentOpacity, [cell]);
            const lopTextVisible = this.isLopTextVisible(plan, cellGuid, filter);
            graph.setCellStyles('textOpacity', lopTextVisible ? null : percentOpacity, [cell]);
            break;
          }
        }
      }
    }
    finally {
      graph.getModel().endUpdate();
    }
  }

  public static getAssigneeSet(plan: IVistoPlan) {
    const resultSet = {};
    for (const guid in plan.items) {
      const item = plan.items[guid] as IVistoListItemWithAssignee;
      if ([VistoKind.Action, VistoKind.DP, VistoKind.LOP].includes(item.kind) && item.assignedTo) {
        for (const assignee of item.assignedTo) {
          resultSet[assignee.userName || assignee.guid] = assignee;
        }
      }
    }
    return resultSet;
  }
}
