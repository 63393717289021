import * as strings from 'VistoWebPartStrings';
import { PlanDataService } from 'services/PlanDataService';
import { IVistoPlan } from 'sp';
import { mxgraph } from 'ts-mxgraph-typings';
import { mx } from 'frames/TopFrame/drawing/common';

export const getDeleteInfo = (plan: IVistoPlan, graph: mxgraph.mxGraph) => {

  // Cancels interactive operations
  graph.escape(null);
  const cells = graph.getDeletableCells(graph.getSelectionCells());
  const allCells = mx.getAllCells(graph);

  const items = [];
  for (const cell of cells) {
    const kind = mx.getCellKind(cell);
    if (kind) {
      const guid = mx.getCellGuid(cell);
      const item = PlanDataService.getItemByGuid(plan.items, guid);
      if (item) {
        const depItems = PlanDataService.getDependencis(plan.items, item);
        const depCells = allCells.filter(depCell => depItems.some(dep => dep.guid === mx.getCellGuid(depCell)));
        cells.push(...depCells);
        items.push(item, ...depItems);
      }
    }
  }

  return { cells, items };
};
