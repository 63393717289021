import { mxgraph } from 'ts-mxgraph-typings';
import { mxglobals } from 'frames/TopFrame/drawing/common';
import { IVistoPlan } from 'sp';
import { TextService } from 'services/TextService';

export class PrintService {

  public static printPlan(plan: IVistoPlan, graph: mxgraph.mxGraph) {

    const divMM = document.createElement('div');
    divMM.style.visibility = 'hidden';
    divMM.style.height = '190mm'; // 297mm - 10mm -10mm
    document.body.append(divMM);
    const scale = divMM.clientHeight / 827;
    divMM.remove();

    // const margin = 5 * 1169 / 297 * scale;
    // const ps = scale * (graph.pageFormat.width - margin*2) / graph.pageFormat.width;

    const preview = new mxglobals.mxPrintPreview(graph, scale);

    preview.title = TextService.makeExportFileName(plan.name, '');

    preview.pageSelector = false;
    preview.pageCount = 1;
    preview.autoOrigin = false;

    preview.x0 = 40;
    preview.y0 = 35;
    preview.printBackgroundImage = true;

    // preview.autoOrigin = true;
    let bg = graph['background'];
    if (bg == null || bg == '' || bg == mxglobals.mxConstants.NONE) {
      bg = '#ffffff';
    }
    preview.backgroundColor = bg;

    const writeHead = preview.writeHead;

    // Adds a border in the preview
    preview.writeHead = function (doc) {
      writeHead.apply(this, arguments);

      doc.writeln(`<style type='text/css'>`);
      doc.writeln(`
      @media print {
        @page {
          size: A4 landscape;
          margin: 0;
        }
      }
      html, body {
        padding: 0;
        margin: 0;
        width: 296mm;
        height: 209mm;
        overflow: hidden;
      }
      `);
      // doc.writeln('@media print { html, body { height:100%; overflow: visible; } }');
      // doc.writeln('@page { margin-top: 0.5in; margin-bottom: 0.5in; margin-left: 0.5in; margin-right: 0.5in; } }');
      // doc.writeln('@media screen { body > div { padding:30px; box-sizing:content-box; } }');
      doc.writeln(`</style>`);
    };

    preview.open();

    if (preview.wnd) {
      preview.wnd.focus();
      preview.wnd.print();
      preview.wnd.close();
    }
  }
}
