import * as React from 'react';
import { Panel, PanelType, IPanelStyles, useTheme } from '@fluentui/react';
import { SidebarTopMenu } from './SidebarTopMenu';
import { AppContext } from 'services/AppContext';
import { Advisory } from 'components/Advisory';
import { ISidebarProps } from './ISidebarProps';
import { EnvContext } from 'services/EnvContext';

export function SidebarBase(props: ISidebarProps) {

  const theme = useTheme();

  const { propertyBag, setPropertyBag } = React.useContext(AppContext);

  const { hostKind } = React.useContext(EnvContext);
  const isWeb = (hostKind === 'WebMobile' || hostKind === 'WebDesktop');
  const isMobileApp = hostKind === 'TeamsMobile';

  const sidebarStyles: Partial<IPanelStyles> = {
    main: {
      animation: 'none',
      marginTop: isWeb ? 98 : 50,
      borderTopLeftRadius: 4,
      '@media (min-width: 1920px)': { // use default on HD screens
        width: isMobileApp ? '380px' : '640px'
      },
      '@media (min-width: 1366px)': { // use default on HD screens
        width: isMobileApp ? '380px' : '640px'
      },
      '@media (min-width: 1024px)': { // reduce sidebar size on default laptop
        width: isMobileApp ? '380px' : '550px'
      },
      '@media (min-width: 640px)': { // reduce sidebar size on default laptop
        width: '380px'
      },
    },
    closeButton: {
      maxHeight: 40,
      backgroundColor: theme.palette.white,
      '&:hover': {
        backgroundColor: theme.palette.neutralLight,
      },
      '&:active': {
        backgroundColor: theme.palette.neutralPrimary
      }
    }
  };

  const divStyle = {
    borderTop: `1px solid ${theme.palette.neutralTertiary}`
  };

  return <Panel
    type={PanelType.medium}
    isBlocking={!!props.isBlocking}
    isOpen={props.isOpen}
    onDismiss={props.onDismiss}
    styles={sidebarStyles}
    focusTrapZoneProps={{ disabled: true }}
    layerProps={{ styles: { root: { zIndex: 999 } } }}
    onRenderNavigationContent={(panelProps, defaultRender) =>
      <SidebarTopMenu
        menuItems={props.menuItems}
        overflowMenuItems={props.overflowMenuItems}
        farItems={props.farItems}
        closeButton={defaultRender(panelProps)} />
    }
  >
    {props.content}
    {propertyBag.showAdvisory && props.advisoryTopicKey && <Advisory isSidebar style={divStyle} topic={props.advisoryTopicKey} />}
  </Panel>;
}
