import * as React from 'react';
import { Persona, PersonaSize, Stack, TooltipHost } from '@fluentui/react';

import { IFieldValueUser } from 'sp';
import { ColorService } from 'services/colorService';
import { useAssignees } from 'services/useAssignees';

export function AssigneeBlockBase(props: {
  assignedTo: IFieldValueUser[];
  hideDetails?: boolean;
}) {

  const assignees = useAssignees(props.assignedTo, [props.assignedTo]);

  return (
    <Stack horizontal wrap tokens={{ childrenGap: 'l1', padding: 'm' }}>
      {assignees.map(assignedTo =>
        <TooltipHost
          key={assignedTo.userName}
          styles={{ root: { margin: 0 } }}
          tooltipProps={{
            onRenderContent: () => {
              return (<Persona
                key={assignedTo.userName}
                initialsColor={ColorService.getUserColor(assignedTo.userName || assignedTo.guid)}
                imageUrl={assignedTo.imageUrl}
                imageShouldFadeIn={false}
                text={assignedTo.title}
                secondaryText={assignedTo.userName}
                hidePersonaDetails={false}
                size={PersonaSize.size48}
              />);
            }
          }}>
          <Persona
            key={assignedTo.userName}
            initialsColor={ColorService.getUserColor(assignedTo.userName || assignedTo.guid)}
            imageUrl={assignedTo.imageUrl}
            imageShouldFadeIn={false}
            text={assignedTo.title}
            size={PersonaSize.size24}
            hidePersonaDetails={props.hideDetails}
          />
        </TooltipHost>
      )
      }
    </Stack>
  );
}
