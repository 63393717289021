import React from 'react';
import { DefaultButton, FontSizes, Icon, Link, Stack, Image } from '@fluentui/react';
import { ICheckListItem } from 'services/ICheckListItem';
import { TextService } from 'services/TextService';
import strings from 'VistoWebPartStrings';

const SidebarCheckListItem = (props: {
  item: ICheckListItem;
}) => {

  const style = { textDecoration: props.item.checked ? 'line-through' : 'none' };
  const url = props.item.url;

  return (
    <Stack horizontal verticalAlign='baseline' tokens={{ childrenGap: 's2' }}>
      <Icon iconName={props.item.checked ? 'CheckMark' : 'RadioBullet'} ></Icon>
      {props.item.iconUrl && <Image width={12} height={12} src={props.item.iconUrl} />}
      {url
        ? <Link href={url} style={style} target='_blank'>{props.item.title}</Link>
        : <span style={style}>{props.item.title}</span>
      }
    </Stack>
  );
};


export const SidebarCheckLists = (props: {
  checkList: ICheckListItem[];
}) => {

  const [isExpanded, setExpanded] = React.useState(false);

  return props.checkList ? (
    <Stack horizontalAlign='start' tokens={{ childrenGap: 'm' }}>
      <DefaultButton
        iconProps={{ iconName: 'CheckList' }}
        styles={{ root: { fontSize: FontSizes.small, padding: '0 4px', height: 24 } }}
        disabled={props.checkList.length === 0}
        text={TextService.format(strings.SidebarCheckList_DoneTotal, { done: props.checkList?.filter(x => x.checked).length ?? 0, total: props.checkList?.length ?? 0 })}
        checked={isExpanded}
        onClick={() => setExpanded(!isExpanded)}
      />
      {isExpanded &&
        <Stack tokens={{ childrenGap: 's2' }}>
          {props.checkList.map(item => (<SidebarCheckListItem key={item.id} item={item} />))}
        </Stack>}
    </Stack>
  ) : null;
};
