import { ContextualMenu, IModalProps } from '@fluentui/react';
import { TextService } from 'services/TextService';
import * as strings from 'VistoWebPartStrings';

export const defaultModalProps: IModalProps = {
  isBlocking: true,
  dragOptions: {
    moveMenuItemText: TextService.format(strings.BasicDialog_MoveMenuItem),
    closeMenuItemText: TextService.format(strings.BasicDialog_CloseMenuItem),
    menu: ContextualMenu,
    keepInBounds: true,
  }
};
