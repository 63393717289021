import React from 'react';
import { DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton, Stack, TextField } from '@fluentui/react';
import { TextService } from 'services/TextService';
import strings from 'VistoWebPartStrings';

export const CustomZoomDialog = (props: {
  percents: number;
  onDismiss: (save: boolean, percents?: number) => void;
}) => {

  const [zoomValue, setZoomValue] = React.useState(props.percents);

  return (
    <Dialog
      hidden={false}
      dialogContentProps={{ title: TextService.format(strings.CustomZoomDialog_Title), type: DialogType.normal, }}
      onDismiss={() => props.onDismiss(false)}
    >
      <Stack>
        <TextField
          label={TextService.format(strings.CustomZoomDialog_FieldLabel)}
          type='number'
          min={5}
          max={1000}
          value={TextService.formatPercents(zoomValue)}
          onChange={(_, val) => setZoomValue(val !== '' && TextService.isValidNumber(+val) ? +val : null)}
        />
      </Stack>
      <DialogFooter>
        <PrimaryButton onClick={() => props.onDismiss(true, zoomValue)} text={TextService.format(strings.ButtonOK)} />
        <DefaultButton onClick={() => props.onDismiss(false)} text={TextService.format(strings.ButtonCancel)} />
      </DialogFooter>
    </Dialog>
  );
};
