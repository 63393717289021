import { ITokenProvider, ITokenResponse } from '@fluidframework/routerlicious-driver';

/**
 * Token Provider implementation for connecting to an Azure Function endpoint for
 * Azure Fluid Relay token resolution.
 */
export class FluidTokenProvider implements ITokenProvider {

  constructor(
    private readonly getToken: (tenantId: string, documentId: string, refresh: boolean) => Promise<ITokenResponse>
  ) { }

  public fetchOrdererToken(tenantId: string, documentId: string, refresh: boolean): Promise<ITokenResponse> {
    return this.getToken(tenantId, documentId, refresh);
  }

  public fetchStorageToken(tenantId: string, documentId: string, refresh: boolean): Promise<ITokenResponse> {
    return this.getToken(tenantId, documentId, refresh);
  }
}
