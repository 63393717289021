import { AuthService } from 'services/AuthService';
import { IExecutableAction } from 'services/IExecutableAction';
import { UrlService } from 'shared/urlService';
import { StorageCacheService } from 'services/StorageCacheService';
import { IVistoPlan } from 'sp';

export const makeMigrateRemoveFKR = (foundPlan: IVistoPlan): IExecutableAction => ({
  title: `Removing focus key results list`,
  execute: async (p) => {

    const sp = AuthService.getSpClient(foundPlan.siteUrl);

    const listName = `Visto_FocusKR_${foundPlan.planId}`
    const listRelativeUrl = UrlService.combine('Lists', listName);
    const siteRelativeUrl = UrlService.getPathName(foundPlan.siteUrl);
    const listUrl = UrlService.combine(siteRelativeUrl, listRelativeUrl);

    // await sp.web.getList(listUrl).recycle();

    StorageCacheService.resetCache();
    return p;
  }
});
