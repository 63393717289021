import stripJsonComments from 'strip-json-comments';
import { trackClient } from './clientTelemetry';

const isoDateRegex = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d+)?)(?:Z|(\+|-)([\d|:]*))?$/;

export const parseIsoDate = (key: string, value: any) => {
  if (key && key.indexOf('Date') >= 0 && typeof value === 'string' && isoDateRegex.test(value)) {
    return new Date(value);
  } else {
    return value;
  }
};

export const parseJSON = (text: string | null, defVal?: any) => {
  try {
    if (text)
      return JSON.parse(stripJsonComments(text), parseIsoDate);
    else
      return defVal;
  } catch (err) {
    if (defVal) {
      trackClient.error(`Error parsing JSON value: ${text}`, err);
      return defVal;
    }
    throw err;
  }
}

export const parseResponseError = async (response) => {
  let message: string = '<empty>';
  try {
    message = await response.text();
    return parseJSON(message);
  } catch (err) {
    const error_description = `Unexpected server response: ${message}. ${err}`;
    throw { error_description };
  }
};

export const parseResponseJSON = async (response) => {
  let message: string = '<empty>';
  try {
    message = await response.text();
    return parseJSON(message);
  } catch (err) {
    const error_description = `Unexpected server response: ${message}. ${err}`;
    throw { error_description };
  }
};

export async function getErrorMessage(e: any) {
  if (e && typeof e === 'object' && e.hasOwnProperty('isHttpRequestError')) {
    const data = await e.response.json();
    // parse this however you want
    if (typeof data['odata.error'] === 'object') {
      return data['odata.error'].message.value;
    } if (typeof data.error === 'object') {
      return data.error.message;
    } else {
      return stringifyError(e);
    }
  } else {
    return stringifyError(e);
  }
}

export const stringifyError = (err: any) => {
  if (typeof err === 'string')
    return err;
  if (typeof err === 'object') {
    if (typeof err.error === 'object') {
      return stringifyError(err.error);
    } else if (typeof err.response === 'object' && typeof err.response.toJSON === 'function') {
      return stringifyError(err.response.toJSON()?.body);
    } else {
      return err?.['odata.error']?.message?.value
        ?? err.error_description
        ?? err.error_message
        ?? err.message
        ?? err.error
        ?? JSON.stringify(err);
    }
  }
};

export function getObjectValues<T>(obj: { [key: string]: T }) {
  return obj ? Object.keys(obj).map(k => obj[k]) : [];
}

export function isConsentError(error) {
  return error.error === 'invalid_grant' ||
    error.error === 'insufficient_scope' ||
    error.errorCode === 'invalid_grant' ||
    error.errorCode === 'insufficient_scope' ||
    `${error.error?.code}`.indexOf('Authorization_RequestDenied') >= 0 ||
    `${error['odata.error']?.code}`.indexOf('ProjectServerUnauthorizedAccessException') >= 0;
}

export function unique<T>(array: T[], propertyName: keyof T) {
  return array.filter((e, i) => array.findIndex(a => a[propertyName] === e[propertyName]) === i);
}

const isObject = (item: any) => item && typeof item === 'object' && !Array.isArray(item);

/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 */
export function mergeDeep(target: any, ...sources: any[]) {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return mergeDeep(target, ...sources);
}

export const NULL_KEY = '##null##';
