
import { PlannerPlan, PlannerBucket, PlannerTask, PlannerPlanDetails, PlannerTaskDetails } from '@microsoft/microsoft-graph-types';
import { graphPost } from '@pnp/graph';
import '@pnp/graph/planner';
import '@pnp/graph/groups';
import '@pnp/graph/batching';
import { body } from '@pnp/queryable';
import { AuthService } from 'services/AuthService';
import { CachingType } from 'services/CachingType';
import { StorageCacheService } from 'services/StorageCacheService';
import { api } from 'shared/api';

const MAX_CHUNK_SIZE = 20;

export class PlannerService {

  private static getClient(caching?: CachingType) {
    return AuthService.getGraphClient(api.TokenKind.planner, '', caching);
  }

  public static resetCache() {
    StorageCacheService.resetCache('https://graph.microsoft.com/v1.0/planner');
  }

  /// plans

  public static async getPlans(groupId: string): Promise<PlannerPlan[]> {
    const client = this.getClient('short');
    const data = await client.groups.getById(groupId).plans();
    return data;
  }

  public static async getPlan(plannerPlanId: string): Promise<PlannerPlan> {
    const client = this.getClient('short');
    const data = await client.planner.plans.getById(plannerPlanId).expand('details')();
    return data;
  }

  public static async createPlan(plan: PlannerPlan): Promise<PlannerPlan> {
    const client = this.getClient();
    const result = await graphPost(client.planner.plans, body(plan));
    this.resetCache();
    return result;
  }

  public static async getPlanDetails(plannerPlanId: string): Promise<PlannerPlanDetails> {
    const client = this.getClient('short');
    const data = await client.planner.plans.getById(plannerPlanId).details();
    return data;
  }

  public static async updatePlanDetails(plannerPlanId: string, changes: Partial<PlannerPlanDetails>) {
    const client = this.getClient();
    const data = await client.planner.plans.getById(plannerPlanId).details.select('id')();
    await client.planner.plans.getById(plannerPlanId).details.update(changes, data['@odata.etag']);
    this.resetCache();
  }

  public static async updatePlan(plannerPlanId: string, changes: Partial<PlannerPlan>) {
    const client = this.getClient();
    const data = await client.planner.plans.getById(plannerPlanId).select('id')();
    await client.planner.plans.getById(plannerPlanId).update(changes, data['@odata.etag']);
    this.resetCache();
  }

  public static async deletePlan(plannerPlanId: string) {
    const client = this.getClient();
    const data = await client.planner.plans.getById(plannerPlanId).select('id')();
    await client.planner.plans.getById(plannerPlanId).delete(data['@odata.etag']);
    this.resetCache();
  }

  public static async deletePlans(ids: string[]) {
    for (const id of ids) {
      await this.deletePlan(id);
    }
  }

  /// buckets

  public static async getBuckets(plannerPlanId: string): Promise<PlannerBucket[]> {
    const client = this.getClient('short');
    const data = await client.planner.plans.getById(plannerPlanId).buckets();
    return data;
  }

  public static async getBucket(plannerBucketId: string): Promise<PlannerBucket> {
    const client = this.getClient('short');
    const data = await client.planner.buckets.getById(plannerBucketId)();
    return data;
  }

  public static async deleteBucket(plannerBucketId: string) {
    const client = this.getClient();
    const data = await client.planner.buckets.getById(plannerBucketId).select('id')();
    await client.planner.buckets.getById(plannerBucketId).delete(data['@odata.etag']);
    this.resetCache();
  }

  public static async deleteBuckets(ids: string[]) {
    for (const id of ids) {
      await this.deleteBucket(id);
    }
  }

  public static async createBucket(bucket: PlannerBucket): Promise<PlannerBucket> {
    const client = this.getClient();
    const data = await graphPost(client.planner.buckets, body(bucket));
    this.resetCache();
    return data;
  }

  public static async moveBucket(bucketId: string, planId: string): Promise<string> {
    const oldBucket = await this.getBucket(bucketId);
    const newBucket = await this.createBucket(<PlannerBucket>{
      name: oldBucket.name,
      planId: planId,
    });
    const tasks = await this.getBucketTasks(bucketId);
    for (const task of tasks) {
      await this.updateTask(task.id, { planId: planId, bucketId: newBucket.id });
    }
    await this.deleteBucket(oldBucket.id);
    this.resetCache();
    return newBucket.id;
  }

  public static async updateBucket(plannerBucketId: string, changes: Partial<PlannerBucket>) {
    const client = this.getClient();
    const data = await client.planner.buckets.getById(plannerBucketId).select('id')();
    await client.planner.buckets.getById(plannerBucketId).update(changes, data['@odata.etag']);
    this.resetCache();
  }

  /// tasks

  public static async getTasks(plannerPlanId: string): Promise<PlannerTask[]> {
    const client = this.getClient('short');
    const data = await client.planner.plans.getById(plannerPlanId).tasks.expand('details')();
    return data;
  }

  public static async getBucketTasks(plannerBucketId: string): Promise<PlannerTask[]> {
    const client = this.getClient('short');
    const data = await client.planner.buckets.getById(plannerBucketId).tasks();
    return data;
  }

  public static async getTask(plannerTaskId: string): Promise<PlannerTask> {
    const client = this.getClient('short');
    const data = await client.planner.tasks.getById(plannerTaskId).expand('details')();
    return data;
  }

  public static async getTaskDetails(id: string): Promise<PlannerTaskDetails> {
    const client = this.getClient();
    const data = await client.planner.tasks.getById(id).details();
    return data;
  }

  public static async updateTaskDetails(id: string, changes: Partial<PlannerTaskDetails>) {
    const client = this.getClient();
    const data = await client.planner.tasks.getById(id).details.select('id')();
    await client.planner.tasks.getById(id).details.update(changes, data['@odata.etag']);
    this.resetCache();
  }

  public static async deleteTask(id: string) {
    const client = this.getClient();
    const data = await client.planner.tasks.getById(id).select('id')();
    await client.planner.tasks.getById(id).delete(data['@odata.etag']);
    this.resetCache();
  }

  public static async deleteTasks(ids: string[]) {
    for (const id of ids) {
      await this.deleteTask(id);
    }
  }

  public static async createTask(task: PlannerTask): Promise<PlannerTask> {
    const client = this.getClient();
    const data = await graphPost(client.planner.tasks, body(task));
    this.resetCache();
    return data;
  }

  public static async updateTask(plannerTaskId: string, changes: Partial<PlannerTask>) {
    const client = this.getClient();
    const data = await client.planner.tasks.getById(plannerTaskId).select('id')();
    await client.planner.tasks.getById(plannerTaskId).update(changes, data['@odata.etag']);
    this.resetCache();
  }

}
