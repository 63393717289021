import { IFieldValueUser } from 'sp';
import { TextService } from 'services/TextService';

export interface IChanges<T> {
  detected: boolean;
  properties: string[];
  oldValues: Partial<T>;
  newValues: Partial<T>;
}

export class ChangesService {

  public static getValueOrDefault = (v: any, def: any): any => {
    if (v) return v;
    if (typeof (v) === 'undefined') return def;
    if (v === null) return def;
    if (v === '') return def;
    return v;
  }

  private static addChange<T>(result: IChanges<T>, propName: keyof T, oldValue: any, newValue: any) {
    result.detected = true;
    result.properties.push(propName as string);
    result.oldValues[propName] = oldValue;
    result.newValues[propName] = newValue;
  }

  public static isSameUser(oldItem: IFieldValueUser, newItem: IFieldValueUser) {
    if (oldItem.guid && newItem.guid)
      return oldItem.guid.toLowerCase() === newItem.guid.toLowerCase();
    if (oldItem.userName && newItem.userName)
      return oldItem.userName.toLowerCase() === newItem.userName.toLowerCase();
    if (oldItem.id && newItem.id)
      return oldItem.id === newItem.id;
    return false;
  }

  public static getChanges<T>(item: T, request: Partial<T>, props?: (keyof T)[], options? : { roundDates?: boolean }): IChanges<T> {
    const result: IChanges<T> = {
      detected: false,
      oldValues: {},
      newValues: {},
      properties: []
    };

    if (!props)
      props = Object.keys(request) as (keyof T)[];

    props.forEach(propName => {
      // special case: assignedTo
      if (propName === 'assignedTo') {
        const oldValue = this.getValueOrDefault(item[propName], []);
        const newValue = this.getValueOrDefault(request[propName], []);

        if (Array.isArray(oldValue) && Array.isArray(newValue) && oldValue.length === newValue.length) {
          for (let i = 0; i < newValue.length; ++i) {
            if (!this.isSameUser(oldValue[i], newValue[i])) {
              ChangesService.addChange(result, propName, oldValue, newValue);
              break;
            }
          }
        } else {
          ChangesService.addChange(result, propName, oldValue, newValue);
        }
      } else if (propName === 'description' || propName === 'Description') {
        const oldValue = this.getValueOrDefault(item[propName], '');
        const newValue = this.getValueOrDefault(request[propName], '');
  
        const oldDescription = TextService.htmlToLines(oldValue, ['div', 'p']);
        const newDescription = TextService.htmlToLines(newValue, ['div', 'p']);
        if (oldDescription !== newDescription) {
          ChangesService.addChange(result, propName, oldValue, newValue);
        }
      } else {
        let oldValue = this.getValueOrDefault(item[propName], null);
        let newValue = this.getValueOrDefault(request[propName], null);

        if (options?.roundDates && (propName as string).indexOf('Date') >= 0) {
          newValue = newValue ? TextService.getDate(new Date(newValue)) : null;
          oldValue = oldValue ? TextService.getDate(new Date(oldValue)) : null;
        }

        if (JSON.stringify(oldValue) !== JSON.stringify(newValue)) {
          ChangesService.addChange(result, propName, oldValue, newValue);
        }
      }
    });
    return result;
  }

}
