import { useTheme } from '@fluentui/react';
import { RichTextTooltipHost } from 'components';
import React from 'react';
import { PlanKeyresultsService } from 'services/PlanKeyresultsService';
import { TextService } from 'services/TextService';
import { IVistoPlan, VistoKeyResultItem, VistoKeyResultValueItem } from 'sp';
import strings from 'VistoWebPartStrings';
import { ProgressBarTooltip } from './ProgressBarTooltip';
import { SimpleProgressBar } from './SimpleProgressBar';

export const ProgressBarBlockNumeric = (props: {
  plan: IVistoPlan;
  viewStartDate?: Date;
  viewEndDate?: Date;
  targets: VistoKeyResultValueItem[];
  actuals: VistoKeyResultValueItem[];
  kr: VistoKeyResultItem;
  onClick?: () => void;
}) => {

  const { startValue, currentValue, endValue, referenceValue } = PlanKeyresultsService.getKeyResultProgress(props.targets, props.actuals,
    props.viewStartDate, props.viewEndDate, props.plan.statusDate);

  const progress = PlanKeyresultsService.normalizedProgress(currentValue, startValue, endValue, true);
  const reference = PlanKeyresultsService.normalizedProgress(referenceValue, startValue, endValue, true);

  const start = PlanKeyresultsService.formatKeyResultValue(startValue, props.kr, false);
  const end = PlanKeyresultsService.formatKeyResultValue(endValue, props.kr, false);
  const valueText = PlanKeyresultsService.formatKeyResultValue(currentValue, props.kr, true);
  const referenceText = PlanKeyresultsService.formatKeyResultValue(referenceValue, props.kr, true);

  const rows = [
    { label: TextService.format(strings.SimpleProgressBar_Reference), value: referenceText },
    { label: TextService.format(strings.SimpleProgressBar_Actual), value: valueText },
  ];

  const { palette } = useTheme();

  return (
    <RichTextTooltipHost tooltipFooter={<ProgressBarTooltip rows={rows} />}>
      <SimpleProgressBar
        onClick={props.onClick}
        color={palette.themeLight}
        value={progress}
        reference={reference}
        valueText={valueText}
        start={start} end={end} />
    </RichTextTooltipHost>
  );
};
