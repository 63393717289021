import { Stack, Text } from '@fluentui/react';
import React from 'react';

export const ProgressValue = (valueProps: { label: string; value: string; width?: string; }) => {

  const style: React.CSSProperties = {
    minWidth: valueProps.width,
    textAlign: 'right',
    whiteSpace: 'nowrap'
  };

  return (
    <Stack horizontal tokens={{ childrenGap: 's2' }}>
      <Text variant='medium'>{valueProps.label}</Text>
      <Text variant='medium' style={style}>{valueProps.value}</Text>
    </Stack>
  );
};
