import { AuthService } from 'services/AuthService';
import { IExecutableAction } from 'services/IExecutableAction';
import { PlanDataService } from 'services/PlanDataService';
import { UrlService } from 'shared/urlService';
import { StorageCacheService } from 'services/StorageCacheService';
import { getListDefinition, IFieldDefinition, IVistoPlan, VistoKind } from 'sp';

export const MigrateSourceUrlFields = (foundPlan: IVistoPlan): IExecutableAction => ({
  title: `Convert SourceUrl fields to Note`,
  execute: async (p) => {

    const sp = AuthService.getSpClient(foundPlan.siteUrl);

    const kinds = PlanDataService.getAllVistoKinds().sort(PlanDataService.compareItemKind);

    for (const kind of kinds) {
      const listUrl = UrlService.getListRelativeUrl(foundPlan, kind);

      const listDefinition = getListDefinition(kind);
      for (const fieldKey in listDefinition.fields) {
        const field: IFieldDefinition = listDefinition.fields[fieldKey];
        if (fieldKey === 'sourceItemUrl') {
          await sp.web.getList(listUrl).fields.getByInternalNameOrTitle(field.name).update({ FieldTypeKind: 3 });
          await sp.web.getList(listUrl).fields.getByInternalNameOrTitle(field.name).update({ RichText: 'FALSE' }, 'SP.FieldMultiLineText');
        }
      }
    }
    StorageCacheService.resetCache();
    return p;
  }
});
