import { VistoKind } from '.';
import { IFieldDefinitions } from './IFieldDefinition';
import { ListItemUpdater } from './ListItemUpdater';

export interface IViewDefinition {
  viewNameStringId: keyof IVistoWebPartStrings;
  viewXml: string;
}

export interface IVistoList<T> {
  key?: string;
  enableVersioning?: boolean;
  majorVersionLimit?: number;

  kind?: VistoKind;
  fields?: IFieldDefinitions<T>;
  defaultViewXml: string;
  additionalViews?: IViewDefinition[];
  hasProgress?: boolean;

  setItemSpecificFields?: (updater: ListItemUpdater, spItem, item: Partial<T>) => void;
  getItemSpecificFields?: (updater: ListItemUpdater, item: Partial<T>, spItem) => void;
}

const listDefinitions = {};

export function addListDefinition<T>(list: IVistoList<T>) {
  listDefinitions[list.kind] = list;
}

export function getListDefinition<T>(kind: VistoKind): IVistoList<T> {
  return listDefinitions[kind];
}
