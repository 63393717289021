import * as React from 'react';
import { DefaultButton, Callout, IContextualMenuProps, Stack, ChoiceGroup, IChoiceGroupOption, IconButton } from '@fluentui/react';
import * as strings from 'VistoWebPartStrings';
import { TextService } from 'services/TextService';

const buttonStyles = {
  splitButtonMenuButton: {
    backgroundColor: 'transparent',
    border: '0'
  },
  root: {
    minWidth: 58,
    padding: 6,
    margin: '0 2px 0 2px 0',
    backgroundColor: 'transparent',
    border: '0'
  },
};

const horizontal: IChoiceGroupOption[] = [
  { key: 'left', text: 'Left', iconProps: { iconName: 'AlignLeft' } },
  { key: 'center', text: 'Center', iconProps: { iconName: 'AlignCenter' } },
  { key: 'right', text: 'Right', iconProps: { iconName: 'AlignRight' } },
];

const vertical: IChoiceGroupOption[] = [
  { key: 'top', text: 'Top', iconProps: { iconName: 'PaddingTop' } },
  { key: 'middle', text: 'Middle', iconProps: { iconName: 'Padding' } },
  { key: 'bottom', text: 'Bottom', iconProps: { iconName: 'PaddingBottom' } },
];

export function FontAlignComboBox(props: {
  disabled: boolean;
  align: string; setAlign(val: string);
  verticalAlign: string; setVerticalAlign(val: string);
}) {

  const renderDropdnown = (menuProps: IContextualMenuProps) => {

    return (
      <Callout target={menuProps.target} onDismiss={menuProps.onDismiss} isBeakVisible={false}>
        <Stack tokens={{ childrenGap: 's2', padding: 's1' }}>
          <Stack horizontal tokens={{ childrenGap: 's2' }}>
            {horizontal.map((x, i) =>
              <IconButton iconProps={{ iconName: x.iconProps.iconName }} text={x.text} checked={x.key === props.align} onClick={() => props.setAlign(x.key)} />)
            }
          </Stack>
          <Stack horizontal tokens={{ childrenGap: 's2' }}>
            {vertical.map((x, i) =>
              <IconButton iconProps={{ iconName: x.iconProps.iconName }} text={x.text} checked={x.key === props.verticalAlign} onClick={() => props.setVerticalAlign(x.key)} />)
            }
          </Stack>
        </Stack>
      </Callout>
    );
  };

  return (
    <DefaultButton
      styles={buttonStyles}
      iconProps={{ iconName: 'AlignLeft' }}
      title={TextService.format(strings.TextAlighComboBox_Title)}
      menuAs={renderDropdnown}
      disabled={props.disabled}
      menuProps={{ items: [] }}
    />
  );
}
