import * as strings from 'VistoWebPartStrings';
import { StorageService } from './StorageService';
import { ChangesService, IChanges } from 'services/ChangesService';
import { INotify } from 'services/Notify';
import { TextService } from 'services/TextService';
import { IAttachment, IVistoListItem, IItemWithAttachments, IVistoPlan } from 'sp';
import { IUndoUnit } from './IUndoUnit';
import { ICommand } from './ICommand';
import { trackClient } from 'shared/clientTelemetry';
import { CommandName } from 'shared/CommandName';
import { Commands } from 'services/Commands';

export type MakeAttachmentCommand = (item: IItemWithAttachments, changes: IChanges<IItemWithAttachments>, notify: INotify) => IUndoUnit;

export class AttachmentService {

  public static getItemAttachments(item: IItemWithAttachments): IAttachment[] {
    try {
      return JSON.parse(item.links) ?? [];
    } catch (err) {
      trackClient.error('cannot get item attachments', err);
      return [];
    }
  }

  public static makeDeleteAttachmentCommand(item: IItemWithAttachments, i: number, setAttachments: MakeAttachmentCommand, notify: INotify): ICommand {
    return {
      prepare: async () => {

        const attachments = AttachmentService.getItemAttachments(item);
        attachments.splice(i, 1);
        return AttachmentService.prepareSetAttachmentsCommand(item, attachments, setAttachments, notify);
      },
      message: TextService.format(strings.Command_DeleteAttachment),
      name: CommandName.DeleteAttachment
    };
  }

  public static makeAddAttachmentCommand(item: IItemWithAttachments, attachment: IAttachment, setAttachments: MakeAttachmentCommand, notify: INotify): ICommand {
    return {
      prepare: async () => {
        const attachments = AttachmentService.getItemAttachments(item);
        attachments.push(attachment);
        return AttachmentService.prepareSetAttachmentsCommand(item, attachments, setAttachments, notify);
      },
      message: TextService.format(strings.Command_AddAttachment),
      name: CommandName.AddAttachment
    };
  }

  public static prepareSetAttachmentsCommand(item: IItemWithAttachments, attachments: IAttachment[], setAttachments: MakeAttachmentCommand, notify: INotify) {
    const oldLinks = item.links;
    const newLinks = attachments.length ? JSON.stringify(attachments) : null;

    const changes = ChangesService.getChanges({
      links: oldLinks
    }, {
      links: newLinks
    });

    return setAttachments(item, changes, notify);
  }

  public static makeItemAttachmentsCommand(item: IVistoListItem, changes: IChanges<IVistoListItem>, notify: INotify) {
    return Commands.makeUpdateUndoUnit([{ item, changes }], notify);
  }

  public static makePlanAttachmentsCommand(item: IVistoPlan, changes: IChanges<IVistoPlan>, notify: INotify) {
    return {
      do: (plan: IVistoPlan) => StorageService.get(plan.siteUrl).updatePlanItem(plan, changes.newValues, notify),
      undo: (plan: IVistoPlan) => StorageService.get(plan.siteUrl).updatePlanItem(plan, changes.oldValues, notify)
    };
  }

}
