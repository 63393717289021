import * as strings from 'VistoWebPartStrings';

import { mxgraph } from 'ts-mxgraph-typings';
import { mxglobals } from './common';
import { TextService } from 'services/TextService';
import { VistoKind } from 'sp';
import * as Mustache from 'mustache';
import { Theme } from '@fluentui/react';
import { CellKind } from 'shared/CellKind';

const logoImage = require('mxresources/styles/logo.png');

const icon_lop = require('mxresources/styles/stencil-lop.svg.txt');
const icon_dp = require('mxresources/styles/stencil-dp.svg.txt');
const icon_picture = require('mxresources/styles/stencil-image.svg.txt');
const icon_text = require('mxresources/styles/stencil-text.svg.txt');
// const icon_textbox = require('mxresources/styles/stencil-textbox.svg.txt');

const createItem = (sidebar, cells, width, height, tooltip, icon_template, theme: Theme) => {
  const a = document.createElement('a');
  a.className = 'geItem geStencilItem';
  a.style.overflow = 'hidden';
  const border = 2 * sidebar.thumbBorder;
  a.style.width = (sidebar.thumbWidth + border) + 'px';
  a.style.height = (sidebar.thumbHeight + border) + 'px';
  a.style.padding = sidebar.thumbPadding + 'px';

  // Blocks default click action
  mxglobals.mxEvent.addListener(a, 'click', (evt) => mxglobals.mxEvent.consume(evt));

  // this.createThumb(cells, this.thumbWidth, this.thumbHeight, elt, title, showLabel, showTitle, width, height);
  a.innerHTML = Mustache.render(icon_template, theme);

  const bounds = new mxglobals.mxRectangle(0, 0, width, height);
  const ds = sidebar.createDragSource(a, sidebar.createDropHandler(cells, true, false, bounds), sidebar.createDragPreview(width, height), cells, bounds);
  ds.isGuidesEnabled = () => sidebar.editorUi.editor.graph.graphHandler.guidesEnabled;

  // Shows a tooltip with the rendered cell
  mxglobals.mxEvent.addGestureListeners(a, null, (evt) => {
    if (mxglobals.mxEvent.isMouseEvent(evt)) {
      sidebar.showTooltip(a, cells, bounds.width, bounds.height, tooltip, false);
    }
  }, null);

  return a;
};

const createLopTemplateEntry = (sidebar, width, height, theme: Theme) => {
  const label = TextService.getVistoKindName(VistoKind.LOP);
  return sidebar.addEntry(CellKind.LOP, () => {
    const cell: mxgraph.mxCell = new mxglobals.mxCell(label, new mxglobals.mxGeometry(-0.985, 0, width, height), 'LOP');
    cell.geometry.setTerminalPoint(new mxglobals.mxPoint(0, 0), true);
    cell.geometry.setTerminalPoint(new mxglobals.mxPoint(width, height), false);
    cell.geometry.points = [new mxglobals.mxPoint(width * 0.7, 0)];
    cell.geometry.relative = true;
    cell.edge = true;

    return createItem(sidebar, [cell], width, height, TextService.format(strings.Stencil_TooltipLop), icon_lop, theme);
  });
};

const createTemplateEntry = (sidebar, theme: Theme, props: { width: number, height: number, style: string, icon: any, iconLabel: string, cellKind: CellKind, value: string }) => {
  return sidebar.addEntry(props.cellKind, () => {
    const cell: mxgraph.mxCell = new mxglobals.mxCell(props.value, new mxglobals.mxGeometry(0, 0, props.width, props.height), props.style);
    cell.vertex = true;
    return createItem(sidebar, [cell], props.width, props.height, props.iconLabel, props.icon, theme);
  });
};

export function createStencil(sidebar, theme: Theme) {

  if (sidebar) {

    const fns = [
      createLopTemplateEntry(sidebar, 1000, 50, theme),

      createTemplateEntry(sidebar, theme, {
        width: 110,
        height: 60,
        style: 'DP',
        icon: icon_dp,
        cellKind: CellKind.DP,
        iconLabel: TextService.format(strings.Stencil_TooltipDp),
        value: TextService.getVistoKindName(VistoKind.DP)
      }),

      // sidebar.createVertexTemplateEntry('FOCUS', 100, 90, 'FOCUS', 'Focus', null, null, 'focus'),
      // sidebar.createVertexTemplateEntry('POSITION', 110, 90, 'POSITION', 'Position', null, null, 'position'),
      // sidebar.createVertexTemplateEntry('TITLE', 1000, 90, 'TITLE', 'Title', null, null, 'title'),
      createTemplateEntry(sidebar, theme, {
        width: 120,
        height: 70,
        style: 'IMAGE;shape=image;imageAspect=1;aspect=fixed;image=' + logoImage,
        icon: icon_picture,
        iconLabel: TextService.format(strings.Stencil_TooltipImage),
        cellKind: CellKind.IMAGE,
        value: '',
      }),

      createTemplateEntry(sidebar, theme, {
        style: 'text;html=1;strokeColor=none;fillColor=none;align=center;verticalAlign=middle;whiteSpace=wrap;rounded=0;',
        width: 40,
        height: 20,
        icon: icon_text,
        iconLabel: TextService.format(strings.Stencil_Text),
        cellKind: CellKind.text,
        value: TextService.format(strings.Stencil_TextContent)
      }),

      // createTemplateEntry(sidebar, theme, {
      //   style: 'text;html=1;strokeColor=none;fillColor=none;spacing=5;spacingTop=-20;whiteSpace=wrap;overflow=hidden;rounded=0;', 
      //   width: 190, 
      //   height: 120,
      //   value: TextService.format(strings.Stencil_TextboxContent),
      //   icon: icon_textbox,
      //   iconLabel: TextService.format(strings.Stencil_Textbox),
      //   cellKind: CellKind.text
      // }),

    ];

    sidebar.addPaletteFunctions('visto', 'Shapes', true, fns);
  }
}
