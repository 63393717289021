import React from 'react';
import { Callout, ActionButton, IContextualMenuProps, Stack, Text, TooltipHost } from '@fluentui/react';
import { IVistoPlan } from 'sp';
import { IVisualFilter } from './IVisualFilter';
import { TextService } from 'services/TextService';
import strings from 'VistoWebPartStrings';
import { VisualFilterService } from './VisualFilterService';
import { PlanVisualFilterCallout } from './PlanVisualFilterCallout';

export const PlanVisualFilter = (props: {
  disabled: boolean;
  plan: IVistoPlan;
  filter: IVisualFilter;
  setFilter: (filter: IVisualFilter) => void;
}) => {

  const renderDropdnown = (menuProps: IContextualMenuProps) => {

    return (
      <Callout target={menuProps.target} onDismiss={menuProps.onDismiss} isBeakVisible={false} preventDismissOnResize={true} preventDismissOnLostFocus={true}>
        <PlanVisualFilterCallout
          plan={props.plan}
          filter={props.filter}
          setFilter={props.setFilter}
          onDismiss={menuProps.onDismiss}
        />
      </Callout>
    );
  }

  const buttonStyles = {
    root: {
      minWidth: 58,
      padding: 6,
      margin: '0 2px 0 2px 0',
      backgroundColor: 'transparent',
      border: '0'
    },
  };

  const isFilterEmpty = VisualFilterService.isFilterEmpty(props.filter);

  const formatTooltipText = () => {
    return isFilterEmpty ? (
      TextService.format(strings.VisualFilter_None)
    ) : (
      <Stack tokens={{ childrenGap: 's1' }}>
        <Text variant='large'>{TextService.format(strings.VisualFilter_Title)}</Text>
        {VisualFilterService.formatFilter(props.plan, props.filter).map((text, index) => <Text key={index}>{text}</Text>)}
      </Stack>
    );
  }

  return (
    <TooltipHost content={formatTooltipText()} >
      <ActionButton
        styles={buttonStyles}
        iconProps={{ iconName: isFilterEmpty ? 'Filter' : 'FilterSolid' }}
        menuAs={renderDropdnown}
        disabled={props.disabled}
        menuProps={{ items: [] }}
      />
    </TooltipHost>
  )

}
