import * as React from 'react';
import { DefaultButton, DirectionalHint, IContextualMenuItem } from '@fluentui/react';
import { AppContext } from 'services/AppContext';

interface IOption<T> {
  name: string;
  val: T;
}

export interface ILineStyleButtonProps<T> {
  text: string;
  propertyName: string;
  defaultValue: T;
  value: T;
  options: IOption<T>[];
  setValue: (val: T) => void;
  renderIcon: (val: T) => JSX.Element;
}

export function LineStyleButton<T>(props: ILineStyleButtonProps<T>) {

  const { propertyBag, setPropertyBag } = React.useContext(AppContext);

  const value: T = propertyBag[props.propertyName] ?? props.defaultValue;
  const setValue = (val: T) => {
    setPropertyBag({ [props.propertyName]: val });
    props.setValue(val);
  };

  const items: IContextualMenuItem[] = props.options.map((x, i) => {
    return {
      key: i.toString(),
      text: x.name,
      iconProps: { iconName: 'x' },
      onRenderIcon: () => props.renderIcon(x.val),
      checked: JSON.stringify(x.val) === JSON.stringify(props.value),
      onClick: () => setValue(x.val)
    };
  });

  const current = props.options.find(x => JSON.stringify(x.val) === JSON.stringify(value));
  const text = props.text + (current ? `: ${current.name}` : ``);

  return (
    <DefaultButton
      styles={{
        splitButtonContainer: {
          display: 'block'
        },
        splitButtonMenuButton: {
          border: 0,
          backgroundColor: 'transparent'
        },
        textContainer: {
          textAlign: 'left',
          fontWeight: 400
        },
        root: {
          flexGrow: 1,
          border: 0,
          backgroundColor: 'transparent'
        }
      }}
      onRenderIcon={() => props.renderIcon(value)}
      text={text}
      split
      onClick={() => setValue(value)}
      menuIconProps={{ iconName: 'ChevronRight' }}
      menuProps={{
        styles: {
          root: {
            minWidth: 0,
          }
        },
        directionalHint: DirectionalHint.rightTopEdge,
        items: items
      }}
    />
  );
}
