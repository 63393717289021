import * as React from 'react';
import { registerIcons } from '@fluentui/react';

registerIcons({
  icons: {
    'visto-assoc-1': (
      <svg xmlns='http://www.w3.org/2000/svg' width='16px' height='16px' viewBox='0 0 10 10' version='1.1'>
        <g style={{ display: 'inline' }}>
          <circle cx='5' cy='5' r='4' style={{ fill: '#DEEBF6', strokeWidth: '0.5', stroke: '#1f497d' }} />
          <path d='m 5, 9 4, 0 0, -4' style={{ fill: '#DEEBF6', strokeWidth: '0.5', stroke: '#1f497d' }} />
        </g>
      </svg>),
    'visto-assoc-3': (
      <svg xmlns='http://www.w3.org/2000/svg' width='16px' height='16px' viewBox='0 0 10 10' version='1.1'>
        <g style={{ display: 'inline' }}>
          <circle cx='5' cy='5' r='4' style={{ fill: '#7C9DD6', strokeWidth: '0.5', stroke: '#1f497d' }} />
        </g>
      </svg>),

    'visto-assoc-9': (
      <svg xmlns='http://www.w3.org/2000/svg' width='16px' height='16px' viewBox='0 0 10 10' version='1.1'>
        <g style={{ display: 'inline' }}>
          <circle cx='5' cy='5' r='4' style={{ fill: '#1f497d', strokeWidth: '0.5', stroke: '#1f497d' }} />
          <path d='m 5, 1 4, 0 0, 4' style={{ fill: '#1f497d', strokeWidth: '0.5', stroke: '#1f497d' }} />
        </g>
      </svg>),

    'visto-assoc-missing': (
      <svg xmlns='http://www.w3.org/2000/svg' width='16px' height='16px' viewBox='0 0 541.2 476.532'>
        <circle cx='270' cy='238' r='225' stroke='#e67225' fill='#f6a235' stroke-width='25' />
        <text x='190.266' y='400.777' font-weight='600' font-size='450' font-family='Arial Bold' fill='#fff' style={{ fontStyle: 'normal' }}>!</text>
      </svg>),

    'visto-assoc-new': (
      <svg xmlns='http://www.w3.org/2000/svg' width='16px' height='16px' viewBox='0 0 10 10' version='1.1'>
        <g style={{ display: 'inline' }}>
          <circle cx='5' cy='5' r='4' style={{ fill: 'rgb(255,255,255)', strokeWidth: '0.5', stroke: 'rgb(127,127,127)' }} />
          <line x1='3' y1='5' x2='7' y2='5' style={{ stroke: 'rgb(127,127,127)', strokeWidth: '1' }} />
          <line x1='5' y1='3' x2='5' y2='7' style={{ stroke: 'rgb(127,127,127)', strokeWidth: '1' }} />
        </g>
      </svg>),

    'visto-assoc-duplicate': (
      <svg xmlns='http://www.w3.org/2000/svg' width='16px' height='16px' viewBox='0 0 10 10'>
        <g stroke='#fff'>
          <circle cx='5' cy='5' r='4.2' stroke='#a00' fill='#cf0000' stroke-width='.6' />
          <path stroke-width='1.5' d='M3 3l4 4M3 7l4-4' />
        </g>
      </svg>)
  }
});

export const VistoIcon = {
  AssocConfidence1: 'visto-assoc-1',
  AssocConfidence3: 'visto-assoc-3',
  AssocConfidence9: 'visto-assoc-9',
  AssocMissing: 'visto-assoc-missing',
  AssocNew: 'visto-assoc-new',
  AssocDuplicate: 'visto-assoc-duplicate'
};
