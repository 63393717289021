export enum PlanWizardOperation {
  CreateNew,
  OpenExisting,
  Upgrade,
}

export type PlanWizardCreateNewOperation = 'CreateNewBlank' | 'CreateNewOrg' | 'CreateNewFromFile' | 'CreateNewText';
export type PlanWizardOpenExistingOperation = 'SelectPlan' | 'SelectFile';

export enum PlanWizardPage {
  start,

  createNew,
  openExisting,

  storage,
  progress,
}

export type PlanWizardTrigger = 'SpfxSiteHeader' | 'SpfxConfigurationButton' | 'SpfxSettingsButton' | 'TeamsConfigTab' | 'UpgradePlaceholder';
