import { api } from 'shared/api';
import { trackClient } from 'shared/clientTelemetry';
import { parseJSON } from 'shared/parse';
import { VistoKind } from 'sp';
import { ApiService } from './ApiService';
import { IRealtimeService } from './IRealtimeService';

export class WSContext implements IRealtimeService {

  private timeout;

  public instanceId: string;
  public connectionId: string;
  private socket: WebSocket;
  private terminated: boolean = false;

  private send(req: any) {
    if (this.socket) {
      const json = JSON.stringify(req);
      try {
        if (!this.terminated) {
          if (this.socket.readyState !== WebSocket.CLOSED) {
            this.socket.send(json);
          } else {
            this.connect().then(() => {
              this.socket.send(json);
            });
          }
        } else {
          trackClient.warn(`[WS CLIENT] socket is disconnected, ignoring the message`);
        }
      } catch (err) {
        trackClient.error(`[WS CLIENT] error happend, reconnecting`, err);
        this.connect().then(() => {
          this.socket.send(json);
        })
      }
    }
  }

  public notifyItemOperation(operation: api.WSOperation, planId: string, kind: VistoKind, guid: string) {
    const req: api.IWSItemOperationInfo = {
      type: api.WSMessageType.item,
      planId: planId,
      kind: kind,
      guid: guid,
      operation: operation
    };

    this.send(req);
  }

  public setEditor(val: boolean): void {
    if (this.connectionId) {
      const req: api.IWSPlanEdit = {
        type: api.WSMessageType.edit,
        set: val,
      };

      this.send(req);
    }
  }

  public setSelection(val: string) {

    if (this.connectionId) {
      const req: api.IWSPlanSelect = {
        type: api.WSMessageType.select,
        shape: val,
      };

      this.send(req);
    }
  }

  public setDrawingRevision(drawingRevision: number) {

    if (this.connectionId) {
      const req: api.IWSPlanRevision = {
        type: api.WSMessageType.drawingRevision,
        drawing_revision: drawingRevision
      };

      this.send(req);
    }
  }

  public realtimeUpdate: (msg: api.IWSMessage) => void;

  constructor(instanceId: string, connectionId: string, realtimeUpdate: (msg: api.IWSMessage) => void) {
    this.instanceId = instanceId;
    this.connectionId = connectionId;
    this.realtimeUpdate = realtimeUpdate;
  }

  private ping() {
    if (!this.terminated) {
      this.socket.send('__ping__');
      this.timeout = setTimeout(() => {
        if (!this.terminated) {
          console.warn('[WS CLIENT] ping timeout, restarting the socket');
          this.socket.close();
        }
      }, 20000);
  }
}

  private reconnectHandler(ev) {
    trackClient.warn(`[WS CLIENT] connection closed, reconnecting in 5 seconds`, ev);

    if (this.realtimeUpdate) {
      this.realtimeUpdate({ type: api.WSMessageType.disconnected });
    }

    setTimeout(() => {
      if (!this.terminated) {
        this.connect()
      }
    }, 5000);
  }

  public async connect(editor?: boolean) {

    try {
      const token = await ApiService.getIdToken();
      const protocol = window.location.protocol === 'http:' ? 'ws:' : 'wss:';
      const url = `${protocol}//${window.location.host}?instance_id=${encodeURIComponent(this.instanceId)}&connection_id=${this.connectionId}&access_token=${token}&editor=${editor ? 'true' : 'false'}`;

      trackClient.debug(`[WS CLIENT] opening connection`, this.instanceId);

      this.socket = new WebSocket(url);

      // set up ping-pong
      this.socket.addEventListener('open', ev => {
        setTimeout(() => this.ping(), 30000);
      });

      this.socket.addEventListener('error', ev => {
        trackClient.error(`[WS CLIENT] connection issue, closing the socket`, ev);
        this.socket.close();
      });

      this.socket.addEventListener('close', this.reconnectHandler);

      this.socket.addEventListener('message', (msg) => {

        if (msg.data === '__pong__') {
          clearTimeout(this.timeout);
          setTimeout(() => this.ping(), 30000);
          return;
        }

        const data = parseJSON(msg.data) as api.IWSMessage;

        trackClient.debug(`[WS CLIENT] ${api.WSMessageType[data.type]}`, msg);

        switch (data.type) {
          case api.WSMessageType.status:
          case api.WSMessageType.item:
            if (this.realtimeUpdate)
              this.realtimeUpdate(data);
            break;

          default:
            trackClient.warn(`[WS CLIENT]: unknown message ${msg.data} (ignored)`);
            break;
        }
      });
    } catch (error) {
      trackClient.error(`[WS CLIENT] connection failed`, error);
    }
  };

  disconnect() {
    if (this.socket) {
      console.debug('[WS CLIENT] disconnected');
      this.socket.removeEventListener('close', this.reconnectHandler);
      clearTimeout(this.timeout);
      this.socket.close();
      this.terminated = true;
    }
  }
}
