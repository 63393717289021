import * as React from 'react';
import { DirectionalHint, TooltipDelay, TooltipHost, Stack, useTheme } from '@fluentui/react';
import styles from 'components/Tooltip.module.scss';
import { TextService } from 'services/TextService';

export function RichTextTooltipHost(props: {
  tooltip?: string;
  tooltipFooter?: JSX.Element;
  children: React.ReactNode;
}) {

  const theme = useTheme();

  const haveTooltip = props.tooltipFooter || TextService.getPlainText(props.tooltip);

  const onRenderContent = () => {
    return (
      <Stack tokens={{ padding: 's1' }}>
        {props.tooltip && <div className='ql-editor' style={{ padding: 0 }} dangerouslySetInnerHTML={{ __html: props.tooltip }}></div>}
        {props.tooltipFooter}
      </Stack>
    );
  };

  return (haveTooltip
    ? <TooltipHost
      calloutProps={{
        className: styles.tooltip,
        styles: { root: { backgroundColor: theme.palette.neutralLighterAlt, padding: 0 } },
        calloutMaxWidth: 300,
        backgroundColor: theme.palette.neutralLighterAlt,
        directionalHint: DirectionalHint.topAutoEdge,
        gapSpace: 0,
      }}
      tooltipProps={{
        onRenderContent
      }}
      delay={TooltipDelay.medium}
    >

      {props.children}

    </TooltipHost>
    : <React.Fragment>
      {props.children}
    </React.Fragment>
  );
}
