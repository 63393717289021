import { AuthService } from 'services/AuthService';
import { IExecutableAction } from 'services/IExecutableAction';
import { UrlService } from 'shared/urlService';
import { StorageCacheService } from 'services/StorageCacheService';
import { IVistoPlan, VistoKind } from 'sp';
import { MigrationHelperService } from 'services/MigrationHelperService';
import { FieldGenerator } from 'sp/common/FieldGenerator';

export const migrateFixAssocMatrix = (foundPlan: IVistoPlan, getListId: (kind: VistoKind) => string): IExecutableAction => ({
  title: `Fix Validation KPI references`,
  execute: async (p) => {

    const sp = AuthService.getSpClient(foundPlan.siteUrl);

    const actionListUrl = UrlService.getListRelativeUrl(foundPlan, VistoKind.Action);

    const actionFields = await sp.web.getList(actionListUrl).fields();
    if (!actionFields.some(f => f.InternalName === 'KPI')) {
      const fd = FieldGenerator.makeLookupField('VISTO_Action_KPI', 'KPI', true, VistoKind.KeyResult, false)
      const xml = MigrationHelperService.getFieldXml(fd, getListId);
      await sp.web.getList(actionListUrl).fields.createFieldAsXml(xml);
    }

    const actions = await sp.web.getList(actionListUrl).items.top(2000)();

    const map_Action_KPI = {};

    for (const action of actions) {
      if (!action['Id'] || !action['KPIId']) {
        continue;
      }

      const Id = +action['Id'];
      const kpiId = +action['KPIId'];

      map_Action_KPI[Id] = kpiId;
    }

    const assocListUrl = UrlService.getListRelativeUrl(foundPlan, VistoKind.Assoc);
    const assocs = await sp.web.getList(assocListUrl).items.top(2000)();
    for (const assoc of assocs) {

      if (!assoc['Id'] || !assoc['ActionId'] || !assoc['KPIId']) {
        continue;
      }

      const assocId = +assoc['Id'];
      const assocActionId = +assoc['ActionId'];
      const assocKpiId = +assoc['KPIId'];

      const actionKpiId = map_Action_KPI[assocActionId];

      if (assocKpiId !== actionKpiId) {
        
        await sp.web.getList(assocListUrl).items.getById(assocId).update({
          'KPIId': actionKpiId,
        });
      }
    }

    StorageCacheService.resetCache();
    return p;
  }
});
