import { useNavigate } from 'react-router-dom';
import * as microsoftTeams from '@microsoft/teams-js';

export const useEnvNavigate = (isTeams: boolean) => {

  const navigate = isTeams ? null : useNavigate();

  return (url: string, target?: string) => {

    if (url?.startsWith('http://') || url?.startsWith('https://') || !navigate) {

      if (url.startsWith('https://teams.microsoft.com/')) {
        microsoftTeams.executeDeepLink(url);
      } else {
        window.open(url, target);
      }
    } else {
      navigate(url);
    }
  }
}
