import * as React from 'react';
import { Stack } from '@fluentui/react';

export const MatrixLegendItem = (props: {
  confidence: number;
  label: string;
}) => {
  const className = `visto-confidence visto-confidence-${props.confidence}`;
  const itemStyle = {
    backgroundSize: '1.2em',
    width: '1.2em',
    height: '1.2em'
  };
  return (
    <Stack horizontal tokens={{ childrenGap: 's2' }}>
      <span className={className} style={itemStyle} />
      <span>{props.label}</span>
    </Stack>
  );
};
