import * as React from 'react';
import { IconButton } from '@fluentui/react';
import { useExpandedPropertyBag } from 'services/useExpandedPropertyBag';

export function ExpandButton(props: {
  expandKey: string;
  disabled?: boolean;
}) {

  const [isExpanded, onToggleExpand] = useExpandedPropertyBag(props.expandKey, false);
  
  return (
    <IconButton
      style={{ padding: '0 4px 0 0', width: 'auto' }}
      disabled={props.disabled}
      iconProps={{ iconName: isExpanded ? 'ChevronDown' : 'ChevronRight' }}
      onClick={onToggleExpand}
    />
  );

}
