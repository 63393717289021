import { AuthService } from 'services/AuthService';
import { IExecutableAction } from 'services/IExecutableAction';
import { UrlService } from 'shared/urlService';
import { StorageCacheService } from 'services/StorageCacheService';
import { IVistoPlan, VistoKind } from 'sp';
import { makeGuidString } from 'shared/guid';

export const MigrateKeyResultsToAssocActionFill = (foundPlan: IVistoPlan): IExecutableAction => ({
  title: `Fix up migrated validations`,
  execute: async (p) => {

    const sp = AuthService.getSpClient(foundPlan.siteUrl);

    const actionListUrl = UrlService.getListRelativeUrl(foundPlan, VistoKind.Action);
    const assocListUrl = UrlService.getListRelativeUrl(foundPlan, VistoKind.Assoc);

    const actions = await sp.web.getList(actionListUrl).items.top(2000)();

    const kpiActions: { [key: string]: number[]} = {};

    for (const action of actions) {
      const actionId = +action['Id'];
      const kpiId = +action['KPIId'];

      if (kpiId && actionId) {
        if (kpiActions[kpiId]) {
          kpiActions[kpiId].push(actionId);
        } else {
          kpiActions[kpiId] = [actionId];
        }
      }
    }

    const assocs = await sp.web.getList(assocListUrl).items.top(2000)();

    const actionEntries: { id, actionId, kpiId, krId, soId, confidence, name }[] = [];
    const kpiEntries: { id, kpiId, krId, soId, confidence, name }[] = [];

    for (const assoc of assocs) {

      const id = +assoc['Id'];
      const actionId = +assoc['ActionId'];
      const kpiId = +assoc['KPIId'];
      const krId = +assoc['KeyResultId'];
      const soId = +assoc['SOId'];
      const confidence = assoc['Confidence'];
      const name = assoc['Title'];

      if (actionId) {
        actionEntries.push({ id, actionId, kpiId, krId, soId, confidence, name });
      } else {
        kpiEntries.push({ id,kpiId, krId, soId, confidence, name });
      }
    }

    for (const entry of kpiEntries) {

      const candidateActionIds = kpiActions?.[entry.kpiId];
      if (candidateActionIds?.length) {

        const missingActionIds = candidateActionIds.filter(actionId => !actionEntries.some(x => 
            x.actionId === actionId && 
            x.kpiId === entry.kpiId && 
            x.krId === entry.krId && 
            x.soId === entry.soId
          ));

        for (const missingActionId of missingActionIds) {
          await sp.web.getList(assocListUrl).items.add({
            'VISTO_Guid': makeGuidString(),
            'Title': entry.name,
            'KeyResultId': entry.krId,
            'SOId': entry.soId,
            'KPIId': entry.kpiId,
            'ActionId': missingActionId,
            'Confidence': entry.confidence
          });
        }

        await sp.web.getList(assocListUrl).items.getById(entry.id).recycle();
      }
    }

    StorageCacheService.resetCache();
    return p;
  }
});
