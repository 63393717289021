import { ICommand } from 'services/ICommand';
import { IChanges } from 'services/ChangesService';
import { Commands } from 'services/Commands';
import { INotify, NotificationType, notifyInfoBar } from 'services/Notify';
import { IProgressData } from 'services/IProgressData';
import { TextService } from 'services/TextService';
import { CommandName } from 'shared/CommandName';
import { IFieldValueUser, IVistoListItem, IVistoListItemWithProgress, IVistoPlan } from 'sp';
import strings from 'VistoWebPartStrings';
import { DevOpsService } from './DevOpsService';

export class DevOpsNotifications {

  public static makeUpdateWorkItemCommand(devopsUrl: string, itemId: string, changes: IChanges<IVistoListItemWithProgress>): ICommand {
    return {
      prepare: async () => {
        return {
          do: async (plan) => {
            await DevOpsService.updateWorkItem(devopsUrl, itemId, changes.oldValues);
            return plan;
          },
          undo: async (plan) => {
            await DevOpsService.updateWorkItem(devopsUrl, itemId, changes.newValues);
            return plan;
          }
        };
      },
      message: TextService.format(strings.Command_DevOpsUpdateWorkItem, { title: changes.properties.join(', ') }),
      name: CommandName.MicrosoftDevOps_UpdateWorkItem
    };
  }

  public static makeUpdateItemCommand(item: IVistoListItem, changes: IChanges<IVistoListItem>, notify: INotify): ICommand {
    return {
      prepare: async () => {
        return Commands.makeUpdateUndoUnit([{ item, changes }], notify, { enableSimpleUpdate: true });
      },
      message: TextService.format(strings.Command_DevOpsUpdateVisPlanItem, { title: changes.properties.join(', ') }),
      name: CommandName.MicrosoftDevOps_UpdateItem
    };
  }

  public static makeWorkItemNameConflictNotification(
    p: IVistoPlan,
    devopsUrl: string,
    item: IVistoListItem,
    itemId: string,
    changes: IChanges<IProgressData>,
    notify: INotify) {

    notifyInfoBar(notify, {
      type: NotificationType.warn,
      message: TextService.format(strings.Command_DevOpsConflictTitle, { itemId }),
      error: TextService.format(strings.Command_DevOpsConflictDetails, {
        title: TextService.formatTitle(item, p.items),
        properties: TextService.formatChanges(item.kind, changes)
      }),
      group: 'DevOps',
      kind: item.kind,
      guid: Commands.getClickableGuid(item),
      actions: [
        {
          title: TextService.format(strings.Command_DevOpsConflictUseVisPlan),
          command: this.makeUpdateWorkItemCommand(devopsUrl, itemId, changes)
        },
        {
          title: TextService.format(strings.Command_DevOpsConflictUseDevOps),
          command: this.makeUpdateItemCommand(item, changes, notify)
        },
      ]
    });
  }
}
