import * as React from 'react';
import { renderToString } from 'react-dom/server';
import * as strings from 'VistoWebPartStrings';

const $ = require('jquery');
require('jquery-ui/ui/core');
require('jquery-ui/ui/widgets/sortable');
require('pivottable');

import { AppContext } from 'services/AppContext';
import { EnvContext } from 'services/EnvContext';
import { PlanDataService } from 'services/PlanDataService';
import { TextService } from 'services/TextService';
import { IVistoListItem, VistoActionItem, VistoAssocItem, VistoDpItem, VistoEffectItem, VistoFocusItem, VistoKeyResultItem, VistoKind, VistoLopItem, VistoSoItem } from 'sp';
import { ConfirmDeleteDialog, EditActionDialog, EditAssocDialog, EditDpDialog, EditFocusDialog, EditLopDialog, EditSoDialog } from 'dialogs';
import { MatrixTooltip } from './MatrixTooltip';
import { ContextualMenu, Icon, IContextualMenuItem, Stack, Toggle, TooltipHost, useTheme } from '@fluentui/react';
import { Placeholder } from '@pnp/spfx-controls-react/lib/controls/placeholder';

import styles from './MatrixFrame.module.scss';
import { trackClient } from 'shared/clientTelemetry';
import { EditEffectDialog } from 'dialogs/EditEffectDialog';
import { EditKeyResultValuesDialog } from 'dialogs/EditKeyResultValuesDialog';
import { LicenseService } from 'services/LicenseService';
import { SvgIndicators } from 'graphics/SvgIndicators';
import { makeGuidString } from 'shared/guid';
import { MenuItems } from 'frames/TopFrame/MenuItems';
import { Commands } from 'services/Commands';
import { MatrixChartDialog } from 'dialogs/MatrixChartDialog';
import { MatrixLegend } from './components/MatrixLegend';
import { FocusFilter } from './components/FocusFilter';
import { ProgressService } from 'services/ProgressService';

export function MatrixFrame(props: {
  fontSize: number;
}) {

  React.useEffect(() => trackClient.page('MatrixFrame'), []);

  const { hostKind } = React.useContext(EnvContext);
  const { planRef, propertyBag, isPlanEditEnabled, isPopupOpen, dispatchCommand, notify } = React.useContext(AppContext);

  const isEditDisabled = !isPlanEditEnabled || isPopupOpen;

  const planItems = planRef.current?.items;

  const [eidtDialogInfo, setEditDialogInfo] = React.useState<{ attr: string, item: IVistoListItem }>(null);
  const [deleteDialogInfo, setDeleteDialogInfo] = React.useState<{ attr: string, item: IVistoListItem }>(null);
  const [menuInfo, setMenuInfo] = React.useState<{ menuItems: IContextualMenuItem[], elem: any }>(null);

  const [chartInfo, setChartInfo] = React.useState<{ items: VistoKeyResultItem[] }>(null);

  const ref = React.useRef<HTMLDivElement>();

  const [tooltipContext, setTooltipContext] = React.useState<{ content: string; target: any; }>(null);

  const [isBlank, setIsBlank] = React.useState(false);

  const VISTO_ACTION_AGGREGATOR_NAME = 'VisPlan_Action';

  function getItemGuids(items: IVistoListItem[]) {
    return items.reduce((r, x) => ({ ...r, [x.guid]: x }), {});
  };

  const getAssocRelations = (assoc: VistoAssocItem) => {
    const action: VistoActionItem = assoc && PlanDataService.getItemByGuid(planItems, assoc.actionGuid);
    const so: VistoSoItem = assoc && PlanDataService.getItemByGuid(planItems, assoc.soGuid);
    const assocKr: VistoKeyResultItem = assoc && PlanDataService.getItemByGuid(planItems, assoc.krGuid);
    const [kr, kpi] = assocKr?.parentKrGuid
      ? [PlanDataService.getItemByGuid(planItems, assocKr.parentKrGuid), assocKr]
      : [assocKr, undefined];
    const dp: VistoDpItem = action && PlanDataService.getItemByGuid(planItems, action.dpGuid);
    const lop: VistoLopItem = dp && PlanDataService.getItemByGuid(planItems, dp.lopGuid);
    const effect: VistoEffectItem = action && PlanDataService.getItemByGuid(planItems, action.effectGuid);
    return { assoc, action, dp, so, kr, kpi, lop, effect };
  };

  const getActionRelations = (action: VistoActionItem) => {
    const dp: VistoDpItem = action && PlanDataService.getItemByGuid(planItems, action.dpGuid);
    const lop: VistoLopItem = dp && PlanDataService.getItemByGuid(planItems, dp.lopGuid);
    const focus: VistoFocusItem = action && PlanDataService.getItemByGuid(planItems, action.focusGuid);
    const effect: VistoEffectItem = action && PlanDataService.getItemByGuid(planItems, action.effectGuid);
    return { action, dp, lop, focus, effect };
  };

  const getSoRelations = (so: VistoSoItem) => {
    return { so };
  };

  const getKpiRelations = (kpi: VistoKeyResultItem) => {
    const kr: VistoKeyResultItem = kpi && PlanDataService.getItemByGuid(planItems, kpi.parentKrGuid);
    const so: VistoSoItem = kpi && PlanDataService.getItemByGuid(planItems, kr.soGuid);
    return { so, kr, kpi };
  };

  const getKrRelations = (kr: VistoKeyResultItem) => {
    const so: VistoSoItem = kr && PlanDataService.getItemByGuid(planItems, kr.soGuid);
    return { so, kr };
  };

  const isMobile = (hostKind === 'WebMobile' || hostKind === 'TeamsMobile');
  const isWeb = (hostKind === 'WebMobile' || hostKind === 'WebDesktop');

  const isActionExcludedBySelectedFocus = (actionGuid: string) => {
    const action = PlanDataService.getItemByGuid<VistoActionItem>(planItems, actionGuid);
    if (action) {
      if (!showCompleted && ProgressService.isCompleted(action)) {
        return true;
      }
      if (filterByFocus && selectedFocus) {
        return action.focusGuid !== selectedFocus.guid;
      }
    }
  }

  const getFilteredAssocItems = () => {
    return PlanDataService.getItemsHaving<VistoAssocItem>(planItems, x =>
      x.kind === VistoKind.Assoc && !isActionExcludedBySelectedFocus(x.actionGuid));
  }

  const getFilteredActionItems = () => {
    return PlanDataService.getItemsHaving<VistoActionItem>(planItems, x =>
      x.kind === VistoKind.Action && !isActionExcludedBySelectedFocus(x.guid));
  }

  const getFilteredKpiItems = () => {

    const kpisToKeep = {};
    if (filterByFocus) {
      const assocs = getFilteredAssocItems();
      for (const assoc of assocs) {
        if (assoc.krGuid) {
          kpisToKeep[assoc.krGuid] = true;
        }
      }
    }

    const kpis = PlanDataService.getItemsHaving<VistoKeyResultItem>(planItems, x =>
      x.kind === VistoKind.KeyResult && !!x.parentKrGuid && (!filterByFocus || kpisToKeep[x.guid]))

    return kpis;
  }

  const getFilteredSoItems = () => {
    return PlanDataService.getItemsHaving<VistoSoItem>(planItems, x =>
      x.kind === VistoKind.SO);
  }

  const getFilteredLopItems = () => {
    return PlanDataService.getItemsHaving<VistoSoItem>(planItems, x =>
      x.kind === VistoKind.LOP);
  }

  const getFilteredDpItems = () => {
    return PlanDataService.getItemsHaving<VistoSoItem>(planItems, x =>
      x.kind === VistoKind.DP);
  }

  const getFilteredKrItems = () => {
    return PlanDataService.getItemsHaving<VistoKeyResultItem>(planItems, x =>
      x.kind === VistoKind.KeyResult && !!x.soGuid && !x.parentKrGuid)
  }

  const markUsed = (entry: { guids: { [guid: string]: IVistoListItem }, count: number }, item: IVistoListItem) => {
    if (item && entry.guids[item.guid]) {
      delete entry.guids[item.guid];
      entry.count += 1;
    }
  }

  const getAssocList = () => {

    const pivotData = [];

    const lopItems = getFilteredLopItems();
    const dpItems = getFilteredDpItems();
    const actionItems = getFilteredActionItems();
    const soItems = getFilteredSoItems();
    const krItems = getFilteredKrItems();
    const kpiItems = getFilteredKpiItems();

    const unused = {
      lop: {
        guids: getItemGuids(lopItems),
        count: 0,
      },
      dp: {
        guids: getItemGuids(dpItems),
        count: 0,
      },
      action: {
        guids: getItemGuids(actionItems),
        count: 0,
      },
      so: {
        guids: getItemGuids(soItems),
        count: 0,
      },
      kr: {
        guids: getItemGuids(krItems),
        count: 0,
      },
      kpi: {
        guids: getItemGuids(kpiItems),
        count: 0,
      }
    };

    const addPivotDataItem = (data) => {

      const { dp, so, lop, kpi, action, kr, assoc, effect } = data;

      markUsed(unused.action, action);
      markUsed(unused.so, so);
      markUsed(unused.kr, kr);
      markUsed(unused.kpi, kpi);
      markUsed(unused.lop, lop);
      markUsed(unused.dp, dp);

      pivotData.push({
        [TextService.format(strings.MatrixColumnSO)]: so?.name,
        [TextService.format(strings.MatrixColumnKR)]: kr?.name,
        [TextService.format(strings.MatrixColumnLOP)]: lop?.name,
        [TextService.format(strings.MatrixColumnDP)]: dp?.name,
        [TextService.format(strings.MatrixColumnKPI)]: kpi?.name,
        [TextService.format(strings.MatrixColumnAction)]: action?.name,
        [TextService.format(strings.MatrixColumnConfidence)]: assoc?.confidence,
        [TextService.format(strings.MatrixColumnPercentComplete)]: action?.percentComplete,
        [TextService.format(strings.MatrixColumnWhy)]: assoc?.description,
        [TextService.format(strings.MatrixColumnEffect)]: effect?.name,
        'object': {
          [TextService.format(strings.MatrixColumnLOP)]: lop,
          [TextService.format(strings.MatrixColumnDP)]: dp,
          [TextService.format(strings.MatrixColumnAction)]: action,
          [TextService.format(strings.MatrixColumnSO)]: so,
          [TextService.format(strings.MatrixColumnKR)]: kr,
          [TextService.format(strings.MatrixColumnEffect)]: effect,
          [TextService.format(strings.MatrixColumnConfidence)]: assoc,
          [TextService.format(strings.MatrixColumnWhy)]: assoc,
          [TextService.format(strings.MatrixColumnKPI)]: kpi,
          [TextService.format(strings.MatrixColumnPercentComplete)]: action,
        }
      });
    }

    const assocItems = getFilteredAssocItems();
    const assocItemsNoBlanksActions = assocItems.filter(x => selectedRowDetails < 3 || !!x.actionGuid);

    for (const item of assocItemsNoBlanksActions) {
      const pivotItem = getAssocRelations(item);
      addPivotDataItem(pivotItem);
    }

    if (!showValidatedOnly) {
      for (const guid in unused.action.guids) {
        const pivotItem = getActionRelations(unused.action.guids[guid]);
        addPivotDataItem(pivotItem);
      }

      for (const guid in unused.kpi.guids) {
        const pivotItem = getKpiRelations(unused.kpi.guids[guid]);
        addPivotDataItem(pivotItem);
      }

      for (const guid in unused.kr.guids) {
        const pivotItem = getKrRelations(unused.kr.guids[guid]);
        addPivotDataItem(pivotItem);
      }

      for (const guid in unused.so.guids) {
        const pivotItem = getSoRelations(unused.so.guids[guid]);
        addPivotDataItem(pivotItem);
      }
    }

    return { pivotData, unused };
  };

  const getTooltipContent = (attr: string, records: any[]) => {
    const descriptions = [];
    for (const record of records) {
      const item: IVistoListItem = record[attr];
      if (item.description) {
        descriptions.push(item.description);
      }
    }

    return TextService.getTooltipHtml(descriptions.join(' '));
  }

  const addTooltip = (elem: HTMLElement, tooltip: string) => {

    if (isMobile) {
      return;
    }

    if (tooltip) {
      elem.onmouseenter = () => setTooltipContext({ content: tooltip, target: elem });
      elem.onmouseleave = () => setTooltipContext(null);
    }
  }

  const mapConfidence = (x: number) => {
    if (x >= 9) return 9;
    if (x >= 3) return 3;
    if (x >= 1) return 1;
    return 0;
  };

  const addAssocMenu = (elem: HTMLElement, attr: string, records: any[]) => {

    const listMenuItems: IContextualMenuItem[] = [];

    const collected: { [guid: string]: VistoKeyResultItem } = {};

    const assocs: VistoAssocItem[] = records.map(x => x[attr]).sort((a, b) => TextService.compareAssoc(a, b, planItems));

    for (const assoc of assocs) {

      if (assoc.krGuid) {
        const kr = PlanDataService.getItemByGuid<VistoKeyResultItem>(planItems, assoc.krGuid);
        if (kr) {
          collected[assoc.krGuid] = kr;
          if (kr.parentKrGuid) {
            const parentKr = PlanDataService.getItemByGuid<VistoKeyResultItem>(planItems, kr.parentKrGuid);
            if (parentKr) {
              collected[parentKr.guid] = parentKr;
            }
          }
        }
      }

      const assocMenuItems = [
        MenuItems.getDefaultMenuItemValidation(isPopupOpen, isPlanEditEnabled, () => {
          setEditDialogInfo({ attr, item: assoc });
          setMenuInfo(null);
        }),
        MenuItems.getDeleteAssocMenuItem(isEditDisabled, () => {
          setDeleteDialogInfo({ attr, item: assoc });
          setMenuInfo(null);
        })
      ];

      listMenuItems.push({
        key: `MenuItem_List_${assoc.guid}`,
        text: TextService.formatTitle(assoc, planItems),
        items: assocMenuItems
      });
    }

    const menuItems = [
      ...(LicenseService.license?.okrEnabled ? [
        MenuItems.getShowAssocChartMenuItem(false, () => setChartInfo({
          items: Object.values(collected).sort(TextService.compareKeyResults)
        }))] : []),
      ...listMenuItems
    ]

    elem.onclick = (e) => {
      e.preventDefault();
      setMenuInfo({ menuItems, elem });
    };
  }

  const init = (options) => {

    const { pivotData, unused } = getAssocList();

    // if there are no associations
    setIsBlank(pivotData.length == 0);

    $.pivotUtilities.aggregators = {

    };

    $.pivotUtilities.renderers = {
      'Table': $.pivotUtilities.renderers['Table']
    };

    $.pivotUtilities.aggregators[VISTO_ACTION_AGGREGATOR_NAME] = () => {
      return (data, rowKey, colKey) => {
        const assocList = [];
        let filled = false;
        return {
          push: (record) => {
            const target = record.object;
            if (target) {
              const assoc: VistoAssocItem = target[TextService.format(strings.MatrixColumnConfidence)];
              if (assoc) {
                if (TextService.isValidNumber(assoc.confidence)) {
                  assocList.push(target);
                }
              }
            }
          },
          value: () => {
            return !!assocList.length;
          },
          format: (x) => {
            return x;
          },
          formatHtml: (td: HTMLElement, x) => {
            if (assocList.length) {
              const attr = TextService.format(strings.MatrixColumnConfidence);
              const elem = document.createElement('a');
              const confidence = assocList.map(x => x[attr]).reduce((r, v) => r + v.confidence, 0);
              elem.className = `visto-confidence visto-confidence-${mapConfidence(confidence / assocList.length)}`;
              addAssocMenu(elem, attr, assocList);
              addTooltip(elem, getTooltipContent(attr, assocList));
              td.appendChild(elem);
              if (filled) {
                // td.style.backgroundColor = palette.neutralLight;
              }
            }
          },
          numInputs: 0
        };
      };
    };

    const formatAxis = (th: HTMLElement, label: string, val: number, level: number, toggle: (val: number) => void) => {

      const a = document.createElement('a');
      a.style.cursor = 'pointer';
      a.onclick = (e) => {
        e.preventDefault();
        toggle(val < level ? level : level - 1);
      }

      const spanIcon = document.createElement('span');
      spanIcon.style.padding = '0 0.4em';
      spanIcon.innerHTML = renderToString(val < level
        ? <Icon iconName='BoxAdditionSolid' />
        : <Icon iconName='BoxSubtractSolid' />);
      a.appendChild(spanIcon);

      const spanText = document.createElement('span');
      spanText.textContent = label;
      a.append(spanText);

      addTooltip(a, val < level
        ? TextService.format(strings.MatrixTooltip_Expand)
        : TextService.format(strings.MatrixTooltip_Collapse)
      );

      th.appendChild(a);
    }

    const axisFormatter = (th: HTMLElement, label: string) => {

      th.style.whiteSpace = 'nowrap';

      switch (label) {
        case TextService.format(strings.MatrixColumnLOP):
          formatAxis(th, `${label} (${unused.lop.count})`, selectedRowDetails, 1, setSelectedRowDetails);
          return;
        case TextService.format(strings.MatrixColumnDP):
          formatAxis(th, `${label} (${unused.dp.count})`, selectedRowDetails, 2, setSelectedRowDetails);
          return;
        case TextService.format(strings.MatrixColumnSO):
          if (LicenseService.license?.okrEnabled) {
            formatAxis(th, `${label} (${unused.so.count})`, selectedColDetails, 1, setSelectedColDetails);
            return;
          }
        case TextService.format(strings.MatrixColumnKR):
          if (LicenseService.license?.okrEnabled) {
            formatAxis(th, `${label} (${unused.kr.count})`, selectedColDetails, 2, setSelectedColDetails);
            return;
          }
        case TextService.format(strings.MatrixColumnAction):
          th.textContent = `${label} (${unused.action.count})`;
          return;
      }

      th.textContent = label;
    };

    const headerFormatter = (th: HTMLElement, val, attr) => {
      const record = pivotData.filter((d) => {
        return d[attr] === val;
      })[0];
      if (record) {
        const item = record.object?.[attr];

        let elem = document.createElement(item ? 'a' : 'span');
        if (item) {
          elem.style.cursor = 'pointer';
        }
        addTooltip(elem, getTooltipContent(attr, [record]));

        if (item) {
          elem.onclick = (e) => {
            e.preventDefault();
            setEditDialogInfo({ attr, item });
          };
        }

        let label = (val || '');
        if (label.length > 75)
          label = label.substring(0, 75) + '...';

        if (item?.kind === VistoKind.KeyResult) {
          const spanIcon = document.createElement('span');
          spanIcon.style.padding = '0 0.4em 0 0';
          spanIcon.innerHTML = renderToString(<Icon iconName='Chart' />);
          elem.appendChild(spanIcon);
        }

        const svg = SvgIndicators.buildSvgIndicator(planRef.current, item, 20 * props.fontSize / 14, propertyBag?.viewStartDate, propertyBag?.viewEndDate);
        if (svg) {
          const spanSvg = document.createElement('span');
          spanSvg.style.float = 'right';
          spanSvg.innerHTML = svg;
          th.appendChild(spanSvg);
        }

        const spanText = document.createElement('span');
        spanText.style.padding = '0 0.4em 0 0';
        spanText.textContent = label;
        elem.appendChild(spanText);

        th.appendChild(elem);

      } else {
        th.innerHTML = (val === 'null') ? '' : val;
      }
    };

    const defaultHtmlCallback = (td: HTMLElement, val, keys: Object, pivotData) => {
      if (!val) {
        const actionName = keys[TextService.format(strings.MatrixColumnAction)];
        const action = PlanDataService.getItemsHaving<VistoActionItem>(planItems, x => x.kind === VistoKind.Action && x.name === actionName)[0];

        const soName = keys[TextService.format(strings.MatrixColumnSO)];
        const so = PlanDataService.getItemsHaving<VistoSoItem>(planItems, x => x.kind === VistoKind.SO && x.name === soName)[0];

        const krName = keys[TextService.format(strings.MatrixColumnKR)];
        const kr = PlanDataService.getItemsHaving<VistoKeyResultItem>(planItems, x => x.kind === VistoKind.KeyResult && x.soGuid && x.name === krName)[0];

        const kpiName = keys[TextService.format(strings.MatrixColumnKPI)];
        const kpi = PlanDataService.getItemsHaving<VistoKeyResultItem>(planItems, x => x.kind === VistoKind.KeyResult && x.parentKrGuid && x.name === kpiName)[0];

        if (action && (so || kr || kpi)) {
          const elem = document.createElement('a');
          elem.className = 'visto-confidence visto-confidence-plus';
          elem.onclick = (e) => {
            e.preventDefault();
            const attr = TextService.format(strings.MatrixColumnConfidence);
            const krGuid = kpi?.guid || kr?.guid || null;
            const item: VistoAssocItem = {
              kind: VistoKind.Assoc,
              guid: makeGuidString(),
              actionGuid: action?.guid || null,
              soGuid: so?.guid || null,
              krGuid: (LicenseService.license?.okrEnabled) ? krGuid : null,
              confidence: 9
            };
            setEditDialogInfo({ attr, item });
          }
          addTooltip(elem, TextService.format(strings.MatrixTooltip_AddValidation));
          td.appendChild(elem);
        }
      }
    };

    const sortAs = $.pivotUtilities.sortAs;

    options.showUI = false;
    options.hiddenAttributes = ['object'];
    options.unusedAttrsVertical = false;
    options.aggregators = $.pivotUtilities.aggregators;
    options.renderers = $.pivotUtilities.renderers;
    options.rendererOptions = options.rendererOptions || {};
    options.rendererOptions.table = options.rendererOptions.table || {};
    options.rendererOptions.table.rowTotals = false;
    options.rendererOptions.table.colTotals = false;

    options.rendererOptions.table.defaultHtmlCallback = defaultHtmlCallback;
    options.rendererOptions.headerHtmlFormatter = headerFormatter;
    options.rendererOptions.axisHtmlFormatter = axisFormatter;
    options.onRefresh = () => {
    };

    options.sorters = {
      [TextService.format(strings.MatrixColumnSO)]: sortAs(PlanDataService.getItems(planItems, VistoKind.SO).map(x => x.name)),
      [TextService.format(strings.MatrixColumnKR)]: sortAs(PlanDataService.getItems(planItems, VistoKind.KeyResult).map(x => x.name)),
      [TextService.format(strings.MatrixColumnKPI)]: sortAs(PlanDataService.getItems(planItems, VistoKind.KeyResult).map(x => x.name)),
      [TextService.format(strings.MatrixColumnLOP)]: sortAs(PlanDataService.getItems(planItems, VistoKind.LOP).map(x => x.name)),
      [TextService.format(strings.MatrixColumnDP)]: sortAs(PlanDataService.getItems(planItems, VistoKind.DP).map(x => x.name)),
      [TextService.format(strings.MatrixColumnEffect)]: sortAs(PlanDataService.getItems(planItems, VistoKind.Effect).map(x => x.name)),
      [TextService.format(strings.MatrixColumnAction)]: sortAs(PlanDataService.getItems(planItems, VistoKind.Action).map(x => x.name)),
    };

    $(ref.current).pivotUI(pivotData, options, true);
  };

  const getSelectedOptions = (rowDetails: number, colDetails: number) => {
    return {
      rows: [
        TextService.format(strings.MatrixColumnLOP),
        ...(rowDetails >= 1 ? [TextService.format(strings.MatrixColumnDP)] : []),
        ...(rowDetails >= 1 && LicenseService.license?.effectsEnabled ? [TextService.format(strings.MatrixColumnEffect)] : []),
        ...(rowDetails >= 2 ? [TextService.format(strings.MatrixColumnAction)] : []),
      ],
      cols: [
        TextService.format(strings.MatrixColumnSO),
        ...(colDetails >= 1 && LicenseService.license?.okrEnabled ? [TextService.format(strings.MatrixColumnKR)] : []),
        ...(colDetails >= 2 && LicenseService.license?.okrEnabled ? [TextService.format(strings.MatrixColumnKPI)] : []),
      ],
      vals: [
        TextService.format(strings.MatrixColumnConfidence)
      ],
      rendererName: 'Table',
      aggregatorName: VISTO_ACTION_AGGREGATOR_NAME,
    };
  };

  const [selectedRowDetails, setSelectedRowDetails] = React.useState<number>(3);
  const [selectedColDetails, setSelectedColDetails] = React.useState<number>(LicenseService.license?.okrEnabled ? 2 : 0);

  const [showValidatedOnly, setShowValidatedOnly] = React.useState(false);
  const [showCompleted, setShowCompleted] = React.useState(false);
  const [filterByFocus, setFilterByFocus] = React.useState(false);
  const [selectedFocus, setSelectedFocus] = React.useState<VistoFocusItem>(PlanDataService.getActiveFocus(planRef.current));

  React.useEffect(() => {
    const pivotOptions = getSelectedOptions(selectedRowDetails, selectedColDetails);
    init(pivotOptions);
  }, [planRef.current.revision, props.fontSize, showValidatedOnly, showCompleted, filterByFocus, selectedFocus, selectedRowDetails, selectedColDetails]);

  const height = `calc(100vh - ${110 + (isMobile ? 40 : 0) + (isWeb ? 40 : 0)}px)`;

  const deleteConfirmed = (items: IVistoListItem[]) => {
    return dispatchCommand(Commands.makeDeleteCommand(items, notify), { wrap: false });
  };

  const onSaveItem = async (oldItem: IVistoListItem, newItem: IVistoListItem) => {
    const cmd = Commands.makeSaveCommand(oldItem, newItem, notify);
    await dispatchCommand(cmd, { wrap: false });
  }

  return (
    <Stack grow style={{ height }}>

      <Stack wrap horizontal tokens={{ padding: 's2 m' }}>
        <Stack horizontal grow tokens={{ childrenGap: 'm' }}>
          <TooltipHost content={TextService.format(strings.MatrixFrame_TooltipFilterByFocus)}>
            <Toggle inlineLabel label={TextService.format(strings.MatrixFrame_FilterByFocus)} checked={filterByFocus} onChange={(_, val) => setFilterByFocus(show => !show)} />
          </TooltipHost>
          <FocusFilter selectedFocus={selectedFocus} setSelectedFocus={setSelectedFocus} disabled={!filterByFocus} plan={planRef.current} />
          <TooltipHost content={TextService.format(strings.MatrixFrame_TooltipShowValidatedOnly)}>
            <Toggle inlineLabel label={TextService.format(strings.MatrixFrame_ShowValidatedOnly)} checked={showValidatedOnly} onChange={(_, val) => setShowValidatedOnly(show => !show)} />
          </TooltipHost>
          <TooltipHost content={TextService.format(strings.MatrixFrame_TooltipShowCompleted)}>
            <Toggle inlineLabel label={TextService.format(strings.MatrixFrame_ShowCompleted)} checked={showCompleted} onChange={(_, val) => setShowCompleted(show => !show)} />
          </TooltipHost>
        </Stack>
        <MatrixLegend />
      </Stack>

      {isBlank && <Placeholder iconName='Info' iconText={TextService.format(strings.MatrixFrame_NoDataTitle)} description={TextService.format(strings.MatrixFrame_NoDataDescription)} />}

      <div ref={ref} style={{ fontSize: props.fontSize }} className={styles.pvtContainer}></div>

      {menuInfo && <ContextualMenu
        items={menuInfo.menuItems}
        target={menuInfo.elem}
        onDismiss={() => setMenuInfo(null)}
      />}

      {tooltipContext &&
        <MatrixTooltip
          target={tooltipContext.target}
          tooltip={tooltipContext.content}
          onDismiss={() => setTooltipContext(null)}
        />
      }

      {eidtDialogInfo?.attr === TextService.format(strings.MatrixColumnDP) && <EditDpDialog plan={planRef.current} dp={eidtDialogInfo.item as VistoDpItem} onDismiss={() => setEditDialogInfo(null)} />}
      {eidtDialogInfo?.attr === TextService.format(strings.MatrixColumnLOP) && <EditLopDialog plan={planRef.current} lop={eidtDialogInfo.item as VistoLopItem} onDismiss={() => setEditDialogInfo(null)} />}
      {eidtDialogInfo?.attr === TextService.format(strings.MatrixColumnConfidence) && <EditAssocDialog plan={planRef.current} assoc={eidtDialogInfo.item as VistoAssocItem} onDismiss={() => setEditDialogInfo(null)} />}
      {eidtDialogInfo?.attr === TextService.format(strings.MatrixColumnAction) && <EditActionDialog plan={planRef.current} action={eidtDialogInfo.item as VistoActionItem} onDismiss={() => setEditDialogInfo(null)} onSave={onSaveItem} />}
      {eidtDialogInfo?.attr === TextService.format(strings.MatrixColumnSO) && <EditSoDialog plan={planRef.current} so={eidtDialogInfo.item as VistoSoItem} onDismiss={() => setEditDialogInfo(null)} />}
      {eidtDialogInfo?.attr === TextService.format(strings.MatrixColumnKR) && <EditKeyResultValuesDialog plan={planRef.current} kr={eidtDialogInfo.item as VistoKeyResultItem} onDismiss={() => setEditDialogInfo(null)} />}
      {eidtDialogInfo?.attr === TextService.format(strings.MatrixColumnKPI) && <EditKeyResultValuesDialog plan={planRef.current} kr={eidtDialogInfo.item as VistoKeyResultItem} onDismiss={() => setEditDialogInfo(null)} />}
      {eidtDialogInfo?.attr === TextService.format(strings.MatrixColumnEffect) && <EditEffectDialog plan={planRef.current} effect={eidtDialogInfo.item as VistoEffectItem} onDismiss={() => setEditDialogInfo(null)} />}
      {deleteDialogInfo && <ConfirmDeleteDialog planItems={planItems} items={[deleteDialogInfo.item]} onDelete={deleteConfirmed} onDismiss={() => setDeleteDialogInfo(null)} />}

      {chartInfo && <MatrixChartDialog onDismiss={() => setChartInfo(null)} items={chartInfo.items} plan={planRef.current} />}
    </Stack>
  );
}
