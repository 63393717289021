import { useTheme } from '@fluentui/react';
import React from 'react';
import { ImageCacheService } from 'services/ImageCacheService';
import { PlanTemplateService } from 'services/PlanTemplateService';
import { ITemplate } from 'services/ITemplate';

export const NewPlanCard = (props: {
  template: ITemplate,
  isSelected: boolean;
  onClick: () => void;
}) => {

  const theme = useTheme();

  const cardStyles: React.CSSProperties = {
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    boxShadow: theme.effects.elevation4,
    width: 100,
    padding: 4,
    border: `1px solid ${props.isSelected ? theme.palette.themePrimary : theme.palette.neutralLight}`,
    backgroundColor: props.isSelected ? theme.palette.themeLighter : null,
    cursor: 'pointer',
    marginRight: 8,
    marginBottom: 8
  };

  const [imagePreview, setTemplatePreview] = React.useState(null);

  React.useEffect(() => {
    const plan = PlanTemplateService.createTemplatePlan(props.template);
      ImageCacheService.getPreviewSvg({
        embedImages: false,
        drawingXml: plan.drawingXml,
        styleJson: plan.styleJson,
        theme: theme,
        width: 80,
        height: 60,
      }).then(preview => {
        setTemplatePreview(preview);
      });
  }, [props.template]);

  return (
    <div style={cardStyles} onClick={props.onClick} >
      <div style={{ width: 80, height: 60 }} dangerouslySetInnerHTML={{__html: imagePreview }} ></div>
      <div>{props.template.name}</div>
    </div>
  );
};
