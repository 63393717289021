import * as React from 'react';
import * as strings from 'VistoWebPartStrings';
import { DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton, Stack, ContextualMenu, IModalProps, TextField, IChoiceGroupOption, ChoiceGroup } from '@fluentui/react';
import { defaultModalProps, IFont } from './common';
import { trackClient } from 'shared/clientTelemetry';
import { TextService } from 'services/TextService';

export function CustomFontDialog(props: {
  font: IFont;
  setFont: (font: IFont) => void;
  onDismiss: () => void;
}) {

  React.useEffect(() => trackClient.page('CustomFontDialog'), []);

  const [isCustom, setIsCustom] = React.useState(!!props.font.url);
  const [systemFontName, setSystemFontName] = React.useState(isCustom ? null : props.font.name);
  const [customFontName, setCustomFontName] = React.useState(isCustom ? props.font.name : null);
  const [customFontUrl, setCustomFontUrl] = React.useState(isCustom ? props.font.url : null);

  const options: IChoiceGroupOption[] = [
    {
      key: 'system',
      text: TextService.format(strings.CustomFontDialog_SystemFont),
      onRenderField: (choiceGroupProps, render) => (
        <Stack tokens={{ padding: 's2', childrenGap: 's1' }}>
          {render!(choiceGroupProps)}
          <Stack tokens={{ childrenGap: 's2' }}>
            <TextField style={{ minWidth: 300 }} disabled={isCustom} placeholder={TextService.format(strings.CustomFontDialog_EnterFontName)} value={systemFontName} onChange={(_, val) => setSystemFontName(val)} />
          </Stack>
        </Stack>
      )
    },
    {
      key: 'custom',
      text: TextService.format(strings.CustomFontDialog_WebFont),
      onRenderField: (choiceGroupProps, render) => (
        <Stack grow tokens={{ padding: 's2', childrenGap: 's1' }}>
          {render!(choiceGroupProps)}
          <Stack grow tokens={{ childrenGap: 's1' }}>
            <TextField style={{ minWidth: 300 }} disabled={!isCustom} placeholder={TextService.format(strings.CustomFontDialog_EnterFontName)} value={customFontName} onChange={(_, val) => setCustomFontName(val)} />
            <TextField style={{ minWidth: 300 }} multiline rows={4} disabled={!isCustom} placeholder={TextService.format(strings.CustomFontDialog_EnterFontUrl)} value={customFontUrl} onChange={(_, val) => setCustomFontUrl(val)} />
          </Stack>
        </Stack>
      )
    },
  ];

  const disabled = !isCustom && !systemFontName || isCustom && (!customFontName || !customFontUrl);

  const save = () => {
    props.onDismiss();
    props.setFont(isCustom ? { name: customFontName, url: customFontUrl } : { name: systemFontName, url: null });
  };

  return (
    <Dialog
      hidden={false}
      dialogContentProps={{ title: TextService.format(strings.CustomFontDialog_EditFont), type: DialogType.normal }}
      modalProps={defaultModalProps}
      onDismiss={props.onDismiss} >
      <ChoiceGroup options={options} selectedKey={isCustom ? 'custom' : 'system'} onChange={(_, option) => setIsCustom(option.key === 'custom')} />
      <DialogFooter>
        <PrimaryButton disabled={disabled} onClick={save} text={TextService.format(strings.ButtonOK)} />
        <DefaultButton onClick={props.onDismiss} text={TextService.format(strings.ButtonCancel)} />
      </DialogFooter>
    </Dialog>
  );
}
