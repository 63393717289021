import React from 'react';
import { ActionButton, Calendar, DatePicker, FontSizes, Stack, Text, useTheme } from '@fluentui/react';
import { TextService } from 'services/TextService';
import { PlanDataService } from 'services/PlanDataService';
import { IVistoPlan } from 'sp';
import strings from 'VistoWebPartStrings';

export const KeyResultDatePicker = (props: {
  plan: IVistoPlan;
  disabled: boolean;
  label: string;
  showMilestones: boolean;
  date: Date;
  setDate: (val: Date) => void;
}) => {

  const { palette } = useTheme();

  const dateOptions = PlanDataService.getFocuses(props.plan)
    .filter(f => f.endDate)
    .map(d => ({ key: d.name, text: d.name, data: d.endDate }));

  const getDateName = (d: Date) => {
    const text = TextService.formatDate(TextService.getDate(d));
    const found = dateOptions.filter(x => TextService.formatDate(x.data) === text);
    const today = TextService.getDate(props.plan.statusDate);
    if (TextService.formatDate(today) === text) {
      found.push({ key: 'today', text: TextService.format(strings.DateName_Today), data: today });
    }
    return found.map(x => x.text).join(', ');
  };

  const [dateName, setDateName] = React.useState(getDateName(props.date));

  const setDate = (d: Date) => {
    props.setDate(d);
    setDateName(getDateName(d));
  };

  return (
    <Stack>
      <DatePicker
        calendarAs={(calendarProps) => props.showMilestones
          ? <Stack>
            <Stack horizontal wrap style={{ maxWidth: 400 }} tokens={{ padding: '0 m' }}>
              {dateOptions.map(x => (
                <ActionButton
                  styles={{
                    root: {
                      fontSize: FontSizes.small,
                      height: 'auto',
                      paddingTop: 8,
                      textDecoration: 'underline'
                    }
                  }}
                  title={TextService.formatDate(x.data)}
                  key={x.key}
                  onClick={() => calendarProps.onSelectDate(x.data)}
                  text={x.text}
                />
              ))}
            </Stack>
            <Calendar {...calendarProps} today={props.plan.statusDate} />
          </Stack>
          : <Calendar {...calendarProps} today={props.plan.statusDate} />
        }
        strings={TextService.datePickerStrings}
        firstDayOfWeek={TextService.firstDayOfWeek}
        formatDate={d => TextService.formatDate(d)}
        label={props.label}
        disabled={props.disabled}
        isRequired
        value={props.date}
        allowTextInput={true}
        onSelectDate={val => setDate(val)}
      />
      <Text style={{ color: palette.neutralSecondary }} variant='small'>{dateName}</Text>
    </Stack>
  );
};
