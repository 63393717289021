import React from 'react';

import { Chart, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
Chart.register(TimeScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, annotationPlugin);

import { Line } from 'react-chartjs-2';

import moment from 'moment';
import 'chartjs-adapter-moment';

import { IVistoPlan, VistoKeyResultItem, VistoKeyResultValueItem } from 'sp';
import { useTheme, Stack } from '@fluentui/react';
import strings from 'VistoWebPartStrings';
import { AppContext } from 'services/AppContext';
import { TextService } from 'services/TextService';
import { PlanKeyresultsService } from 'services/PlanKeyresultsService';
import { HtmlLegendPlugin } from 'components/HtmlLegendPlugin';
import { ColorService } from 'services/colorService';
import { PlanDataService } from 'services/PlanDataService';

const getJsonDate = (d: Date) => d ? TextService.getDate(d).toJSON() : undefined;

export const EditKeyResultChart = (props: {
  plan: IVistoPlan;
  kr: VistoKeyResultItem;
  targets?: VistoKeyResultValueItem[];
  actuals?: VistoKeyResultValueItem[];
  minHeight?: number;
  title?: string;
}) => {

  moment.locale(TextService.uiLanguage);

  const { palette } = useTheme();

  const date = getJsonDate(props.plan.statusDate);
  const activeFocus = PlanDataService.getActiveFocus(props.plan);
  const viewStartDate = activeFocus && getJsonDate(activeFocus.startDate);
  const viewEndDate = activeFocus && getJsonDate(activeFocus.endDate);

  const datasets = [];
  if (props.actuals) {
    datasets.push({
      item: props.kr,
      source: props.actuals,
      label: TextService.format(strings.EditKeyResultListChart_LabelActual),
      borderColor: palette.themePrimary,
      data: props.actuals.map(v => ({
        x: v.valueDate,
        y: v.value
      })),
      tension: 0,
      borderWidth: 3
    });
  }

  if (props.targets) {
    datasets.push({
      item: props.kr,
      source: props.targets,
      label: TextService.format(strings.EditKeyResultListChart_LabelTarget),
      borderColor: palette.neutralTertiary,
      data: props.targets.map(v => ({
        x: v.valueDate,
        y: v.value
      })),
      tension: 0,
      borderWidth: 1,
      borderDash: [10,5],
      // pointStyle: 'rect',
      // pointBackgroundColor: palette.neutralTertiary,
      // pointBorderWidth: 3,
    })
  }

  const chartOptions = {
    plugins: {
      htmlLegend: {
        // ID of the container to put the legend in
        containerId: 'legend-container',
        todayDate: date,
        todayColor: palette.red,
        focusStartDate: viewStartDate,
        focusEndDate: viewEndDate,
        focusTitle: `${TextService.formatTitle(activeFocus, props.plan.items)} 🟊`,
        focusColor: ColorService.hexToRGB(palette.neutralSecondary, '0.2')
      },
      legend: {
        display: false
      },
      tooltip: {
        callbacks: {
          title: (context) => {
            const ctx = context[0];
            const title = TextService.formatDate(TextService.getDate(ctx.raw['x']));
            return `${title} ${ctx.dataset.label}`;
          },
          label: (ctx) => {
            const val = PlanKeyresultsService.formatKeyResultValue(ctx.raw['y'], ctx.dataset.item, true);
            const krv: VistoKeyResultValueItem = ctx.dataset.source[ctx.dataIndex];
            return `${val} ${krv.description || ''}`;
          }
        }
      },
      annotation: {
        annotations: {
          today: {
            type: 'line',
            xMin: date,
            xMax: date,
            borderDash: [5, 5],
            borderColor: palette.red,
            borderWidth: 1,
          },
          focus: {
            type: 'box',
            xMin: viewStartDate,
            xMax: viewEndDate,
            // adjustScaleRange: false,
            backgroundColor: ColorService.hexToRGB(palette.neutralSecondary, '0.2')
          }
        }
      }
    },
    scales: {
      y: {
        title: {
          display: true,
          text: `${props.kr.name} (${props.kr.units})`,
          color: palette.themePrimary
        }, 
        ticks: {
          color: palette.themePrimary
        }
      },
      x: {
        type: 'time',
      }
    }
  };

  const chartPlugins = [HtmlLegendPlugin];

  return (
    <Stack style={{ minHeight: props.minHeight }}>
      <div id='legend-container' style={{ alignSelf: 'end' }}></div>
      <Line title={props.title} datasetIdKey='label' data={{ datasets }} plugins={chartPlugins} options={chartOptions as any} />
    </Stack>
  );
};
