import * as React from 'react';
import { DefaultButton, Callout, IContextualMenuProps, Stack, IChoiceGroupOption, IconButton } from '@fluentui/react';
import * as strings from 'VistoWebPartStrings';
import { TextService } from 'services/TextService';

const buttonStyles = {
  splitButtonMenuButton: {
    backgroundColor: 'transparent',
    border: '0'
  },
  root: {
    minWidth: 58,
    padding: 6,
    margin: '0 2px 0 2px 0',
    backgroundColor: 'transparent',
    border: '0'
  },
};

const horizontal: IChoiceGroupOption[] = [
  { key: 'left', text: TextService.format(strings.ArrangeComboBox_Left), iconProps: { iconName: 'AlignHorizontalLeft' } },
  { key: 'center', text: TextService.format(strings.ArrangeComboBox_Center), iconProps: { iconName: 'AlignHorizontalCenter' } },
  { key: 'right', text: TextService.format(strings.ArrangeComboBox_Right), iconProps: { iconName: 'AlignHorizontalRight' } },
];

const vertical: IChoiceGroupOption[] = [
  { key: 'top', text: TextService.format(strings.ArrangeComboBox_Top), iconProps: { iconName: 'AlignVerticalTop' } },
  { key: 'middle', text: TextService.format(strings.ArrangeComboBox_Middle), iconProps: { iconName: 'AlignVerticalCenter' } },
  { key: 'bottom', text: TextService.format(strings.ArrangeComboBox_Bottom), iconProps: { iconName: 'AlignVerticalBottom' } },
];

const distribute: IChoiceGroupOption[] = [
  { key: 'horizontal', text: TextService.format(strings.ArrangeComboBox_Horizontal), iconProps: { iconName: 'HorizontalDistributeCenter' } },
  { key: 'vertical', text: TextService.format(strings.ArrangeComboBox_Vertical), iconProps: { iconName: 'VerticalDistributeCenter' } },
];

const zindex: IChoiceGroupOption[] = [
  { key: 'toFront', text: TextService.format(strings.ArrangeComboBox_BringToFront), iconProps: { iconName: 'ArrangeBringToFront' } },
  { key: 'toBack', text: TextService.format(strings.ArrangeComboBox_SendToBack), iconProps: { iconName: 'ArrangeSendToBack' } },
];

export function ArrangeComboBox(props: {
  disabled: boolean;
  setArrange(val: string);
}) {

  const renderDropdnown = (menuProps: IContextualMenuProps) => {

    return (
      <Callout target={menuProps.target} onDismiss={menuProps.onDismiss} isBeakVisible={false}>
        <Stack tokens={{ childrenGap: 's2', padding: 's1' }}>
          <strong>{TextService.format(strings.ArrangeComboBox_GroupFrontBack)}</strong>
          <Stack horizontal tokens={{ childrenGap: 's2' }}>
            {zindex.map((x, i) =>
              <IconButton title={x.text} iconProps={{ iconName: x.iconProps.iconName }} text={x.text} onClick={() => props.setArrange(x.key)} />)
            }
          </Stack>
          <strong>{TextService.format(strings.ArrangeComboBox_GroupAlignHorizontal)}</strong>
          <Stack horizontal tokens={{ childrenGap: 's2' }}>
            {horizontal.map((x, i) =>
              <IconButton title={x.text} iconProps={{ iconName: x.iconProps.iconName }} text={x.text} onClick={() => props.setArrange(x.key)} />)
            }
          </Stack>
          <strong>{TextService.format(strings.ArrangeComboBox_GroupAlignVertical)}</strong>
          <Stack horizontal tokens={{ childrenGap: 's2' }}>
            {vertical.map((x, i) =>
              <IconButton title={x.text} iconProps={{ iconName: x.iconProps.iconName }} text={x.text} onClick={() => props.setArrange(x.key)} />)
            }
          </Stack>
          <strong>{TextService.format(strings.ArrangeComboBox_GroupDistribute)}</strong>
          <Stack horizontal tokens={{ childrenGap: 's2' }}>
            {distribute.map((x, i) =>
              <IconButton title={x.text} iconProps={{ iconName: x.iconProps.iconName }} text={x.text} onClick={() => props.setArrange(x.key)} />)
            }
          </Stack>
        </Stack>
      </Callout>
    );
  };

  return (
    <DefaultButton
      styles={buttonStyles}
      title={TextService.format(strings.ArrangeComboBox_Title)}
      iconProps={{ iconName: 'AlignHorizontalLeft' }}
      menuAs={renderDropdnown}
      disabled={props.disabled}
      menuProps={{ items: [] }}
    />
  );
}
