import React from 'react';
import { ActionButton, IContextualMenuItem, TooltipHost } from '@fluentui/react';
import { IVistoPlan, VistoFocusItem } from 'sp';
import { TextService } from 'services/TextService';
import strings from 'VistoWebPartStrings';
import { PlanDataService } from 'services/PlanDataService';

export const FocusFilter = (props: {
  plan: IVistoPlan;
  disabled: boolean;
  selectedFocus: VistoFocusItem;
  setSelectedFocus: (val: VistoFocusItem) => void;
}) => {

  const focuses = PlanDataService.getFocuses(props.plan);
  const focus = props.selectedFocus;
  const dates = TextService.foramtDateRange(strings.SidebarTimeFrameBlock_Text, focus?.startDate, focus?.endDate);
  const text = focus?.name;

  const items = focuses
    .map(x => {
      const item: IContextualMenuItem = {
        key: x.guid,
        text: x.name + (x.active ? ' ★' : ''),
        title: TextService.foramtDateRange(strings.SidebarTimeFrameBlock_Text, x.startDate, x.endDate),
        onClick: () => props.setSelectedFocus(x)
      };
      return item;
    })

  return (
    <TooltipHost content={dates}>
      <ActionButton disabled={props.disabled} menuProps={{ items }} text={text} iconProps={{ iconName: 'ExploreData' }} />
    </TooltipHost>
  );
};
