import { Slider } from '@fluentui/react/lib/Slider';
import React from 'react';
import { TextService } from 'services/TextService';
import strings from 'VistoWebPartStrings';

export const ImageOpacitySlider = (props: {
  disabled: boolean;
  opacity: number;
  setOpacity: (val: number) => void;
}) => {
  return (
    <Slider 
      label={TextService.format(strings.ImageOpacitySlider_Label)}
      disabled={props.disabled}
      min={0} 
      max={100} 
      step={10} 
      showValue 
      value={props.opacity} 
      onChange={v => props.setOpacity(v)} 
    />
  );
};
