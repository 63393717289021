import { ISearchBoxStyles, SearchBox, useTheme } from '@fluentui/react'
import React from 'react'

export const TopFilter = (props: {
  placeholder: string;
  value: string;
  setValue: (value: string) => void;
}) => {

  const { palette } = useTheme();

  const styles = {
    root: {
      width: 0,
      flexGrow: 1,
    }
  };

  if (props.value) {
    styles.root['backgroundColor'] = palette.themeLighter;
  }

  return <SearchBox
    styles={styles}
    placeholder={props.placeholder}
    value={props.value}
    onChange={(_, v) => props.setValue(v)}
  />
}
