import * as React from 'react';
import { IVistoListItem } from 'sp';
import { IconButton, FontSizes } from '@fluentui/react';
import { MenuItems } from 'frames/TopFrame/MenuItems';
import { AppContext } from 'services/AppContext';
import { AttachmentService } from 'services/AttachmentService';

export function IconButtonAttachments(props: {
  item: IVistoListItem
}) {

  const { dispatchCommand, notify } = React.useContext(AppContext);

  if (!props.item.links)
    return null;

  return (<IconButton
    style={{ fontSize: FontSizes.xLarge }}
    menuIconProps={{ iconName: 'Attach' }}
    menuProps={{ items: MenuItems.getAttachmentsMenuItems(props.item, dispatchCommand, AttachmentService.makeItemAttachmentsCommand, notify) }}
  />);
}
