import * as React from 'react';

import { IFieldValueUser } from 'sp';
import { AppContext } from 'services/AppContext';
import { AssigneeBlockBase } from './AssigneeBlockBase';

export function AssigneeBlock(props: {
  assignedTo: IFieldValueUser[];
  hideDetails?: boolean;
}) {

  const { propertyBag } = React.useContext(AppContext);
  const haveAssignees = propertyBag?.showAssignees && props.assignedTo?.length > 0;

  return haveAssignees
    ? <AssigneeBlockBase assignedTo={props.assignedTo} hideDetails={props.hideDetails} />
    : null;
}
