import * as React from 'react';
import * as strings from 'VistoWebPartStrings';
import { DefaultButton, Dialog, DialogType, Stack, TextField, Text, PrimaryButton } from '@fluentui/react';
import { defaultModalProps } from './common';
import { TextService } from 'services/TextService';

export function CopyLinkDialogBase(props: {
  content: string;
  linkLabel: string;
  link: string;
  onDismiss: () => void;
}) {

  const onCopy = () => {
    document.execCommand('copy');
    props.onDismiss();
  };

  return (
    <Dialog
      minWidth={640}
      hidden={false}
      dialogContentProps={{ title: TextService.format(strings.CopyLinkDialog_Title), type: DialogType.normal }}
      modalProps={defaultModalProps}
      onDismiss={() => props.onDismiss()} >

      <Text>{props.content}</Text>

      <TextField
        readOnly
        label={props.linkLabel}
        value={props.link}
        autoFocus
        onFocus={event => event.target.select()}
      />

      <Stack horizontal horizontalAlign='end' tokens={{ childrenGap: 'l1', padding: 'm 0 0 0' }} grow>
        <DefaultButton onClick={() => props.onDismiss()} text={TextService.format(strings.ButtonCancel)} />
        <PrimaryButton onClick={onCopy} text={TextService.format(strings.CopyLinkDialog_ButtonCopy)} />
      </Stack>
    </Dialog>
  );
}
