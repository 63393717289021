import React from 'react';
import { DirectionalHint, TeachingBubble } from '@fluentui/react';
import { TextService } from 'services/TextService';
import strings from 'VistoWebPartStrings';

export const Tutorial = (props: {
  step: string;
  setStep: (val: string) => void;
}) => {

  return (<>
    {props.step === 'first' &&
      <TeachingBubble
        calloutProps={{ directionalHint: DirectionalHint.rightTopEdge }}
        target='.geSidebarContainer'
        primaryButtonProps={{ children: TextService.format(strings.Tutorial_next_tip), onClick: () => props.setStep('second') }}
        secondaryButtonProps={{ children: TextService.format(strings.Tutorial_close), onClick: () => props.setStep('done') }}
        headline={TextService.format(strings.Tutorial_drag_drop_headline)}
      >{TextService.format(strings.Tutorial_drag_drop_text)}</TeachingBubble>
    }
    {props.step === 'second' &&
      <TeachingBubble
        calloutProps={{ directionalHint: DirectionalHint.bottomCenter }}
        target='#ToggleEditButton'
        primaryButtonProps={{ children: TextService.format(strings.Tutorial_done), onClick: () => props.setStep('done') }}
        headline={TextService.format(strings.Tutorial_save_headline)}
      >{TextService.format(strings.Tutorial_save_text)}</TeachingBubble>
    }
  </>);
};
