import strings from 'VistoWebPartStrings';
import { mx } from 'frames/TopFrame/drawing/common';
import { CommandName } from 'shared/CommandName';
import { IVistoPlan, IVistoListItemWithProgress } from 'sp';
import { ICommand } from './ICommand';
import { INotify } from './Notify';
import { PlanDataService } from './PlanDataService';
import { StorageService } from './StorageService';
import { TextService } from './TextService';

export class CommandsMx {

  public static makeDeleteItemAction(plan: IVistoPlan, item: IVistoListItemWithProgress, notify: INotify): ICommand {
    const items = [item, ...PlanDataService.getDependencis(plan.items, item)];
    return {
      prepare: async () => {
        return {
          do: async (p, editor) => {
            if (editor?.graph) {
              mx.removeCells(editor.graph, items.map(x => x.guid));
            }
            p = await StorageService.get(p.siteUrl).deleteItems(p, items, notify, { excludeExternals: true, validate: true });
            return p;
          },
          undo: async (p, editor) => {
            // TODO: restore the graphics
            p = await StorageService.get(p.siteUrl).createItems(p, items, notify, { excludeExternals: true, validate: true });
            return p;
          },
        };
      },
      message: TextService.format(strings.Commands_DeleteItemMessage, { title: TextService.formatTitle(item, plan.items) }),
      name: CommandName.DeleteItemWithDependencies
    };
  }

  public static makeDeleteItemNotificationAction(plan: IVistoPlan, item: IVistoListItemWithProgress, notify: INotify) {
    return {
      title: TextService.format(strings.Command_DeleteVisPlanItemTitle, { kindName: TextService.getVistoKindName(item.kind) }),
      command: CommandsMx.makeDeleteItemAction(plan, item, notify),
      confirmation: {
        buttonOkText: TextService.format(strings.ButtonDelete),
        buttonOkBusyText: TextService.format(strings.ButtonDeleting),
        buttonCancelText: TextService.format(strings.ButtonCancel),
        title: TextService.format(strings.Command_DeleteVisPlanItem),
        content: TextService.format(strings.Command_DeleteVisPlanItemDescription, { title: TextService.formatTitle(item, plan.items) })
      }
    };
  }

}
