import { VistoKind, IFieldDefinitions, IVistoListItem, addListDefinition, ListItemUpdater, Choices } from './common';
import { FieldGenerator } from './common/FieldGenerator';

export interface VistoAssocItem extends IVistoListItem {
  readonly actionGuid?: string;
  readonly soGuid?: string;
  readonly confidence?: number;
  readonly krGuid?: string;
  readonly showOnDiagram?: boolean;
}

const confidences: Choices = [
  { key: 1, stringId: '__ConfidenceLow' },
  { key: 3, stringId: '__ConfidenceMedium' },
  { key: 9, stringId: '__ConfidenceHigh' }
];

export const VistoAssocListFields: IFieldDefinitions<VistoAssocItem> = {
  itemId: FieldGenerator.makeIdField('VISTO_Assoc_ItemId'),
  guid: FieldGenerator.makeGuidField('VISTO_Assoc_Guid'),
  name: FieldGenerator.makeTitleField('VISTO_Assoc_Name'),
  description: FieldGenerator.makeDescriptionField('VISTO_Assoc_Description'),
  links: FieldGenerator.makeLinksField('VISTO_Assoc_Links'),
  actionGuid: FieldGenerator.makeLookupField('VISTO_Assoc_Action', 'Action', true, VistoKind.Action, false),
  soGuid: FieldGenerator.makeLookupField('VISTO_Assoc_SO', 'SO', true, VistoKind.SO, true),
  confidence: FieldGenerator.makeChoiceField('VISTO_Assoc_Confidence', 'Confidence', true, confidences),
  createdDate: FieldGenerator.makeCreatedField('VISTO_Assoc_Created'),
  modifiedDate: FieldGenerator.makeModifiedField('VISTO_Assoc_Modified'),
  krGuid: FieldGenerator.makeLookupField('VISTO_Assoc_KeyResult', 'KeyResult', true, VistoKind.KeyResult, false),
  showOnDiagram: FieldGenerator.makeBooleanField('VISTO_KR_ShowOnDiagram', 'ShowOnDiagram', true),
};

addListDefinition({

  kind: VistoKind.Assoc,
  fields: VistoAssocListFields,
  enableVersioning: true,
  majorVersionLimit: 500,
  defaultViewXml: `<View>
    <Query />
    <ViewFields>
      <FieldRef Name='${VistoAssocListFields.actionGuid.name}' />
      <FieldRef Name='${VistoAssocListFields.soGuid.name}' />
      <FieldRef Name='${VistoAssocListFields.confidence.name}' />
      <FieldRef Name='${VistoAssocListFields.showOnDiagram.name}' />
      <FieldRef Name='${VistoAssocListFields.description.name}' />
    </ViewFields>
    <RowLimit Paged='TRUE'>30</RowLimit>
  </View>`,

  setItemSpecificFields: (updater: ListItemUpdater, spItem, update: Partial<VistoAssocItem>) => {
    updater.setItemSpField(VistoAssocListFields, spItem, update, 'actionGuid');
    updater.setItemSpField(VistoAssocListFields, spItem, update, 'soGuid');
    updater.setItemSpField(VistoAssocListFields, spItem, update, 'krGuid');
    updater.setItemSpField(VistoAssocListFields, spItem, update, 'confidence');
    updater.setItemSpField(VistoAssocListFields, spItem, update, 'showOnDiagram');
  },

  getItemSpecificFields: (updater: ListItemUpdater, item: Partial<VistoAssocItem>, spItem) => {
    updater.getItemSpField(VistoAssocListFields, item, spItem, 'actionGuid');
    updater.getItemSpField(VistoAssocListFields, item, spItem, 'soGuid');
    updater.getItemSpField(VistoAssocListFields, item, spItem, 'krGuid');
    updater.getItemSpField(VistoAssocListFields, item, spItem, 'confidence');
    updater.getItemSpField(VistoAssocListFields, item, spItem, 'showOnDiagram');
  }
});
