import { loadStyles } from '@microsoft/load-themed-styles';

const styles = {
  title: 'title_65786b70',
  name: 'name_28cf8da1',
};

export default styles;

loadStyles('.title_65786b70{position:relative}.title_65786b70 .name_28cf8da1{white-space:pre-line}');
