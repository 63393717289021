import * as strings from 'VistoWebPartStrings';
import * as React from 'react';
import { Progress } from '@pnp/spfx-controls-react/lib/controls/progress';
import { mergeStyles, Stack, Toggle, Text, Spinner, Icon, IIconStyleProps, FontSizes } from '@fluentui/react';
import { IExecutableAction } from 'services/IExecutableAction';
import { TextService } from 'services/TextService';
import { PlanWizardOperation } from './PlanWizardTypes';

export function PlanWizardProgressPage(props: {
  operation: PlanWizardOperation;
  statusText: string;
  actions: IExecutableAction[];
  currentProgressActionIndex?: number;
}) {

  const [showDetails, setShowDetails] = React.useState(false);

  const actionsContainerClassName = mergeStyles({
    display: showDetails ? 'block' : 'none'
  });

  const isRunning = TextService.isValidNumber(props.currentProgressActionIndex) && props.currentProgressActionIndex < props.actions.length;
  const isFinished = TextService.isValidNumber(props.currentProgressActionIndex) && props.currentProgressActionIndex === props.actions.length;

  const haveErrors = props.actions.some(a => a.hasError);
  const finishIconName = haveErrors ? 'Error' : 'CheckMark';
  const getStyles = (styleProps: IIconStyleProps) => {
    return {
      root: {
        fontSize: FontSizes.large,
        color: haveErrors
          ? styleProps.theme.semanticColors.errorIcon
          : styleProps.theme.semanticColors.successIcon
      }
    };
  };

  return (
    <Stack grow>
      <Stack horizontal verticalAlign='center' tokens={{ childrenGap: 'm' }}>
        {isRunning && <Spinner />}
        {isFinished && <Icon iconName={finishIconName} styles={getStyles} />}
        <Text variant='mediumPlus'>{props.statusText}</Text>
      </Stack>
      <Stack grow>
        <Progress
          key={'' + showDetails}
          height='275px'
          showOverallProgress={true}
          showIndeterminateOverallProgress={false}
          hideNotStartedActions={false}
          actions={props.actions}
          actionsContainerClassName={actionsContainerClassName}
          currentActionIndex={props.currentProgressActionIndex}
          longRunningText={TextService.format(strings.PlanWizardProgressPage_LongerThanExpected)}
          longRunningTextDisplayDelay={60000}
        />
      </Stack>
      {(haveErrors || props.operation === PlanWizardOperation.Upgrade) &&
        <Toggle
          label={TextService.format(strings.PlanWizardProgressPage_HideDetails)}
          checked={!showDetails}
          onChange={() => setShowDetails(!showDetails)}
        />
      }
    </Stack>
  );
}
