import { MsalProvider } from '@azure/msal-react';
import { ThemeProvider, Stack, Text, Image, mergeStyles } from '@fluentui/react';
import { AppTheme } from 'components/AppTheme';
import React from 'react';
import { Link, Outlet } from 'react-router-dom';
import { WebAuthService } from 'teams/services/WebAuthService';
import styles from './AppFrame.module.scss';
import { LoginControl } from './LoginControl';
import { TextService } from 'services/TextService';
import strings from 'VistoWebPartStrings';

const iconUrl = require('static/assets/icon.svg');

export const AppFrame = () => {

  const defaultTheme = AppTheme.loadAppTheme();

  const bodyStyle: React.CSSProperties = {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  };
  
  const topnavStyle = mergeStyles(styles.topnav, {
    boxShadow: defaultTheme.effects.elevation16,
    color: defaultTheme.palette.neutralPrimary
  });
  
  const isDesktop = window.location.protocol === 'file:';

  return (
    <ThemeProvider theme={defaultTheme} style={bodyStyle}>
      <MsalProvider instance={WebAuthService.msal}>
        <Stack className={topnavStyle} horizontal verticalAlign='center' horizontalAlign='space-between' tokens={{ childrenGap: 'm', padding: '0 m' }}>
          <Link to='/'>
            <Stack horizontal verticalAlign='center' tokens={{ childrenGap: 'm' }}>
              <Image height={32} width={32} src={iconUrl} />
              <Text variant='xLarge'>{TextService.format(strings.AppFrame_Title)}</Text>
            </Stack>
          </Link>
          {!isDesktop && <LoginControl />}
        </Stack>
        <Stack grow>
          <Outlet />
        </Stack>
      </MsalProvider>
    </ThemeProvider>
  );
};
