import { SvgIndicators } from 'graphics/SvgIndicators';
import React from 'react';
import { IVistoPlan, KeyResultType, VistoKeyResultItem, VistoKeyResultValueItem } from 'sp';

export const ProgressCircle = (props: {
  size: number;
  plan: IVistoPlan;
  kr: VistoKeyResultItem;
  viewStartDate?: Date;
  viewEndDate?: Date;
  targets: VistoKeyResultValueItem[];
  actuals: VistoKeyResultValueItem[];
}) => {

  const svg = (props.kr.keyResultType === KeyResultType.Binary)
    ? SvgIndicators.buildSvgDone(props.size, props.plan, props.targets, props.actuals, props.viewStartDate, props.viewEndDate)
    : (props.kr.keyResultType === KeyResultType.Budget)
      ? SvgIndicators.buildSvgBudget(props.size, props.plan, props.targets, props.actuals, props.viewStartDate, props.viewEndDate, props.kr.inverse)
      : SvgIndicators.buildSvgTrend(props.size, props.plan, props.targets, props.actuals, props.viewStartDate, props.viewEndDate, props.kr.inverse);

  const style = {
    width: props.size, 
    height: props.size,
  };

  return (<div style={style} dangerouslySetInnerHTML={{ __html: svg }}></div>);
}
