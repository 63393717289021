import { api } from 'shared/api';
import { trackClient } from 'shared/clientTelemetry';
import { parseResponseError, parseResponseJSON } from 'shared/parse';
import { IEnvContext } from './EnvContext';
import { StorageCacheService } from './StorageCacheService';

export class ApiService {

  public static getIdToken: () => Promise<string>;
  public static configure(getIdToken: () => Promise<string>) {
    this.getIdToken = getIdToken;
  }

  public static async fetch<T>(path: string, method: string, data: any, cacheKey?: string): Promise<T> {

    if (cacheKey) {
      const val: T = StorageCacheService.getValue(cacheKey);
      if (val) {
        return val;
      }
    }

    const token = await this.getIdToken();
    const response = await fetch(path, {
      method,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: data && JSON.stringify(data),
      mode: 'cors',
      cache: 'default'
    });
    if (response.ok) {
      const result = await parseResponseJSON(response);
      if (cacheKey) {
        StorageCacheService.setValue(cacheKey, result);
      }
      return result;
    } else {
      throw await parseResponseError(response);
    }
  }

  public static async openPlan(envContext: IEnvContext, planId: string) {

    const req: api.IPlanOpenedRequest = {
      plan_id: planId,
      group_id: envContext.groupId,
      site_url: envContext.siteUrl,
      channel_id: envContext.channelId,
    };

    const result = await this.fetch<api.IPlanOpenedResponse>(`/plan/open`, 'post', req);
    return result;
  }

  public static async getFluidToken(tenantId: string, containerId: string) {
    const req: api.IFluidTokenRequest = { tenantId, containerId };
    trackClient.debug(`[FLUID] getting fluid token for ${tenantId} ${containerId}`);
    const result = await this.fetch<api.IFluidTokenResponse>(`/auth/fluid`, 'post', req);
    return result.token;
  }

  public static async getRelatedPlans(tid: string, oid: string, groupIds: string[], channelIds: string[])  {
    const req: api.IDashboardPlansRequest = { groupIds, channelIds };
    const cacheKey = `https://dashboard.${tid}.${oid}`;
    const result = await this.fetch<api.IDashboardPlansResponse>(`/plan/related`, 'post', req, cacheKey);
    return result.plans;
  }

  public static async markPlanDeleted(planRef: string, channelRef: string) {
    const req: api.IMarkPlanDeletedRequest = { planRef, channelRef };
    const result = await this.fetch<api.IMarkPlanDeletedResponse>(`/plan/markDeleted`, 'post', req);
    StorageCacheService.resetCache('https://dashboard');
    return result.deleted;
  }

  public static async markPlanPinned(planRef: string, channelRef: string, pinned: boolean) {
    const req: api.IMarkPlanPinnedRequest = { planRef, channelRef, pinned };
    const result = await this.fetch<api.IMarkPlanPinnedResponse>(`/plan/markPinned`, 'post', req);
    StorageCacheService.resetCache('https://dashboard');
    return result.pinned;
  }

  public static async setPlanChildren(parentPlanSiteUrl: string, parentPlanId: string, childRefs: string[]) {
    const req: api.ISetPlanChildrenRequest = { parentPlanSiteUrl, parentPlanId, childRefs };
    const result = await this.fetch<api.ISetPlanChildrenResponse>(`/plan/setPlanChildren`, 'post', req);
    StorageCacheService.resetCache('https://dashboard');
    return result.succeeded;
  }

  public static async connectParentChild(parentPlanSiteUrl: string, parentPlanId: string, childRef: string) {
    const req: api.IConnectParentChildRequest = { parentPlanSiteUrl, parentPlanId, childRef };
    const result = await this.fetch<api.IConnectParentChildResponse>(`/plan/connectParentChild`, 'post', req);
    StorageCacheService.resetCache('https://dashboard');
    return result.succeeded;
  }

  public static async disconnectParentChild(parentPlanSiteUrl: string, parentPlanId: string, childRef: string) {
    const req: api.IDisconnectParentChildRequest = { parentPlanSiteUrl, parentPlanId, childRef };
    const result = await this.fetch<api.IDisconnectParentChildResponse>(`/plan/disconnectParentChild`, 'post', req);
    StorageCacheService.resetCache('https://dashboard');
    return result.succeeded;
  }

  public static async fetchImage(url: string, token: string): Promise<Blob> {
    const options = {
      headers: {
        'Content-Type': 'application/json',
        mode: 'cors',
        cache: 'default'
      }
    };
    if (token) {
      options.headers['Authorization'] = `Bearer ${token}`;
    }
    try {
      const response = await fetch(url, options);
      const blob = await response.blob();
      return blob;
    } catch (err) {
      const proxyUrl = `/image?url=${encodeURIComponent(url)}`;
      const response = await fetch(proxyUrl, options);
      const blob = await response.blob();
      return blob;
    }
  }
}
