import { FontSizes, mergeStyles, useTheme } from '@fluentui/react';
import { RichTextTooltipHost } from 'components';
import React from 'react';
import { PlanKeyresultsService } from 'services/PlanKeyresultsService';
import { TextService } from 'services/TextService';
import { IVistoPlan, VistoKeyResultItem, VistoKeyResultValueItem } from 'sp';
import strings from 'VistoWebPartStrings';
import { BudgetProgressBar } from './BudgetProgressBar';
import { ProgressBarTooltip } from './ProgressBarTooltip';

export const ProgressBarBlockBudget = (props: {
  plan: IVistoPlan;
  viewStartDate?: Date;
  viewEndDate?: Date;
  targets: VistoKeyResultValueItem[];
  actuals: VistoKeyResultValueItem[];
  kr: VistoKeyResultItem;
  onClick?: () => void;
}) => {

  const { palette } = useTheme();

  let { currentValue, referenceValue } = PlanKeyresultsService.getKeyResultProgress(props.targets, props.actuals,
    props.viewStartDate, props.viewEndDate, props.plan.statusDate);

  if (props.kr.inverse) {
    [currentValue, referenceValue] = [referenceValue, currentValue];
  }

  const invalid = !TextService.isValidNumber(currentValue) || !TextService.isValidNumber(referenceValue);
  let positive = currentValue < referenceValue;
  const progress = Math.round(100 * (positive ? (currentValue / referenceValue) : (referenceValue / currentValue)));
  const color = invalid ? palette.neutralLight : positive ? '#92D050' : '#F26E6E';

  const budget = PlanKeyresultsService.formatKeyResultValue(referenceValue, props.kr, true);
  const spent = PlanKeyresultsService.formatKeyResultValue(currentValue, props.kr, true);
  const difference = PlanKeyresultsService.formatKeyResultValue(referenceValue - currentValue, props.kr, true);

  const rows = [
    {
      label: TextService.format(strings.BudgetProgressBar_TooltipLabel_Budget),
      value: props.kr.inverse ? spent : budget
    },
    {
      label: TextService.format(props.kr.inverse
        ? strings.BudgetProgressBar_TooltipLabel_Actual
        : strings.BudgetProgressBar_TooltipLabel_Spent),
      value: props.kr.inverse ? budget : spent
    },
    {
      label: TextService.format(props.kr.inverse
        ? strings.BudgetProgressBar_TooltipLabel_Variance
        : strings.BudgetProgressBar_TooltipLabel_Remaining),
      value: difference, color
    }
  ];

  return (
    <RichTextTooltipHost tooltipFooter={<ProgressBarTooltip rows={rows} />}>
      <BudgetProgressBar onClick={props.onClick} value={progress} color={color} valueText={difference} />
    </RichTextTooltipHost>
  );
};
