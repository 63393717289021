import React from 'react'
import { Image } from '@fluentui/react';

export const DevOpsIcon = (props: {
  size: number;
  src: string;
  title?: string;
}) => {
  return (
    <Image width={props.size} height={props.size} src={props.src} title={props.title} />
  )
}
