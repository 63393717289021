import React from 'react';
import { Text, Stack, IconButton, useTheme, DefaultButton, Tooltip, TooltipHost } from '@fluentui/react';
import { ITextMessage, TextPlanJson } from 'shared/PlanGenerationModel';
import { parseJSON } from 'shared/parse';
import { IAssistatCard } from 'frames/IAssistatCard';
import { TextService } from 'services/TextService';
import strings from 'VistoWebPartStrings';

const defaultPlanJson = {
  planName: TextService.format(strings.PlanName_ErrorGenerating),
  capabilities: [],
};

// Plan Card Component
export const AssistantCard = (props: {
  card: IAssistatCard;
}) => {

  const [isExpanded, setIsExpanded] = React.useState(false);

  const planJson = parseJSON(props.card.content, defaultPlanJson) as TextPlanJson;

  const theme = useTheme();

  const onAccpt = async () => {
    if (props.card.onAccept) {
      props.card.showButtons = false;
      await props.card.onAccept();
    }
  }

  const onReject = async () => {
    if (props.card.onReject) {
      props.card.showButtons = false;
      await props.card.onReject();
    }
  }

  return (
    <Stack
      tokens={{ childrenGap: 8, padding: 12 }}
      styles={{
        root: {
          border: theme.palette.neutralTertiary,
          backgroundColor: theme.palette.neutralLighter,
          boxShadow: theme.effects.elevation4,
          borderRadius: theme.effects.roundedCorner4,
          maxWidth: '350px',
          marginBottom: '10px',
        },
      }}
    >
      <Text variant='small'><strong>Assistant</strong></Text>
      <Stack horizontal verticalAlign='center' tokens={{ childrenGap: 8 }}>
        <Text variant='large'>{planJson.planName}</Text>
        <IconButton
          iconProps={{ iconName: isExpanded ? 'ChevronUp' : 'ChevronDown' }}
          ariaLabel={isExpanded ? TextService.format(strings.MenuItem_Collapse) : TextService.format(strings.MenuItem_Expand)}
          onClick={() => setIsExpanded(!isExpanded)}
          styles={{ root: { marginLeft: 'auto' } }}
        />
      </Stack>

      {isExpanded && (
        <Stack tokens={{ childrenGap: 8 }} styles={{ root: { paddingLeft: 12, maxHeight: 150, overflowY: 'auto' } }}>
          <ul>
            {planJson.capabilities.map((capability, index) => (
              <li key={index}>
                <Text variant='mediumPlus'>{capability.name}</Text>
                <ul>
                  {capability.ambitions.map((ambition, idx) => (
                    <li key={idx}>
                      <Text variant='small'>{ambition.name}</Text>
                    </li>
                  ))}
                </ul>
              </li>
            ))}
          </ul>
        </Stack>
      )}

      {props.card.warning && <Text style={{ color: theme.palette.yellowDark }}>{props.card.warning}</Text>}
      
      {props.card.showButtons && (
        <Stack horizontal horizontalAlign='end' tokens={{ childrenGap: 's1', padding: 'm 0 0 0' }}>
          <TooltipHost content={props.card.acceptButtonDescription}>
            <DefaultButton iconProps={{ iconName: 'Accept' }} text={props.card.acceptButtonLabel} onClick={onAccpt} />
          </TooltipHost>
          <DefaultButton iconProps={{ iconName: 'Cancel' }} text={TextService.format(strings.AssistantCardButton_Reject)} onClick={onReject} />
        </Stack>
      )}

    </Stack>
  );
};
