import * as React from 'react';
import { Selection, useTheme, mergeStyles, DetailsList, DetailsListLayoutMode, TextField, Stack, IColumn, ITextFieldStyles, Label, DatePicker, PrimaryButton, IDetailsRowProps } from '@fluentui/react';
import { TextService } from 'services/TextService';
import strings from 'VistoWebPartStrings';

const textFieldStyles: Partial<ITextFieldStyles> = { root: { maxWidth: 150 } };

export function ActionLassoDialogList(props: {
  label: string;
  allActions: any[];
  buttonLabel: string;
  startDate?: Date;
  endDate?: Date;
  today?: Date;
  onButtonClick: (guids: string[]) => void;
  isRowBold: (item: any) => boolean;
}) {

  const onColumnClick = (event: React.MouseEvent<HTMLElement>, column: IColumn): void => {
    let isSortedDescending = column.isSortedDescending;

    // If we've sorted this column, flip it.
    if (column.isSorted) {
      isSortedDescending = !isSortedDescending;
    }

    // Sort the items.
    // sortedItems = _copyAndSort(sortedItems, column.fieldName!, isSortedDescending);

    // Reset the items and columns to match the state.

    setColumns(columns.map(col => {
        col.isSorted = col.key === column.key;

        if (col.isSorted) {
          col.isSortedDescending = isSortedDescending;
        }

        return col;
      }),
    );
    applyFilter(filter.current);
  };

  const [columns, setColumns] = React.useState<IColumn[]>([
    {
      key: 'name',
      name: TextService.format(strings.ActionLassoDialog_ColumnNameTitle),
      fieldName: 'name',
      minWidth: 100,
      onColumnClick,
      isResizable: true
    },
    {
      key: 'startDate',
      name: TextService.format(strings.ActionLassoDialog_ColumnStartDateTitle),
      fieldName: 'startDate',
      minWidth: 80,
      onColumnClick,
      isResizable: true,
    },
    {
      key: 'endDate',
      name: TextService.format(strings.ActionLassoDialog_ColumnEndDateTitle),
      fieldName: 'endDate',
      minWidth: 80,
      onColumnClick,
      isResizable: true
    }
  ]);
  
  const allItems = props.allActions.map(item => {
    const name = item.name;
    const startDate = TextService.formatDate(item.startDate);
    const endDate = TextService.formatDate(item.endDate);
    const key = item.guid;
    const comment = item.description;
    return { key, name, startDate, endDate, comment, item };
  });

  const { palette } = useTheme();

  const [selectedItems, setSelectedItems] = React.useState([]);

  const selection = React.useRef(new Selection({
    onSelectionChanged: () => {
      setSelectedItems(selection.current.getSelection() as any[]);
    },
  }));

  const [filteredItems, setFilteredItems] = React.useState(allItems);

  const listWrapperStyle = mergeStyles({
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: palette.neutralTertiary,
    height: '25vh',
    overflow: 'auto'
  });

  type Filter = {
    text?: string;
    startDate?: Date,
    endDate?: Date
  };

  const filter = React.useRef<Filter>({ text: '', startDate: props.startDate, endDate: props.endDate });

  const applyFilter = (f: Filter) => {
    filter.current = f;
    let items = allItems.filter(x => {
      return (!f.text || x.name.toLowerCase().indexOf(f.text.toLowerCase()) > -1)
        && (!f.startDate || TextService.compareDateTime(f.startDate, x.item.endDate) >= 0)
        && (!f.endDate || TextService.compareDateTime(x.item.startDate, f.endDate) >= 0);
      });
      if (columns[0].isSorted) {
        items = items.sort((a, b) => columns[0].isSortedDescending ? TextService.compareNames(b.item.name, a.item.name) : TextService.compareNames(a.item.name, b.item.name));
      }
      if (columns[1].isSorted) {
        items = items.sort((a, b) => columns[1].isSortedDescending ? TextService.compareDateTime(a.item.startDate, b.item.startDate) : TextService.compareDateTime(b.item.startDate, a.item.startDate));
      }
      if (columns[2].isSorted) {
        items = items.sort((a, b) => columns[2].isSortedDescending ? TextService.compareDateTime(a.item.endDate, b.item.endDate) : TextService.compareDateTime(b.item.endDate, a.item.endDate));
      }
      setFilteredItems(items);
  };

  React.useEffect(() => {
    applyFilter({...filter.current });
  }, [props.allActions]);

  const getRowProps = (rowProps?: IDetailsRowProps) => {
    if (props.isRowBold(rowProps.item.item)) {
      return {...rowProps, styles: { root: { fontWeight: 'bold' }} };
    } else {
      return rowProps;
    }
  };

  return (
    <Stack tokens={{ childrenGap: 's2' }}>
      <Label>{props.label}</Label>
      <Stack horizontalAlign='space-between' horizontal tokens={{ childrenGap: 'm' }}>
        <TextField
          placeholder={TextService.format(strings.ActionLassoDialog_FilterPlaceholderName)}
          onChange={(_, text: string) => applyFilter({ ...filter.current, text })}
          styles={textFieldStyles} />
        <DatePicker
          today={props.today}
          initialPickerDate={filter.current.startDate || props.today}
          strings={TextService.datePickerStrings}
          firstDayOfWeek={TextService.firstDayOfWeek}
          formatDate={d => TextService.formatDate(d)}
          placeholder={TextService.format(strings.ActionLassoDialog_FilterPlaceholderEndsAfter)}
          value={filter.current.startDate}
          allowTextInput={true}
          onSelectDate={startDate => applyFilter({ ...filter.current, startDate })}
        />
        <DatePicker
          today={props.today}
          initialPickerDate={filter.current.endDate || props.today}
          strings={TextService.datePickerStrings}
          firstDayOfWeek={TextService.firstDayOfWeek}
          formatDate={d => TextService.formatDate(d)}
          placeholder={TextService.format(strings.ActionLassoDialog_FilterPlaceholderStartsBefore)}
          value={filter.current.endDate}
          allowTextInput={true}
          onSelectDate={endDate => applyFilter({ ...filter.current, endDate })}
        />
        <PrimaryButton
          disabled={selectedItems.length === 0}
          text={props.buttonLabel}
          onClick={() => props.onButtonClick(selectedItems.map(x => x.item.guid))}
        />
      </Stack>
      <Stack data-is-scrollable='true' className={listWrapperStyle}>
        <DetailsList
          styles={{
            headerWrapper: {
              '.ms-DetailsHeader': {
                paddingTop: 0
              }
            }
          }}
          items={filteredItems}
          columns={columns}
          selection={selection.current}
          setKey='set'
          onRenderRow={(props, defaultRender) => defaultRender(getRowProps(props))}
          layoutMode={DetailsListLayoutMode.justified}
          selectionPreservedOnEmptyClick={true} />
      </Stack>
    </Stack>);
}
