import { VistoKind } from './VistoKind';
import { IUserInfo } from './IUserInfo';
import { ILicenseInfo } from 'shared/ILicenseInfo';

export namespace api {

  export enum TokenKind {
    sharepoint,
    planner,
    project,
    devops,
    dashboard,
    excel,
    api
  }

  export function getAuthScopes(kind: TokenKind, domain: string, appId: string, appDomain: string): string[] {
    switch (kind) {
      case api.TokenKind.sharepoint:
        return [`https://${domain}/AllSites.Manage`];

      case api.TokenKind.project:
        return [`https://${domain}/Project.Read`];

      case api.TokenKind.devops:
        return [`499b84ac-1321-427f-aa17-267ca6975798/user_impersonation`];

      case api.TokenKind.planner:
        return [`Tasks.ReadWrite`, `User.ReadBasic.All`];

      case api.TokenKind.dashboard:
        return [`User.Read`, `User.ReadBasic.All`, `Team.ReadBasic.All`, `Channel.ReadBasic.All`];

      case api.TokenKind.excel:
        return [`Files.ReadWrite.All`];

      case api.TokenKind.api:
        return [`api://${appDomain}/${appId}/access_as_user`];

      default:
        return [`openid`, `profile`, `email`, `offline_access`];
    }
  }

  export interface ITokenRequest {
    host: string;
    kind: TokenKind;
    license: boolean;
  }

  export interface ITokenResponse {
    token: string;
    license?: ILicenseInfo;
  }

  export enum WSMessageType {
    status,
    edit,
    select,
    drawingRevision,
    item,
    disconnected
  }

  export interface IWSMessage {
    type: WSMessageType;
  }

  export interface IPlanOpenedRequest {
    plan_id: string;
    group_id: string;
    site_url: string;
    channel_id: string;
  }

  export interface IPlanOpenedResponse {
    container_id?: string;
    license: ILicenseInfo;
  }

  export interface IWSPlanEdit extends IWSMessage {
    type: WSMessageType.edit;
    set: boolean;
  }

  export interface IWSPlanSelect extends IWSMessage {
    type: WSMessageType.select;
    shape: string;
  }

  export interface IWSUserInfo extends IUserInfo {
    connection_id: string;
  }

  export interface IWSPlanStatus extends IWSMessage {
    type: WSMessageType.status;
    editor: string;
    drawing_revision: number;
    selection: { [connectionId: string]: string };
    users: IWSUserInfo[];
  }

  export interface IWSPlanRevision extends IWSMessage {
    type: WSMessageType.drawingRevision;
    drawing_revision: number;
  }

  export enum WSOperation {
    create = 1,
    update = 2,
    delete = 3,
    load = 4,
    settings = 5,
  }

  export interface IWSItemOperationInfo {
    type: WSMessageType.item;
    operation: WSOperation;
    planId: string;
    kind: VistoKind;
    guid: string;
  }

  export interface IMarkPlanDeletedRequest {
    planRef: string;
    channelRef: string;
  }

  export interface IMarkPlanDeletedResponse {
    deleted: boolean;
  }

  export interface IMarkPlanPinnedRequest {
    planRef: string;
    channelRef: string;
    pinned: boolean;
  }

  export interface IMarkPlanPinnedResponse {
    pinned: boolean;
  }

  export interface ISetPlanChildrenRequest {
    parentPlanSiteUrl: string;
    parentPlanId: string;
    childRefs: string[];
  }

  export interface ISetPlanChildrenResponse {
    succeeded: boolean;
  }

  export interface IConnectParentChildRequest {
    parentPlanSiteUrl: string;
    parentPlanId: string;
    childRef: string;
  }

  export interface IConnectParentChildResponse {
    succeeded: boolean;
  }

  export interface IDisconnectParentChildRequest {
    parentPlanSiteUrl: string;
    parentPlanId: string;
    childRef: string;
  }

  export interface IDisconnectParentChildResponse {
    succeeded: boolean;
  }

  export interface IDashboardPlansRequest {
    groupIds: string[];
    channelIds: string[];
  }

  export interface IDashboardPlanItem {
    ChannelRef: string;
    SiteUrl: string;
    PlanRef: string;
    CreatedDate: Date;
    LastAccessedDate?: Date;
    LastAccessedByMeDate?: Date;
    LastModifiedDate?: Date;
    LastModifiedByMeDate?: Date;
    Pinned: boolean;
    GroupId: string;
    Children: {
      ChildRef: string;
      Fixed: boolean;
    }[];
    Users: {
      UserObjectId: string;
      LastModifiedDate?: Date;
      LastAccessedDate?: Date;
    }[];
  }

  export interface IDashboardPlansResponse {
    plans: IDashboardPlanItem[];
  }

  export interface IFluidTokenRequest {
    tenantId: string;
    containerId: string;
  }

  export interface IFluidTokenResponse {
    token: string;
  }

}
