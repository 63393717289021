import { makeGuidString } from 'shared/guid';
import { parseJSON } from 'shared/parse';
import { ITemplate } from './ITemplate';
import { PlanDataService, PlanVersion } from './PlanDataService';
import { ProgressService } from './ProgressService';
import { TextService } from './TextService';
import Mustache from 'mustache';
import strings from 'VistoWebPartStrings';
import { PlanSettingsService } from './PlanSettingsService';

export class PlanTemplateService {

  public static getTemplates(): ITemplate[] {
    return [
      {
        id: 'default',
        name: TextService.format(strings.PlanTemplate_name_default),
        description: TextService.format(strings.PlanTemplate_description_default),
        planJson: require('mxresources/styles/templates/default.jsonc') as string,
      },
      {
        id: 'blank',
        name: TextService.format(strings.PlanTemplate_name_blank),
        description: TextService.format(strings.PlanTemplate_description_blank),
        planJson: require('mxresources/styles/templates/blank.jsonc') as string,
      },
      {
        id: 'windypower',
        name: TextService.format(strings.PlanTemplate_name_windypower),
        description: TextService.format(strings.PlanTemplate_description_windypower),
        planJson: require('mxresources/styles/templates/windypower.jsonc') as string,
      },
      {
        id: 'ebike',
        name: TextService.format(strings.PlanTemplate_name_ebike),
        description: TextService.format(strings.PlanTemplate_description_ebike),
        planJson: require('mxresources/styles/templates/ebike.jsonc') as string,
      }
    ]
  };

  public static createTemplatePlan(template: ITemplate) {

    const now = TextService.getDate(new Date());

    const focusStartDate = new Date(now.getFullYear(), 0, 1);
    const focusEndDate = new Date(now.getFullYear(), 11, 31);

    const actionStartDate = TextService.addDays(now, -14);
    const actionEndDate = TextService.addDays(now, +14);

    const nowYear0 = now.getFullYear();
    const nowYear1 = nowYear0 + 1;
    const nowYear2 = nowYear0 + 2;

    const params = {
      guid: Array.from(Array(1000).keys()).reduce((o, k) => ({ ...o, [`new${k}`]: makeGuidString() }), {}),
      planLanguage: TextService.uiLanguage,
      lopName: TextService.format(strings.PlanTemplate_LOP),
      dpName: TextService.format(strings.PlanTemplate_DP),
      lopNameFocus: TextService.format(strings.PlanTemplate_LOP_Focus),
      dpNameFocus: TextService.format(strings.PlanTemplate_DP_Focus),

      focusName: TextService.format(strings.PlanTemplate_FOCUS),
      focusDescription: TextService.format(strings.PlanTemplate_FOCUS_Description),
      focusStartDate: focusStartDate.toJSON(),
      focusEndDate: focusEndDate.toJSON(),

      nowYear0: nowYear0,
      nowYear1: nowYear1,
      nowYear2: nowYear2,

      soName: TextService.format(strings.PlanTemplate_SO),
      assocDescription: TextService.format(strings.PlanTemplate_ASSOC),
      positionName: TextService.format(strings.PlanTemplate_POSITION),
      planVersion: PlanVersion.current,

      actionName: TextService.format(strings.PlanTemplate_ACTION),
      actionStartDate: actionStartDate.toJSON(),
      actionEndDate: actionEndDate.toJSON(),

      krName: TextService.format(strings.PlanTemplate_KR),
      kpiName: TextService.format(strings.PlanTemplate_KPI),

      todayDate: now.toJSON()
    };

    const guids = {};

    const planJsonTemplate = PlanDataService.removeGuids(template.planJson, guids);
    const json = Mustache.render(planJsonTemplate, {
      ...params,
      planName: TextService.escapeJson(template.name),
    });

    const created = parseJSON(json);

    const planSettings = PlanSettingsService.getPlanSettings(created);
    created.statusDate = PlanSettingsService.getStatusDate(planSettings);

    const updates = ProgressService.getAllPercentCompleteUpdates(created);
    for (const update of updates) {
      for (const field of update.changes.properties) {
        update.item[field] = update.changes.newValues[field];
      }
    }

    return created;
  }
}
