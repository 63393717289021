import { ComboBox, IComboBoxOption, SelectableOptionMenuItemType } from '@fluentui/react';
import React from 'react';
import { PlanDataService } from 'services/PlanDataService';
import { IVistoPlan } from 'sp';

export const FocusMultiPicker = (props: {
  disabled?: boolean;
  label: string;
  plan: IVistoPlan;
  focusGuids: string[];
  setFocusGuids: (lopGuids: string[]) => void;
}) => {

  const activeFocus = PlanDataService.getActiveFocus(props.plan);
  const allFocuses = PlanDataService.getFocuses(props.plan);

  const options = allFocuses.map(focus => ({
    key: focus.guid,
    text: focus.name + (activeFocus?.guid === focus?.guid ? ' ★' : '')
  }));

  const onChange = (_, option?: IComboBoxOption) => {

    if (option) {
      if (option?.selected) {
        props.setFocusGuids([...props.focusGuids, option.key as string])
      } else {
        props.setFocusGuids(props.focusGuids.filter(focusGuid => focusGuid !== option.key));
      }
    }
  };

  return (
    <ComboBox
      multiSelect
      disabled={allFocuses.length === 0 || props.disabled}
      label={props.label}
      selectedKey={props.focusGuids}
      onChange={onChange}
      options={options}
    />
  );
}
