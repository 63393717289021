import { mxgraph } from 'ts-mxgraph-typings';
import { mxglobals } from 'frames/TopFrame/drawing/common';
import { ITheme } from '@fluentui/react';

export class TreeMouseListener {

  graph: mxgraph.mxGraph;
  theme: ITheme;

  constructor(graph: mxgraph.mxGraph, theme: ITheme) {
    this.graph = graph;
    this.theme = theme;
    this.graph.getCursorForCell = (cell: mxgraph.mxCell) => cell.vertex ? 'pointer' : 'default';
  }

  currentState = null;
  previousStyle = null;

  updateStyle(state, hover) {
    state.style['cursor'] = 'pointer';
    state.style[mxglobals.mxConstants.STYLE_STROKECOLOR] = hover 
      ? this.theme.palette.neutralTertiary 
      : this.theme.palette.neutralQuaternary;
  };

  mouseDown(sender, me) {
    if (this.currentState != null) {
      this.dragLeave(me.getEvent(), this.currentState);
      this.currentState = null;
    }
  };

  mouseMove(sender, me) {
    if (this.currentState != null && me.getState() == this.currentState) {
      return;
    }

    var tmp = this.graph.view.getState(me.getCell());

    // Ignores everything but vertices
    if (this.graph.isMouseDown || (tmp != null && !this.graph.getModel().isVertex(tmp.cell))) {
      tmp = null;
    }

    if (tmp != this.currentState) {
      if (this.currentState != null) {
        this.dragLeave(me.getEvent(), this.currentState);
      }

      this.currentState = tmp;

      if (this.currentState != null) {
        this.dragEnter(me.getEvent(), this.currentState);
      }
    }
  };

  mouseUp(sender, me) { }

  dragEnter(evt, state) {
    if (state != null) {
      if (state.shape != null) {
        this.previousStyle = state.style;
        state.style = mxglobals.mxUtils.clone(state.style);
        this.updateStyle(state, true);
        state.shape.apply(state);
        state.shape.redraw();
      }

      if (state.text != null) {
        state.text.apply(state);
        state.text.redraw();
      }
    }
  }

  dragLeave(evt, state) {
    if (state != null) {
      if (state.shape != null) {
        state.style = this.previousStyle;
        this.updateStyle(state, false);
        state.shape.apply(state);
        state.shape.redraw();
      }

      if (state.text != null) {
        state.text.apply(state);
        state.text.redraw();
      }
    }
  }
}
