
export enum PlannerIntegrationLevel {
  Plan = 1,
  LOP = 2,
  DP = 3,
}

export interface IPlannerIntegrationSettings {
  level: PlannerIntegrationLevel;
  url: string;
  enableLabelSync: boolean;
  syncDate?: Date;
}
