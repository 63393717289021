import { loadStyles } from '@microsoft/load-themed-styles';

const styles = {
  DragDropFilesArea: 'DragDropFilesArea_f6cb0b53',
  DragDropFilesAreaLeave: 'DragDropFilesAreaLeave_28982080',
  DragDropFilesAreaBorder: 'DragDropFilesAreaBorder_b17534e8',
  DragDropFilesAreaZone: 'DragDropFilesAreaZone_65e44ebe',
};

export default styles;

loadStyles('.DragDropFilesArea_f6cb0b53{position:relative;min-height:150px}.DragDropFilesAreaLeave_28982080{position:relative}.DragDropFilesAreaBorder_b17534e8{border:dashed grey 1px;background-color:rgba(255,255,255,.6);position:absolute;top:0;bottom:0;left:0;right:0;z-index:1}.DragDropFilesAreaZone_65e44ebe{position:absolute;top:35%;right:0;left:0;text-align:center;color:grey;font-size:34px}');
