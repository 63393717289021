import { loadStyles } from '@microsoft/load-themed-styles';

const styles = {
  defaultClass: 'defaultClass_974b877c',
  errorMessage: 'errorMessage_58a975ce',
  errorIcon: 'errorIcon_6abaca70',
};

export default styles;

loadStyles('.defaultClass_974b877c{color:#000}.errorMessage_58a975ce{font-size:12px;font-weight:400;color:#a80000;margin:0;padding-top:5px;display:flex;align-items:center}.errorIcon_6abaca70{font-size:14px;margin-right:5px}');
