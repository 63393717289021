import { Dropdown } from '@fluentui/react';
import strings from 'VistoWebPartStrings';
import React from 'react';
import { PlanDataService } from 'services/PlanDataService';
import { TextService } from 'services/TextService';
import { IVistoPlan, VistoDpItem, VistoKind } from 'sp';
import { NULL_KEY } from 'shared/parse';

export const DpPicker = (props: {
  disabled?: boolean;
  label: string;
  plan: IVistoPlan;
  lopGuid: string;
  dpGuid: string;
  setDpGuid: (dpGuid: string) => void;
}) => {

  const dpOptions = PlanDataService.getItemsHaving<VistoDpItem>(props.plan.items, x => x.kind === VistoKind.DP && x.lopGuid === props.lopGuid)
    .map(dp => ({
      key: dp.guid,
      text: dp.name,
    }))
    .sort((a, b) => TextService.compareNames(a.text, b.text))

  dpOptions.unshift({
    key: NULL_KEY,
    text: TextService.format(strings.SelectOption),
  });

  return (
    <Dropdown
      required
      label={props.label}
      disabled={props.disabled || !props.lopGuid}
      selectedKey={props.dpGuid ?? NULL_KEY}
      onChange={(_, val) => props.setDpGuid(val.key === NULL_KEY ? null : '' + val.key)}
      options={dpOptions}
    />
  );
}
