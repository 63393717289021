import React from 'react';
import { Dropdown, IComboBoxOption } from '@fluentui/react';
import { UrlService } from 'shared/urlService';
import { TextService } from 'services/TextService';
import strings from 'VistoWebPartStrings';

export const SharePointSiteUrlPicker = (props: {
  label: string;
  disabled: boolean;
  planInfos: { SiteUrl: string, TeamName?: string }[];
  siteUrl: string;
  setSiteUrl: (val: string) => void;
}) => {

  const [siteUrls, setSiteUrls] = React.useState<IComboBoxOption[]>([{ key: props.siteUrl, text: TextService.format(strings.ItemPicker_Loading) }]);

  React.useEffect(() => {
    const newSiteUrls = [];
    for (const planInfo of props.planInfos) {
      const siteUrl = planInfo.SiteUrl;
      const text = planInfo.TeamName || UrlService.getPathName(siteUrl);
      const found = newSiteUrls.find(x => x.key === siteUrl);
      if (!found && !UrlService.isLocalUrl(siteUrl)) {
        newSiteUrls.push({ key: siteUrl, text });
      }
    };
    setSiteUrls(newSiteUrls);
  }, [props.planInfos]);

  return (
    <Dropdown
      label={props.label}
      disabled={props.disabled}
      calloutProps={{ calloutMaxHeight: 200 }}
      options={siteUrls}
      selectedKey={props.siteUrl}
      onChange={(_, val) => props.setSiteUrl('' + val.key)}
    />
  );
}
