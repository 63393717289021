import React, { ChangeEvent, useState } from 'react';
import { IconButton, IPanelStyles, Panel, PanelType, PrimaryButton, Stack, Text, TextField, Tooltip, TooltipHost, useTheme } from '@fluentui/react';
import { AssistantCard } from './AssistantCard';
import { UserCard } from './UserCard';
import { EnvContext } from 'services/EnvContext';
import { IAssistatCard } from 'frames/IAssistatCard';
import { FileService } from 'services/FileService';
import { UrlService } from 'shared/urlService';
import { TextService } from 'services/TextService';
import strings from 'VistoWebPartStrings';
import { AssistantInputField } from 'frames/TopFrame/drawing/common/AssistantInputField';
import { AppContext } from 'services/AppContext';

export const GenerateSidebar = (props: {
  isOpen: boolean;
  cards: IAssistatCard[];
  isGenerating: boolean;
  onClearMessages: () => void;
  onDismiss: () => void;
  onGenerate: (text: string) => Promise<void>;
}) => {

  const [newMessage, setNewMessage] = useState<string>('');

  const { propertyBag } = React.useContext(AppContext);

  const handleSend = async () => {
    setNewMessage('');
    props.onGenerate(newMessage);
  }

  const { hostKind } = React.useContext(EnvContext);
  const isWeb = (hostKind === 'WebMobile' || hostKind === 'WebDesktop');

  const sidebarStyles: Partial<IPanelStyles> = {
    main: {
      animation: 'none',
      marginTop: isWeb ? 248 : 200,
      borderTopRightRadius: 4,
    }
  };

  const exportLogs = () => {
    FileService.export('debug.log', 'application/json', JSON.stringify(props.cards, null, 2));
  }


  const GenerateSidebarHeader = () => {
    return (
      <Stack grow horizontal tokens={{ padding: '0 m', childrenGap: 'm' }}>
        <Stack><Text variant='xLarge'>{TextService.format(strings.AssistantToolbar_Caption)}</Text></Stack>
        <TooltipHost content={TextService.format(strings.AssistantToolbar_Reset)} >
          <IconButton iconProps={{ iconName: 'Delete' }} onClick={props.onClearMessages} />
        </TooltipHost>
        {UrlService.isDev && <TooltipHost content={TextService.format(strings.AssistantToolbar_ExportLogs)} >
          <IconButton iconProps={{ iconName: 'PageSolid' }} onClick={exportLogs} />
        </TooltipHost>}
      </Stack>
    );
  }

  const assistantConsented = propertyBag.assistantConsented;

  return <Panel
    type={PanelType.customNear}
    customWidth='400px'
    isBlocking={false}
    isOpen={props.isOpen}
    onDismiss={props.onDismiss}
    styles={sidebarStyles}
    onRenderHeader={() => (<GenerateSidebarHeader />)}
    focusTrapZoneProps={{ disabled: true }}
    layerProps={{ styles: { root: { zIndex: 999 } } }}
  >
    <Stack grow tokens={{ childrenGap: 's1', padding: 'm 0 0 0' }}>
      <Stack grow style={{ overflow: 'auto' }} data-is-scrollable='true' tokens={{ childrenGap: 's1' }}>
        {props.cards.filter(c => c.role !== 'system').map((card, idx) => {
          return card.role === 'assistant'
            ? <AssistantCard key={idx} card={card} />
            : <UserCard key={idx} card={card} />
        })}
      </Stack>

      <Stack horizontal tokens={{ childrenGap: 's1' }}>
        <AssistantInputField
          disabled={props.isGenerating}
          text={newMessage}
          setText={setNewMessage}
          placeholder={TextService.format(strings.Assistant_PlaceholderText)}
          styles={{ root: { flexGrow: 1 } }}
        />
        {assistantConsented && <PrimaryButton disabled={props.isGenerating} onClick={handleSend}>{TextService.format(strings.Assistant_ButtonSend)}</PrimaryButton>}
      </Stack>
    </Stack>

  </Panel>;

}
