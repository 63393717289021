import { FieldType, getListDefinition, IFieldDefinition, ILookupFieldDefinition, IVistoListItem, VistoKind } from 'sp/common';
import { LicenseService } from 'services/LicenseService';
import strings from 'VistoWebPartStrings';
import { TextService } from 'services/TextService';
import { PlanDataService } from 'services/PlanDataService';
import { IVistoPlan, VistoActionItem } from 'sp';
import { CellKind } from 'shared/CellKind';

export class ActionListService {

  private static isFieldValid(field: IFieldDefinition) {
    if (field.type === FieldType.Lookup) {
      const fieldLookup = field as ILookupFieldDefinition;
      if (fieldLookup.lookupKind === VistoKind.Effect) {
        return LicenseService?.license?.effectsEnabled;
      }
    }

    return field.show;
  }

  public static getCellKind(kind: VistoKind) {
    switch (kind) {
      case VistoKind.DP:
        return CellKind.DP;
      case VistoKind.LOP:
        return CellKind.LOP;
      case VistoKind.Focus:
        return CellKind.FOCUS;
    }
  }

  public static getColumns(plan: IVistoPlan, viewSettings: any, isMobile: boolean) {

    const allUsers = PlanDataService.getPlanUsers(plan);

    const getDps = (options) => {
      return {
        store: {
          type: 'array',
          data: options?.data?.lopGuid
            ? PlanDataService.getLopDps(plan, options?.data?.lopGuid)
            : PlanDataService.getDps(plan),
          key: 'guid'
        }
      };
    };

    const getEffects = (options) => {
      return {
        store: {
          type: 'array',
          data: options?.data?.lopGuid
            ? PlanDataService.getDpEffects(plan, options?.data?.dpGuid)
            : PlanDataService.getEffects(plan),
          key: 'guid'
        }
      };
    };

    const getLops = (options) => {
      return {
        store: {
          type: 'array',
          data: PlanDataService.getLops(plan),
          key: 'guid'
        }
      };
    };

    const getFocuses = (options) => {
      return {
        store: {
          type: 'array',
          data: PlanDataService.getFocuses(plan),
          key: 'guid'
        }
      };
    };

    const getKeyResults = (options) => {
      return {
        store: {
          type: 'array',
          data: PlanDataService.getItems(plan.items, VistoKind.KeyResult),
          key: 'guid'
        }
      };
    };

    const postProcessLookupField = (result: any, fieldLookup: ILookupFieldDefinition) => {

      function setLopValue(rowData, value) {
        rowData.dpGuid = null;
        this.defaultSetCellValue(rowData, value);
      }

      switch (fieldLookup.lookupKind) {
        case VistoKind.LOP: {
          result.groupIndex = 0;
          result.cellTemplate = 'linkField';
          result.lookup = {
            dataSource: getLops,
            valueExpr: 'guid',
            displayExpr: 'name'
          };
          break;
        }
        case VistoKind.Focus: {
          result.cellTemplate = 'linkField';
          result.lookup = {
            dataSource: getFocuses,
            valueExpr: 'guid',
            displayExpr: 'name'
          };
          break;
        }
        case VistoKind.KeyResult: {
          result.cellTemplate = 'linkField';
          result.lookup = {
            dataSource: getKeyResults,
            valueExpr: 'guid',
            displayExpr: 'name'
          };
          break;
        }
        case VistoKind.Effect: {
          result.cellTemplate = 'linkField';
          result.lookup = {
            dataSource: getEffects,
            valueExpr: 'guid',
            displayExpr: 'name'
          };
          break;
        }
        case VistoKind.DP:
          result.groupIndex = 1;
          result.cellTemplate = 'linkField';
          result.lookup = {
            dataSource: getDps,
            valueExpr: 'guid',
            displayExpr: 'name'
          };
          break;
      }
    };

    const postProcessPercentField = (result: any) => {
      result.headerFilter = {
        dataSource: [
          {
            text: TextService.format(strings.ActionListPercentFilter_Blanks),
            value: ['percentComplete', '=', null]
          },
          {
            text: TextService.format(strings.ActionListPercentFilter_0),
            value: ['percentComplete', '<', 0.1]
          },
          {
            text: TextService.format(strings.ActionListPercentFilter_25),
            value: [['percentComplete', '>=', 0.1], ['percentComplete', '<', 25.5]]
          },
          {
            text: TextService.format(strings.ActionListPercentFilter_50),
            value: [['percentComplete', '>=', 25.5], ['percentComplete', '<', 50.5]]
          },
          {
            text: TextService.format(strings.ActionListPercentFilter_75),
            value: [['percentComplete', '>=', 50.5], ['percentComplete', '<', 75.5]]
          },
          {
            text: TextService.format(strings.ActionListPercentFilter_100),
            value: [['percentComplete', '>=', 75.5], ['percentComplete', '<=', 99.9999]]
          },
          {
            text: TextService.format(strings.ActionListPercentFilter_Done),
            value: ['percentComplete', '>', 99.9999]
          },
        ]
      };
    };

    const postProcessUserField = (result: any, fieldKey: string) => {
      
      result.cellTemplate = (container: { textContent: any; title: any; }, options) => {
        const noBreakSpace = '\u00A0';
      
        const assignees = (options.value || []).map(
          (item) => options.column.lookup.calculateCellValue(item),
        );
        const text = assignees.join(', ');
      
        container.textContent = text || noBreakSpace;
        container.title = text;
      };

      result.calculateFilterExpression = (filterValue, _selectedFilterOperation: any, target: string) => {
        if (target === 'search' && typeof (filterValue) === 'string') {
          return [fieldKey, 'contains', filterValue];
        }
      
        return (rowData) => (rowData[fieldKey] || []).indexOf(filterValue) !== -1;
      };

      result.lookup = {
        dataSource: allUsers,
        valueExpr: 'userName',
        displayExpr: 'title'
      };
    };

    const fields = getListDefinition<VistoActionItem>(VistoKind.Action).fields;
    const columns = Object.keys(fields)
      .filter(fieldKey => ActionListService.isFieldValid(fields[fieldKey]))
      .map(fieldKey => {

        const field: IFieldDefinition = fields[fieldKey];
        const columnSettings = viewSettings?.columns.find(x => x.dataField === fieldKey);

        const result: any = {
          caption: TextService.format(strings[field.key]),
          dataField: fieldKey,
          visible: !!field.defaultView,
          minWidth: field.minWidth
        };


        if (field.type === FieldType.Lookup) {
          postProcessLookupField(result, field as ILookupFieldDefinition);
        }

        if (field.type === FieldType.User) {
          postProcessUserField(result, fieldKey);
        }

        if (field.usePercents) {
          postProcessPercentField(result);
        }

        if (fieldKey === 'name') {
          result.cellTemplate = 'linkField';
        }

        if (field.type === FieldType.DateTime) {
          result.dataType = 'date';
          result.width = 140;
        }

        if (field.type === FieldType.Number) {
          result.dataType = 'number';
          result.width = 160;
        }

        if (columnSettings) {
          result.groupIndex = columnSettings.groupIndex;
          result.visible = columnSettings.visible;
          result.visibleIndex = columnSettings.visibleIndex;
          result.width = columnSettings.width;
        }

        if (isMobile) {
          result.groupIndex = null;
        }

        return result;
      });

    return columns;
  }
}
