import { IItemPosition, IVistoPlan, IVistoPlanSettings, VistoDpItem, VistoKeyResultItem, VistoKind, VistoLopItem } from 'sp';
import { mxgraph } from 'ts-mxgraph-typings';
import { mx, mxglobals } from './common';
import { IntegrationService } from 'services/IntegrationService';
import { PlanDataService } from 'services/PlanDataService';
import { TextService } from 'services/TextService';
import { api } from 'shared/api';
import { PlanSettingsService } from 'services/PlanSettingsService';
import strings from 'VistoWebPartStrings';
import { CellKind } from 'shared/CellKind';
import { trackClient } from 'shared/clientTelemetry';
import { ColorService } from 'services/colorService';
import { SvgIndicators } from 'graphics/SvgIndicators';
import { LicenseService } from 'services/LicenseService';
import { IPropertyBag } from 'services/IPropertyBag';
import { getObjectValues } from 'shared/parse';

const updateLabel = (model: mxgraph.mxGraphModel, cell: mxgraph.mxCell, newLabel: string) => {
  const oldLabel = model.getValue(cell);
  if (newLabel != oldLabel) {
    model.setValue(cell, newLabel);
  }
};

type Align = { horizontal: string, vertical: string };

const alignBottomRight: Align = {
  horizontal: mxglobals.mxConstants.ALIGN_RIGHT,
  vertical: mxglobals.mxConstants.ALIGN_BOTTOM,
};

const alignTopRight: Align = {
  horizontal: mxglobals.mxConstants.ALIGN_RIGHT,
  vertical: mxglobals.mxConstants.ALIGN_TOP,
};

const alignNavigation: Align = {
  horizontal: mxglobals.mxConstants.ALIGN_LEFT,
  vertical: mxglobals.mxConstants.ALIGN_TOP,
};

const defaultSize = { w: 20, h: 20 };

const makeCellOverlay = (
  data: { url: string, tooltip: string },
  align: Align,
  offset: { x: number, y: number },
  size: { w: number, h: number },
  onClick?: () => void) => {

  const img = new mxglobals.mxImage(data.url, size.w, size.h);

  const overlay: mxgraph.mxCellOverlay = new mxglobals.mxCellOverlay(img, data.tooltip);
  overlay.verticalAlign = align.vertical;
  overlay.align = align.horizontal;
  overlay.offset = new mxglobals.mxPoint(offset.x, offset.y);
  overlay.cursor = 'default';
  if (onClick) {
    overlay.cursor = 'pointer';
    overlay.addListener(mxglobals.mxEvent.CLICK, () => onClick());
  }
  return overlay;
};

const addPlanIndicatorOverlays = (
  graph: mxgraph.mxGraph,
  cell: mxgraph.mxCell,
  plan: IVistoPlan,
  viewStartDate: Date,
  viewEndDate: Date,
  onClick: (param: string) => void,
) => {
  const planSettings = PlanSettingsService.getPlanSettings(plan);

  if (planSettings?.showProgressIcons || typeof (planSettings?.showProgressIcons) === 'undefined') {
    const link = plan.navigationLink && IntegrationService.getBrowserLink(plan.navigationLink.url);
    if (link) {
      SvgIndicators.buildSvgNavigation(plan.navigationLink.url).then(nav => {
        if (nav) {
          graph.addCellOverlay(cell, makeCellOverlay({ url: nav.iconUrl, tooltip: plan.navigationLink.description }, alignNavigation, { x: 10, y: -10 }, { w: 10, h: 10 }, () => onClick(link)));
        }
      }, err => {
        trackClient.error(`build navigation failed for ${plan.navigationLink.url}`, err);
      });
    }
  }
};

const showKpiIndicators = (planSettings: IVistoPlanSettings) => LicenseService.license?.okrEnabled &&
  (planSettings?.showKpiIndicators || typeof (planSettings?.showKpiIndicators) === 'undefined');

const addDpIndicatorOverlays = (
  graph: mxgraph.mxGraph,
  cell: mxgraph.mxCell,
  plan: IVistoPlan,
  item: VistoDpItem,
  viewStartDate: Date,
  viewEndDate: Date,
  onClick: (param: string) => void
) => {

  const planSettings = PlanSettingsService.getPlanSettings(plan);

  if (planSettings?.showProgressIcons || typeof (planSettings?.showProgressIcons) === 'undefined') {
    const link = item.sourceItemUrl && IntegrationService.getBrowserLink(item.sourceItemUrl);
    if (link) {
      SvgIndicators.buildSvgNavigation(item.sourceItemUrl).then(nav => {
        if (nav) {
          graph.addCellOverlay(cell, makeCellOverlay({ url: nav.iconUrl, tooltip: nav.tooltipText }, alignNavigation, { x: 10, y: -10 }, { w: 10, h: 10 }, () => onClick(link)));
        }
      }, err => {
        trackClient.error(`build navigation failed for ${item.sourceItemUrl}`, err);
      });
    }
  }

  const offset = { x: -20, y: 0 };
  if (planSettings?.showIndicators || typeof (planSettings?.showIndicators) === 'undefined') {
    const svg = SvgIndicators.buildSvgProgress(10, 2, plan, item);
    if (svg) {
      const url = 'data:image/svg+xml;base64,' + window.btoa(svg);
      const tooltip = TextService.format(strings.DiagramProgressTooltip, {
        percentComplete: TextService.formatPercents(item.percentComplete, false, true)
      });
      graph.addCellOverlay(cell, makeCellOverlay({ url, tooltip }, alignTopRight, offset, defaultSize));
      offset.x -= 35;
    }
  }

  if (showKpiIndicators(planSettings)) {

    const krInfoSet = PlanDataService.getDpKeyResultSet(plan, item.guid);
    const krInfos = getObjectValues(krInfoSet)
      .filter(x => x.showOnDiagram)
      .sort((a, b) => TextService.compareNames(a.kr.name, b.kr.name));

    for (const krInfo of krInfos) {
      const kr = krInfo.kr;
      const svg = SvgIndicators.buildSvgIndicator(plan, kr, 10, viewStartDate, viewEndDate);
      if (svg) {
        const url = 'data:image/svg+xml;base64,' + window.btoa(svg);
        const tooltip = TextService.format(strings.Drawing_KeyResultTooltip, { krName: kr.name });
        graph.addCellOverlay(cell, makeCellOverlay({ url, tooltip }, alignTopRight, offset, defaultSize, () => onClick(kr.guid)));
        offset.x -= 25;
      }
    }
  }


  if (planSettings?.showOwnerIndicators || typeof (planSettings?.showOwnerIndicators) === 'undefined') {
    const assignees = item.assignedTo ?? [];
    const assigneeOffset = { x: -20, y: 0 };
    for (const assignee of assignees) {
      SvgIndicators.buildSvgCircleImageUrl(assignee, 10).then(url => {
        const tooltip = TextService.format(assignee.title);
        graph.addCellOverlay(cell, makeCellOverlay({ url, tooltip }, alignBottomRight, assigneeOffset, defaultSize, () => { }));
        assigneeOffset.x -= 25;
      });
    }
  }
};

const addSoIndicatorOverlays = (
  graph: mxgraph.mxGraph,
  cell: mxgraph.mxCell,
  plan: IVistoPlan,
  viewStartDate: Date,
  viewEndDate: Date,
  onClick: (param: string) => void
) => {

  const planSettings = PlanSettingsService.getPlanSettings(plan);

  const offset = { x: -20, y: 0 };

  if (showKpiIndicators(planSettings)) {

    const krs = PlanDataService.getItemsHaving<VistoKeyResultItem>(plan.items, x => x.kind === VistoKind.KeyResult && !!x.soGuid && x.showOnDiagram);
    for (const kr of krs.reverse()) {
      const svg = SvgIndicators.buildSvgIndicator(plan, kr, 10, viewStartDate, viewEndDate);
      if (svg) {
        const url = 'data:image/svg+xml;base64,' + window.btoa(svg);
        const tooltip = TextService.format(strings.Drawing_KeyResultTooltip, { krName: kr.name });
        graph.addCellOverlay(cell, makeCellOverlay({ url, tooltip }, alignTopRight, offset, defaultSize, () => onClick(kr.guid)));
        offset.x -= 25;
      }
    }
  }
};

const highlight = {
  oldHighlightState: '{}',
  oldHighlights: []
};

export const clearHighlights = () => {
  for (const h of highlight.oldHighlights) {
    h.destroy();
  }
  highlight.oldHighlightState = '{}';
  highlight.oldHighlights = [];
};

export const updateHighlights = (graph: mxgraph.mxGraph, status: api.IWSPlanStatus) => {

  if (!status?.selection) {
    return;
  }

  const newHighlightState = JSON.stringify(status.selection);

  if (newHighlightState === highlight.oldHighlightState)
    return;

  // first, remove all highlights
  for (const h of highlight.oldHighlights) {
    h.destroy();
  }

  const newHighlights = [];

  graph.eventsEnabled = false;

  const allCells = mx.getAllCells(graph);
  for (let cell of allCells) {
    for (const userId in status.selection) {

      const shapeId = status.selection[userId];
      if (shapeId === mx.getCellGuid(cell)) {
        const user = status.users.find(u => u.oid == userId);
        const color = ColorService.getUserColor(user?.preferred_username || userId);
        const cellHighlight = new mxglobals.mxCellHighlight(graph, color, 5);
        cellHighlight.highlight(graph.view.getState(cell));
        newHighlights.push(cellHighlight);
      }
    }
  }

  graph.eventsEnabled = true;

  highlight.oldHighlights = newHighlights;
  highlight.oldHighlightState = newHighlightState;
};

export function getPosition(cell: mxgraph.mxCell): IItemPosition {
  if (cell.edge) {
    return {
      x: (cell.geometry.sourcePoint.x + cell.geometry.targetPoint.x) / 2,
      y: (cell.geometry.sourcePoint.y + cell.geometry.targetPoint.y) / 2,
      width: cell.geometry.width,
      height: cell.geometry.height
    };
  } else {
    return {
      x: cell.geometry.x,
      y: cell.geometry.y,
      width: cell.geometry.width,
      height: cell.geometry.height
    };
  }
}

export function updateDiagramShapeFromPlanWithDates(graph: mxgraph.mxGraph, cell: mxgraph.mxCell, plan: IVistoPlan,
  viewStartDate: Date, viewEndDate: Date, onClick: (param: string) => void) {

  const kind = mx.getCellKind(cell);
  switch (kind) {
    case CellKind.DP:
      const dp = PlanDataService.getItemByGuid<VistoDpItem>(plan.items, mx.getCellGuid(cell));
      if (dp) {
        dp.position = getPosition(cell);
        updateLabel(graph.model, cell, dp.name);
        graph.removeCellOverlays(cell);
        addDpIndicatorOverlays(graph, cell, plan, dp, viewStartDate, viewEndDate, onClick);
      }
      break;
    case CellKind.LOP:
      const lop = PlanDataService.getItemByGuid<VistoLopItem>(plan.items, mx.getCellGuid(cell));
      if (lop) {
        lop.position = getPosition(cell);
        updateLabel(graph.model, cell, lop.name);
      }
      break;
    case CellKind.TITLE:
      graph.removeCellOverlays(cell);
      addPlanIndicatorOverlays(graph, cell, plan, viewStartDate, viewEndDate, onClick);
      updateLabel(graph.model, cell, plan.name);
      break;
    case CellKind.FOCUS:
      const activeFocus = PlanDataService.getActiveFocus(plan);
      const focusName = activeFocus?.name;
      updateLabel(graph.model, cell, focusName);
      break;
    case CellKind.POSITION:
      updateLabel(graph.model, cell, plan.esName);
      graph.removeCellOverlays(cell);
      addSoIndicatorOverlays(graph, cell, plan, viewStartDate, viewEndDate, onClick);
      break;
  }

}

export function updateDiagramFromPlanWithDates(
  graph: mxgraph.mxGraph,
  plan: IVistoPlan,
  propertyBag: IPropertyBag,
  repaintCellIds: string[],
  onClick: (param: string) => void) {

  graph.eventsEnabled = false;

  const allCells = mx.getAllCells(graph);
  for (let cell of allCells) {

    if (repaintCellIds?.length && repaintCellIds.indexOf(mx.getCellGuid(cell)) < 0) {
      continue;
    }

    updateDiagramShapeFromPlanWithDates(graph, cell, plan, propertyBag?.viewStartDate, propertyBag?.viewEndDate, onClick);
  }

  graph.eventsEnabled = true;
}
