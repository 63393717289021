import * as strings from 'VistoWebPartStrings';
import * as React from 'react';
import { PrimaryButton, DefaultButton, Dialog, DialogFooter, IDialogContentProps, DialogType, Stack } from '@fluentui/react';

import { AppContext } from 'services/AppContext';
import { AuthService } from 'services/AuthService';
import { NotificationType } from 'services/Notify';
import { StorageService } from 'services/StorageService';
import { IOperationOptions } from 'services/IOperationOptions';
import { TextService } from 'services/TextService';
import { IVistoListItemWithProgress, IVistoPlan } from 'sp';
import { trackClient } from 'shared/clientTelemetry';
import { defaultModalProps } from 'dialogs/common';
import { PlannerPlanWizardProgressPage } from './PlannerPlanWizardProgressPage';
import { PlannerPlanWizardStartPage } from './PlannerPlanWizardStartPage';
import { PlannerIntegrationLevel, PlannerService, PlannerConfigurationService, IPlannerChangesResult, PlannerLinkService } from '../services';
import { stringifyError } from 'shared/parse';
import { api } from 'shared/api';
import { PlanSettingsService } from 'services/PlanSettingsService';
import { InfoBar, useErrorInfo } from 'components';
import { PlannerPlanWizardSelectPage } from './PlannerPlanWizardSelectPage';
import { IntegrationService } from 'services/IntegrationService';

enum PlanWizardPage {
  start,
  selection,
  progress,
}

export function PlannerPlanWizard(props: {
  plan: IVistoPlan;
  onDismiss: (changed: boolean, plan?: IVistoPlan) => void;
}) {

  const [visPlan, setVisPlan] = React.useState<IVistoPlan>(props.plan);

  const { notify } = React.useContext(AppContext);

  const settings = PlanSettingsService.getPlanSettings(visPlan);

  const [plannerChanges, setPlannerChanges] = React.useState<IPlannerChangesResult>(null);
  const [currentProgressActionIndex, setCurrentProgressActionIndex] = React.useState<number>();

  const [isProcessing, setIsProcessing] = React.useState(false);

  const [statusText, setStatusText] = React.useState('');

  const [errorInfo, setErrorInfo] = useErrorInfo();

  const executed =
    TextService.isValidNumber(currentProgressActionIndex) && currentProgressActionIndex === plannerChanges?.actions.length || plannerChanges?.actions.length === 0;

  const [plans, setPlans] = React.useState<any>(null);

  const [integrationLevel, setIntegrationLevel] = React.useState(settings.integrations?.planner?.level ?? PlannerIntegrationLevel.LOP);

  const [enableLabelSync, setEnableLabelSync] = React.useState(settings.integrations?.planner?.enableLabelSync ?? false);

  const defaultSelectedKeys = PlannerLinkService.getLinkedPlannerKeys(visPlan);

  const [selectedKeys, setSelectedKeys] = React.useState(defaultSelectedKeys ?? []);

  const pageInfo = {

    [PlanWizardPage.start]: {
      subText: TextService.format(strings.PlanWizardPageStart_SubText),
      backDisabled: () => true,
      backLabel: () => TextService.format(strings.PlanWizard_Back),
      nextDisabled: () => false,
      nextLabel: () => TextService.format(strings.PlanWizard_Next),
    },

    [PlanWizardPage.selection]: {
      subText: TextService.format(strings.PlanWizardPageSelection_SubText),
      backDisabled: () => false,
      backLabel: () => TextService.format(strings.PlanWizard_Back),
      nextDisabled: () => !plans || !selectedKeys.length,
      nextLabel: () => TextService.format(strings.PlanWizard_Next),
    },

    [PlanWizardPage.progress]: {
      subText: TextService.format(strings.PlanWizard_ConfigurePlan),
      backDisabled: () => false,
      backLabel: () => TextService.format(strings.PlanWizard_Back),
      nextDisabled: () => isProcessing,
      nextLabel: () => executed ? TextService.format(strings.PlanWizard_Close) : TextService.format(strings.PlanWizard_Configure),
    },
  };

  const [currentPageId, setCurrentPageId] = React.useState<PlanWizardPage>(
    settings.integrations?.planner?.level ? PlanWizardPage.selection : PlanWizardPage.start);

  const analyzeChanges = () => {
    setCurrentProgressActionIndex(undefined);
    setPlannerChanges(null);
    setStatusText(TextService.format(strings.PlanWizard_AnalyzingSite));
    setIsProcessing(true);

    const defaultSelectedKeySet = new Set(defaultSelectedKeys);

    const isUnrelatedLink = (item: IVistoListItemWithProgress) => {
      return item && item.sourceItemUrl && !PlannerLinkService.isPlannerLink(item.sourceItemUrl);
    };
  
    const allItems = Object.keys(visPlan.items)
      .map(key => visPlan.items[key])
      .filter(item => !defaultSelectedKeySet.has(item.guid) && !isUnrelatedLink(item));

    PlannerConfigurationService.getPlannerChanges(visPlan, allItems, notify, api.WSOperation.load, { integrationLevel, enableLabelSync, selectedKeys }).then(result => {
      setIsProcessing(false);
      setPlannerChanges(result);
      if (result.actions.length) {
        setStatusText(TextService.format(strings.PlanWizard_ClickToProceed));
      } else {
        setStatusText(TextService.format(strings.PlanWizard_UpToDate));
      }
    }, err => {
      trackClient.error(`error generating actions for plan`, err);
      setStatusText(TextService.format(strings.PlanWizard_ErrorAnalyzingSite));
      setIsProcessing(false);
    });
  };

  const executeActions = async () => {
    setIsProcessing(true);
    setCurrentProgressActionIndex(undefined);

    const executeAction = async (p: IVistoPlan, i: number) => {
      const action = plannerChanges.actions[i];
      setStatusText(action.title);
      setCurrentProgressActionIndex(i);
      try {
        return await action.execute(p);
      } catch (err) {
        action.hasError = true;
        action.errorMessage = stringifyError(err);
        return p;
      }
    };

    let updatedVisPlan = visPlan;
    for (let i = 0; i < plannerChanges.actions.length; ++i) {
      updatedVisPlan = await executeAction(updatedVisPlan, i);
    }

    const spUpdateOptions: IOperationOptions = { 
      excludeExternals: true, 
      excludePercentComplete: true,
      excludeGroupByKind: true
    };

    if (plannerChanges.updated.length) {
      plannerChanges.actions.push({
        title: TextService.format(strings.PlannerPlanWizard_UpdateItems, { count: plannerChanges.updated.length }),
        execute: plan => StorageService.get(plan.siteUrl).updateItems(plan, plannerChanges.updated, notify, spUpdateOptions),
      });
      updatedVisPlan = await executeAction(updatedVisPlan, plannerChanges.actions.length - 1);
    }

    if (plannerChanges.created.length) {
      plannerChanges.actions.push({
        title: TextService.format(strings.PlannerPlanWizard_CreateItems, { count: plannerChanges.created.length }),
        execute: plan => StorageService.get(plan.siteUrl).createItems(plan, plannerChanges.created, notify, spUpdateOptions),
      });
      updatedVisPlan = await executeAction(updatedVisPlan, plannerChanges.actions.length - 1);
    }

    if (plannerChanges.deleted.length) {
      plannerChanges.actions.push({
        title: TextService.format(strings.PlannerPlanWizard_DeleteItems, { count: plannerChanges.deleted.length }),
        execute: plan => StorageService.get(plan.siteUrl).deleteItems(plan, plannerChanges.created, notify, spUpdateOptions),
      });
      updatedVisPlan = await executeAction(updatedVisPlan, plannerChanges.actions.length - 1);
    }

    await IntegrationService.updatePlanChildRefs(updatedVisPlan);

    setIsProcessing(false);
    setVisPlan(updatedVisPlan);
    setCurrentProgressActionIndex(plannerChanges.actions.length);
  };

  const onWizardBack = () => {
    switch (currentPageId) {

      case PlanWizardPage.progress:
        setCurrentPageId(PlanWizardPage.selection);
        break;

      case PlanWizardPage.selection:
        setCurrentPageId(PlanWizardPage.start);
        break;
    }
  };

  const onWizardNext = () => {
    switch (currentPageId) {

      case PlanWizardPage.start:
        setCurrentPageId(PlanWizardPage.selection);
        break;

      case PlanWizardPage.selection:
        setCurrentPageId(PlanWizardPage.progress);
        analyzeChanges();
        break;

      case PlanWizardPage.progress:
        if (executed) {
          props.onDismiss(true, visPlan);
        } else {
          setStatusText(TextService.format(strings.PlanWizard_Processing));
          executeActions().then(() => {
            if (plannerChanges.actions.some(a => a.hasError)) {
              setStatusText(TextService.format(strings.PlanWizard_CompletedError));
            } else {
              setStatusText(TextService.format(strings.PlanWizard_CompletedSuccess));
            }
          });
        }
        break;
    }
  };

  const contentProps: IDialogContentProps = {
    type: DialogType.largeHeader,
    title: TextService.format(strings.PlanWizard_WizardTitle),
    subText: pageInfo[currentPageId].subText
  };

  const onLoad = async () => {
    if (PlannerConfigurationService.groupId) {
      const result = await PlannerService.getPlans(PlannerConfigurationService.groupId);
      setPlans(result);
    } else {
      setErrorInfo({
        type: NotificationType.warn,
        message: TextService.format(strings.PlannerPlanWizard_NoGroupError),
        error: TextService.format(strings.PlannerPlanWizard_NoGroupErrorDetails)
      });
    }
  };

  return (
    <Dialog
      minWidth={500}
      isBlocking={true}
      dialogContentProps={contentProps} modalProps={defaultModalProps} isOpen={true} onDismiss={() => props.onDismiss(false)}>
      <Stack style={{ minHeight: 200 }}>
        <InfoBar {...errorInfo} />
        {(currentPageId == PlanWizardPage.start)
          ? <PlannerPlanWizardStartPage integrationLevel={integrationLevel} setIntegrationLevel={setIntegrationLevel} />
          : <AuthService.AuthScope name='PlannerWizard' kind={api.TokenKind.planner} onLoad={onLoad}>
              {(currentPageId == PlanWizardPage.selection)
                ? <PlannerPlanWizardSelectPage integrationLevel={integrationLevel} plan={props.plan} selectedKeys={selectedKeys} setSelectedKeys={setSelectedKeys} defaultSelectedKeys={defaultSelectedKeys} 
                    enableLabelSync={enableLabelSync} setEnableLabelSync={setEnableLabelSync} />
                : (currentPageId == PlanWizardPage.progress)
                  ? <PlannerPlanWizardProgressPage statusText={statusText} actions={plannerChanges?.actions ?? []} currentProgressActionIndex={currentProgressActionIndex} />
                  : null
              }
          </AuthService.AuthScope>}
      </Stack>
      <DialogFooter>
        <DefaultButton text={TextService.format(strings.ButtonCancel)} onClick={() => props.onDismiss(false)} />
        <DefaultButton text={pageInfo[currentPageId].backLabel()} disabled={pageInfo[currentPageId].backDisabled()} onClick={onWizardBack} />
        <PrimaryButton text={pageInfo[currentPageId].nextLabel()} disabled={pageInfo[currentPageId].nextDisabled()} onClick={onWizardNext} />
      </DialogFooter>
    </Dialog>
  );
}
