import { IVistoListItem, VistoKind, addListDefinition, IFieldDefinitions, ListItemUpdater } from './common';
import { FieldGenerator } from './common/FieldGenerator';

export interface VistoEffectItem extends IVistoListItem {
  readonly dpGuid?: string;
}

export const VistoEffectListFields: IFieldDefinitions<VistoEffectItem> = {
  itemId: FieldGenerator.makeIdField('VISTO_Effect_ItemId'),
  guid: FieldGenerator.makeGuidField('VISTO_Effect_Guid'),
  name: FieldGenerator.makeTitleField('VISTO_Effect_Name'),
  description: FieldGenerator.makeDescriptionField('VISTO_Effect_Description'),
  links: FieldGenerator.makeLinksField('VISTO_Effect_Links'),
  dpGuid: FieldGenerator.makeLookupField('VISTO_Effect_DP', 'DP', true, VistoKind.DP, true),
  createdDate: FieldGenerator.makeCreatedField('VISTO_Effect_Created'),
  modifiedDate: FieldGenerator.makeModifiedField('VISTO_Effect_Modified'),
};

addListDefinition({

  kind: VistoKind.Effect,
  fields: VistoEffectListFields,
  enableVersioning: true,
  majorVersionLimit: 500,

  defaultViewXml: `<View>
    <Query />
    <ViewFields>
      <FieldRef Name='LinkTitle' />
      <FieldRef Name='${VistoEffectListFields.description.name}' />
      <FieldRef Name='${VistoEffectListFields.dpGuid.name}' />
    </ViewFields>
    <RowLimit Paged='TRUE'>30</RowLimit>
  </View>`,

  setItemSpecificFields: (updater: ListItemUpdater, spItem, update: Partial<VistoEffectItem>) => {
    updater.setItemSpField(VistoEffectListFields, spItem, update, 'dpGuid');
  },

  getItemSpecificFields: (updater: ListItemUpdater, item: Partial<VistoEffectItem>, spItem) => {
    updater.getItemSpField(VistoEffectListFields, item, spItem, 'dpGuid');
  },
});
