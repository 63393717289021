import * as strings from 'VistoWebPartStrings';
import * as React from 'react';
import { Link, Stack, Text, Toggle } from '@fluentui/react';
import { ITreeItem, SelectChildrenMode, TreeItemActionsDisplayMode, TreeView, TreeViewSelectionMode } from '@pnp/spfx-controls-react/lib/controls/treeView';
import { IVistoListItem, IVistoListItemWithProgress, IVistoPlan, VistoKind } from 'sp';
import { PlanDataService } from 'services/PlanDataService';
import { TextService } from 'services/TextService';
import { PlannerIntegrationLevel, PlannerLinkService } from '../services';

export const PlannerPlanWizardSelectPage = (props: {
  plan: IVistoPlan,
  integrationLevel: PlannerIntegrationLevel;
  selectedKeys: string[];
  defaultSelectedKeys: string[];
  setSelectedKeys: (keys: string[]) => void;
  enableLabelSync: boolean;
  setEnableLabelSync: (val: boolean) => void;
}) => {

  const defaultSelectedKeySet = new Set(props.defaultSelectedKeys);

  const isUnrelatedLink = (item: IVistoListItemWithProgress) => {
    return item && item.sourceItemUrl && !PlannerLinkService.isPlannerLink(item.sourceItemUrl);
  };

  const treeitems: ITreeItem[] = PlanDataService.getLops(props.plan).map(lop => ({
    key: lop.guid,
    label: lop.name,
    subLabel: lop.description,
    data: lop,
    disabled: defaultSelectedKeySet.has(lop.guid) || isUnrelatedLink(lop),
    children: PlanDataService.getLopDps(props.plan, lop.guid).map(dp => ({
      key: dp.guid,
      label: dp.name,
      data: dp,
      disabled: defaultSelectedKeySet.has(dp.guid) || isUnrelatedLink(dp),
    }))
  }));

  const addParents = (keySet: Set<string>) => {
    for (const lop of PlanDataService.getLops(props.plan)) {
      for (const dp of PlanDataService.getLopDps(props.plan, lop.guid)) {
        for (const action of PlanDataService.getDpActions(props.plan, dp.guid)) {
          if (keySet.has(action.guid)) {
            keySet.add(dp.guid);
          }
        }
        if (keySet.has(dp.guid)) {
          keySet.add(lop.guid);
        }
      }
    }
  };

  const onTreeItemSelect = (items: ITreeItem[]) => {
    const keys = new Set(items.map(item => item.key));
    addParents(keys);
    props.setSelectedKeys(Array.from(keys));
  };

  const getPlannerTargetName = (data: IVistoListItem) => {
    switch (props.integrationLevel) {

      case PlannerIntegrationLevel.Plan:
        switch (data.kind) {
          case VistoKind.LOP: return '';
          case VistoKind.DP: return TextService.format(strings.PlannerName_Bucket);
          default: return '';
        }

      case PlannerIntegrationLevel.LOP:
        switch (data.kind) {
          case VistoKind.LOP: return TextService.format(strings.PlannerName_Plan);
          case VistoKind.DP: return TextService.format(strings.PlannerName_Bucket);
          default: return '';
        }
      case PlannerIntegrationLevel.DP:
        switch (data.kind) {
          case VistoKind.LOP: return '';
          case VistoKind.DP: return TextService.format(strings.PlannerName_Plan);
          default: return '';
        }

      default: return '';
    }
  };

  const onRenderItem = (item: ITreeItem) => {
    return (
      <Stack horizontal id={item.key} tokens={{ childrenGap: 's2' }}>
        <Text>{item.label}</Text>
        <Link disabled={!PlannerLinkService.isPlannerLink(item.data)} href={item.data.sourceItemUrl} target={item.data.guid}>{getPlannerTargetName(item.data)}</Link>
      </Stack>
    );
  };

  return (
    <Stack grow tokens={{ childrenGap: 'm' }}>
      <Stack grow style={{ maxHeight: '50vh', overflowY: 'auto' }}>
        <Toggle
          label={TextService.format(strings.PlannerPlanWizardStartPage_EnableLabelSync)}
          checked={props.enableLabelSync}
          onChange={(_, val) => props.setEnableLabelSync(val)}
          inlineLabel
        />
        <TreeView
          items={treeitems}
          defaultExpanded={false}
          selectionMode={TreeViewSelectionMode.Multiple}
          selectChildrenMode={SelectChildrenMode.Select | SelectChildrenMode.Unselect}
          showCheckboxes={true}
          treeItemActionsDisplayMode={TreeItemActionsDisplayMode.ContextualMenu}
          defaultSelectedKeys={props.selectedKeys}
          expandToSelected={true}
          defaultExpandedChildren={true}
          onSelect={onTreeItemSelect}
          onRenderItem={onRenderItem}
        />
      </Stack>
    </Stack>
  );
};
