import * as strings from 'VistoWebPartStrings';
import * as React from 'react';
import { Stack } from '@fluentui/react';
import { TextService } from 'services/TextService';
import { PlanStorage } from './PlanWizard';
import { IWizardChoiceGroupOption, WizardChoiceGroup } from 'components/WizardChoiceGroup';
import { EnvContext } from 'services/EnvContext';

export function PlanWizardStoragePage(props: {
  storage: PlanStorage;
  setStorage: (val: PlanStorage) => void;
}) {

  const { hostKind } = React.useContext(EnvContext);

  const operations: IWizardChoiceGroupOption[] = [{
    key: PlanStorage.Local.toString(),
    text: TextService.format(strings.PlanWizardStoragePage_LocalStorageLabel),
    disabled: hostKind === 'TeamsDesktop' || hostKind === 'TeamsMobile' || hostKind === 'TeamsWeb',
    description: TextService.format(strings.PlanWizardStoragePage_LocalStorageDescription)
  }, {
    key: PlanStorage.Database.toString(),
    text: TextService.format(strings.PlanWizardStoragePage_CloudStorageLabel),
    disabled: true,
    description: TextService.format(strings.PlanWizardStoragePage_CloudStorageDescription)
  }, {
    key: PlanStorage.TeamSite.toString(),
    text: TextService.format(strings.PlanWizardStoragePage_SharePointStorageLabel),
    disabled: hostKind === 'WebDesktop' || hostKind === 'WebMobile',
    description: TextService.format(strings.PlanWizardStoragePage_SharePointStorageDescription)
  }];

  return (
    <Stack grow>
      <WizardChoiceGroup
        options={operations}
        selectedKey={props.storage.toString()}
        onKeyChanged={val => props.setStorage(+val)}
      />
    </Stack>
  );
}
