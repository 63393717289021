import * as strings from 'VistoWebPartStrings';
import * as React from 'react';
import { Stack, ChoiceGroup, IChoiceGroupOption } from '@fluentui/react';
import { PlannerIntegrationLevel } from '../services';
import { TextService } from 'services/TextService';

export const PlannerPlanWizardStartPage = (props: {
  integrationLevel: PlannerIntegrationLevel;
  setIntegrationLevel: (val: PlannerIntegrationLevel) => void;
}) => {

  const operations: IChoiceGroupOption[] = [
    {
      key: PlannerIntegrationLevel[PlannerIntegrationLevel.Plan],
      text: TextService.format(strings.PlannerPlanWizardStartPage_OptionPlan),
      disabled: true
    },
    {
      key: PlannerIntegrationLevel[PlannerIntegrationLevel.LOP],
      text: TextService.format(strings.PlannerPlanWizardStartPage_OptionLOP),
    },
    {
      key: PlannerIntegrationLevel[PlannerIntegrationLevel.DP],
      text: TextService.format(strings.PlannerPlanWizardStartPage_OptionDP),
      disabled: true
    },
  ];

  return (
    <Stack grow>
      <ChoiceGroup
        options={operations}
        selectedKey={PlannerIntegrationLevel[props.integrationLevel]}
        onChange={(_, val: IChoiceGroupOption) => props.setIntegrationLevel(PlannerIntegrationLevel[val.key])}
      />
    </Stack>
  );
};
