// Default entry point for client scripts
// Automatically generated
// Please avoid from modifying to much...
import * as ReactDOM from 'react-dom';
import * as React from 'react';

export const render = (type: any, element: HTMLElement, props) => {
  ReactDOM.render(React.createElement(type, props), element);
};
// Automatically added for the vistoTeamsTab tab
export * from './tabs/VistoTeamsTab';
export * from './tabs/VistoTeamsTabConfig';
export * from './tabs/VistoTeamsTabRemove';
export * from './tabs/VistoTeamsTabPersonal';
export * from './tabs/web/Home';
