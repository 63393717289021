import { CopyPlanLinkDialog } from 'dialogs/CopyPlanLinkDialog';
import React from 'react';
import { AppContext } from 'services/AppContext';
import { ISelectedCellInfo } from 'shared/ISelectedCellInfo';
import { mx } from '../drawing/common/mx';

export const useSidebarShareDialog = (): [JSX.Element, (val) => void] => {

  const { editorUiRef } = React.useContext(AppContext);

  const selectedCellInfoRef = React.useRef<ISelectedCellInfo>(null);
  const [isCopyPlanLinkDialogVisible, _setIsCopyPlanLinkDialogVisible] = React.useState(false);
  
  const setIsCopyPlanLinkDialogVisible = (visible: boolean) => {
    selectedCellInfoRef.current = visible ? mx.getSelection(editorUiRef.current?.editor?.graph) : null;
    _setIsCopyPlanLinkDialogVisible(visible);
  };

  return [
    isCopyPlanLinkDialogVisible && <CopyPlanLinkDialog selection={selectedCellInfoRef.current} onDismiss={() => setIsCopyPlanLinkDialogVisible(false)} />,
    setIsCopyPlanLinkDialogVisible
  ];
};
