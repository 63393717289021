import { Icon, Stack, Text, useTheme } from '@fluentui/react'
import React from 'react'
import { TextService } from 'services/TextService'
import strings from 'VistoWebPartStrings';

const OkrLegendCard = (props: {
  icon: string;
  text: string;
}) => {
  const theme = useTheme();

  const style = {
    boxShadow: theme.effects.elevation4,
    border: `1px solid ${theme.palette.neutralTertiary}`,
    width: `5rem`,
  };

  return (
    <Stack style={style} horizontalAlign='center' tokens={{ childrenGap: 4, padding: 4 }}>
      <Icon iconName={props.icon} />
      <Text variant='medium'>{props.text}</Text>
    </Stack>
  )
}

export const OkrLegend = (props: {

}) => {
  return (
    <Stack horizontalAlign='center' tokens={{ childrenGap: 4, padding: 8 }}>
      <OkrLegendCard icon='Bullseye' text={TextService.format(strings.__StrategicObjective)} />
      <Icon iconName='Down' />
      <OkrLegendCard icon='Chart' text={TextService.format(strings.__KeyResult)} />
      <Icon iconName='Down' />
      <OkrLegendCard icon='LightningBolt' text={TextService.format(strings.__Action)} />
    </Stack>
  )
}
