import { PeoplePicker, PrincipalType } from '@pnp/spfx-controls-react/lib/controls/peoplepicker';
import React from 'react';
import { EnvContext } from 'services/EnvContext';
import { SharepointUserResolver } from 'services/SharepointUserResolver';
import { IFieldValueUser } from 'sp';

export const AssigneePicker = (props: {
  label: string;
  value: IFieldValueUser[];
  setValue: (val: IFieldValueUser[]) => void;
  disabled?: boolean;
}) => {

  const { webPartContext } = React.useContext(EnvContext);
  
  const defaultSelectedUsers = props.value?.map(u => u.title ? `${u.userName}/${u.title}` : u.userName) ?? [];

  const updateAssignee = (items: any[]) => {
    const assignedTo: IFieldValueUser[] = items.map(x => ({
      id: x.id,
      guid: null,
      title: x.text,
      userName: SharepointUserResolver.getUserName(x.loginName),
    }));
    props.setValue(assignedTo);
  };

  return (
    <PeoplePicker
      context={webPartContext as any}
      titleText={props.label}
      personSelectionLimit={10}
      showtooltip={false}
      required={false}
      disabled={props.disabled}
      ensureUser={true}
      onChange={updateAssignee}
      showHiddenInUI={false}
      principalTypes={[PrincipalType.User]}
      defaultSelectedUsers={defaultSelectedUsers}
      resolveDelay={1000}
    />
  );
};
