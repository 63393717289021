import { TooltipHost } from '@fluentui/react';
import { IButtonStyles, PrimaryButton } from '@fluentui/react/lib/Button';
import * as React from 'react';
import { AppContext } from 'services/AppContext';
import { TextService } from 'services/TextService';
import { api } from 'shared/api';
import * as strings from 'VistoWebPartStrings';
import { TopEditButton } from './TopEditButton';

export const ToggleEditButton = (props: {
  status: api.IWSPlanStatus;
  readOnly: boolean;
  disabled?: boolean;
  setReadOnly: (val: boolean) => void;
}) => {

  const { wsContext, isPlanEditEnabled } = React.useContext(AppContext);

  const lockedTo = props.status?.editor
    && (props.status?.editor !== wsContext?.connectionId)
    && props.status.users.find(u => u.connection_id === props.status.editor);

  const toggleReadOnlyDisabled = !!lockedTo || !isPlanEditEnabled || props.disabled;

  const tooltipMessage = toggleReadOnlyDisabled
    ? lockedTo
      ? TextService.format(strings.TopToggleButton_TooltipEditByAnotherUser, { lockedToName: lockedTo.name })
      : TextService.format(strings.TopToggleButton_TooltipReadOnly)
    : '';

  const toggleReadOnly = () => {
    const newVal = !props.readOnly;
    props.setReadOnly(newVal);
    if (wsContext) {
      wsContext.setEditor(!newVal);
    }
  };

  return <TopEditButton tooltipMessage={tooltipMessage} disabled={toggleReadOnlyDisabled} toggleReadOnly={toggleReadOnly} readOnly={props.readOnly} />

};
