import React from 'react';
import { useTheme } from '@fluentui/react';
import { EditorUi, FixedViewType, mx, mxglobals, ViewAlignment } from 'frames/TopFrame/drawing/common';
import { mxgraph } from 'ts-mxgraph-typings';
import { OkrService } from './OkrGraphService';
import { TreeMouseListener } from '../../components/TreeMouseListener';
import { IOkrCardInfo } from './IOkrCardInfo';
import { TreeService } from 'services/TreeService';
import { IRenderCardData } from './IRenderCardData';
import { useZoomInfo } from 'services/useZoomInfo';

export const OkrTree = (props: {
  readOnly: boolean;
  zoomInfoKey: string;
  items: IOkrCardInfo[];
  sort: (a: IOkrCardInfo, b: IOkrCardInfo) => number;
  setEditorUi: (editorUi: EditorUi) => void;
  onConnect: (src: IOkrCardInfo, dst: IOkrCardInfo) => void;
  onDisconnect: (ci: IOkrCardInfo, dst: IOkrCardInfo) => void;
  onClick: (ci: IOkrCardInfo, target: HTMLElement) => void;
  renderCard: (item: IOkrCardInfo) => IRenderCardData;
  expandedCards: { [key: string]: IOkrCardInfo };
}) => {

  const theme = useTheme();

  const [zoomInfo] = useZoomInfo(props.zoomInfoKey);

  const tree = React.useRef<{ [key: string]: IOkrCardInfo }>(null);

  const containerRef = React.useRef<HTMLDivElement>(null);
  const editorUiRef = React.useRef<any>(null);
  const mouseListenerRef = React.useRef<TreeMouseListener>(null);

  const getGraph = (editorUi: any) => {
    return editorUi.editor.graph as mxgraph.mxGraph;
  };

  const onCellsAdded = (sender, evt) => {

    const graph = sender;

    const [edge] = evt.getProperty('cells');
    const source = graph.getModel().getTerminal(edge, true);
    const target = graph.getModel().getTerminal(edge, false);

    console.log('cells added', edge);

    if (source && target) {
      props.onConnect(tree.current[OkrService.getCellKey(source)], tree.current[OkrService.getCellKey(target)]);
    }
  };

  const onCellsRemoved = (sender, evt) => {

    const graph = sender;
    const [edge] = evt.getProperty('cells');
    const source = graph.getModel().getTerminal(edge, true);
    const target = graph.getModel().getTerminal(edge, false);

    console.log('cells removed', edge);

    if (source && target) {
      props.onDisconnect(tree.current[OkrService.getCellKey(source)], tree.current[OkrService.getCellKey(target)]);
    }
  };

  React.useEffect(() => {
    if (containerRef.current) {
      const editorUi = OkrService.createTreeEditorUi(containerRef.current, theme, props.readOnly);
      const graph = getGraph(editorUi);
      if (props.readOnly) {
        mouseListenerRef.current = new TreeMouseListener(graph, theme);
        graph.addMouseListener(mouseListenerRef.current);

      } else {
        graph.addListener(mxglobals.mxEvent.CELLS_ADDED, onCellsAdded);
        graph.addListener(mxglobals.mxEvent.CELLS_REMOVED, onCellsRemoved);
      }

      props.setEditorUi(editorUi);
      editorUiRef.current = editorUi;
    }
    return () => {
      const graph = getGraph(editorUiRef.current);
      if (mouseListenerRef.current) {
        graph.removeMouseListener(mouseListenerRef.current);
        mouseListenerRef.current = null;
      }
      if (editorUiRef.current) {
        graph.setEventsEnabled(false);
        props.setEditorUi(null);
        editorUiRef.current.destroy();
        editorUiRef.current = null;
      }
    }
  }, [containerRef.current, theme, props.readOnly]);

  React.useEffect(() => {
    if (editorUiRef.current) {

      tree.current = TreeService.createItemSet(props.items);

      const graph = getGraph(editorUiRef.current);
      const model: mxgraph.mxGraphModel = graph.getModel();

      const onClick = (sender: any, evt: any) => {
        var cell: mxgraph.mxCell = evt.getProperty('cell');
        var event = evt.getProperty('event');
        const item = tree.current[OkrService.getCellKey(cell)];
        if (item) {
          const target = event.target as HTMLElement;
          props.onClick(item, target);
        }
      };

      if (props.readOnly) {
        graph.addListener(mxglobals.mxEvent.CLICK, onClick);
      }

      model.beginUpdate();
      try {
        graph.setEventsEnabled(false);
        model.clear();
        const roots = OkrService.getRootItems(tree.current, props.sort);
        if (roots.length) {
          OkrService.addChildItems(graph, props.readOnly, tree.current, null, roots, theme, {}, props.sort, props.renderCard, props.expandedCards);
          OkrService.runLayout(graph);
          mx.setFixedView(graph, zoomInfo.scale, FixedViewType.Width, ViewAlignment.Middle, ViewAlignment.Begin);
          editorUiRef.current.chromelessResize(false, null, 0, 0);
        }
      }
      finally {
        model.endUpdate();
        graph.setEventsEnabled(true);
      }

      return () => {
        graph.removeListener(onClick);
      }
    }
  }, [props.items, props.expandedCards, editorUiRef.current]);

  return <div ref={containerRef} className='geEditor' style={{ flex: 1, position: 'relative', overflow: 'auto' }}></div>
}
