import * as strings from 'VistoWebPartStrings';
import { trackClient } from 'shared/clientTelemetry';
import { IPropertyBag } from './IPropertyBag';
import { TextService } from 'services/TextService';
import { parseJSON } from 'shared/parse';

export const VISTO_SETTINGS_KEY = 'VisPlan';

export class PropertyBagService {

  public static defaultPropertyBag: IPropertyBag = {
    showAdvisory: true,
    showProgress: true,
    showAssignees: true,
    showValidations: true,
    showIndicators: true,
    showEffects: false,
    skipGettingStarted: false,
    showCompleted: false,
    showInactive: true,
    expanded: {},
    showHistory: false,
    historyDate: TextService.getDate(new Date()),
    integrations: {},
    assistantConsented: false,
    gantt: {},
    sidebarGroupBy: 'focus',

    fillColor: 'blue',
    strokeColor: 'black',
    fontColor: 'black'
  };

  public static loadSettings(): IPropertyBag {
    let result = PropertyBagService.defaultPropertyBag;
    try {
      const serialized = localStorage.getItem(VISTO_SETTINGS_KEY);
      if (serialized) {
        result = parseJSON(serialized);
      }
    } catch (err) {
      trackClient.error(`Unable to read Visto settings for key ${VISTO_SETTINGS_KEY}. Using default settings`, err);
    }

    return result;
  }

  public static saveSettings(settings: IPropertyBag) {
    const serialized = JSON.stringify(settings);
    localStorage.setItem(VISTO_SETTINGS_KEY, serialized);
    return settings;
  }

}
