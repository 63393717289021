import { Dropdown } from '@fluentui/react/lib/Dropdown';
import React from 'react';
import { TextService } from 'services/TextService';
import strings from 'VistoWebPartStrings';
import { FixedViewType } from '../common/mx';

export const ImageResizeTypeDropdown = (props: {
  disabled: boolean;
  resizeType: FixedViewType;
  setResizeType: (val: FixedViewType) => void;
}) => {

  const resizeTypes = [
    {
      key: FixedViewType.None,
      text: TextService.format(strings.ImageDialog_ResizeNone)
    },
    {
      key: FixedViewType.FitPage,
      text: TextService.format(strings.ImageDialog_ResizeFit)
    },
    {
      key: FixedViewType.FillPage,
      text: TextService.format(strings.ImageDialog_ResizeFill)
    }
  ];

  const updateSelectedFitStyle = (val: FixedViewType) => {
    props.setResizeType(val);
  };

  return (
    <Dropdown
      disabled={props.disabled}
      label={TextService.format(strings.ImageDialog_ImagePlacement)}
      selectedKey={props.resizeType}
      onChange={(_, val) => updateSelectedFitStyle(+val.key)}
      options={resizeTypes}
    />
  );
}
