import { loadStyles } from '@microsoft/load-themed-styles';

const styles = {
  errorMessage: 'errorMessage_a198428e',
  errorIcon: 'errorIcon_df5e24de',
};

export default styles;

loadStyles('.errorMessage_a198428e{font-size:12px;font-weight:400;color:#a80000;margin:0;padding-top:5px;display:flex;align-items:center}.errorIcon_df5e24de{font-size:14px;margin-right:5px}');
