// from chart.js documentation
// https://www.chartjs.org/docs/latest/samples/legend/html.html

import { TextService } from 'services/TextService';
import strings from 'VistoWebPartStrings';

const getOrCreateLegendList = (chart, id, direction) => {
  const legendContainer = document.getElementById(id);
  let listContainer = legendContainer.querySelector('ul');

  if (!listContainer) {
    listContainer = document.createElement('ul');
    listContainer.style.display = 'flex';
    listContainer.style.flexDirection = direction ?? 'row';
    listContainer.style.margin = '0';
    listContainer.style.padding = '0';

    legendContainer.appendChild(listContainer);
  }

  return listContainer;
};

export const HtmlLegendPlugin = {
  id: 'htmlLegend',
  afterUpdate(chart, args, options) {
    const ul = getOrCreateLegendList(chart, options.containerId, options.direction);

    // Remove old legend items
    while (ul.firstChild) {
      ul.firstChild.remove();
    }

    // Reuse the built-in legendItems generator
    const items = chart.options.plugins.legend.labels.generateLabels(chart);

    const addItem = (item, onclick) => {
      const li = document.createElement('li');
      li.style.alignItems = 'center';
      li.style.cursor = 'pointer';
      li.style.display = 'flex';
      li.style.flexDirection = 'row';
      li.style.marginLeft = '10px';
      li.onclick = onclick;

      // Color box
      if (item.lineDash?.length) {
        const boxSpan1 = document.createElement('span');
        boxSpan1.style.background = item.strokeStyle;
        boxSpan1.style.display = 'inline-block';
        boxSpan1.style.height = '3px';
        boxSpan1.style.marginRight = '6px';
        boxSpan1.style.width = '7px';
        li.appendChild(boxSpan1);
        const boxSpan2 = document.createElement('span');
        boxSpan2.style.background = item.strokeStyle;
        boxSpan2.style.display = 'inline-block';
        boxSpan2.style.height = '3px';
        boxSpan2.style.marginRight = '10px';
        boxSpan2.style.width = '7px';
        li.appendChild(boxSpan2);
      } else {
        const boxSpan = document.createElement('span');
        boxSpan.style.background = item.strokeStyle;
        boxSpan.style.display = 'inline-block';
        boxSpan.style.height = '5px';
        boxSpan.style.marginRight = '10px';
        boxSpan.style.width = '20px';
        li.appendChild(boxSpan);
      }

      // Text
      const textContainer = document.createElement('p');
      textContainer.style.color = item.fontColor;
      textContainer.style.margin = '0';
      textContainer.style.padding = '0';
      textContainer.style.textDecoration = item.hidden ? 'line-through' : '';

      const text = document.createTextNode((item.hidden ? '☐ ' : '☑ ') + item.text);
      textContainer.appendChild(text);

      li.appendChild(textContainer);
      ul.appendChild(li);
    };

    const todayHidden = !chart.options.plugins?.annotation?.annotations?.today;

    const todayItem = {
      strokeStyle: options.todayColor,
      text: TextService.format(strings.LegendLabel_Today),
      lineDash: [0],
      hidden: todayHidden
    };

    addItem(todayItem, () => {
      if (todayHidden) {
        chart.options.plugins.annotation.annotations.today = {
          type: 'line',
          xMin: options.todayDate,
          xMax: options.todayDate,
          borderDash: [5, 5],
          borderColor: options.todayColor,
          borderWidth: 1,
        }
      } else {
        delete chart.options.plugins.annotation.annotations.today;
      }
      chart.update();
    });

    const focusHidden = !chart.options.plugins?.annotation?.annotations?.focus;

    const focusItem = {
      strokeStyle: options.focusColor,
      text: options.focusTitle,
      hidden: focusHidden
    };

    addItem(focusItem, () => {
      if (focusHidden) {
        chart.options.plugins.annotation.annotations.focus = {
          type: 'box',
          xMin: options.focusStartDate,
          xMax: options.focusEndDate,
          // adjustScaleRange: false,
          backgroundColor: options.focusColor
        }
      } else {
        delete chart.options.plugins.annotation.annotations.focus;
      }
      chart.update();
    });

    items.forEach(item => addItem(item, () => {
      chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex));
      chart.update();
    }));
  }
};
