import { AuthService } from 'services/AuthService';
import { IExecutableAction } from 'services/IExecutableAction';
import { UrlService } from 'shared/urlService';
import { SharepointService } from 'services/SharepointService';
import { StorageCacheService } from 'services/StorageCacheService';
import { TextService } from 'services/TextService';
import { makeGuidString } from 'shared/guid';
import { IVistoPlan, KeyResultType, KeyResultValueKind, ListItemUpdater, VistoKeyResultItem, VistoKeyResultValueItem, VistoKind, VistoSoItem } from 'sp';
import strings from 'VistoWebPartStrings';

export const makeMigrateKeyResults = (foundPlan: IVistoPlan): IExecutableAction => ({
  title: `Migrate Key Results`,
  execute: async (p) => {
    const sp = AuthService.getSpClient(foundPlan.siteUrl);
    
    const timeZone = await sp.web.regionalSettings.timeZone();
    const timeZoneBias = timeZone.Information.Bias + timeZone.Information.DaylightBias;

    const krListUrl = UrlService.getListRelativeUrl(foundPlan, VistoKind.KeyResult);
    const soListUrl = UrlService.getListRelativeUrl(foundPlan, VistoKind.SO);
    const krvListUrl = UrlService.getListRelativeUrl(foundPlan, VistoKind.KRV);

    const soSpItems = await sp.web.getList(soListUrl).items.top(2000)();
    const updater = new ListItemUpdater(timeZoneBias);

    for (const soSpItem of soSpItems) {

      const soId = soSpItem['ID'];
      const soGuid = soSpItem['VISTO_Guid'];
      const soCurrentValue = soSpItem['CurrentValue'];
      const soTargetValue = soSpItem['TargetValue'];
      const soUnits = soSpItem['Units'] ?? '%';

      const soItem: VistoSoItem = {
        kind: VistoKind.SO,
        itemId: soId,
        guid: soGuid,
      };
      updater.addKnownItem(soItem);

      const krGuid = makeGuidString();
      const krItemInit: VistoKeyResultItem = {
        kind: VistoKind.KeyResult,
        guid: krGuid,
        name: TextService.format(strings.Migrated_DefaultKeyResultName),
        units: soUnits,
        soGuid: soGuid,
        keyResultType: KeyResultType.Numeric
      };
      const krSpItem = SharepointService.makeSpChanges<VistoKeyResultItem>(updater, VistoKind.KeyResult, krItemInit);
      const created = await sp.web.getList(krListUrl).items.add(krSpItem);
      const krItem = {
        ...krItemInit,
        itemId: created.data['ID']
      };
      updater.addKnownItem(krItem);

      if (soTargetValue) {
        const krvItemStart = SharepointService.makeSpChanges<VistoKeyResultValueItem>(updater, VistoKind.KRV, {
          guid: makeGuidString(),
          name: TextService.format(strings.Migrated_DefaultKeyResultName),
          value: 0,
          krGuid: krGuid,
          valueKind: KeyResultValueKind.Target,
          valueDate: new Date(2022, 0, 1, 10),
        });
        await sp.web.getList(krvListUrl).items.add(krvItemStart);

        const krvItemTarget = SharepointService.makeSpChanges<VistoKeyResultValueItem>(updater, VistoKind.KRV, {
          guid: makeGuidString(),
          name: TextService.format(strings.Migrated_DefaultKeyResultName),
          value: soTargetValue,
          krGuid: krGuid,
          valueKind: KeyResultValueKind.Target,
          valueDate: new Date(2022, 11, 31, 10),
        });
        await sp.web.getList(krvListUrl).items.add(krvItemTarget);
      }

      if (soCurrentValue) {
        const krvItemCurrent = SharepointService.makeSpChanges<VistoKeyResultValueItem>(updater, VistoKind.KRV, {
          guid: makeGuidString(),
          name: TextService.format(strings.Migrated_DefaultKeyResultName),
          value: soCurrentValue,
          krGuid: krGuid,
          valueKind: KeyResultValueKind.Actual,
          valueDate: new Date()
        });
        await sp.web.getList(krvListUrl).items.add(krvItemCurrent);
      }
    }
    StorageCacheService.resetCache();
    return p;
  }
});
