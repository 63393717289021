import { FolderExplorer, IFolder } from '@pnp/spfx-controls-react/lib/controls/folderExplorer';
import * as React from 'react';
import { EnvContext } from 'services/EnvContext';
import { TextService } from 'services/TextService';
import { UrlService } from 'shared/urlService';
import strings from 'VistoWebPartStrings';

export const AppFolderExplorer = (props: {
  className?: string;
  selectedFolder: string;
  setSelectedFolder: (val: string) => void;
}) => {

  const { siteUrl, defaultFolderName, defaultFolderRelativeUrl, webPartContext } = React.useContext(EnvContext);

  const rootFolder: IFolder = {
    Name: TextService.format(strings.AttachFolder_RootName),
    ServerRelativeUrl: webPartContext.pageContext.web.serverRelativeUrl
  };

  const documentsFolder: IFolder = {
    Name: defaultFolderName,
    ServerRelativeUrl: defaultFolderRelativeUrl
  };

  return (siteUrl === UrlService.LOCAL_URL
    ? null
    : <FolderExplorer
      className={props.className}
      context={webPartContext as any}
      rootFolder={rootFolder}
      defaultFolder={documentsFolder}
      onSelect={folder => props.setSelectedFolder(folder.ServerRelativeUrl)}
      canCreateFolders={true}
    />
  );

};
