import { PlanKeyresultsService } from 'services/PlanKeyresultsService';
import { IFieldValueUser, IVistoPlan, KeyResultType, VistoKeyResultItem, VistoKeyResultValueItem, VistoKind } from 'sp';
import { SvgShapes } from './SvgShapes';
import * as Mustache from 'mustache';
import { TextService } from 'services/TextService';
import { IProgressData } from 'services/IProgressData';
import { PlanStylesService } from 'services/PlanStylesService';
import { IntegrationService } from 'services/IntegrationService';
import { IIconInfo } from 'services/IIconInfo';
import { ColorService } from 'services/colorService';
import { UserInfoService } from 'services/UserInfoService';
import { ImageCacheService } from 'services/ImageCacheService';
import { trackClient } from 'shared/clientTelemetry';

export class SvgIndicators {

  public static overlayTrendTemplate = (size: number, direction: number) => SvgShapes.makeArrowShape({
    direction,
    size,
    fillColor: '{{fillColor}}',
    strokeColor: '{{strokeColor}}',
    stroke: 2
  });

  public static overlayProgressTemplate = (size: number, angle: number, stroke: number) => SvgShapes.makeSvgSectorShape({
    angle,
    arc: true,
    circleColor: '{{fillColor}}',
    sectorColor: '{{strokeColor}}',
    size,
    fillCircle: true,
    stroke
  });

  public static overlayCheckboxTemplate = (size: number, checked: boolean) => SvgShapes.makeCircleCheckboxShape({
    checked,
    size,
    fillColor: '{{fillColor}}',
    strokeColor: '{{strokeColor}}',
    stroke: 2
  });

  public static overlayBudgetTemplate = (size: number, checked: boolean) => SvgShapes.makeCircleTextShape({
    size,
    fillColor: '{{fillColor}}',
    strokeColor: '{{strokeColor}}',
    stroke: 2,
    text: '$'
  });

  public static buildSvgDone = (
    size: number,
    plan: IVistoPlan,
    targets: VistoKeyResultValueItem[],
    actuals: VistoKeyResultValueItem[],
    viewStartDate: Date,
    viewEndDate: Date) => {

    const { currentValue } = PlanKeyresultsService.getKeyResultProgress(targets, actuals, viewStartDate, viewEndDate, plan.statusDate);

    const checked = !!currentValue;
    const svg = Mustache.render(this.overlayCheckboxTemplate(size, checked), {
      fillColor: checked ? '#92D050' : '#F0F0F0',
      strokeColor: checked ? '#548235' : '#CCC'
    });
    return svg;
  };

  public static buildSvgBudget = (
    size: number,
    plan: IVistoPlan,
    targets: VistoKeyResultValueItem[],
    actuals: VistoKeyResultValueItem[],
    viewStartDate: Date,
    viewEndDate: Date,
    inverse: boolean) => {

    let { currentValue, referenceValue } = PlanKeyresultsService.getKeyResultProgress(targets, actuals,
      viewStartDate, viewEndDate, plan.statusDate);

    if (inverse) {
      [currentValue, referenceValue] = [referenceValue, currentValue]
    }
  
    const invalid = !TextService.isValidNumber(currentValue) || !TextService.isValidNumber(referenceValue);
    const positive = currentValue < referenceValue;
    const svg = Mustache.render(this.overlayBudgetTemplate(size, positive), {
      fillColor: invalid ? 'black' : positive ? '#92D050' : '#F26E6E',
      strokeColor: invalid ? 'white' : positive ? '#548235' : '#C00000'
    });
    return svg;
  };

  public static buildSvgTrend = (
    size: number,
    plan: IVistoPlan,
    targets: VistoKeyResultValueItem[],
    actuals: VistoKeyResultValueItem[],
    viewStartDate: Date,
    viewEndDate: Date,
    inverse: boolean) => {

    const { currentValue, referenceValue, trend } = PlanKeyresultsService.getKeyResultProgress(targets, actuals,
      viewStartDate, viewEndDate, plan.statusDate);

    const progress: IProgressData = {
      percentComplete: inverse ? (referenceValue / currentValue * 100) : (currentValue / referenceValue * 100),
      plannedPercentComplete: 100
    };

    const krFillColor = PlanStylesService.getColor(plan, progress, 'fillColor');
    const krStrokeColor = PlanStylesService.getColor(plan, progress, 'strokeColor');

    const svg = Mustache.render(this.overlayTrendTemplate(size, trend), { fillColor: krFillColor, strokeColor: krStrokeColor });
    return svg;
  };

  public static buildSvgProgress = (size: number, stroke: number, plan: IVistoPlan, item: IProgressData) => {

    const havePartialProgress = TextService.isValidNumber(item.percentComplete) || TextService.isValidNumber(item.plannedPercentComplete);
    const fillColor = PlanStylesService.getColor(plan, item, 'fillColor') || havePartialProgress && '#ccc';
    const strokeColor = PlanStylesService.getColor(plan, item, 'strokeColor') || havePartialProgress && '#777';

    if (fillColor || strokeColor) {
      const hasPercentComplete = TextService.isValidNumber(item.percentComplete);
      const angle = hasPercentComplete && 360 * item.percentComplete / 100;

      const svg = Mustache.render(this.overlayProgressTemplate(size, angle, stroke), { fillColor, strokeColor });
      return svg;
    }
  };

  public static buildSvgIndicator = (plan: IVistoPlan, kr: VistoKeyResultItem, size: number, viewStartDate: Date, viewEndDate: Date) => {

    if (kr?.kind == VistoKind.KeyResult) {
      const { targets, actuals } = PlanKeyresultsService.getKeyResultValues(plan, kr.guid);
      switch (kr.keyResultType) {
        case KeyResultType.Binary:
          return this.buildSvgDone(size, plan, targets, actuals, viewStartDate, viewEndDate);
        case KeyResultType.Budget:
          return this.buildSvgBudget(size, plan, targets, actuals, viewStartDate, viewEndDate, kr.inverse);
        case KeyResultType.Numeric:
        default:
          return SvgIndicators.buildSvgTrend(size, plan, targets, actuals, viewStartDate, viewEndDate, kr.inverse);
      }
    }
  }

  static blobToBase64(blob: Blob) {
    return new Promise<string>((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result as string);
      reader.readAsDataURL(blob);
    });
  }

  public static buildSvgCircleImage = async (assignee: IFieldValueUser, size: number) => {

    const color = ColorService.getUserColor(assignee.userName || assignee.guid);

    try {
      const imageUrl = await UserInfoService.getUserPhotoUrl(assignee.guid ?? assignee.userName);
      if (imageUrl) {
        const imageData = await fetch(imageUrl);
        if (imageData.ok) {
          const imageBlob = await imageData.blob();
          const imageBase64 = await this.blobToBase64(imageBlob);

          const svg = SvgShapes.makeCircleImageSvg({
            size,
            strokeColor: '#ccc',
            stroke: 2,
            url: imageBase64
          });

          return svg;
        }
      }
    } catch (err) {
      trackClient.warn(`Unable to build circle image for user`, err);
    }

    const svg = SvgShapes.makeCircleTextShape({
      size,
      fillColor: color,
      strokeColor: '#ccc',
      stroke: 2,
      text: TextService.getInitials(assignee.title)
    });
    return svg;
  }

  public static buildSvgCircleImageUrl = async (assignee: IFieldValueUser, size: number) => {
    const imageCacheUrl = `visplan://photo-size-${size}/${assignee.userName}`;
    const imageUrl = await ImageCacheService.getImageAsync(imageCacheUrl, async () => {
      const imageSvg = await this.buildSvgCircleImage(assignee, size);
      return new Blob([imageSvg], { type: 'image/svg+xml' })
    });
    return imageUrl;
  }

  public static buildSvgNavigation = async (url: string): Promise<IIconInfo> => {
    const img = await IntegrationService.getIconInfo(url);
    return img && {
      iconUrl: img.iconUrl ? img.iconUrl : require('static/assets/links/default.svg'),
      tooltipText: img.tooltipText ? img.tooltipText : url
    };
  };

}
