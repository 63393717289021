import { Stack, ActionButton, useTheme, Text } from '@fluentui/react';
import React from 'react';
import { AppContext } from 'services/AppContext';
import { PropertyBagService } from 'services/PropertyBagService';
import { TextService } from 'services/TextService';
import strings from 'VistoWebPartStrings';
import { HistoryDatePickerDialog } from './HistoryDatePickerDialog';

export const HistoryBlock = () => {

  const { propertyBag, setPropertyBag } = React.useContext(AppContext);

  const { palette } = useTheme();

  const defaultDate = propertyBag?.historyDate ?? PropertyBagService.defaultPropertyBag.historyDate;
  const [showPicker, setShowPicker] = React.useState(false);

  const onPickerClosed = async (save: boolean, date: Date) => {
    setShowPicker(false);
    if (save) {
      setPropertyBag({ historyDate: date });
    }
  };

  const label = TextService.format(strings.HistoryBlock_Title, { date: TextService.formatDate(defaultDate) });

  return (
    <Stack horizontal style={{ backgroundColor: palette.neutralLight }} tokens={{ childrenGap: 'm' }} >
      <ActionButton iconProps={{ iconName: 'DateTime' }} onClick={() => setShowPicker(true)}>{label}</ActionButton>
      {showPicker && <HistoryDatePickerDialog date={defaultDate} onDismiss={onPickerClosed} />}
    </Stack>
  );
};
