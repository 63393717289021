import { mxgraph } from 'ts-mxgraph-typings';
import { mxglobals } from 'frames/TopFrame/drawing/common';
import Mustache from 'mustache';
import { PlanStylesService } from 'services/PlanStylesService';
import { ITheme } from '@fluentui/react';
import { ICardInfo, ICardInfoEx } from 'services/ICardInfo';
import { TreeService } from 'services/TreeService';
import { getObjectValues } from 'shared/parse';

const svgArrow = require('mxresources/styles/connect.svg.txt');
const svgQuestion = require('mxresources/styles/question.svg.txt');

const getStyleString = (style: any) =>
  Object.keys(style).map(key => `${key}=${style[key]}`).join(';');

const savedStyles = {};

const getDefaultVertexStyle = (theme: ITheme, opts: { isLarge: boolean }): Object => {

  const styleKey = `${theme.name}.defaultVertexStyle.${opts.isLarge}`

  if (savedStyles[styleKey]) {
    return savedStyles[styleKey];
  } else {

    const style = {
      html: 1,
      shape: 'label',
      perimeter: 'rectanglePerimeter',
      align: 'left',
      verticalAlign: 'bottom',
      imageAlign: 'center',
      imageVerticalAlign: 'top',
      imageAspect: 1,
      allowArrows: 0,
      whiteSpace: 'wrap',
      fontSize: Number.parseInt(theme.fonts.medium.fontSize + '', 10) / (opts.isLarge ? 1 : 1.2),
      fontFamily: theme.fonts.medium.fontFamily,
      imageBorder: theme.palette.neutralQuaternary,
      strokeColor: theme.palette.neutralQuaternary,
      strokeWidth: 1,
      fillColor: theme.palette.white,
      image: `data:image/svg+xml,${encodeURIComponent(Mustache.render(svgQuestion, theme))}`,
      imageWidth: 75,
      imageHeight: 75,
      connectable: 0,
      deletable: 0,
      overflow: 'width',
      shadow: 1,
    };
    savedStyles[styleKey] = style;
    return style;
  }
}

const getDefaultEdgeStyle = (theme: ITheme, opts: { fixed: boolean }): Object => {
  const styleKey = `${theme.name}.defaultEdgeStyle.${opts.fixed}`;

  if (savedStyles[styleKey]) {
    return savedStyles[styleKey];
  } else {
    const style = {
      shape: 'connector',
      endArrow: 'classic',
      strokeWidth: 3,
      rounded: 1,
      dashed: opts.fixed ? 0 : 1,
      dashPattern: '1 1',
      deletable: opts.fixed ? 1 : 0,
      strokeColor: theme.palette.neutralSecondary,
    };
    savedStyles[styleKey] = style;
    return style;
  }
}

const deepGetChildren = (cell: mxgraph.mxCell, ids: Set<string>) => {
  const cellId = DashboardGraphService.getCellKey(cell);
  if (!ids.has(cellId)) {
    ids.add(cellId)
    if (cell.edges) {
      for (const item of cell.edges) {
        if (item.source !== cell) {
          deepGetChildren(item.source, ids);
        }
        if (item.target !== cell) {
          deepGetChildren(item.target, ids);
        }
      }
    }
  }
}

export class DashboardGraphService {

  public static createTreeEditorUi = (
    container: HTMLDivElement,
    theme: ITheme,
    readOnly: boolean
  ) => {

    const defaultStyles = {
      styles: {
        defaultEdge: getDefaultEdgeStyle(theme, { fixed: true }),
        defaultVertex: getDefaultVertexStyle(theme, { isLarge: true }),
      }
    };

    const mxStylesheet = PlanStylesService.getMxStylesheet(JSON.stringify(defaultStyles));

    const mxThemes = {};
    const editorStyles = Mustache.render(mxStylesheet, theme);
    const node = new DOMParser().parseFromString(editorStyles, 'text/xml').documentElement;
    mxThemes['default'] = node;

    const newEditor = new mxglobals.Editor(true, mxThemes, null, null, true);
    const graph: mxgraph.mxGraph = newEditor.graph;

    // Disables some global features
    graph.panningHandler.useLeftButtonForPanning = true;
    graph.setEventsEnabled(false);
    graph.setConnectable(!readOnly);
    graph.setCellsDisconnectable(!readOnly);
    graph.setCellsCloneable(false);
    graph.setCellsMovable(false);
    graph.setCellsResizable(false);
    graph.swimlaneNesting = false;
    graph.dropEnabled = false;
    graph.autoExtend = false;
    graph.setCellsEditable(false);
    graph['defaultPageBackgroundColor'] = 'transparent'; // theme.palette.white;
    graph['defaultPageBorderColor'] = theme.palette.neutralLighterAlt;
    graph.canExportCell = () => false;
    graph.canImportCell = () => false;
    graph['isFastZoomEnabled'] = () => false;

    graph.view['defaultGridColor'] = theme.palette.neutralQuaternary;
    graph.view['gridColor'] = theme.palette.neutralQuaternary;

    graph.connectionHandler.select = false;
    graph.connectionHandler.connectImage = new mxglobals.mxImage(`data:image/svg+xml;utf8,${encodeURIComponent(Mustache.render(svgArrow, theme))}`, 50, 50);
    // newGraph.model.createId = makeGuidString;
    graph.connectionHandler.factoryMethod = () => {
      const edge = new mxglobals.mxCell('');
      edge.setEdge(true);
      const edgeStyle = getDefaultEdgeStyle(theme, { fixed: true });
      edge.setStyle(getStyleString(edgeStyle));

      const geo = new mxglobals.mxGeometry();
      geo.relative = true;
      edge.setGeometry(geo);
      return edge;
    }
    graph.connectionHandler.isValidSource = (cell) => {
      return !readOnly && cell.isVertex();
    };

    graph.connectionHandler.validateConnection = (source, target) => {
      if (readOnly || !source?.isVertex() || !target?.isVertex()) {
        return '';
      }
      const keys = new Set<string>();
      deepGetChildren(source, keys);
      const targetId = this.getCellKey(target);
      if (keys.has(targetId)) {
        return '';
      }
    }

    mxglobals.EditorUi.prototype.chromeButtonColor = theme.palette.black;
    const newEditorUi = new mxglobals.EditorUi(newEditor, container, null);
    newEditorUi.setScrollbars(true);

    graph.allowLoops = false;
    graph.allowDanglingEdges = false;
    graph.gridEnabled = !readOnly;
    graph.pageVisible = !readOnly;
    graph.pageBreaksVisible = false;
    graph.preferPageSize = false;
    graph.allowAutoPanning = true;

    graph.view.validateBackground();
    graph.sizeDidChange();

    if (readOnly) {
      graph.isLabelMovable = (cell) => false;
      graph.isCellMovable = (cell) => false;
      graph.isCellResizable = (cell) => false;
      graph.isVertexLabelsMovable = () => false;
      graph.isEdgeLabelsMovable = () => false;
      graph.isCellsEditable = () => false;
      graph.isCellBendable = (cell) => false;
      graph['handlesDisabled'] = true;

      // graph.setCellsMovable(false);
      graph.setAutoSizeCells(true);
      graph.setPanning(true);
      graph.setCellsResizable(false);
      // graph.setResizeContainer(true);
      // graph.centerZoom = true;
      // graph.setEnabled(false);
      // graph.panningHandler.useLeftButtonForPanning = true;

      newEditorUi.keyHandler.enabled = false;
    }

    // var layoutMgr = new mxglobals.mxLayoutManager(graph);
    // layoutMgr.getLayout = function (cell) {
    //   if (cell.getChildCount() > 0) {
    //     return layout;
    //   }
    // };
    return newEditorUi;
  };

  private static getChildItems(tree: { [key: string]: ICardInfo }, key: string, sort: (a: ICardInfo, b: ICardInfo) => number) {
    const childSet = TreeService.getChildSet(tree, key);
    return getObjectValues(childSet)
      .map(child => ({
        ...tree[child.key],
        name: tree[child.key]?.name || child.key.split(':')[0],
        fixed: child.fixed
      })).filter(c => !!c.key).sort(sort);
  }

  public static getRootItems(tree: { [key: string]: ICardInfo }, sort: (a: ICardInfo, b: ICardInfo) => number) {
    const roots = TreeService.getRoots(tree);
    return roots.map(c => ({
      ...c,
      name: c.name || 'visplan',
      fixed: true,
    })).filter(c => !!c.key).sort(sort);
  }

  private static makeVertex(
    pos: number,
    graph: mxgraph.mxGraph,
    item: ICardInfo,
    theme: ITheme,
    readOnly: boolean,
    renderValue: (item: ICardInfo) => string,
  ): mxgraph.mxCell {

    const image = item.imageUrl?.replace(';base64', '');

    const isLarge = item.key.startsWith('visplan:');

    const vertexStyle = {
      ...getDefaultVertexStyle(theme, { isLarge }),
      connectable: (isLarge && !readOnly) ? 1 : 0,
      ...(image ? {
        image,
        imageWidth: isLarge ? (image ? 235 : 75) : 50,
        imageHeight: isLarge ? (image ? 125 : 75) : 50,
      } : {})

    };

    const vertexStyleString = getStyleString(vertexStyle);

    const value = renderValue(item);
    const w = isLarge ? 250 : 150;
    const h = isLarge ? 150 : 120;

    const cellId = this.makeCellId(pos, item.key);
    return graph.insertVertex(null, cellId, value, undefined, undefined, w, h, vertexStyleString);
  }

  private static makeEdge(graph: mxgraph.mxGraph, parentVertex: mxgraph.mxCell, vertex: mxgraph.mxCell, fixed: boolean, theme: ITheme) {
    const edgeStyle = getDefaultEdgeStyle(theme, { fixed });
    const edge = graph.insertEdge(null, null, '', parentVertex, vertex, getStyleString(edgeStyle));
    graph.orderCells(true, [edge]);
  }

  public static addChildItems(
    graph: mxgraph.mxGraph,
    readOnly: boolean,
    tree: { [key: string]: ICardInfo },
    parentVertex: mxgraph.mxCell,
    items: ICardInfoEx[],
    theme: ITheme,
    marks: { [key: string]: mxgraph.mxCell },
    sort: (a: ICardInfo, b: ICardInfo) => number,
    render: (item: ICardInfo) => string,
    skippedItems: { parentVertex: mxgraph.mxCell, vertex: mxgraph.mxCell, fixed: boolean }[]
  ) {

    graph['tree'] = tree;

    const newVertexes: { vertex: mxgraph.mxCell, childItems: ICardInfoEx[] }[] = [];

    for (let i = 0; i < items.length; ++i) {
      const item = items[i];
      const fixed = item.fixed;

      if (marks[item.key]) {
        skippedItems.push({ parentVertex, vertex: marks[item.key], fixed });
        continue;
      }

      const childItems = this.getChildItems(tree, item.key, sort);
      const vertex = this.makeVertex(i, graph, item, theme, readOnly, render);

      marks[item.key] = vertex;

      if (parentVertex) {
        this.makeEdge(graph, parentVertex, vertex, fixed, theme);
      }

      if (childItems.length > 0) {
        newVertexes.push({ vertex, childItems });
      }
    }

    for (let i = 0; i < newVertexes.length; ++i) {
      const newVertex = newVertexes[i];
      this.addChildItems(graph, readOnly, tree, newVertex.vertex, newVertex.childItems, theme, marks, sort, render, skippedItems);
    }
  }

  public static getCellKey(cell: mxgraph.mxCell) {
    return cell?.id?.substring(5 + 1);
  }

  public static makeCellId(index: number, key: string) {
    return (2 + index).toString().padStart(5, '0') + '_' + key;
  }

  static addSkippedItems(graph: mxgraph.mxGraph, skippedItems: { parentVertex: mxgraph.mxCell; vertex: mxgraph.mxCell; fixed: boolean; }[], theme: ITheme) {
    for (let i = 0; i < skippedItems.length; ++i) {
      const skippedItem = skippedItems[i];
      this.makeEdge(graph, skippedItem.parentVertex, skippedItem.vertex, skippedItem.fixed, theme);
    }
  }

  public static runLayout(graph: mxgraph.mxGraph) {
    const page = graph.getDefaultParent();
    const layout = new mxglobals.mxOrgChartLayout(graph, 8, 75, 50);
    layout.isVertexMovable = () => true;
    layout.execute(page);
    if (page.children) {
      const bounds = graph.getBoundingBoxFromGeometry(page.children, true);
      graph.moveCells(page.children, -bounds.x, -bounds.y);
      graph.pageFormat = new mxglobals.mxRectangle(0, 0, bounds.width, bounds.height + 50);
    }
  }
}
