import { makeGuidString } from 'shared/guid';
import { IVistoPlan, KeyResultValueKind, VistoKeyResultItem, VistoKeyResultValueItem, VistoKind } from 'sp';
import { PlanDataService } from './PlanDataService';
import { TextService } from './TextService';

export class KeyResultService {

  public static makeDeafultResultTargets(plan: IVistoPlan, newKeyResult: VistoKeyResultItem) {
    
    const activeFocus = PlanDataService.getActiveFocus(plan);

    const today = plan.statusDate;
    const defaultStartDate = new Date(today.getFullYear(), 0, 1, 10);
    const defaultEndDate = new Date(today.getFullYear(), 11, 31, 10);
  
    const newKeyResultTargets: VistoKeyResultValueItem[] = [
      {
        kind: VistoKind.KRV,
        guid: makeGuidString(),
        valueDate: activeFocus?.startDate ?? TextService.getDate(defaultStartDate),
        value: 0,
        krGuid: newKeyResult.guid,
        valueKind: KeyResultValueKind.Target
      },
      {
        kind: VistoKind.KRV,
        guid: makeGuidString(),
        valueDate: activeFocus?.endDate ?? TextService.getDate(defaultEndDate),
        value: 100,
        krGuid: newKeyResult.guid,
        valueKind: KeyResultValueKind.Target
      },
    ];

    return newKeyResultTargets;
  }

}
