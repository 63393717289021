import { Text, Stack, TextField } from '@fluentui/react';
import { AppFolderExplorer } from 'components/AppFolderExplorer';
import * as React from 'react';
import { TextService } from 'services/TextService';
import strings from 'VistoWebPartStrings';
import styles from './ExportPlanFileNamePage.module.scss';

export const ExportPlanFileNamePage = (props: {
  suffix: string;
  fileName: string;
  setFileName: (val: string) => void;
  selectedFolder: string;
  setSelectedFolder: (val: string) => void;
}) => {

  const fileName = TextService.removeSuffix(props.fileName, props.suffix);
  const setFileName = (_, val: string) => {
    props.setFileName(val + props.suffix);
  }

  return (
    <Stack tokens={{ childrenGap: 'm' }}>
      <Text variant='large'>{TextService.format(strings.ExportPlanFileNamePage_Title)}</Text>
      <TextField label={TextService.format(strings.ExportPlanFileNamePage_FileNameLabel)} value={fileName} onChange={setFileName} suffix={props.suffix} />
      <AppFolderExplorer className={styles.folderPicker} selectedFolder={props.selectedFolder} setSelectedFolder={(val) => props.setSelectedFolder(val)} />
    </Stack>
  );
};
