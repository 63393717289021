import React from 'react';
import { IVistoPlan, KeyResultType, VistoKeyResultItem, VistoKeyResultValueItem } from 'sp';
import { ProgressBarBlockBinary } from './ProgressBarBlockBinary';
import { ProgressBarBlockBudget } from './ProgressBarBlockBudget';
import { ProgressBarBlockNumeric } from './ProgressBarBlockNumeric';

export const ProgressBarBlock = (props: {
  plan: IVistoPlan;
  viewStartDate?: Date;
  viewEndDate?: Date;
  targets: VistoKeyResultValueItem[];
  actuals: VistoKeyResultValueItem[];
  kr: VistoKeyResultItem;
  onClick?: () => void;
}) => {

  return (props.kr.keyResultType === KeyResultType.Binary)
    ? <ProgressBarBlockBinary {...props} />
    : (props.kr.keyResultType === KeyResultType.Budget)
      ? <ProgressBarBlockBudget {...props} />
      : <ProgressBarBlockNumeric {...props} />;
};
