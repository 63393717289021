import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as Mustache from 'mustache';

import { IVistoListItem } from 'sp';
import { AppContext } from 'services/AppContext';
import { Commands } from 'services/Commands';
import { EnvContext } from 'services/EnvContext';
import { PlanDataService } from 'services/PlanDataService';
import { PlanStylesService } from 'services/PlanStylesService';
import { TextService } from 'services/TextService';

import { SidebarDp, SidebarLop, SidebarFocus, SidebarPosition, SidebarTitle } from './sidebars';

import { createTheme, Stack, useTheme } from '@fluentui/react';

import { mx, FixedViewType, ViewAlignment, mxglobals } from './drawing/common';

import { mxgraph } from 'ts-mxgraph-typings';

import { getDeleteInfo, handleCellsAdded, handleCellsMoved, handleCellsResized, handleLabelChanged, IDropInfo, replaceDropHandler } from './drawing/handlers';
import { IImageInfo, ImageDialog, BackgroundDialog, registerBackgroundEditDialog, registerImageEditDialog } from './drawing/images';
import { createStencil } from './drawing';
import { ConfirmDeleteDialog } from 'dialogs';
import { ColorPickerDialog, registerColorPickerDialog } from 'dialogs/common';
import * as strings from 'VistoWebPartStrings';
import { TopMenu } from './ribbon';
import { makeGuidString } from 'shared/guid';
import { AppTheme } from 'components/AppTheme';
import { trackClient } from 'shared/clientTelemetry';
import { UrlService } from 'shared/urlService';
import { ISelectedCellInfo } from 'shared/ISelectedCellInfo';
import { CellKind } from 'shared/CellKind';
import { PlanSettingsService } from 'services/PlanSettingsService';

const defaultResources = require('mxresources/resources/grapheditor.txt') as string;
mxglobals.mxResources.parse(defaultResources);

const itemSidebars = {
  [CellKind.LOP]: SidebarLop,
  [CellKind.DP]: SidebarDp
};

const planSidebars = {
  [CellKind.FOCUS]: SidebarFocus,
  [CellKind.POSITION]: SidebarPosition,
  [CellKind.TITLE]: SidebarTitle,
};

export function TopFrame(props: {
  readOnly: boolean;
  setReadOnly: (val: boolean) => void;
}) {

  const { planRef, editorUiRef, dispatchCommand, setDrawingData, setEditorUi, notify, wsContext } = React.useContext(AppContext);
  const { subEntityId } = React.useContext(EnvContext);
  const defaultSelectedInfo = subEntityId ? UrlService.parseSubEntityId(subEntityId) : null;
  const [isSidebarVisible, setIsSidebarVisible] = React.useState(!!defaultSelectedInfo);

  React.useEffect(() => trackClient.page('TopFrame'), []);

  const theme = useTheme();

  const [selectedCellInfo, setSelectedCellInfo] = React.useState<ISelectedCellInfo>(defaultSelectedInfo);

  const [colorPickerInfo, setColorPickerInfo] = React.useState<{ color: string, onSave }>(null);

  const idleCounterRef = React.useRef(0);

  const canCopyPasteCell = (cell) => {
    switch (mx.getCellKind(cell)) {
      case CellKind.FOCUS:
      case CellKind.TITLE:
      case CellKind.POSITION:
        return false;
      default:
        return true;
    }
  };

  const dropInfoRef = React.useRef<IDropInfo>(null);
  const [showDropDialog, setShowDropDialog] = React.useState(false);
  const onDropDialogClosed = (confirmed: boolean, item: IVistoListItem | IImageInfo) => {
    if (confirmed) {
      dropInfoRef.current.drop(item);
      dropInfoRef.current = null;
    }
    setShowDropDialog(false);
  };

  interface IDeleteInfo {
    graph: mxgraph.mxGraph;
    cells: mxgraph.mxCell[];
    items: IVistoListItem[];
  }

  const deleteInfoRef = React.useRef<IDeleteInfo>(null);
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);
  const deleteConfirmed = (items: IVistoListItem[]) => {
    deleteInfoRef.current.graph.removeCells(deleteInfoRef.current.cells, true);
    setShowDeleteDialog(false);
    deleteInfoRef.current = null;
    return Promise.resolve(planRef.current);
  };

  const registerActions = (ui) => {
    const saveAction = ui.actions.addAction('save', () => setDrawingData(mx.getGraphXml(editorUiRef.current.editor), true));
    saveAction.isEnabled = ui.editor.graph.isEnabled;
    ui.keyHandler.bindAction(83, true, 'save');

    const sidebarAction = ui.actions.addAction('sidebar', () => setIsSidebarVisible(true));
    sidebarAction.isEnabled = ui.editor.graph.isEnabled;

    const clearFormattingAction = ui.actions.addAction('clearFormatting', () => mx.clearStyles(editorUiRef.current.editor.graph));
    clearFormattingAction.isEnabled = ui.editor.graph.isEnabled;

    const pageSizeAction = ui.actions.addAction('pageSize', () => mx.clearStyles(editorUiRef.current.editor.graph));
    pageSizeAction.isEnabled = ui.editor.graph.isEnabled;

    // const fullScreenAction = ui.actions.addAction('fullScreen', () => setIsFullScreen(!isFullScreenRef.current));
    // fullScreenAction.isEnabled = ui.editor.graph.isEnabled;
    // fullScreenAction.setToggleAction(true);
    // fullScreenAction.setSelectedCallback(() => isFullScreenRef.current);
  };

  const registerDeleteAction = (ui) => {

    const graph: mxgraph.mxGraph = ui.editor.graph;

    const oldDeleteAction = ui.actions.get('delete');
    const newDeleteAction = () => {

      const { items, cells } = getDeleteInfo(planRef.current, graph);

      if (items.length) {
        deleteInfoRef.current = { graph, cells, items };
        setShowDeleteDialog(true);
      }
      else {
        graph.removeCells(cells, true);
      }
    };

    ui.actions.addAction('delete', newDeleteAction, null, null, TextService.format(strings.MenuItem_Delete)).isEnabled = graph.isEnabled;
    ui.keyHandler.bindAction(46, false, 'delete');

    return () => {
      ui.actions.put('delete', oldDeleteAction);
      ui.keyHandler.bindAction(46, false, 'delete');
    };
  };

  const registerTooltips = (graph: mxgraph.mxGraph) => {

    graph.getTooltipForCell = (cell) => {
      const guid = mx.getCellGuid(cell);
      const cellKind = mx.getCellKind(cell);
      let tooltip = '';
      switch (cellKind) {
        case CellKind.LOP:
        case CellKind.DP:
          tooltip = guid && PlanDataService.getItemByGuid(planRef.current.items, guid)?.description;
          break;
        case CellKind.FOCUS:
          const activeFocus = PlanDataService.getActiveFocus(planRef.current);
          tooltip = activeFocus?.description;
          break;
        case CellKind.POSITION:
          tooltip = planRef.current.esDescription;
          break;
      }
      const html = TextService.getTooltipHtml(tooltip ?? '');
      if (html) {
        return html;
      }
    };
  };

  const onMoveCells = (graph: mxgraph.mxGraph, event) => {
    handleCellsMoved(editorUiRef.current, planRef.current, dispatchCommand, graph, event, notify);
  };

  const onResizeCells = (graph: mxgraph.mxGraph, event) => {
    handleCellsResized(editorUiRef.current, graph, event);
  };

  const onCellsAdded = (graph: mxgraph.mxGraph, event: mxgraph.mxEventObject) => {
    handleCellsAdded(dropInfoRef.current, dispatchCommand, graph, event, notify);
  };

  const onCellsRemoved = (graph: mxgraph.mxGraph, event: mxgraph.mxEventObject) => {

    if (deleteInfoRef.current) {
      dispatchCommand(Commands.makeDeleteCommand(deleteInfoRef.current.items, notify), { wrap: true });
    }
  };

  const onCellLabelChanged = (graph: mxgraph.mxGraph, event: mxgraph.mxEventObject) => {
    handleLabelChanged(graph, planRef.current, dispatchCommand, event, notify);
  };

  const onSelectionChanged = (graph: mxgraph.mxGraph, event: mxgraph.mxEventObject) => {
    // const removed = event.getProperty('added');
    const addedCells = event.getProperty('removed');
    const primaryCell = addedCells?.length === 1 ? addedCells[0] : null;
    const primaryCellInfo = primaryCell ? ({ cellKind: mx.getCellKind(primaryCell), cellId: mx.getCellGuid(primaryCell) }) : null;
    if (primaryCellInfo) {
      trackClient.page(`Sidebar${CellKind[primaryCellInfo.cellKind]}`);
    }
    setTimeout(() => setSelectedCellInfo(primaryCellInfo), 25);
  };

  const onEndEdit = () => {

    const editor = editorUiRef.current?.editor;
    if (editor?.graph?.eventsEnabled) {
      const graphXml = mx.getGraphXml(editor);
      if (planRef.current && planRef.current.drawingXml !== graphXml) {
        planRef.current.drawingXml = graphXml;
        setDrawingData(graphXml, false);
      }
    }
  };

  const ref = React.useRef<HTMLDivElement>(null);

  const addLopDpCells = (cells) => {
    let result = [...cells];
    for (const cell of cells) {
      if (mx.getCellKind(cell) === CellKind.LOP) {
        const graph = editorUiRef.current.editor.graph;
        const overlappingDpCells = mx.findOverlappingDpCells(graph, cell);
        for (const overlappingDpCell of overlappingDpCells) {
          if (!result.find(c => mx.getCellGuid(overlappingDpCell) === mx.getCellGuid(c)))
            result.push(overlappingDpCell);
        }
      }
    }
    return result;
  };

  const previousView = React.useRef<{ scale, dx, dy }>();

  const createEditorUi = () => {

    const mxStylesheet = PlanStylesService.getMxStylesheet(planRef.current.styleJson);

    const mxThemes = {};
    const editorStyles = Mustache.render(mxStylesheet, theme);
    const node = new DOMParser().parseFromString(editorStyles, 'text/xml').documentElement;
    mxThemes['default'] = node;

    const readOnly = props.readOnly;
    const newEditor = new mxglobals.Editor(readOnly, mxThemes, null, null, true);
    const newGraph: mxgraph.mxGraph = newEditor.graph;

    // Disables some global features
    newGraph.panningHandler.useLeftButtonForPanning = readOnly;
    newGraph.setConnectable(false);
    newGraph.setCellsDisconnectable(false);
    newGraph.setCellsCloneable(false);
    newGraph.setCellsMovable(!readOnly);
    newGraph.setCellsResizable(!readOnly);
    newGraph.swimlaneNesting = false;
    newGraph.dropEnabled = false;
    newGraph.autoExtend = false;
    newGraph.setCellsEditable(!readOnly);
    newGraph['defaultPageBackgroundColor'] = 'transparent'; // theme.palette.white;
    newGraph['defaultPageBorderColor'] = theme.palette.neutralLighterAlt;
    newGraph.canExportCell = canCopyPasteCell;
    newGraph.canImportCell = canCopyPasteCell;

    newGraph.view['defaultGridColor'] = theme.palette.neutralQuaternary;
    newGraph.view['gridColor'] = theme.palette.neutralQuaternary;

    newGraph.model.createId = makeGuidString;
    newGraph.connectionHandler.factoryMethod = null;

    mxglobals.EditorUi.prototype.chromeButtonColor = theme.palette.black;
    const newEditorUi = new mxglobals.EditorUi(newEditor, ref.current, null);

    mx.setGraphXml(newEditor, planRef.current.drawingXml);

    newGraph.gridEnabled = !readOnly;
    newGraph.pageVisible = !readOnly;
    newGraph.pageBreaksVisible = !readOnly;
    newGraph.preferPageSize = !readOnly;

    if (previousView.current) {
      newGraph.view.scaleAndTranslate(previousView.current.scale, previousView.current.dx, previousView.current.dy);
    } else {
      mx.setFixedView(newGraph, null, FixedViewType.FitPage, ViewAlignment.Middle, ViewAlignment.Middle);
      if (defaultSelectedInfo) {
        const selection = mx.getAllCells(newGraph).find(x => mx.getCellKind(x) === defaultSelectedInfo.cellKind && mx.getCellGuid(x) === defaultSelectedInfo.cellId);
        if (selection) {
          newGraph.setSelectionCell(selection);
        }
      }
    }

    newGraph.view.validateBackground();
    newGraph.sizeDidChange();

    if (document.location.hash?.indexOf('vistoFirstRun') >= 0) {
      mx.focusFirstTime(newGraph);
      history.replaceState(null, null, ' ');
    }

    if (readOnly) {
      newGraph.isLabelMovable = (cell) => false;
      newGraph.isCellMovable = (cell) => false;
      newGraph.isCellResizable = (cell) => false;
      newGraph.isVertexLabelsMovable = () => false;
      newGraph.isEdgeLabelsMovable = () => false;
      newGraph.isCellsEditable = () => false;
      newGraph.isCellBendable = (cell) => false;
      newGraph['handlesDisabled'] = true;
      newEditorUi.keyHandler.enabled = false;
      newEditorUi.menubarHeight = 8;
    } else {
      replaceDropHandler(newEditorUi.sidebar, newGraph, newDropInfo => {
        dropInfoRef.current = newDropInfo;
        setShowDropDialog(true);
      });

      newEditorUi.menubarHeight = 40;
      newEditorUi.sidebar.dragPreviewBorder = `1px dashed ${theme.palette.black}`;

      const drawingTheme = createTheme(AppTheme.teamsTheme.blue);
      createStencil(newEditorUi.sidebar, drawingTheme);

      registerBackgroundEditDialog(newEditorUi, (imageInfo: IImageInfo, onSave) => {
        dropInfoRef.current = {
          data: imageInfo,
          dialog: BackgroundDialog,
          drop: onSave
        };
        setShowDropDialog(true);
      });

      registerImageEditDialog(newEditorUi, (imageInfo: IImageInfo, onSave) => {
        dropInfoRef.current = {
          data: imageInfo,
          dialog: ImageDialog,
          drop: onSave
        };
        setShowDropDialog(true);
      });

      registerColorPickerDialog(newEditorUi, (color: string, onSave) => {
        setColorPickerInfo({ color, onSave });
      });

      registerDeleteAction(newEditorUi);
    }

    registerTooltips(newGraph);

    newEditorUi.chromelessResize = (autosize) => {
      if (autosize)
        mx.setFixedView(newGraph, null, FixedViewType.FitPage, ViewAlignment.Middle, ViewAlignment.Middle);
    };

    registerActions(newEditorUi);

    newGraph.addListener(mxglobals.mxEvent.CELLS_ADDED, onCellsAdded);
    newGraph.addListener(mxglobals.mxEvent.CELLS_REMOVED, onCellsRemoved);
    newGraph.addListener(mxglobals.mxEvent.LABEL_CHANGED, onCellLabelChanged);
    newGraph.addListener(mxglobals.mxEvent.MOVE_CELLS, onMoveCells);
    newGraph.addListener(mxglobals.mxEvent.RESIZE_CELLS, onResizeCells);
    newGraph.model.addListener(mxglobals.mxEvent.END_EDIT, onEndEdit);
    newGraph.selectionModel.addListener(mxglobals.mxEvent.CHANGE, onSelectionChanged);

    mxglobals.mxVertexHandler.prototype.isCenteredEvent = (state, me) => {
      return state && mx.getCellKind(state.cell) === CellKind.DP;
    };

    const graphHandlerGetCells = newGraph.graphHandler.getCells;
    newGraph.graphHandler.getCells = (initialCell) => {
      const cells = graphHandlerGetCells.call(newGraph.graphHandler, initialCell);
      return addLopDpCells(cells);
    };

    const graphHandlerMouseMove = newGraph.graphHandler.mouseMove;
    newGraph.graphHandler.mouseMove = (sender, me) => {
      idleCounterRef.current = 0;
      return graphHandlerMouseMove.call(newGraph.graphHandler, sender, me);
    };

    const getMovableCells = newGraph.getMovableCells;
    newGraph.getMovableCells = (initialCells) => {
      const cells = getMovableCells.call(newGraph, initialCells);
      return addLopDpCells(cells);
    };

    newGraph.graphHandler['isFixedGluedCell'] = (cell) => {
      return mx.getCellKind(cell) === CellKind.FOCUS;
    };

    newEditorUi.refresh();
    setSelectedCellInfo(defaultSelectedInfo);
    setEditorUi(newEditorUi);
  };

  const destroyEditorUi = () => {

    const editorUi = editorUiRef.current;

    if (editorUi) {
      const graph = editorUi.editor?.graph;
      if (graph) {
        graph.removeListener(onCellsAdded);
        graph.removeListener(onCellsRemoved);
        graph.removeListener(onCellLabelChanged);
        graph.removeListener(onMoveCells);
        graph.removeListener(onResizeCells);
        graph.model.removeListener(onEndEdit);
        graph.selectionModel.removeListener(onSelectionChanged);
      }

      editorUi.destroy();
    }

    setEditorUi(null);

    ref.current.innerHTML = '';
  };

  const planSettings = PlanSettingsService.getPlanSettings(planRef.current);

  React.useEffect(() => {
    if (planRef.current) {

      createEditorUi();

      const timer = setInterval(() => {
        if (idleCounterRef.current > 10) {
          if (!props.readOnly) {
            props.setReadOnly(true);
            if (wsContext) {
              wsContext.setEditor(false);
            }
          }
        } else if (!props.readOnly) {
          idleCounterRef.current = idleCounterRef.current + 1;
        }
      }, 1000*60);

      return () => {
        clearInterval(timer);
        const currentView = editorUiRef.current?.editor?.graph?.view;
        if (currentView) {
          previousView.current = {
            scale: currentView.scale,
            dx: currentView.translate.x,
            dy: currentView.translate.y
          };
        }
        destroyEditorUi();
      };
    }
  }, [
    theme,
    planSettings.showIndicators,
    planSettings.showKpiIndicators,
    planSettings.showOwnerIndicators,
    planSettings.showProgressIcons,
    planSettings.language,
    planSettings.useFixedDate,
    planSettings.fixedDate?.toString(),
    props.readOnly
  ]);

  const onSidebarClose = () => {
    if (props.readOnly)
      editorUiRef.current?.editor?.graph?.setSelectionCells([]);
    setIsSidebarVisible(false);
  };

  React.useEffect(() => {

    if (wsContext) {
      wsContext.setSelection(selectedCellInfo?.cellId);
    }

  }, [selectedCellInfo]);

  // register event handlers

  const PlanSidebar = selectedCellInfo && planSidebars[selectedCellInfo.cellKind];
  const selectedItemGuid = selectedCellInfo && !PlanSidebar && selectedCellInfo.cellId;
  const selectedItem = selectedItemGuid && PlanDataService.getItemByGuid(planRef.current.items, selectedItemGuid);
  const SelectedItemSidebar = selectedItem && itemSidebars[selectedCellInfo.cellKind];

  const SelectedCellSidebar = (isSidebarVisible || props.readOnly) ? (SelectedItemSidebar || PlanSidebar) ?? null : null;

  const DropDialog = dropInfoRef?.current?.dialog;

  const header = ref.current?.querySelector('.geMenubarContainer');

  return (
    <Stack grow>

      <div ref={ref} className='geEditor' style={{ flex: 1, position: 'relative', overflow: 'hidden' }}></div>

      {SelectedCellSidebar &&
        <SelectedCellSidebar
          key={planRef.current.revision}
          plan={planRef.current}
          item={selectedItem}
          isOpen={true}
          status={status}
          onDismiss={onSidebarClose}
        />
      }

      {header && ReactDOM.createPortal(<TopMenu editorUi={editorUiRef.current} />, header)}

      {showDropDialog && <DropDialog onDismiss={onDropDialogClosed} plan={planRef.current} drop={dropInfoRef.current.data} />}
      {colorPickerInfo && <ColorPickerDialog onDismiss={() => setColorPickerInfo(null)} color={colorPickerInfo.color} setColor={(val) => { colorPickerInfo.onSave(val); setColorPickerInfo(null); }} />}
      {showDeleteDialog && <ConfirmDeleteDialog planItems={planRef.current.items} items={deleteInfoRef.current.items} onDismiss={() => setShowDeleteDialog(false)} onDelete={deleteConfirmed} />}
    </Stack>
  );
}
