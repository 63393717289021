import * as React from 'react';
import { Callout, DirectionalHint, useTheme } from '@fluentui/react';
import styles from 'components/Tooltip.module.scss';

export function MatrixTooltip(props: {
  onDismiss: () => void;
  tooltip: string;
  target: any;
}) {

  const theme = useTheme();

  return (
    <Callout
      backgroundColor={theme.palette.neutralLighterAlt}
      calloutMaxWidth={300}
      className={styles.tooltip}
      styles={{ calloutMain: { padding: 16 } }}
      directionalHint={DirectionalHint.topAutoEdge}
      gapSpace={0}
      target={props.target}
      onDismiss={props.onDismiss}>

      <div dangerouslySetInnerHTML={{ __html: props.tooltip }}></div>

    </Callout>
  );
}
