import * as strings from 'VistoWebPartStrings';
import * as React from 'react';
import { Stack, Checkbox, TextField } from '@fluentui/react';
import { TextService } from 'services/TextService';

export const ProjectWizardStartPage = (props: {
  enabled: boolean;
  setEnabled: (val: boolean) => void;
  pwaUrl: string;
  setPwaUrl: (val: string) => void;
}) => {

  return (
    <Stack grow>
      <Stack grow tokens={{ childrenGap: 'm' }}>
        <Checkbox
          label={TextService.format(strings.ProjectWizard_StartPage_EnableBoxTitle)}
          checked={props.enabled}
          onChange={(_, val) => props.setEnabled(val)}
        />
        <TextField
          disabled={!props.enabled}
          value={props.pwaUrl}
          placeholder={TextService.format(strings.ProjectWizard_StartPage_UrlPlaceholder)}
          onChange={(_, val) => props.setPwaUrl(val)} />
      </Stack>
    </Stack>
  );
};
