import { AuthService } from 'services/AuthService';
import { IExecutableAction } from 'services/IExecutableAction';
import { UrlService } from 'shared/urlService';
import { StorageCacheService } from 'services/StorageCacheService';
import { getListDefinition, IFieldDefinition, IVistoPlan, VistoAssocItem, VistoKind } from 'sp';

export const makeMigrateFixHangingValidations = (foundPlan: IVistoPlan): IExecutableAction => ({
  title: `Removing hanging validations`,
  execute: async (p) => {

    const sp = AuthService.getSpClient(foundPlan.siteUrl);

    const assocListUrl = UrlService.getListRelativeUrl(foundPlan, VistoKind.Assoc);
    const listDefinition = getListDefinition<VistoAssocItem>(VistoKind.Assoc);

    const items = await sp.web.getList(assocListUrl).items.top(2000)();
    for (const item of items) {
      const itemIdField: IFieldDefinition = listDefinition.fields['itemId'];
      const actionGuidField: IFieldDefinition = listDefinition.fields['actionGuid'];

      const itemId = item[itemIdField.name];
      const actionId = item[actionGuidField.name + 'Id'];

      if (!actionId) {
        await sp.web.getList(assocListUrl).items.getById(itemId).recycle();
      }
    }
    StorageCacheService.resetCache();
    return p;
  }
});
