import * as strings from 'VistoWebPartStrings';
import * as React from 'react';
import { Stack } from '@fluentui/react';
import { PlanWizardOperation, PlanWizardTrigger } from './PlanWizardTypes';
import { TextService } from 'services/TextService';
import { IWizardChoiceGroupOption, WizardChoiceGroup } from 'components/WizardChoiceGroup';
import { EnvContext } from 'services/EnvContext';
import { UrlService } from 'shared/urlService';

export function PlanWizardStartPage(props: {
  trigger: PlanWizardTrigger;
  operation: PlanWizardOperation;
  siteUrl: string;
  setOperation: (val: PlanWizardOperation) => void;
}) {

  const operations: IWizardChoiceGroupOption[] = [];

  const { hostKind } = React.useContext(EnvContext);

  if (props.trigger === 'TeamsConfigTab' || props.trigger === 'SpfxSiteHeader' || props.trigger === 'SpfxConfigurationButton') {
    operations.push({
      key: PlanWizardOperation.CreateNew.toString(),
      text: TextService.format(strings.PlanWizardStartPage_CreateNewLabel),
      disabled: !props.siteUrl,
      description: TextService.format(strings.PlanWizardStartPage_CreateNewDescription),
    }, {
      key: PlanWizardOperation.OpenExisting.toString(),
      text: TextService.format(strings.PlanWizardStartPage_OpenExistingLabel),
      disabled: !props.siteUrl || UrlService.isLocalUrl(props.siteUrl),
      description: TextService.format(strings.PlanWizardStartPage_OpenExistingDescription)
    });
  }

  if (props.trigger === 'UpgradePlaceholder') {
    operations.push({
      key: PlanWizardOperation.Upgrade.toString(),
      text: TextService.format(strings.PlanSettings_BlockUpgrade),
      description: TextService.format(strings.PlanWizardStartPage_Upgrade)
    });
  }

  return (
    <Stack grow>
      <WizardChoiceGroup
        options={operations}
        selectedKey={props.operation.toString()}
        onKeyChanged={val => props.setOperation(+val)}
      />
    </Stack>
  );
}
