import { ComboBox, IComboBoxOption } from '@fluentui/react';
import React from 'react';
import { getObjectValues } from 'shared/parse';
import { IFieldValueUser, IVistoPlan } from 'sp';
import { VisualFilterService } from './VisualFilterService';

export const AssigneeMultiPicker = (props: {
  disabled?: boolean;
  label: string;
  plan: IVistoPlan;
  value: IFieldValueUser[];
  setValue: (assignees: IFieldValueUser[]) => void;
}) => {

  const assigneeSet = VisualFilterService.getAssigneeSet(props.plan);
  const allAssignees = getObjectValues<IFieldValueUser>(assigneeSet);

  const options = allAssignees.map(a => ({ 
    key: VisualFilterService.getAssigneeKey(a),
    text: a.title,
  }));

  const onChange = (_, option?: IComboBoxOption) => {

    if (option) {
      if (option?.selected) {
        props.setValue([...props.value, allAssignees.find(a => VisualFilterService.getAssigneeKey(a) === option.key)]);
      } else {
        props.setValue(props.value.filter(a => VisualFilterService.getAssigneeKey(a) !== option.key));
      }
    }
  };

  return (
    <ComboBox
      multiSelect
      disabled={allAssignees.length === 0 || props.disabled}
      label={props.label}
      selectedKey={props.value.map(a => VisualFilterService.getAssigneeKey(a))}
      onChange={onChange}
      options={options}
    />
  );
}
