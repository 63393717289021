import * as React from 'react';
import { mxgraph } from 'ts-mxgraph-typings';
import { Stack, ResizeGroup, OverflowSet, IOverflowSetItemProps, IContextualMenuProps, Callout, IconButton } from '@fluentui/react';
import { FontNameComboBox as FontNameBox } from './FontComboBox';
import { FontSizeComboBox as FontSizeBox } from './FontSizeComboBox';
import { SelectionState } from './SelectionState';
import { TopMenuButton } from './TopMenuButton';
import { FontColorComboBox } from './FontColorComboBox';
import { FontAlignComboBox } from './FontAlignComboBox';
import { FillColorComboBox } from './FillColorComboBox';
import { StrokeStyleButton } from './StrokeStyleButton';
import { ArrangeComboBox } from './ArrangeComboBox';
import { IDashStyle } from './DashButton';
import { mx, mxglobals, ViewAlignment } from '../drawing/common';
import { IArrowStyle } from './ArrowButton';
import { IFont } from 'dialogs';
import { EnvContext } from 'services/EnvContext';
import strings from 'VistoWebPartStrings';
import { TextService } from 'services/TextService';

const buttonStyles = {
  splitButtonMenuButton: {
    backgroundColor: 'transparent',
    border: '0'
  },
  root: {
    minWidth: 42,
    padding: 6,
    margin: '0 2px 0 2px 0',
    backgroundColor: 'transparent',
    border: '0'
  },
};

export function TopMenu(props: { editorUi: any }) {

  const { hostKind } = React.useContext(EnvContext);

  const actions = props.editorUi?.actions;
  if (!actions)
    return null;

  const graph: mxgraph.mxGraph = props.editorUi.editor.graph;

  const makeSelection = (selected: mxgraph.mxCell[]) => {
    return {
      empty: !selected.length,
      cells: selected,
      state: SelectionState.get(graph, selected)
    };
  };

  const [selection, setSelection] = React.useState(makeSelection(graph.getSelectionCells()));
  const updateState = () => setSelection(makeSelection(graph.getSelectionCells()));

  React.useEffect(() => {
    graph.selectionModel.addListener(mxglobals.mxEvent.CHANGE, updateState);
    return () => {
      graph.selectionModel.removeListener(onselectionchange);
    };

  }, [graph]);

  const getStyle = (key: string, defaultValue: string): string => {
    const val = mxglobals.mxUtils.getValue(selection.state.style, key, defaultValue);
    return val ? '' + val : '';
  };

  const setStyle = (styles: Object) => {

    if (Object.keys(styles).map(k => styles[k]).every(v => v === ''))
      return;

    graph.getModel().beginUpdate();
    try {
      const newSelection = { ...selection, state: { ...selection?.state, style: { ...selection?.state?.style } } };
      let modified = false;
      for (const key in styles) {
        const val = styles[key];
        if (val !== '') {
          graph.setCellStyles(key, val, selection.cells);
          newSelection.state.style[key] = val;
          modified = true;
        }
      }
      if (modified) {
        setSelection(newSelection);
      }
    }
    finally {
      graph.getModel().endUpdate();
    }
  };

  const fontColor = getStyle(mxglobals.mxConstants.STYLE_FONTCOLOR, '#000');
  const setFontColor = (val: string) => setStyle({ [mxglobals.mxConstants.STYLE_FONTCOLOR]: val });

  const strokeColor = getStyle(mxglobals.mxConstants.STYLE_STROKECOLOR, '#000');
  const setStrokeColor = (val: string) => setStyle({ [mxglobals.mxConstants.STYLE_STROKECOLOR]: val });

  const strokeWidth = getStyle(mxglobals.mxConstants.STYLE_STROKEWIDTH, '12');
  const setStrokeWidth = (val: string) => setStyle({ [mxglobals.mxConstants.STYLE_STROKEWIDTH]: val });

  const fillColor = getStyle(mxglobals.mxConstants.STYLE_FILLCOLOR, '#00f');
  const setFillColor = (val: string) => setStyle({ [mxglobals.mxConstants.STYLE_FILLCOLOR]: val });

  const fontSize = getStyle(mxglobals.mxConstants.STYLE_FONTSIZE, '12');
  const setFontSize = (val: string) => setStyle({ [mxglobals.mxConstants.STYLE_FONTSIZE]: +val });

  const fontFamily: IFont = {
    name: getStyle(mxglobals.mxConstants.STYLE_FONTFAMILY, 'Verdana'),
    url: getStyle('fontSource', null),
  };
  const setFontFamily = (val: IFont) => {
    setStyle({
      [mxglobals.mxConstants.STYLE_FONTFAMILY]: val.name,
      ['fontSource']: val.url
    });
  };

  const align = getStyle(mxglobals.mxConstants.STYLE_ALIGN, 'center');
  const setAlign = (val: string) => setStyle({ [mxglobals.mxConstants.STYLE_ALIGN]: val });

  const verticalAlign = getStyle(mxglobals.mxConstants.STYLE_VERTICAL_ALIGN, 'middle');
  const setVerticalAlign = (val: string) => setStyle({ [mxglobals.mxConstants.STYLE_VERTICAL_ALIGN]: val });

  var fontStyle = mxglobals.mxUtils.getValue(selection.state.style, mxglobals.mxConstants.STYLE_FONTSTYLE, 0);
  const isFontStyleBitSet = (fontStyleBit: number) => (fontStyle & fontStyleBit) == fontStyleBit;

  const dashStyle: IDashStyle = {
    dash: getStyle(mxglobals.mxConstants.STYLE_DASHED, ''),
    dasharray: getStyle(mxglobals.mxConstants.STYLE_DASH_PATTERN, '')
  };
  const setDashStyle = (val: IDashStyle) => setStyle({
    [mxglobals.mxConstants.STYLE_DASHED]: val.dash,
    [mxglobals.mxConstants.STYLE_DASH_PATTERN]: val.dasharray
  });

  const arrowStyle: IArrowStyle = {
    endarrow: getStyle(mxglobals.mxConstants.STYLE_ENDARROW, 'classic'),
  };
  const setArrowStyle = (val: IArrowStyle) => setStyle({
    [mxglobals.mxConstants.STYLE_ENDARROW]: val.endarrow
  });

  const setArrange = (val: string) => {
    switch (val) {
      case 'horizontal':
        mx.distributeCellsHorizontally(graph, selection.cells);
        break;
      case 'vertical':
        mx.distributeCellsVertically(graph, selection.cells);
        break;
      case 'left':
      case 'right':
      case 'center':
      case 'top':
      case 'middle':
      case 'bottom':
        mx.alignCells(graph, val, selection.cells);
        break;
      case 'toFront':
        graph.orderCells(false, selection.cells);
        break;
      case 'toBack':
        graph.orderCells(true, selection.cells);
        break;
    }
  };

  const disabled = !graph.enabled;

  interface IData {
    primary: IOverflowSetItemProps[];
    overflow: IOverflowSetItemProps[];
  }

  const dataToRender: IData = {
    primary: [
      ...((hostKind !== 'WebMobile' && hostKind !== 'TeamsMobile') ? [{ key: 'formatPanel' }] : []),
      { key: 'undo' },
      { key: 'redo' },
      { key: 'cut' },
      { key: 'copy' },
      { key: 'paste' },
      { key: 'delete' },
      { key: 'fontFamily' },
      { key: 'fontSize' },
      { key: 'fontAlign' },
      { key: 'bold' },
      { key: 'italic' },
      { key: 'underline' },
      { key: 'fontColor' },
      { key: 'lineStyle' },
      { key: 'fillColor' },
      { key: 'clearFormatting' },
      { key: 'arrange' },
      { key: 'backgroundImage' },
      { key: 'pageSize' },
    ],
    overflow: [

    ]
  };

  const [isSizingPage, setIsSizingPage] = React.useState(false);
  const toggleSizingPage = () => {
    // graph.model.setEventsEnabled(false);
    if (isSizingPage) {
      mx.removeSizingRect(graph);
      setIsSizingPage(false);
    } else {
      mx.addSizingRect(graph, TextService.format(strings.SizingPage_Placeholder));
      setIsSizingPage(true);
    }
    // graph.model.setEventsEnabled(true);
  }

  const onRenderItem = (item: IOverflowSetItemProps) => {
    switch (item.key) {
      case 'formatPanel':
        return <TopMenuButton actions={actions} name='formatPanel' iconName='SidePanel' title={TextService.format(strings.TopMenuButton_FormatPanel)} />;
      case 'undo':
        return <TopMenuButton actions={actions} name='undo' iconName='Undo' title={TextService.format(strings.TopMenuButton_Undo)} />;
      case 'redo':
        return <TopMenuButton actions={actions} name='redo' iconName='Redo' title={TextService.format(strings.TopMenuButton_Redo)} />;
      case 'cut':
        return <TopMenuButton actions={actions} name='cut' iconName='Cut' title={TextService.format(strings.TopMenuButton_Cut)} />;
      case 'copy':
        return <TopMenuButton actions={actions} name='copy' iconName='Copy' title={TextService.format(strings.TopMenuButton_Copy)} />;
      case 'paste':
        return <TopMenuButton actions={actions} name='paste' iconName='Paste' title={TextService.format(strings.TopMenuButton_Paste)} />;
      case 'delete':
        return <TopMenuButton actions={actions} name='delete' iconName='Delete' title={TextService.format(strings.TopMenuButton_Delete)} />;
      case 'fontFamily':
        return <FontNameBox disabled={disabled} selectedFont={fontFamily} setSelectedFont={setFontFamily} />;
      case 'fontSize':
        return <FontSizeBox disabled={disabled} fontSize={fontSize} setFontSize={setFontSize} />;
      case 'fontAlign':
        return <FontAlignComboBox disabled={disabled} align={align} setAlign={setAlign} verticalAlign={verticalAlign} setVerticalAlign={setVerticalAlign} />;
      case 'bold':
        return (
          <TopMenuButton
            actions={actions}
            name='bold'
            iconName='Bold'
            checked={isFontStyleBitSet(mxglobals.mxConstants.FONT_BOLD)}
            onClick={updateState}
            title={TextService.format(strings.TopMenuButton_Bold)}
          />
        );
      case 'italic':
        return (
          <TopMenuButton
            actions={actions}
            name='italic'
            iconName='Italic'
            checked={isFontStyleBitSet(mxglobals.mxConstants.FONT_ITALIC)}
            onClick={updateState}
            title={TextService.format(strings.TopMenuButton_Italic)}
          />
        );
      case 'underline':
        return (
          <TopMenuButton
            actions={actions}
            name='underline'
            iconName='Underline'
            checked={isFontStyleBitSet(mxglobals.mxConstants.FONT_UNDERLINE)}
            onClick={updateState}
            title={TextService.format(strings.TopMenuButton_Underline)}
          />
        );
      case 'backgroundImage':
        return (
          <TopMenuButton
            actions={actions}
            name='backgroundImage'
            iconName='FileImage'
            onClick={updateState}
            // coachmarkName='coachmark_backgroundImage'
            // coachmarkTitle={TextService.format(strings.CoachmarkTitle_Background)}
            // coachmarkText={TextService.format(strings.CoachmarkText_Background)}
            title={TextService.format(strings.TopMenuButton_BackgroundImage)}
          />
        );
      case 'fontColor':
        return <FontColorComboBox disabled={disabled} color={fontColor} setColor={setFontColor} />;
      case 'lineStyle':
        return (
          <StrokeStyleButton disabled={disabled}
            color={strokeColor} setColor={setStrokeColor}
            width={strokeWidth} setWidth={setStrokeWidth}
            dashStyle={dashStyle} setDashStyle={setDashStyle}
            arrowStyle={arrowStyle} setArrowStyle={setArrowStyle}
          />
        );
      case 'fillColor':
        return <FillColorComboBox disabled={disabled} color={fillColor} setColor={setFillColor} />;
      case 'clearFormatting':
        return (
          <TopMenuButton
            actions={actions}
            name='clearFormatting'
            iconName='ClearFormatting'
            onClick={updateState}
            title={TextService.format(strings.TopMenuButton_ClearFormatting)}
          />
        );
      case 'pageSize':
        return (
          <TopMenuButton
            actions={actions}
            name='pageSize'
            iconName='FitPage'
            checked={isSizingPage}
            onClick={toggleSizingPage}
            title={TextService.format(strings.TopMenuButton_PageSize)}
          />
        );
      case 'arrange':
        return <ArrangeComboBox disabled={disabled} setArrange={setArrange} />;
      default:
        return null;
    }
  };

  const onReduceData = (currentData: IData) => {
    if (currentData.primary.length === 0)
      return undefined;
    const overflow = [...currentData.primary.slice(-1), ...currentData.overflow];
    const primary = currentData.primary.slice(0, -1);
    const cacheKey = JSON.stringify(primary);
    return { primary, overflow, cacheKey };
  };

  const onGrowData = (currentData: IData) => {
    if (currentData.overflow.length === 0)
      return undefined;
    const overflow = currentData.overflow.slice(1);
    const primary = [...currentData.primary, ...currentData.overflow.slice(0, 1)];
    const cacheKey = JSON.stringify(primary);
    return { primary, overflow, cacheKey };
  };

  const renderOverflowCallout = (menuProps: IContextualMenuProps) => {

    return (
      <Callout target={menuProps.target} onDismiss={menuProps.onDismiss} isBeakVisible={false}>
        <Stack tokens={{ childrenGap: 's2', padding: 's1' }}>
          {menuProps.items.map(x => onRenderItem(x))}
        </Stack>
      </Callout>
    );
  };

  const onRenderOverflowButton = (overflowItems: IOverflowSetItemProps[]) => (
    <IconButton
      styles={buttonStyles}
      menuIconProps={{ iconName: 'More' }}
      menuAs={renderOverflowCallout}
      menuProps={{ items: overflowItems }}
    />
  );

  const onRenderData = (data: any) => {
    return (
      <OverflowSet
        items={data.primary}
        overflowItems={data.overflow.length ? data.overflow : null}
        onRenderItem={onRenderItem}
        // eslint-disable-next-line react/jsx-no-bind
        onRenderOverflowButton={onRenderOverflowButton}
      />
    );
  };

  const defaultViewAlignment = ViewAlignment.Middle;

  return (
    <ResizeGroup
      data={dataToRender}
      onReduceData={onReduceData}
      onGrowData={onGrowData}
      onRenderData={onRenderData}
    />
  );
}
