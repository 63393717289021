import { loadStyles } from '@microsoft/load-themed-styles';

const styles = {
  filePickerFolderCardGrid: 'filePickerFolderCardGrid_96addcc2',
  filePickerFolderCardTile: 'filePickerFolderCardTile_303b4db6',
  filePickerFolderCardPadder: 'filePickerFolderCardPadder_38679844',
  filePickerFolderCardSizer: 'filePickerFolderCardSizer_d9d52b80',
  filePickerFolderCardImage: 'filePickerFolderCardImage_f8044846',
  filePickerFolderCardLabel: 'filePickerFolderCardLabel_9b85f11b',
  documentLibraryBrowserContainer: 'documentLibraryBrowserContainer_23d06a81',
};

export default styles;

loadStyles('.filePickerFolderCardGrid_96addcc2{overflow:hidden;font-size:0}.filePickerFolderCardGrid_96addcc2 .ms-List-page{overflow:hidden;font-size:0}.filePickerFolderCardGrid_96addcc2 .ms-List-surface{position:relative}.filePickerFolderCardTile_303b4db6{text-align:center;outline:0;position:relative}[dir=ltr] .filePickerFolderCardTile_303b4db6{float:left}[dir=rtl] .filePickerFolderCardTile_303b4db6{float:right}.filePickerFolderCardPadder_38679844{position:absolute;left:5px;top:5px;right:5px;bottom:5px}.ms-Fabric--isFocusVisible .filePickerFolderCardTile_303b4db6:focus:after{content:"";position:absolute;left:2px;right:2px;top:2px;bottom:2px;box-sizing:border-box;border:1px solid "[theme:white, default:#ffffff]"}.filePickerFolderCardSizer_d9d52b80{padding-bottom:100%}.filePickerFolderCardImage_f8044846{width:100%;left:0;top:0;color:"[theme:white, default:#ffffff]";position:absolute;bottom:0;font-size:12px;width:100%}.filePickerFolderCardLabel_9b85f11b{background-color:"[theme:neutralLight, default:#edebe9]";height:100%;width:100%;top:0;color:"[theme:black, default:#000000]";padding:10px;position:absolute;bottom:0;font-size:12px;left:0;width:100%;box-sizing:border-box;font-size:21px;font-weight:600}.filePickerFolderCardLabel_9b85f11b .ms-Button-textContainer{max-width:100%;word-break:break-word}.filePickerFolderCardLabel_9b85f11b:hover{color:"[theme:black, default:#000000]";background-color:"[theme:neutralLighter, default:#f3f2f1]"}.filePickerFolderCardLabel_9b85f11b:active{color:"[theme:black, default:#000000]";background-color:"[theme:neutralLighterAlt, default:#faf9f8]"}.documentLibraryBrowserContainer_23d06a81{padding:0}');
