import { ActionButton, Stack } from '@fluentui/react';
import React from 'react'
import { Container, DropResult } from 'react-smooth-dnd';
import { AppContext } from 'services/AppContext';
import { Commands } from 'services/Commands';
import { ProgressService } from 'services/ProgressService';
import { TextService } from 'services/TextService';
import { useExpandedPropertyBag } from 'services/useExpandedPropertyBag';
import { IVistoPlan, VistoActionItem } from 'sp';
import strings from 'VistoWebPartStrings';
import { SidebarAction } from './SidebarAction';

const SidebarActionListExpandButton = (props: { content: JSX.Element | string, isExpanded: boolean, toggle: () => void }) => {
  return <ActionButton
    toggle
    styles={{ root: { padding: 0, textAlign: 'left' } }}
    allowDisabledFocus
    iconProps={{ iconName: props.isExpanded ? 'ChevronDown' : 'ChevronRight', style: { paddingRight: 8 } }}
    onClick={props.toggle} >{props.content}</ActionButton>
}

export const SidebarActionListGroup = (props: {
  plan: IVistoPlan;
  actions: VistoActionItem[];
  expandKey: string;
  header?: JSX.Element | string;
  defaultExpanded?: boolean;
}) => {

  const [actions, setActions] = React.useState(props.actions);
  React.useEffect(() => setActions(props.actions), [props.actions]);

  const { dispatchCommand, notify } = React.useContext(AppContext);

  const activeActions = actions.filter(a => !ProgressService.isCompleted(a));
  const completedActions = actions.filter(a => ProgressService.isCompleted(a));

  const onDrop = (actions: VistoActionItem[], dropResult: DropResult) => {
    const reordered = Commands.getReorderedItems(actions, dropResult.removedIndex, dropResult.addedIndex);
    setActions(reordered);
    const command = Commands.makeChangeSortOrderCommand(reordered, notify);
    dispatchCommand(command, { wrap: true });
  };

  const [isExpanded, toggleShowInactive] = useExpandedPropertyBag(props.expandKey, props.defaultExpanded);
  const [showCompleted, setShowCompleted] = React.useState(false);

  return actions.length > 0 &&
    <Stack>
      {props.header && <SidebarActionListExpandButton
        content={props.header}
        isExpanded={isExpanded}
        toggle={toggleShowInactive}
      />
      }
      {isExpanded &&
        <Stack>
          <Container dragHandleSelector='.draghandle' getGhostParent={() => document.body} onDrop={e => onDrop(actions, e)} getChildPayload={i => activeActions[i]} >
            {activeActions.map(action => <SidebarAction key={action.guid} plan={props.plan} action={action} />)}
          </Container>
          {completedActions.length > 0 &&
            <Stack style={{ paddingLeft: 16 }}>
              <SidebarActionListExpandButton
                content={TextService.format(strings.SidebarGroup_Completed, { count: completedActions.length })}
                isExpanded={showCompleted}
                toggle={() => setShowCompleted(val => !val)}
              />
              {showCompleted && completedActions.map(action => <SidebarAction key={action.guid} plan={props.plan} action={action} />)}
            </Stack>
          }
        </Stack>}
    </Stack>
};
