import { loadStyles } from '@microsoft/load-themed-styles';

const styles = {
  breadcrumbPath: 'breadcrumbPath_bb3fd353',
  filterBox: 'filterBox_5d77f9cb',
  libraryItem: 'libraryItem_29ebabba',
  folderIcon: 'folderIcon_aaa110f1',
  status: 'status_5af819a7',
};

export default styles;

loadStyles('.breadcrumbPath_bb3fd353 button{font-size:18px}.breadcrumbPath_bb3fd353 button[aria-current=page]>div{font-weight:600;color:"[theme:neutralPrimary, default:#323130]"}.filterBox_5d77f9cb{margin-bottom:10px;margin-top:20px;padding-left:8px;padding-right:8px;max-width:450px}.libraryItem_29ebabba{padding:5px 0 5px 8px;cursor:pointer;height:30px;line-height:30px;border-width:1px;display:flex;align-items:center}.libraryItem_29ebabba:hover{background-color:"[theme:neutralLighter, default:#f3f2f1]"}.folderIcon_aaa110f1{margin-right:10px}.status_5af819a7{font-size:14px;color:"[theme:neutralSecondary, default:#605e5c]";padding-top:15px;padding-left:30px;padding-bottom:20px;padding-right:40px}');
