export const AssistantInstructions = `
You are making a plan, typically a strategic, business, program management, change management or operational plan depending on user input, splitting it into capabilities (functions or areas) relevant to the plan, 
and ambitions based on the user input. Create from 1 to 10 capability lines, each ideally containing from 1 to 5 ambitions, depending on user input.  
Do not modify fields of previous plan unless explicitly requested. Do allow empty descriptions. Do not remove HTML markup in the descriptions unless expliciltly requested.
`.trim();

export const AssitantPlanSchema =
{
  'name':'plan',
  'strict': true,
  'schema': {
    'type': 'object',
    'properties': {
      'planName': {
        'type': 'string',
        'description': 'The name of the plan. Try to keep below 50 chars'
      },
      'planDescription': {
        'type': 'string',
        'description': 'The detailed description of the plan. Up to 250 chars'
      },
      'positionName': {
        'type': 'string',
        'description': 'The final state or overall goal of the plan, summarizing the ultimate outcome when the plan is successful. Present tense. Up to 30 chars.'
      },
      'positionDescription': {
        'type': 'string',
        'description': 'A detailed description of the final state (outcome) when the plan succeeds. Up to 250 chars. Basic HTML or plain text.'
      },
      'focusName': {
        'type': 'string',
        'description': 'to represents short-term goals or milestones. Should according to the context, reflecting milestones, sprints, or key phases of the plan. Default to just Focus if not clear. Up to 30 chars'
      },
      'focusDescription': {
        'type': 'string',
        'description': 'The detailed description of the focus. Up to 250 chars. Basic HTML or plain text.'
      },
      'capabilities': {
        'type': 'array',
        'items': {
          'type': 'object',
          'properties': {
            'name': {
              'type': 'string',
              'description': 'The name of the capability (up to 20 characters)'
            },
            'description': {
              'type': 'string',
              'description': 'The detailed description of the capability line. Basic HTML or plain text.'
            },
            'ambitions': {
              'type': 'array',
              'items': {
                'type': 'object',
                'properties': {
                  'name': {
                    'type': 'string',
                    'description': 'The name of the ambition (up to 30 characters)'
                  },
                  'description': {
                    'type': 'string',
                    'description': 'The detailed description of the ambition. Basic HTML or plain text. Up to some 250 chars when you create it'
                  }
                },
                'required': [
                  'name',
                  'description'
                ],
                'additionalProperties': false
              }
            }
          },
          'required': [
            'name',
            'description',
            'ambitions'
          ],
          'additionalProperties': false
        }
      }
    },
    'required': [
      'planName',
      'planDescription',
      'positionName',
      'positionDescription',
      'focusName',
      'focusDescription',
      'capabilities'
    ],
    'additionalProperties': false
  }
};

