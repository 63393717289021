import * as React from 'react';
import { IComboBoxOption, TextField, ComboBox } from '@fluentui/react';

const options: IComboBoxOption[] = [6, 8, 9, 10, 11, 12, 14, 16, 18, 20, 24, 26, 28, 36, 48, 72]
  .map(v => v.toString())
  .map(v => ({ key: v, text: v }));

export function FontSizeComboBox(props: {
  disabled: boolean;
  fontSize: string;
  setFontSize: (val: string) => void;
}) {

  return (
    <ComboBox
      text={props.fontSize}
      selectedKey={props.fontSize}
      onChange={(_, val) => props.setFontSize(val.key as string)}
      disabled={props.disabled}
      options={options}
      calloutProps={{
        styles: {
          calloutMain: {
            minWidth: '4em'
          }
        }
      }}
      allowFreeform
      styles={{
        root: {
          margin: '0 2px 0 2px 0',
          width: '6em'
        }
      }}
    />
  );
}
