import React from 'react';
import { PlanDataService } from 'services/PlanDataService';
import { TextService } from 'services/TextService';
import { ChangesService } from 'services/ChangesService';
import { IFieldValueUser, IVistoPlan, VistoDpItem } from 'sp';
import strings from 'VistoWebPartStrings';
import { SidebarActionListGroup } from './SidebarActionListGroup';
import { Persona, PersonaSize, Stack } from '@fluentui/react';
import { ColorService } from 'services/colorService';
import { useAssignees } from 'services/useAssignees';

const GROUP_KEY_NO_ASSIGNEE = 'e07e75d834ad4424bfa8cd4f58459689';

export const SidebarActionAssigneeList = (props: {
  plan: IVistoPlan;
  dp: VistoDpItem;
}) => {

  const actions = PlanDataService
    .getDpActions(props.plan, props.dp.guid);

  const assignedTo: IFieldValueUser[] = [];
  for (const a of actions) {
    if (Array.isArray(a.assignedTo)) {
      for (const u of a.assignedTo) {
        if (assignedTo.find(x => ChangesService.isSameUser(x, u)))
          continue;
        assignedTo.push(u);
      }
    }
  }

  const assignees = useAssignees(assignedTo, [props.dp]);
  
  const unassignedActions = actions.filter(a => !a?.assignedTo?.length);

  return (
    <>
      {assignees.map(u => {
        const assigneeActions = actions.filter(a => a.assignedTo.find(x => ChangesService.isSameUser(x, u)));
        return <SidebarActionListGroup
          key={u.userName}
          plan={props.plan}
          expandKey={u.userName}
          header={
              <Persona
                initialsColor={ColorService.getUserColor(u.userName || u.guid)}
                imageUrl={u.imageUrl}
                imageShouldFadeIn={false}
                text={TextService.format(strings.SidebarGroup_AssigneeTitle, { title: u.title || u.userName, count: assigneeActions.length })}
                secondaryText={u.userName}
                hidePersonaDetails={false}
                size={PersonaSize.size24}
              />
          }
          actions={assigneeActions}
        />
      })}

      <SidebarActionListGroup
        key={GROUP_KEY_NO_ASSIGNEE}
        plan={props.plan}
        expandKey={GROUP_KEY_NO_ASSIGNEE}
        header={TextService.format(strings.SidebarGroup_AssigneeNoneTitle, { count: unassignedActions.length })}
        actions={unassignedActions}
      />
    </>
  );

};

