import React from 'react';
import { parseJSON } from 'shared/parse';

export function useExpand<T> (expandedCardsKey: string) {

  const [expandedCards, _setExpandedCards] = React.useState<{ [key: string]: T }>(parseJSON(localStorage.getItem(expandedCardsKey), {}));
  
  const setExpandedCards = (val: { [key: string]: T }) => {
    _setExpandedCards(val);
    localStorage.setItem(expandedCardsKey, JSON.stringify(val));
  };

  return [expandedCards, setExpandedCards] as const;
}

