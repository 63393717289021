import { trackClient } from 'shared/clientTelemetry';

export class RateLimiter {

  public static limiters: { [domain: string]: RateLimiter } = {};

  private maxRequestsPerSec: number;
  private active: number[];

  constructor(maxRequestsPerSec: number) {
    this.maxRequestsPerSec = maxRequestsPerSec;
    this.active = [];
  }

  processRquests() {
    let released = 0;
    const now = Date.now();
    const detained = [];
    for (const ts of this.active) {
      if ((ts < now - 1000) && (released < this.maxRequestsPerSec)) {
        ++released;
      } else {
        detained.push(ts);
      }
    }
    return detained;
  }

  async consume() {
    const now = Date.now();
    this.active.push(now);
    while (this.active.length > this.maxRequestsPerSec) {
      trackClient.warn('Limiting request rate');
      await new Promise((resolve) => setTimeout(resolve, 1000));
      this.active = this.processRquests();
    }
    this.active = this.processRquests();
  }
}
