import { ComboBox, IComboBoxOption, SelectableOptionMenuItemType } from '@fluentui/react';
import React from 'react';
import { PlanDataService } from 'services/PlanDataService';
import { TextService } from 'services/TextService';
import { IVistoPlan, VistoKeyResultItem, VistoKind } from 'sp';

export const KrMultiPicker = (props: {
  disabled?: boolean;
  label: string;
  plan: IVistoPlan;
  krGuids: string[];
  setKrGuids: (krGuids: string[]) => void;
}) => {

  const allKeyResults = PlanDataService.getItems<VistoKeyResultItem>(props.plan.items, VistoKind.KeyResult);

  const krOptions = allKeyResults.map(kpi => ({
      key: kpi.guid,
      text: kpi.name,
      styles: { root: { paddingLeft: kpi.parentKrGuid ? 20 : 8 } },
      sortKey: (kpi.parentKrGuid ? PlanDataService.getItemByGuid(props.plan.items, kpi.parentKrGuid)?.name + ' > ' : '') + kpi.name
    }))
    .sort((a, b) => TextService.compareNames(a.sortKey, b.sortKey))

  const onChange = (_, option?: IComboBoxOption) => {

    if (option) {
      if (option?.selected) {
        const selectedGuid = option.key as string;
        const cleanedSelection = props.krGuids.filter(krGuid => PlanDataService.getItemByGuid<VistoKeyResultItem>(props.plan.items, krGuid)?.parentKrGuid !== selectedGuid);
        const additionalGuids = allKeyResults.filter(kr => kr.parentKrGuid === selectedGuid).map(kr => kr.guid);
        props.setKrGuids([...cleanedSelection, ...additionalGuids, selectedGuid])
      } else {
        const selectedGuid = option.key as string;
        props.setKrGuids(props.krGuids.filter(krGuid => krGuid !== selectedGuid && PlanDataService.getItemByGuid<VistoKeyResultItem>(props.plan.items, krGuid)?.parentKrGuid !== selectedGuid));
      }
    }
  };

  return (
    <ComboBox
      multiSelect
      disabled={krOptions.length === 0 || props.disabled}
      label={props.label}
      selectedKey={props.krGuids}
      onChange={onChange}
      options={krOptions}
    />
  );
}
