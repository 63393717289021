import * as React from 'react';
import * as strings from 'VistoWebPartStrings';
import { EnvContext } from 'services/EnvContext';
import { TextService } from 'services/TextService';
import { IVistoListItem, IVistoPlan, VistoKind } from 'sp';
import { trackClient } from 'shared/clientTelemetry';
import { CellKind } from 'shared/CellKind';
import { CopyLinkDialogBase } from './CopyLinkDialogBase';
import { UrlService } from 'shared/urlService';

export function CopyLinkDialog(props: {
  plan: IVistoPlan;
  item: IVistoListItem;
  onDismiss: () => void;
}) {

  React.useEffect(() => trackClient.page('CopyLinkDialog'), []);

  const { siteUrl, entityId, channelId } = React.useContext(EnvContext);

  const cellKind =
    props.item.kind === VistoKind.LOP ? CellKind.LOP
      : props.item.kind === VistoKind.DP ? CellKind.DP
        : undefined;

  const subEntityId = UrlService.makeSubEntityId(cellKind && { cellKind, cellId: props.item.guid });
  const deepLink = UrlService.makePlanDeepLink(entityId, channelId, subEntityId);

  const listUrl = UrlService.getListRelativeUrl(props.plan, props.item.kind);
  const link = `${UrlService.getOrigin(siteUrl)}${listUrl}/DispForm.aspx?ID=${props.item.itemId}&deepLink=${encodeURIComponent(deepLink)}`;

  return (
    <CopyLinkDialogBase
      content={TextService.format(strings.CopyLinkDialog_Content)}
      linkLabel={TextService.format(strings.CopyLinkDialog_ItemLink)}
      link={link}
      onDismiss={props.onDismiss}
    />
  );
}
