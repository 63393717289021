import { Stack, Icon, useTheme } from '@fluentui/react';
import React from 'react';
import { IVistoPlan, VistoSoItem } from 'sp';
import { OkrAssigneeBlock } from './OkrAssigneeBlock';
import { OkrCardName } from './OkrCardName';

export const OkrCardSO = (props: {
  plan: IVistoPlan;
  item: VistoSoItem;
  showExpand: boolean;
  expanded: boolean;
}) => {

  const theme = useTheme();

  return (
    <Stack horizontalAlign='center' tokens={{ padding: 12, childrenGap: 8 }} style={{ height: '100%' }}>
      <Stack grow horizontal style={{ overflow: 'hidden' }} verticalAlign='start' tokens={{ childrenGap: 8, padding: '4px 0' }}>
        <Icon iconName='Bullseye' style={{ fontSize: '2rem', color: theme.palette.themePrimary }} />
        <OkrCardName variant='large'name={props.item.name} />
      </Stack>
      <OkrAssigneeBlock assignedTo={props.item.assignedTo} />
      <Stack horizontal horizontalAlign='center' style={{ height: '2rem' }}>
        {props.showExpand && <Icon className='expand' style={{ fontSize: '2rem', color: theme.palette.neutralTertiary }} iconName={props.expanded ? 'CollapseContentSingle' : 'ExploreContentSingle'} />}
      </Stack>
    </Stack>
  );
}
