import React from 'react';
import { FontSizes, mergeStyles, useTheme } from '@fluentui/react';
import { TextService } from 'services/TextService';

export const BudgetProgressBar = (props: {
  value: number;
  valueText: string;
  color: string;
  units?: string;
  start?: string;
  end?: string;
  onClick?: () => void;
}) => {

  const { palette } = useTheme();

  const outerDivStyle = mergeStyles({
    cursor: props.onClick ? 'pointer' : 'default',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  });

  const wrapDivStyle = mergeStyles({
    width: 120,
    backgroundColor: props.color,
    borderRadius: 4,
    boxShadow: `inset 0 0 1px 1px ${palette.neutralTertiary}`,
    textOverflow: 'ellipsis'
  });

  const valueDivStyle = mergeStyles({
    padding: '2px 0',
    width: `${TextService.isValidNumber(props.value) ? props.value : 0}%`,
    backgroundColor: palette.neutralLight,
    color: palette.neutralPrimary,
    borderRadius: 4,
    boxShadow: `inset 0 0 1px 1px ${palette.neutralTertiary}`,
  });

  const spanStyle = mergeStyles({
    whiteSpace: 'nowrap',
    margin: '0 8px',
    textAlign: 'right',
    fontSize: FontSizes.smallPlus,
  });

  const styleStart = mergeStyles({
    minWidth: 32,
    textAlign: 'right',
    marginRight: 4,
    color: palette.neutralSecondary,
    fontSize: FontSizes.smallPlus,
  });

  const styleTarget = mergeStyles({
    minWidth: 32,
    textAlign: 'left',
    marginLeft: 4,
    color: palette.neutralSecondary,
    fontSize: FontSizes.smallPlus,
  });

  return (
    <div onClick={props.onClick} className={outerDivStyle}>
      <div className={styleStart}>{props.start}</div>
      <div className={wrapDivStyle}>
        <div className={valueDivStyle}>
          <div className={spanStyle}>{props.valueText}</div>
        </div>
      </div>
      <div className={styleTarget}>{props.end}</div>
    </div>
  );
};
