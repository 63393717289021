import { PlannerLinkService } from 'integrations/planner';
import { AuthService } from 'services/AuthService';
import { IExecutableAction } from 'services/IExecutableAction';
import { PlanDataService } from 'services/PlanDataService';
import { UrlService } from 'shared/urlService';
import { StorageCacheService } from 'services/StorageCacheService';
import { TextService } from 'services/TextService';
import { getListDefinition, IVistoListItemWithProgress, IVistoPlan, VistoKind } from 'sp';

export const makeMigratePlannerDuplicates = (foundPlan: IVistoPlan): IExecutableAction => ({
  title: `Fix planner duplicates issue`,
  execute: async (p) => {

    const sp = AuthService.getSpClient(foundPlan.siteUrl);

    const kinds = PlanDataService.getAllVistoKinds().sort(PlanDataService.compareItemKind);

    for (const kind of kinds) {
      const listUrl = UrlService.getListRelativeUrl(foundPlan, kind);

      const listDefinition = getListDefinition<IVistoListItemWithProgress>(kind);
      if (listDefinition.hasProgress) {

        const fieldSourceItemUrl = listDefinition.fields['sourceItemUrl'].name;
        const fieldModifiedDate = listDefinition.fields['modifiedDate'].name;
        const fieldId = listDefinition.fields['itemId'].name;

        const unsortedItems = await sp.web.getList(listUrl).items.top(2000)();
        const items = unsortedItems.sort((a, b) => {
          const cmp = TextService.compareStrings(a[fieldSourceItemUrl], b[fieldSourceItemUrl]);
          if (cmp != 0) {
            return cmp;
          }
          const aModified = (typeof a[fieldModifiedDate] === 'string') && new Date(a[fieldModifiedDate]);
          const bModified = (typeof b[fieldModifiedDate] === 'string') && new Date(b[fieldModifiedDate]);
          return TextService.compareDateTime(aModified, bModified);
        });
        let prevSourceItemUrl = '';
        for (const item of items) {
          const sourceItemUrl = item[fieldSourceItemUrl];
          const itemId = item[fieldId];
          if (sourceItemUrl && PlannerLinkService.isPlannerLink(sourceItemUrl)) {
            if (sourceItemUrl === prevSourceItemUrl) {
              await sp.web.getList(listUrl).items.getById(itemId).recycle();
            }
          }
          prevSourceItemUrl = sourceItemUrl;
        }
      }
    }
    StorageCacheService.resetCache();
    return p;
  }
});
