export class SvgShapes {

  // Generates SVG arc string
  private static getArc(angle: number, radius: number, center: number) {
    const x = center + radius * Math.cos(angle / 180 * Math.PI);
    const y = center + radius * Math.sin(angle / 180 * Math.PI);

    return 'A' + radius + ',' + radius + ' 1 0 1 ' + x + ',' + y;
  }

  public static makeSvgSectorShape(options: {
    arc: boolean;
    size: number;
    stroke: number;
    angle: number;
    sectorColor: string;
    circleColor: string;
    fillCircle: boolean;
  }) {

    const stroke = options.arc ? options.stroke : 0;

    // Circle dimenstions
    const center = options.size / 2;
    const radius = stroke ? center - stroke / 2 : center;

    // Colors
    const sectorFill = options.arc ? 'none' : options.sectorColor;
    const sectorStroke = options.arc ? options.sectorColor : 'none';

    // Arc angles
    const firstAngle = options.angle > 180 ? 90 : options.angle - 90;
    const secondAngle = -270 + options.angle - 180;

    // Arcs
    const firstArc = this.getArc(firstAngle, radius, center);
    const secondArc = options.angle > 180 ? this.getArc(secondAngle, radius, center) : '';

    // start -> starting line
    // end -> will path be closed or not
    const { start, end } = (options.arc)
      ? { start: 'M' + center + ',' + stroke / 2, end: '' }
      : { start: 'M' + center + ',' + center + ' L' + center + ',' + stroke / 2, end: 'z' };

    const d = start + ' ' + firstArc + ' ' + secondArc + ' ' + end;

    const circleFill = options.fillCircle || !options.arc ? options.circleColor : 'none';

    return `<svg xmlns='http://www.w3.org/2000/svg' width='${options.size}' height='${options.size}' viewBox='0 0 ${options.size} ${options.size}' version='1.1'>
      <circle stroke-width='${stroke}' fill='${circleFill}' stroke='${options.circleColor}' cx='${center}' cy='${center}' r='${radius}' />
      <circle stroke-width='${0.5}' fill='none' stroke='${sectorStroke}' cx='${center}' cy='${center}' r='${radius + stroke / 2 - 0.25}' />
      <path stroke-width='${stroke}' fill='${sectorFill}' stroke='${sectorStroke}' d='${d}' />
    </svg>`;
  }

  public static makeArrowShape(options: {
    size: number;
    stroke: number;
    fillColor: string;
    strokeColor: string;
    direction: number;
  }) {

    // Circle dimenstions
    const center = options.size / 2;
    const radius = options.stroke ? center - options.stroke / 2 : center;

    let transform = 'translate(0 0)';
    if (options.direction < 0)
      transform = `translate(${options.size} 0) rotate(90)`;
    else if (options.direction > 0)
      transform = `translate(0 ${options.size}) rotate(-90)`;

    const points = [
      2, 5,
      2, 4,
      5, 4,
      5, 2.5,
      8, 5,
      5, 7.5,
      5, 6,
      2, 6,
    ];

    const pointsStr = points.map(x => x * options.size / 10).join(' ');

    return `<svg xmlns='http://www.w3.org/2000/svg' width='${options.size}' height='${options.size}' viewBox='0 0 ${options.size} ${options.size}' version='1.1'>
      <circle stroke-width='${0.5}' fill='${options.fillColor}' stroke='${options.strokeColor}' cx='${center}' cy='${center}' r='${radius + options.stroke / 2 - 0.25}' />
      <polygon transform='${transform}' points='${pointsStr}' fill='white' stroke='${options.strokeColor}' stroke-width='0.5' stroke-linejoin='round' />
    </svg>`;
  }

  public static makeCircleCheckboxShape(options: {
    size: number;
    stroke: number;
    fillColor: string;
    strokeColor: string;
    checked: boolean;
  }) {

    // Circle dimenstions
    const center = options.size / 2;
    const radius = options.stroke ? center - options.stroke / 2 : center;


    const points = [
      1.8, 4.5,
      3.8, 4.5,
      4.8, 6,
      6, 2.8,
      8, 3,
      6, 8,
      4, 8,
    ];

    const pointsStr = points.map(x => x * options.size / 10).join(' ');

    return options.checked
      ? `<svg xmlns='http://www.w3.org/2000/svg' width='${options.size}' height='${options.size}' viewBox='0 0 ${options.size} ${options.size}' version='1.1'>
      <circle stroke-width='${0.5}' fill='${options.fillColor}' stroke='${options.strokeColor}' cx='${center}' cy='${center}' r='${radius + options.stroke / 2 - 0.25}' />
      <polygon points='${pointsStr}' fill='white' stroke='${options.strokeColor}' stroke-width='0.5' stroke-linejoin='round' />
    </svg>`
      : `<svg xmlns='http://www.w3.org/2000/svg' width='${options.size}' height='${options.size}' viewBox='0 0 ${options.size} ${options.size}' version='1.1'>
      <circle stroke-width='${0.5}' fill='${options.fillColor}' stroke='${options.strokeColor}' cx='${center}' cy='${center}' r='${radius + options.stroke / 2 - 0.25}' />
    </svg>`;
  }

  public static makeCircleTextShape(options: {
    size: number;
    stroke: number;
    fillColor: string;
    strokeColor: string;
    text: string;
  }) {

    // Circle dimenstions
    const center = options.size / 2;
    const radius = options.stroke ? center - options.stroke / 2 : center;
    const fontSize  = center * 1.3 / options.text.length;

    return `<svg xmlns='http://www.w3.org/2000/svg' width='${options.size}' height='${options.size}' viewBox='0 0 ${options.size} ${options.size}' version='1.1'>
      <circle stroke-width='${0.5}' fill='${options.fillColor}' stroke='${options.strokeColor}' cx='${center}' cy='${center}' r='${radius + options.stroke / 2 - 0.25}' />
      <text text-anchor='middle' alignment-baseline='middle' style='font-family: Verdana; font-size: ${fontSize}px; fill:white' x='${center}' y='${center}'>${options.text}</text>
    </svg>`;
  }

  public static makeCircleImageSvg(options: {
    size: number;
    stroke: number;
    strokeColor: string;
    url: string;
  }) {

    // Circle dimenstions
    const center = options.size / 2;
    const radius = options.stroke ? center - options.stroke / 2 : center;

    return `<svg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='${options.size}' height='${options.size}' viewBox='0 0 ${options.size} ${options.size}' version='1.1'>
      <clipPath id='clip'><circle cx='${center}' cy='${center}' r='${radius + options.stroke/2 - 0.5}' /></clipPath>
      <circle stroke-width='${0.5}' stroke='${options.strokeColor}' cx='${center}' cy='${center}' r='${radius + options.stroke / 2 - 0.25}' />
      <image width='${options.size}' height='${options.size}' xlink:href='${options.url}' clip-path='url(#clip)' />
    </svg>`;
  }
}
