import { api } from 'shared/api';
import { UrlService } from 'shared/urlService';
import { parseJSON } from 'shared/parse';
import { IAttachment, IVistoListItem, IVistoPlan, VistoKind } from 'sp';
import { IExecutableAction } from './IExecutableAction';
import { IndexedDbService } from './IndexedDbService';
import { IItemChanges } from './Interfaces';
import { IOperationOptions } from './IOperationOptions';
import { IBasicNotify, INotify } from './Notify';
import { PlanSettingsService } from './PlanSettingsService';
import { ProgressService } from './ProgressService';
import { IStorageService } from './StorageService';
import { TextService } from './TextService';
import strings from 'VistoWebPartStrings';
import { ITemplate } from './ITemplate';
import { CachingType } from './CachingType';

export class LocalStorageService implements IStorageService {

  resetListCache(plan: IVistoPlan, kind?: VistoKind) {
  }

  public get attachmentsSupported() {
    return false;
  }

  public get assigneeSupported() {
    return false;
  }

  private static async getPlanBlob(planId: string): Promise<IVistoPlan> {
    const data = await IndexedDbService.getItem('plans', planId);
    if (typeof data === 'string') {
      return parseJSON(data);
    }
    return data;
  }

  private static async setPlanBlob(plan: IVistoPlan): Promise<boolean> {
    plan.modifiedDate = TextService.getDate(new Date());
    return await IndexedDbService.setItem('plans', plan.planId, plan);
  }

  public static async listPlans(): Promise<IVistoPlan[]> {
    const items = await IndexedDbService.listItems('plans');
    return items.map(item => {
      if (typeof item === 'string') {
        item = parseJSON(item);
      }
      return {
        ...item,
        siteUrl: UrlService.LOCAL_URL
      }
    });
  }

  public static async generateActions(plan: IVistoPlan) {
    const result: IExecutableAction[] = [];
    result.push({
      title: TextService.format(strings.LocalStorageService_CreatePlanLocalStorage),
      execute: async () => {
        plan.createdDate = TextService.getDate(new Date()),
          await LocalStorageService.setPlanBlob(plan);
        return plan;
      }
    });
    return result;
  }

  public async deleteItems(plan: IVistoPlan, items: IVistoListItem[], notify: INotify, options?: IOperationOptions): Promise<IVistoPlan> {

    const updatedItems = { ...plan.items };

    const processed: IVistoListItem[] = [];
    for (const item of items) {
      delete updatedItems[item.guid];
      processed.push(item);
    }

    plan = { ...plan, items: updatedItems, revision: plan.revision + 1 };
    await LocalStorageService.setPlanBlob(plan);

    return ProgressService.ensureSync(plan, processed, notify, api.WSOperation.delete, options);
  }

  public async createItems(plan: IVistoPlan, items: IVistoListItem[], notify: INotify, options?: IOperationOptions): Promise<IVistoPlan> {

    const updatedItems = { ...plan.items };

    const processed: IVistoListItem[] = [];
    for (const item of items) {
      updatedItems[item.guid] = { ...item, itemId: -1 };
      processed.push(item);
    }

    plan = { ...plan, items: updatedItems, revision: plan.revision + 1 };
    await LocalStorageService.setPlanBlob(plan);

    return ProgressService.ensureSync(plan, processed, notify, api.WSOperation.create, options);
  }

  public async updateItems<T extends IVistoListItem>(plan: IVistoPlan, updates: IItemChanges<T>[], notify: INotify, options?: IOperationOptions): Promise<IVistoPlan> {
    const updatedItems = { ...plan.items };

    const processed: IVistoListItem[] = [];
    for (const update of updates) {
      updatedItems[update.item.guid] = {
        ...updatedItems[update.item.guid],
        ...(options?.reverse ? update.changes.oldValues : update.changes.newValues)
      };
    }

    plan = { ...plan, items: updatedItems, revision: plan.revision + 1 };
    await LocalStorageService.setPlanBlob(plan);

    return ProgressService.ensureSync(plan, processed, notify, api.WSOperation.update, options);
  }

  public async loadItem(plan: IVistoPlan, kind: VistoKind, itemGuid: string, notify: INotify): Promise<IVistoListItem> {
    return plan.items[itemGuid];
  }

  public async putFile(siteUrl: string, folderRelativeUrl: string, fileName: string, fileConent: File): Promise<IAttachment> {
    const result = await IndexedDbService.setItem('files', fileName, fileConent);
    const url = URL.createObjectURL(fileConent);
    return {
      fileName,
      fileAbsoluteUrl: url,
      defaultUrl: url
    };
  }
  public async getTemplates(): Promise<ITemplate[]> {
    return [];
  }

  public async getFile(fileAbsoluteUrl: string): Promise<Blob> {
    const fileRelativeUrl = UrlService.getPathName(fileAbsoluteUrl);
    const result = await IndexedDbService.getItem('files', fileRelativeUrl);
    return result;
  }

  public async makeAttachment(fileName: string, fileAbsoluteUrl: string, siteUrl: string): Promise<IAttachment> {
    return {
      fileName,
      fileAbsoluteUrl,
      defaultUrl: fileAbsoluteUrl
    };
  }

  public async getPlanItems(siteUrl: string, fields: (keyof IVistoPlan)[], caching: CachingType, ids?: string[]): Promise<IVistoPlan[]> {
    const plans = await LocalStorageService.listPlans();
    return plans;
  }

  public async createPlanItem(plan: IVistoPlan): Promise<IVistoPlan> {
    plan = {
      ...plan,
      revision: plan.revision + 1,
      siteUrl: UrlService.LOCAL_URL
    };
    await LocalStorageService.setPlanBlob(plan);
    return plan;
  }

  public async deletePlanItem(plan: IVistoPlan, deletePages?: boolean): Promise<void> {
    await IndexedDbService.deleteItem('plans', plan.planId);
  }

  public async updatePlanItem(p: IVistoPlan, changes: Partial<IVistoPlan>): Promise<IVistoPlan> {
    const plan = await LocalStorageService.getPlanBlob(p.planId);
    const updatedPlan = { ...plan, ...p, ...changes, revision: plan.revision + 1 };
    await LocalStorageService.setPlanBlob(updatedPlan);
    return updatedPlan;
  }

  public async loadPlanItem(plan: IVistoPlan, fields?: (keyof IVistoPlan)[]): Promise<IVistoPlan> {
    plan = await LocalStorageService.getPlanBlob(plan.planId);
    const loaded: IVistoPlan = {
      ...plan,
      editable: true,
      siteUrl: UrlService.LOCAL_URL,
      revision: 0,
      drawingRevision: 0
    };

    const planSettings = PlanSettingsService.getPlanSettings(loaded);
    loaded.statusDate = PlanSettingsService.getStatusDate(planSettings);

    return loaded;
  }

  public async loadPlanData(plan: IVistoPlan, notify: INotify): Promise<IVistoPlan> {
    const planItems = Object.values(plan.items).reduce((r, item) => ({ ...r, [item.guid]: { ...item, itemId: -1 } }), {});
    return {
      ...plan,
      items: planItems,
      revision: plan.revision + 1,
    };
  }

  public makeConsentNotification(callback: () => Promise<void>, siteUrl: string, notify: IBasicNotify) {
    // do nothing
  }
}
