import * as React from 'react';
import { Stack } from '@fluentui/react';
import { ITreeItem, SelectChildrenMode, TreeItemActionsDisplayMode, TreeView, TreeViewSelectionMode } from '@pnp/spfx-controls-react/lib/controls/treeView';
import { IVistoPlan } from 'sp';
import { PlanDataService } from 'services/PlanDataService';

export const ExportPlanFilterItems = (props: {
  plan: IVistoPlan,
  disabled: boolean;
  selectedKeys: string[];
  setSelectedKeys: (keys: string[]) => void;
}) => {

  const treeitems: ITreeItem[] = PlanDataService.getLops(props.plan).map(lop => ({
    key: lop.guid,
    label: lop.name,
    subLabel: lop.description,
    data: lop,
    disabled: props.disabled,
    children: PlanDataService.getLopDps(props.plan, lop.guid).map(dp => ({
      key: dp.guid,
      label: dp.name,
      data: dp,
      disabled: props.disabled
    }))
  }));

  const addParents = (keySet: Set<string>) => {
    for (const lop of PlanDataService.getLops(props.plan)) {
      for (const dp of PlanDataService.getLopDps(props.plan, lop.guid)) {
        for (const action of PlanDataService.getDpActions(props.plan, dp.guid)) {
          if (keySet.has(action.guid)) {
            keySet.add(dp.guid);
          }
        }
        if (keySet.has(dp.guid)) {
          keySet.add(lop.guid);
        }
      }
    }
  };

  const onTreeItemSelect = (items: ITreeItem[]) => {
    const keys = new Set(items.map(item => item.key));
    addParents(keys);
    props.setSelectedKeys(Array.from(keys));
  };

  return (
    <Stack grow style={{ maxHeight: '50vh', overflowY: 'auto' }}>
      <TreeView
        items={treeitems}
        defaultExpanded={false}
        selectionMode={TreeViewSelectionMode.Multiple}
        selectChildrenMode={SelectChildrenMode.Select | SelectChildrenMode.Unselect}
        showCheckboxes={true}
        treeItemActionsDisplayMode={TreeItemActionsDisplayMode.ContextualMenu}
        defaultSelectedKeys={props.selectedKeys}
        onSelect={onTreeItemSelect}
      />
    </Stack>
  );
};
