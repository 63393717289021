import { parseJSON } from 'shared/parse';
import { IZoomInfo } from './IViewInfo';

const zoomInfos = {};

export const useZoomInfo = (viewKey: string): [IZoomInfo, (s: Partial<IZoomInfo>) => void] => {
  
  if (!zoomInfos[viewKey]) {
    try {
      const zoomInfo = parseJSON(localStorage.getItem(viewKey), {});
      zoomInfos[viewKey] = zoomInfo || {};
    } catch (e) {
      zoomInfos[viewKey] = {};
    }
  }

  const updateZoomView = (value: Partial<IZoomInfo>) => {
    Object.assign(zoomInfos[viewKey], value);
    localStorage.setItem(viewKey, JSON.stringify(zoomInfos[viewKey]));
  }

  return [ zoomInfos[viewKey], updateZoomView ]
}
