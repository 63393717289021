import { IFieldDefinition, Choices, FieldType, ILookupFieldDefinition, IChoiceFieldDefinition } from './IFieldDefinition';
import { VistoKind } from '.';

export class FieldGenerator {

  public static makeUserField(key: keyof IVistoWebPartStrings, name: string, show: boolean): IFieldDefinition {
    return { type: FieldType.User, key, name, show, defaultView: true };
  }

  public static makeLookupField(key: keyof IVistoWebPartStrings, name: string, show: boolean, lookupKind: VistoKind, lookupRequired: boolean): ILookupFieldDefinition {
    return { type: FieldType.Lookup, key, name, show, lookupKind, lookupRequired, defaultView: true };
  }

  public static makeTextField(key: keyof IVistoWebPartStrings, name: string, show: boolean): IFieldDefinition {
    return { type: FieldType.Text, key, name, show };
  }

  public static makeSourceItemField(key: keyof IVistoWebPartStrings, name: string, show: boolean): IFieldDefinition {
    return { type: FieldType.Note, key, name, show };
  }

  public static makeGuidField(key: keyof IVistoWebPartStrings): IFieldDefinition {
    return { type: FieldType.Text, key, name: 'VISTO_Guid', show: false, defaultView: false };
  }

  public static makeNoteField(key: keyof IVistoWebPartStrings, name: string, show: boolean, richText: boolean): IFieldDefinition {
    return { type: FieldType.Note, key, name, show, richText };
  }

  public static makeUrlField(key: keyof IVistoWebPartStrings, name: string, show: boolean): IFieldDefinition {
    return { type: FieldType.URL, key, name, show };
  }

  public static makeNumberField(key: keyof IVistoWebPartStrings, name: string, show: boolean): IFieldDefinition {
    return { type: FieldType.Number, key, name, show };
  }

  public static makePercentCompleteField(key: keyof IVistoWebPartStrings, name: string): IFieldDefinition {
    return { type: FieldType.Number, key, name, show: true, usePercents: true, defaultView: true };
  }

  public static makePlannedPercentCompleteField(key: keyof IVistoWebPartStrings, name: string): IFieldDefinition {
    return { type: FieldType.Number, key, name, show: true, usePercents: true, defaultView: false };
  }

  public static makeCommentsField(key: keyof IVistoWebPartStrings, name: string, show: boolean): IFieldDefinition {
    return { type: FieldType.Note, key, name, show };
  }

  public static makeDateField(key: keyof IVistoWebPartStrings, name: string, show: boolean): IFieldDefinition {
    return { type: FieldType.DateTime, key, name, show, defaultView: true };
  }

  public static makeChoiceField(key: keyof IVistoWebPartStrings, name: string, show: boolean, choices: Choices): IChoiceFieldDefinition {
    return { type: FieldType.Choice, key, name, show, choices };
  }

  public static makeBooleanField(key: keyof IVistoWebPartStrings, name: string, show: boolean): IFieldDefinition {
    return { type: FieldType.Boolean, key, name, show };
  }

  public static makeIdField(key: keyof IVistoWebPartStrings): IFieldDefinition {
    return { type: FieldType.Number, key: key, name: 'ID', show: false, builtIn: true, defaultView: false };
  }

  public static makeTitleField(key: keyof IVistoWebPartStrings): IFieldDefinition {
    return { type: FieldType.Text, key, name: 'Title', show: true, builtIn: true, defaultView: true, minWidth: 150 };
  }

  public static makeCreatedField(key: keyof IVistoWebPartStrings): IFieldDefinition {
    return { type: FieldType.DateTime, key, name: 'Created', show: false, builtIn: true };
  }

  public static makeModifiedField(key: keyof IVistoWebPartStrings): IFieldDefinition {
    return { type: FieldType.DateTime, key, name: 'Modified', show: false, builtIn: true };
  }

  public static makeDescriptionField(key: keyof IVistoWebPartStrings): IFieldDefinition {
    return this.makeNoteField(key, 'Description', true, true);
  }

  public static makeLinksField(key: keyof IVistoWebPartStrings): IFieldDefinition {
    return this.makeNoteField(key, 'Links', false, false);
  }
}
