import { CommandsMx } from './CommandMx';
import { getObjectValues } from 'shared/parse';
import { getListDefinition, ILookupFieldDefinition, IVistoListItem, VistoKind, IVistoListItemWithProgress, IVistoPlan } from 'sp';
import strings from 'VistoWebPartStrings';
import { clearInfoBar, INotify, NotificationType, notifyInfoBar } from './Notify';
import { PlanDataService } from './PlanDataService';
import { TextService } from 'services/TextService';
import { Commands } from './Commands';
import { CommandName } from 'shared/CommandName';

const ItemLimits = {
  [VistoKind.SO]: 50,
  [VistoKind.LOP]: 20,
  [VistoKind.DP]: 200,
  [VistoKind.Action]: 2000,
  [VistoKind.Assoc]: 2000,
  [VistoKind.Focus]: 50,
  [VistoKind.KeyResult]: 100,
  [VistoKind.KRV]: 2000,
  [VistoKind.Effect]: 200,
}

export class PlanValidationService {

  public static validateLimits(plan: IVistoPlan, notify: INotify) {

    clearInfoBar(notify, 'limitValidation');
    const counters = {};
    for (const item of getObjectValues(plan.items)) {
      counters[item.kind] = (counters[item.kind] ?? 0) + 1;
    }
    const kinds = PlanDataService.getAllVistoKinds();
    for (const kind of kinds) {
      const itemKindName = TextService.getVistoKindName(kind);
      const limit = ItemLimits[kind];
      const counter = counters[kind];
      if (counter > limit) {
        notifyInfoBar(notify, {
          type: NotificationType.warn,
          group: 'limitValidation',
          message: TextService.format(strings.Validation_Limit_Message, { itemKindName, counter }),
          error: TextService.format(strings.Validation_Limit_Detalis, { limit, itemKindName }),
        });
      }
    }
  }

  public static validateRequiredLinks(plan: IVistoPlan, notify: INotify) {

    const validateRequiredLink = (item: IVistoListItem, kind: VistoKind, fieldName: string) => {
      const linked = PlanDataService.getItemByGuid(plan.items, item[fieldName]);
      if (!linked) {
        const linkKindName = TextService.getVistoKindName(kind);
        const itemKindName = TextService.getVistoKindName(item.kind);
        if (item.kind === VistoKind.DP && kind === VistoKind.LOP) {
          notifyInfoBar(notify, {
            type: NotificationType.warn,
            group: 'RequiredLinkValidation',
            kind: item.kind,
            guid: item.guid,
            message: TextService.format(strings.Validate_RequiredLinkLopDpMessage, {
              linkKindName,
              itemKindName
            }),
            error: TextService.format(strings.Validate_RequiredLinkLopDpMessageDetails, {
              title: TextService.formatTitle(item, plan.items),
              linkKindName,
            })
          });
        } else {
          notifyInfoBar(notify, {
            type: NotificationType.warn,
            group: 'RequiredLinkValidation',
            message: TextService.format(strings.Validate_RequiredLinkMessage, {
              linkKindName,
              itemKindName
            }),
            error: TextService.format(strings.Validate_RequiredLinkMessageDetails, {
              linkKindName,
              title: TextService.formatTitle(item, plan.items)
            }),
            actions: [
              {
                title: TextService.format(strings.Validate_RequiredLinkAction, {
                  itemKindName
                }),
                edit: { item, plan }
              },
              CommandsMx.makeDeleteItemNotificationAction(plan, item, notify)
            ]
          });
        }
      }
    };

    clearInfoBar(notify, 'RequiredLinkValidation');
    try {
      for (const item of getObjectValues(plan.items)) {
        const list = getListDefinition(item.kind);
        for (const fieldName in list.fields) {
          const field: ILookupFieldDefinition = list.fields[fieldName];
          if (field.lookupKind && field.lookupRequired) {
            validateRequiredLink(item, field.lookupKind, fieldName);
          }
        }
      }
    } catch (error) {
      notifyInfoBar(notify, { type: NotificationType.warn, message: TextService.format(strings.MainFrame_ErrorValidatingPlan), error });
    }
  }

  public static validate(plan: IVistoPlan, notify: INotify) {
    this.validateRequiredLinks(plan, notify);
    this.validateLimits(plan, notify);
    this.validateDuplicateSourceLinks(plan, notify);
  }

  public static makeBreakLinkNotification(plan: IVistoPlan, item: IVistoListItemWithProgress, notify: INotify) {
    return {
      title: TextService.format(strings.DuplicateSourceLink_CommandTitle),
      command: Commands.makeBreakLinkAction(plan, item, true, CommandName.BreakLink, notify),
      confirmation: {
        buttonOkText: TextService.format(strings.ButtonBreak),
        buttonOkBusyText: TextService.format(strings.ButtonBreaking),
        buttonCancelText: TextService.format(strings.ButtonCancel),
        title: TextService.format(strings.DuplicateSourceLink_DialogTitle),
        content: TextService.format(strings.DuplicateSourceLink_DialogContent, { title: TextService.formatTitle(item, plan.items) }),
      }
    };
  }

  public static validateDuplicateSourceLinks(plan: IVistoPlan, notify: INotify) {
    clearInfoBar(notify, 'DuplicateSourceLinkValidation');
    const linkMap = new Map<string, IVistoListItemWithProgress>();
    const items = PlanDataService.getItemsHaving<IVistoListItemWithProgress>(plan.items, x => !!x.sourceItemUrl);
    items.sort((a, b) => TextService.compareDateTime(a.createdDate, b.createdDate));
    for (const item of items) {
      if (linkMap.has(item.sourceItemUrl)) {
        const duplicate = linkMap.get(item.sourceItemUrl);
        notifyInfoBar(notify, {
          type: NotificationType.warn,
          group: 'DuplicateSourceLinkValidation',
          message: TextService.format(strings.DuplicateSourceLink_CommandMessage, {
            duplicate: TextService.formatTitle(duplicate, plan.items),
          }),
          error: TextService.format(strings.DuplicateSourceLink_CommandDetails, {
            item: TextService.formatTitle(item, plan.items),
            duplicate: TextService.formatTitle(duplicate, plan.items),
            link: item.sourceItemUrl
          }),
          kind: duplicate.kind,
          guid: Commands.getClickableGuid(duplicate),
          actions: [
            this.makeBreakLinkNotification(plan, duplicate, notify),
          ]
        });
      } else {
        linkMap.set(item.sourceItemUrl, item);
      }
    }
  }

  public static validateSourceLink(plan: IVistoPlan, item: IVistoListItemWithProgress, sourceItemUrl: string) {
    if (sourceItemUrl) {
      const [duplicate] = PlanDataService.getItemsHaving(plan.items, (x: IVistoListItemWithProgress) => x.guid !== item.guid && x.sourceItemUrl === sourceItemUrl);
      if (duplicate) {
        return {
          type: NotificationType.error,
          message: TextService.format(strings.Validate_DuplicateSourceLink, {
            title: TextService.formatTitle(duplicate, plan.items),
            parentTitle: TextService.formatParentTitle(duplicate, plan.items)
          })
        };
      }
    }
  }
}
