import React from 'react';
import { DefaultButton, PrimaryButton, Stack, Text } from '@fluentui/react';
import { IFieldValueUser, IVistoPlan } from 'sp';
import { IVisualFilter } from './IVisualFilter';
import { TextService } from 'services/TextService';
import strings from 'VistoWebPartStrings';
import { FocusMultiPicker } from './FocusMultiPicker';
import { KrMultiPicker } from './KrMultiPicker';
import { LopMultiPicker } from './LopMultiPicker';
import { AssigneeMultiPicker } from './AssigneeMultiPicker';

export const PlanVisualFilterCallout = (props: {
  plan: IVistoPlan;
  filter: IVisualFilter;
  setFilter: (filter: IVisualFilter) => void;
  onDismiss: () => void;
}) => {

  const [filter, setFilter] = React.useState(props.filter);
  React.useEffect(() => setFilter(props.filter), [props.filter]);

  const assignee = filter.assignees || [];
  const setAssignee = (value: IFieldValueUser[]) => {
    setFilter({ ...filter, assignees: value });
  };

  const focusGuids = filter.focusGuids || [];
  const setFocusGuids = (value: string[]) => {
    setFilter({ ...filter, focusGuids: value });
  };

  const lopGuid = filter.lopGuids || [];
  const setLopGuids = (value: string[]) => {
    setFilter({ ...filter, lopGuids: value });
  };

  const krGuid = filter.krGuids || [];
  const setKrGuids = (value: string[]) => {
    setFilter({ ...filter, krGuids: value });
  };

  const onDismiss = () => {
    props.onDismiss();
  }

  const onApply = () => {
    props.setFilter(filter);
    props.onDismiss();
  }

  const onClear = () => {
    props.setFilter({
      assignees: [],
      focusGuids: [],
      krGuids: [],
      lopGuids: []
    });
    props.onDismiss();
  }

  return (
    <Stack style={{ minWidth: 350 }} tokens={{ childrenGap: 's1', padding: 'm' }}>
      <Text variant='large'>{TextService.format(strings.VisualFilter_Title)}</Text>
      <FocusMultiPicker
        plan={props.plan}
        label={TextService.format(strings.EditActionDialog_FocusLabel)}
        focusGuids={focusGuids}
        setFocusGuids={setFocusGuids} />
      <KrMultiPicker
        label={TextService.format(strings.EditAssocDialog_KeyResultSelectorLabel)}
        plan={props.plan}
        krGuids={krGuid}
        setKrGuids={setKrGuids} />
      <LopMultiPicker
        label={TextService.format(strings.EditActionDialog_CapabilityLabel)}
        plan={props.plan}
        lopGuids={lopGuid}
        setLopGuids={setLopGuids} />
      <AssigneeMultiPicker
        label={TextService.format(strings.EditActionDialog_AssigneeLabel)}
        plan={props.plan}
        value={assignee}
        setValue={setAssignee} />
      <Stack horizontal wrap grow horizontalAlign='end' tokens={{ childrenGap: 's1', padding: 'm 0 0 0' }}>
        <Stack.Item grow>
          <DefaultButton text={TextService.format(strings.ButtonClear)} onClick={onClear} />
        </Stack.Item>
        <Stack horizontal tokens={{ childrenGap: 's1' }}>
          <PrimaryButton text={TextService.format(strings.ButtonApply)} onClick={onApply} />
          <DefaultButton text={TextService.format(strings.ButtonCancel)} onClick={onDismiss} />
        </Stack>
      </Stack>
    </Stack>
  );
};
