import React from 'react'
import { Spinner, Stack, Text, useTheme } from '@fluentui/react'
import { IAssistatCard } from 'frames/IAssistatCard'

const getMessageRoleName = (role: 'user' | 'assistant' | 'system' | 'error' | 'progress') => {
  if (role === 'user') {
    return 'User'
  } else if (role === 'system') {
    return 'System'
  } else {
    return 'Assistant'
  }
}

export const UserCard = (props: {
  card: IAssistatCard;
}) => {

  const theme = useTheme();

  return (
    <Stack
      tokens={{ childrenGap: 8, padding: 12 }}
      styles={{
        root: {
          border: theme.palette.neutralTertiary,
          backgroundColor: theme.palette.neutralLighter,
          boxShadow: theme.effects.elevation4,
          borderRadius: theme.effects.roundedCorner4,
          maxWidth: '350px',
          marginBottom: '10px',
        },
      }}>
      <Text variant='small'><strong>{getMessageRoleName(props.card.role)}</strong></Text>
      {props.card.role === 'error' && <Text style={{ color: theme.palette.redDark }}>{props.card.content}</Text>}
      {props.card.role === 'progress' && <Stack horizontal tokens={{ childrenGap: 's2' }}><Spinner /><Text style={{ color: theme.palette.themePrimary }}>{props.card.content}</Text></Stack>}
      {props.card.role !== 'error' && props.card.role !== 'progress' && <Text>{props.card.content}</Text>}
    </Stack>
  )
}
