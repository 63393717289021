import { VistoKind, IVistoListItemWithProgress, addListDefinition, IFieldDefinitions, ListItemUpdater, IItemPosition } from './common';
import { FieldGenerator } from './common/FieldGenerator';

export interface VistoDpItem extends IVistoListItemWithProgress {
  readonly lopGuid?: string;

  position?: IItemPosition;
}

export const VistoDpListFields: IFieldDefinitions<VistoDpItem> = {
  itemId: FieldGenerator.makeIdField('VISTO_DP_ItemId'),
  guid: FieldGenerator.makeGuidField('VISTO_DP_Guid'),
  name: FieldGenerator.makeTitleField('VISTO_DP_Name'),
  description: FieldGenerator.makeDescriptionField('VISTO_DP_Description'),
  links: FieldGenerator.makeLinksField('VISTO_DP_Links'),
  lopGuid: FieldGenerator.makeLookupField('VISTO_DP_LOP', 'LOP', true, VistoKind.LOP, true),
  startDate: FieldGenerator.makeDateField('VISTO_DP_StartDate', 'StartDate', true),
  endDate: FieldGenerator.makeDateField('VISTO_DP_EndDate', 'EndDate', true),
  percentComplete: FieldGenerator.makePercentCompleteField('VISTO_DP_PercentComplete', 'PercentComplete'),
  plannedPercentComplete: FieldGenerator.makePlannedPercentCompleteField('VISTO_DP_PlannedPercentComplete', 'PlannedPercentComplete'),
  comments: FieldGenerator.makeCommentsField('VISTO_DP_Comments', 'Comments', true),
  sourceItemUrl: FieldGenerator.makeSourceItemField('VISTO_DP_SourceItem', 'SourceItem', true),
  createdDate: FieldGenerator.makeCreatedField('VISTO_DP_Created'),
  modifiedDate: FieldGenerator.makeModifiedField('VISTO_DP_Modified'),
  assignedTo: FieldGenerator.makeUserField('VISTO_DP_AssignedTo', 'AssignedTo', true),
};

addListDefinition({

  kind: VistoKind.DP,
  fields: VistoDpListFields,
  enableVersioning: true,
  majorVersionLimit: 500,
  hasProgress: true,

  defaultViewXml: `<View>
    <Query>
      <GroupBy Collapse='FALSE' GroupLimit='30'>
        <FieldRef Name='${VistoDpListFields.lopGuid.name}' />
      </GroupBy>
    </Query>
    <ViewFields>
      <FieldRef Name='LinkTitle' />
      <FieldRef Name='${VistoDpListFields.description.name}' />
      <FieldRef Name='${VistoDpListFields.startDate.name}' />
      <FieldRef Name='${VistoDpListFields.endDate.name}' />
      <FieldRef Name='${VistoDpListFields.percentComplete.name}' />
      <FieldRef Name='${VistoDpListFields.plannedPercentComplete.name}' />
      <FieldRef Name='${VistoDpListFields.assignedTo.name}' />
      <FieldRef Name='${VistoDpListFields.comments.name}' />
    </ViewFields>
    <RowLimit Paged='TRUE'>30</RowLimit>
  </View>`,

  setItemSpecificFields(updater: ListItemUpdater, spItem, update: Partial<VistoDpItem>) {

    updater.setItemSpField(VistoDpListFields, spItem, update, 'lopGuid');
    updater.setItemSpField(VistoDpListFields, spItem, update, 'assignedTo');
  },

  getItemSpecificFields(updater: ListItemUpdater, item: Partial<VistoDpItem>, spItem) {

    updater.getItemSpField(VistoDpListFields, item, spItem, 'lopGuid');
    updater.getItemSpField(VistoDpListFields, item, spItem, 'assignedTo');
  }
});
