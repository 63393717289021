import { VistoKind } from '.';

export type Choices = { key: number; stringId: keyof IVistoWebPartStrings }[];

export enum FieldType {
  Invalid = 0,
  Integer = 1,
  Text = 2,
  Note = 3,
  DateTime = 4,
  Counter = 5,
  Choice = 6,
  Lookup = 7,
  Boolean = 8,
  Number = 9,
  Currency = 10,
  URL = 11,
  Computed = 12,
  Threading = 13,
  Guid = 14,
  MultiChoice = 15,
  GridChoice = 16,
  Calculated = 17,
  File = 18,
  Attachments = 19,
  User = 20,
}

export interface IFieldDefinition {
  key: keyof IVistoWebPartStrings;
  type: FieldType;
  name: string;
  show: boolean;

  builtIn?: boolean;
  usePercents?: boolean;
  richText?: boolean;

  defaultView?: boolean;
  minWidth?: number;
}

export interface IChoiceFieldDefinition extends IFieldDefinition {
  choices: Choices;
}

export interface ILookupFieldDefinition extends IFieldDefinition {
  lookupKind: VistoKind;
  lookupRequired: boolean;
}

export type IFieldDefinitions<T> = { [key in keyof Partial<T>]: IFieldDefinition };
