import { AuthService } from 'services/AuthService';
import { IExecutableAction } from 'services/IExecutableAction';
import { UrlService } from 'shared/urlService';
import { StorageCacheService } from 'services/StorageCacheService';
import { IVistoPlan, VistoKind } from 'sp';

// TODO: Schedule for the next run
export const MigrateDeleteEntityPhase = (foundPlan: IVistoPlan): IExecutableAction => ({
  title: `Remove entity and phase lists`,
  execute: async (p) => {

    const sp = AuthService.getSpClient(foundPlan.siteUrl);

    const getListByName = (name: string) => {
      const listName = `Visto_${name}_${foundPlan.planId}`;
      const listRelativeUrl = UrlService.combine('Lists', listName);
      const siteRelativeUrl = UrlService.getPathName(foundPlan.siteUrl);
      return UrlService.combine(siteRelativeUrl, listRelativeUrl);
    }

    const soListUrl = UrlService.getListRelativeUrl(foundPlan, VistoKind.SO);
    await sp.web.getList(soListUrl).fields.getByInternalNameOrTitle('Entity').delete();

    const dpListUrl = UrlService.getListRelativeUrl(foundPlan, VistoKind.DP);
    await sp.web.getList(dpListUrl).fields.getByInternalNameOrTitle('Phase').delete();

    const entityListUrl = getListByName('Entity');
    await sp.web.getList(entityListUrl).recycle();

    const phaseListUrl = getListByName('Phase');
    await sp.web.getList(phaseListUrl).recycle();

    StorageCacheService.resetCache();
    return p;
  }
});
