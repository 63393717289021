import React from 'react';
import { Stack } from '@fluentui/react';
import { PlanDataService } from 'services/PlanDataService';
import { TextService } from 'services/TextService';
import { IVistoPlan, VistoActionItem, VistoDpItem, VistoFocusItem, VistoKind } from 'sp';
import strings from 'VistoWebPartStrings';
import { SidebarActionListGroup } from './SidebarActionListGroup';

const GROUP_KEY_ACTIVE_FOCUS = 'db4dbad9dba0437087d2b5e8cae05d9b';
const GROUP_KEY_NO_FOCUS = 'e07e75d834ad4424bfa8cd4f58459688';

export const SidebarFocusActionList = (props: {
  plan: IVistoPlan;
  dp: VistoDpItem;
}) => {

  const actions = PlanDataService
    .getDpActions(props.plan, props.dp.guid);

  const activeFocus = PlanDataService.getActiveFocus(props.plan);

  const focuses = PlanDataService.getItemsHaving<VistoFocusItem>(props.plan.items, x => x.kind === VistoKind.Focus && x.guid !== activeFocus?.guid);

  const activeFocusActions = actions.filter(a => a.focusGuid === activeFocus?.guid);
  const noFocusActions = actions.filter(a => !a.focusGuid);

  return (
    <>
      <SidebarActionListGroup
        key={GROUP_KEY_ACTIVE_FOCUS}
        plan={props.plan}
        expandKey={GROUP_KEY_ACTIVE_FOCUS}
        defaultExpanded
        header={<strong>{TextService.format(strings.SidebarActions_ActiveFocusGroup, { 
          title: TextService.formatTitle(activeFocus, props.plan.items), 
          count: activeFocusActions.length 
        })}</strong>}
        actions={activeFocusActions}
      />

      {focuses.map(f => {
        const focusActions = actions.filter(a => a.focusGuid === f.guid);
        return <SidebarActionListGroup
          key={f.guid}
          plan={props.plan}
          expandKey={f.guid}
          header={TextService.format(strings.SidebarActions_FocusGroup, { 
            title: TextService.formatTitle(f, props.plan.items), 
            count: focusActions.length 
          })}
          actions={focusActions}
        />
      })}

      <SidebarActionListGroup
        key={GROUP_KEY_NO_FOCUS}
        plan={props.plan}
        expandKey={GROUP_KEY_NO_FOCUS}
        header={TextService.format(strings.SidebarActions_NoFocusGroup, { count: noFocusActions.length })}
        actions={noFocusActions}
      />
    </>
  );

};

