import * as strings from 'VistoWebPartStrings';
import * as React from 'react';

import { IVistoPlan, VistoEffectItem } from 'sp';
import { ItemEditDialog } from 'dialogs/common';
import { TitleBlock } from 'components';
import { FontSizes, Stack } from '@fluentui/react';
import { AppContext } from 'services/AppContext';
import { Commands } from 'services/Commands';
import { ProgressService } from 'services/ProgressService';
import { trackClient } from 'shared/clientTelemetry';

export function EditEffectDialog(props: {
  plan: IVistoPlan;
  effect: VistoEffectItem;
  onDismiss: (changed: boolean) => void;
}) {

  React.useEffect(() => trackClient.page('EditEffectDialog'), []);

  const { isPlanEditEnabled, dispatchCommand, notify } = React.useContext(AppContext);

  const oldEffect = props.effect;
  const [newEffect, setNewEffect] = React.useState<VistoEffectItem>({ ...oldEffect });

  return <ItemEditDialog
    onDismiss={props.onDismiss}
    onLoaded={loaded => setNewEffect({ ...newEffect, ...loaded })}
    plan={props.plan}
    oldItem={oldEffect}
    newItem={newEffect}
    onCommit={() => dispatchCommand(Commands.makeSaveCommand(oldEffect, newEffect, notify), { wrap: false })}
    validationIssues={!newEffect.name}
    content={
      <Stack tokens={{ childrenGap: 's1' }}>
        <TitleBlock
          hideAssignedTo
          fontSize={FontSizes.xLarge}
          edit={isPlanEditEnabled}
          name={newEffect.name}
          description={newEffect.description}
          nameDisabled={!isPlanEditEnabled || !ProgressService.allowEdit(newEffect, 'name')}
          descriptionDisabled={!isPlanEditEnabled || !ProgressService.allowEdit(newEffect, 'description')}
          onNameChanged={val => setNewEffect({ ...newEffect, name: val })}
          onDescriptionChanged={val => newEffect.description = val}
        />
      </Stack>
    }
  />;

}
