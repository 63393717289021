
///https://stackoverflow.com/questions/105034/how-to-create-a-guid-uuid
export function makeGuidFrom(pattern: string) {
  return pattern.replace(/[xy]/g, (c) => {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

export function makeGuid() {
  return makeGuidFrom('xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx');
}

export function makeGuidString() {
  return makeGuidFrom('xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx');
}

export function areGuidsEqual(a: string, b: string) {
  return a && b && a?.replace(/[{\-}]/g, '').toLowerCase() === b.replace(/[{\-}]/g, '').toLowerCase();
}

export function isGuid(s: string) {
  return /^[0-9a-f]{8}-?[0-9a-f]{4}-?[0-5][0-9a-f]{3}-?[089ab][0-9a-f]{3}-?[0-9a-f]{12}$/i.test(s);
}
