import * as React from 'react';
import { Stack, TextField, DatePicker, FontSizes, MessageBar, MessageBarType, Link, Checkbox } from '@fluentui/react';

import { VistoActionItem as IVistoListItem, IVistoPlan, VistoFocusItem } from 'sp';
import { ItemEditDialog } from 'dialogs/common';
import { TitleBlock } from 'components';

import { AppContext } from 'services/AppContext';
import { ProgressService } from 'services/ProgressService';
import { IntegrationService } from 'services/IntegrationService';
import { TextService } from 'services/TextService';
import * as strings from 'VistoWebPartStrings';
import { trackClient } from 'shared/clientTelemetry';
import { StorageService } from 'services/StorageService';
import { FocusPicker } from './common/FocusPicker';
import { LopPicker } from './common/LopPicker';
import { DpPicker } from './common/DpPicker';
import { PlanDataService } from 'services/PlanDataService';

export function EditActionDialog(props: {
  plan: IVistoPlan;
  action: IVistoListItem;
  onDismiss: (changed: boolean) => void;
  onSave: (oldItem: IVistoListItem, newItem: IVistoListItem) => Promise<any>;
}) {

  React.useEffect(() => trackClient.page('EditActionDialog'), []);

  const { isPlanEditEnabled } = React.useContext(AppContext);

  const oldAction = props.action;
  const [newAction, setNewAction] = React.useState<IVistoListItem>({ ...oldAction });

  const isLinked = !!newAction.sourceItemUrl;
  const isEditDisabled = !isPlanEditEnabled;

  const startDateErrorMessage =
    (newAction.startDate && newAction.endDate && newAction.startDate > newAction.endDate&& TextService.format(strings.EditActionDialog_StartDateErrorMessage)) ||
    (!newAction.startDate && newAction.endDate && TextService.format(strings.EditActionDialog_StartDateRequiredErrorMessage));

  const endDateErrorMessage =
    (newAction.startDate && newAction.endDate && newAction.startDate > newAction.endDate && TextService.format(strings.EditActionDialog_EndDateErrorMessage)) ||
    (!newAction.endDate && newAction.startDate && TextService.format(strings.EditActionDialog_EndDateRequiredErrorMessage));

  const percentCompleteErrorMessage =
    newAction.percentComplete
    && (typeof (newAction.percentComplete) !== 'number' || !(0 <= newAction.percentComplete && newAction.percentComplete <= 100))
    && TextService.format(strings.EditactionDialog_PercentCompleteErrorMessage);

  const [showValidations, setShowValidations] = React.useState(false);
  const valuesMissing = !newAction.name || !newAction.lopGuid || !newAction.dpGuid;

  const onValidate = () => {
    if (valuesMissing || startDateErrorMessage || endDateErrorMessage || percentCompleteErrorMessage) {
      setShowValidations(true);
      return false;
    } else {
      return true;
    }
  }
  
  const onToggleUseFocusDates = (_, val) => {
    if (val) {
      const focus = PlanDataService.getItemByGuid(props.plan.items, newAction.focusGuid) as VistoFocusItem;
      setNewAction({
        ...newAction,
        startDate: focus?.startDate ?? null,
        endDate: focus?.endDate ?? null,
        useFocusDates: true
      });
    } else {
      setNewAction({
        ...newAction,
        startDate: oldAction?.startDate ?? null,
        endDate: oldAction?.endDate ?? null,
        useFocusDates: false
      });
    }
  };

  const onSetFocusGuid = (focusGuid: string) => {
    if (newAction.useFocusDates) {
      const focus = PlanDataService.getItemByGuid(props.plan.items, focusGuid) as VistoFocusItem;
      setNewAction({
        ...newAction,
        focusGuid,
        startDate: focus?.startDate ?? null,
        endDate: focus?.endDate ?? null,
      });
    } else {
      setNewAction({ ...newAction, focusGuid });
    }
  };

  const onSave = async () => {
    return await props.onSave(oldAction, newAction);
  }

  return (
    <ItemEditDialog
      onDismiss={props.onDismiss}
      onLoaded={loaded => setNewAction({ ...newAction, ...loaded })}
      plan={props.plan}
      onCommit={onSave}
      onValidate={onValidate}
      oldItem={oldAction}
      newItem={newAction}
      validationIssues={valuesMissing}
      content={
        <Stack tokens={{ childrenGap: 's1' }}>
          <TitleBlock
            edit={isPlanEditEnabled}
            fontSize={FontSizes.xLarge}
            name={newAction.name}
            description={newAction.description}
            nameDisabled={isEditDisabled || !ProgressService.allowEdit(newAction, 'name')}
            descriptionDisabled={isEditDisabled || !ProgressService.allowEdit(newAction, 'description')}
            onNameChanged={val => setNewAction({ ...newAction, name: val })}
            onDescriptionChanged={val => newAction.description = val}
            assignedTo={newAction.assignedTo}
            onAssignedToChanged={assignedTo => setNewAction({ ...newAction, assignedTo: assignedTo })}
            assignedToDisabled={isEditDisabled || !ProgressService.allowEdit(newAction, 'assignedTo') || !StorageService.get(props.plan.siteUrl).assigneeSupported}
          />
          {isLinked &&
            <MessageBar messageBarType={MessageBarType.info} >
              {TextService.format(strings.EditActionDialog_LinkedItemLabel)} <Link target={newAction.guid} href={IntegrationService.getBrowserLink(newAction.sourceItemUrl)}>{TextService.format(strings.EditActionDialog_LinkedItemLabelLink)}</Link>
            </MessageBar>
          }
          <FocusPicker
            plan={props.plan}
            disabled={!isPlanEditEnabled}
            label={TextService.format(strings.EditActionDialog_FocusLabel)}
            focusGuid={newAction.focusGuid}
            setFocusGuid={onSetFocusGuid}
          />
          <Stack horizontal tokens={{ childrenGap: 'm' }}>
            <DatePicker
              today={props.plan.statusDate}
              initialPickerDate={newAction.startDate || props.plan.statusDate}
              strings={TextService.datePickerStrings}
              firstDayOfWeek={TextService.firstDayOfWeek}
              formatDate={d => TextService.formatDate(d)}
              isRequired={!!newAction.endDate}
              label={TextService.format(strings.EditActionDialog_StartDateLabel)}
              disabled={isEditDisabled || !ProgressService.allowEdit(newAction, 'startDate') || newAction.useFocusDates}
              textField={{ errorMessage: showValidations && startDateErrorMessage }}
              value={newAction.startDate} allowTextInput={true}
              onSelectDate={val => setNewAction({ ...newAction, startDate: val })}
            />
            <DatePicker
              today={props.plan.statusDate}
              initialPickerDate={newAction.endDate || props.plan.statusDate}
              strings={TextService.datePickerStrings}
              firstDayOfWeek={TextService.firstDayOfWeek}
              formatDate={d => TextService.formatDate(d)}
              isRequired={!!newAction.startDate}
              disabled={isEditDisabled || !ProgressService.allowEdit(newAction, 'endDate') || newAction.useFocusDates}
              textField={{ errorMessage: showValidations && endDateErrorMessage }}
              label={TextService.format(strings.EditActionDialog_EndDateLabel)}
              value={newAction.endDate}
              allowTextInput={true}
              onSelectDate={val => setNewAction({ ...newAction, endDate: val })}
            />
            <TextField
              disabled={isLinked || isEditDisabled || !ProgressService.allowEdit(newAction, 'percentComplete')}
              type='number'
              min={0}
              max={100}
              label={TextService.format(strings.EditActionDialog_PercentCompleteLabel)}
              value={TextService.formatPercents(newAction.percentComplete)}
              onChange={(_, val) => setNewAction({ ...newAction, percentComplete: val !== '' && TextService.isValidNumber(+val) ? +val : null })}
              errorMessage={showValidations && percentCompleteErrorMessage}
            />
          </Stack>

          <Checkbox
            label={TextService.format(strings.EditActionDialog_UseFocusDatesLabel)}
            checked={newAction.useFocusDates}
            disabled={!isPlanEditEnabled}
            onChange={onToggleUseFocusDates}
          />

          <Stack horizontal tokens={{ childrenGap: 'm' }}>
            <Stack.Item style={{ flex: 1 }}>
              <LopPicker
                plan={props.plan}
                disabled={!isPlanEditEnabled}
                label={TextService.format(strings.__Capability)}
                lopGuid={newAction.lopGuid}
                setLopGuid={(lopGuid) => setNewAction({ ...newAction, lopGuid, dpGuid: null })}
              />
            </Stack.Item>
            <Stack.Item style={{ flex: 1 }}>
              <DpPicker
                plan={props.plan}
                disabled={!isPlanEditEnabled}
                label={TextService.format(strings.__Ambition)}
                lopGuid={newAction.lopGuid}
                dpGuid={newAction.dpGuid}
                setDpGuid={dpGuid => setNewAction({ ...newAction, dpGuid })}
              />
            </Stack.Item>
          </Stack>
        </Stack>
      }
    />);
}
