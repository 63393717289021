import { IPalette } from '@fluentui/react';

const colors = [
  '#aaa', 'Aqua', 'Blue', 'BlueViolet', 'Chartreuse',
  'Coral', 'CornFlowerBlue', 'DarkBlue', 'DarkCyan', 'DarkGoldenRod',
  'DarkOrange', '#800080', 'DeepPink', 'DodgerBlue', 'Fuchsia', 'HotPink',
  'Indigo', 'Navy', 'PaleVioletRed', 'RebeccaPurple', 'RoyalBlue', 'Tomato',
  '#cccc00', '#cc6600',
];


const hashCode = (s: string) => {
  var hash = 0;
  if (s.length == 0) return hash;
  for (let i = 0; i < s.length; i++) {
    const char = s.charCodeAt(i);
    hash = ((hash << 5) - hash) + char;
    hash = hash & hash; // Convert to 32bit integer
  }
  return hash + 2147483647 + 1;
};

export class ColorService {

  public static getUserColor(oid: string) {
    const hash = hashCode(oid || '');
    return colors[hash % colors.length];
  }

  public static hexToRGB(hex: string, alpha: string) {

    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
      return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    }

    return `rgb(${r}, ${g}, ${b})`;
  };

  public static getFluentColors = (palette: IPalette) => [
    palette.themePrimary,
    palette.tealLight,
    palette.magenta,
    palette.blueMid,
    palette.blueLight,
    palette.green,
    palette.yellow,
    palette.purpleDark,
    palette.greenLight,
    palette.purple,
    palette.redDark,
    palette.purpleLight,
    palette.blue,
    palette.tealDark,
    palette.magentaLight,
    palette.teal,
    palette.orange,
    palette.greenDark,
    palette.magentaDark,
    palette.orangeLight,
    palette.orangeLighter,
    palette.yellowDark,
    palette.blueDark,
  ];

}
