import React from 'react';
import { Dropdown, IComboBoxOption } from '@fluentui/react';
import { DashboardDataService } from 'services/DashboardDataService';
import { TextService } from 'services/TextService';
import strings from 'VistoWebPartStrings';

export const SharePointSitePlanPicker = (props: {
  label: string;
  disabled: boolean;
  planInfos: { SiteUrl: string; PlanRef: string }[];
  siteUrl: string;
  planId: string;
  setPlanId: (val: string) => void;
}) => {
  const [isLoading, setIsLoading] = React.useState(false);

  const [sitePlans, setSitePlans] = React.useState<IComboBoxOption[]>([{
    key: props.planId,
    text: TextService.format(strings.ItemPicker_Loading)
  }]);

  React.useEffect(() => {
    setIsLoading(true);
    const planNames = {};
    const newSitePlans = props.planInfos.filter(x => x.SiteUrl === props.siteUrl).map(x => ({ key: x.PlanRef, text: x.PlanRef }));
    for (const dbItem of newSitePlans) {
      planNames[dbItem.key] = {}
    }
    DashboardDataService.getPlanNames(props.siteUrl, planNames, null).then(() => {
      for (const plan of newSitePlans) {
        plan.text = planNames[plan.key].name;
      }
      setSitePlans(newSitePlans);
      setIsLoading(false);
    }, err => {
      setIsLoading(false);
    });
  }, [props.planInfos, props.siteUrl]);

  return (
    <Dropdown
      label={props.label}
      disabled={props.disabled || isLoading || !props.siteUrl}
      calloutProps={{ calloutMaxHeight: 200 }}
      options={sitePlans}
      selectedKey={props.planId}
      onChange={(_, val) => props.setPlanId('' + val.key)}
    />
  );
}
