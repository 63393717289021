import { IconButton, TooltipHost } from '@fluentui/react';
import React from 'react';
import { TextService } from 'services/TextService';
import strings from 'VistoWebPartStrings';

export const RefreshButton = (
  props: {
    disabled?: boolean;
    onClick: () => void;
  }
) => {

  return (
    <TooltipHost content={TextService.format(strings.ButtonRefresh_Tooltip)}>
      <IconButton iconProps={{ iconName: 'Refresh' }} disabled={props.disabled} onClick={props.onClick} />
    </TooltipHost>
  );
};
