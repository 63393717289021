import React from 'react'
import { IBasePickerSuggestionsProps, ITag, Label, Stack, TagPicker } from '@fluentui/react';
import { TextService } from 'services/TextService';
import { VistoKind } from 'shared/VistoKind';
import { ItemInfoService } from '../services';
import strings from 'VistoWebPartStrings';

interface IVistoItemTag extends ITag {
  guid: string;
}

export const SharePointItemPicker = (props: {
  label: string;
  disabled: boolean;
  siteUrl: string;
  planId: string;
  itemKind: VistoKind;
  itemId: number;
  setItem: (itemId: number) => void;
}) => {

  const pickerSuggestionsProps: IBasePickerSuggestionsProps = {
    suggestionsHeaderText: TextService.format(strings.ItemPicker_ExistingItems),
    noResultsFoundText: TextService.format(strings.ItemPicker_NoResultsFound),
  };

  const getTextFromItem = (item: IVistoItemTag) => {
    return `#${item.key} ${item.name}`;
  }

  const onResolveSuggestions = async (filter: string, prev: IVistoItemTag[] | undefined): Promise<IVistoItemTag[]> => {
    const suggestions = await ItemInfoService.getSpItemInfos(props.siteUrl, props.planId, props.itemKind, filter);
    return suggestions;
  };

  const onDevOpsTaskChanged = (items: IVistoItemTag[]) => {
    setSelectedItems(items);
    if (items.length) {
      const item = items[0];
      props.setItem(+item.key);
    } else {
      props.setItem(undefined);
    }
  };

  const [selectedItems, setSelectedItems] = React.useState<IVistoItemTag[]>(props.itemId ? [{ key: props.itemId, guid: '', name: `${props.itemId}` }] : []);

  React.useEffect(() => {
    if (props.itemId) {
      ItemInfoService.getSpItemInfo(props.siteUrl, props.planId, props.itemKind, props.itemId).then(info => {
        setSelectedItems([info]);
      })
    } else {
      setSelectedItems([]);
    }
  }, [props.siteUrl, props.planId, props.itemKind, props.itemId]);

  return (
    <Stack>
      <Label>{props.label}</Label>
      <TagPicker
        itemLimit={1}
        disabled={props.disabled || !props.siteUrl || !props.planId}
        onResolveSuggestions={onResolveSuggestions}
        getTextFromItem={getTextFromItem}
        pickerSuggestionsProps={pickerSuggestionsProps}
        selectedItems={selectedItems}
        onChange={onDevOpsTaskChanged}
        inputProps={{ placeholder: selectedItems.length ? '' : TextService.format(strings.ItemPicker_SearchForItems) }}
      />
    </Stack>
  );
}
