import { mxgraph } from 'ts-mxgraph-typings';
import { FixedViewType } from '../common';
import { IImageInfo } from './IImageInfo';

export class ImageUpdater {

  private static getFixedViewType(graph: mxgraph.mxGraph, cell: mxgraph.mxCell) {
    const cellStyle = graph.getCellStyle(cell);
    if (+cellStyle['imageSlice'] === 1)
      return FixedViewType.FillPage;
    if (+cellStyle['imageAspect'] === 1)
      return FixedViewType.FitPage;
    return FixedViewType.None;
  }

  private static setFixedViewType(graph: mxgraph.mxGraph, cells: mxgraph.mxCell[], fixedViewType: FixedViewType) {
    switch (fixedViewType) {
      case FixedViewType.None:
        graph.setCellStyles('imageAspect', '0', cells);
        graph.setCellStyles('imageSlice', '0', cells);
        break;
      case FixedViewType.FitPage:
        graph.setCellStyles('imageAspect', '1', cells);
        graph.setCellStyles('imageSlice', '0', cells);
        break;
      case FixedViewType.FillPage:
        graph.setCellStyles('imageAspect', '1', cells);
        graph.setCellStyles('imageSlice', '1', cells);
        break;
    }
  }

  private static getOpacity(graph: mxgraph.mxGraph, cell: mxgraph.mxCell) {
    const cellStyle = graph.getCellStyle(cell);
    return +cellStyle['opacity'];
  }

  private static setOpacity(graph: mxgraph.mxGraph, cells: mxgraph.mxCell[], opacity: number) {
    graph.setCellStyles('opacity', opacity, cells);
  }

  private static getFixedAspect(graph: mxgraph.mxGraph, cell: mxgraph.mxCell) {
    const cellStyle = graph.getCellStyle(cell);
    return cellStyle['aspect'] == 'fixed';
  }

  public static setFixedAspect(graph: mxgraph.mxGraph, cells: mxgraph.mxCell[], aspect: boolean) {
    graph.setCellStyles('aspect', aspect ? 'fixed' : 'variable', cells);
  }

  public static setImageInfo(graph: mxgraph.mxGraph, cell: mxgraph.mxCell, newImageInfo: IImageInfo) {
    graph.setCellStyles('image', newImageInfo.src, [cell]);
    this.setFixedViewType(graph, [cell], newImageInfo.resizeType);
    this.setOpacity(graph, [cell], newImageInfo.opacity);
    this.setFixedAspect(graph, [cell], newImageInfo.fixedAspect);
  }

  public static getImageInfo(graph: mxgraph.mxGraph, cell: mxgraph.mxCell) {
    const state = graph.view.getState(cell);
    return <IImageInfo>{
      src: state.style.image,
      width: cell?.geometry.width,
      height: cell?.geometry.height,
      opacity: cell ? ImageUpdater.getOpacity(graph, cell) : 100,
      resizeType: cell ? ImageUpdater.getFixedViewType(graph, cell) : FixedViewType.FitPage,
      fixedAspect: cell ? ImageUpdater.getFixedAspect(graph, cell) : true,
      backgroundColor: graph['background']
    };
  }
}
